import React, { useCallback, useMemo } from 'react'
import style from './NavBar.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { linkToPage } from '../utils'
import { Spot, Navigation, Section, Scan } from 'Interfaces/interfaces'
import WrappedSection from 'Containers/WrappedSection'
import logo from './logo.png'

type NavBarProps = RouteComponentProps &
  Navigation & {
    subSections: Section[]
    scan: Scan
  }

const NavBar: React.FC<NavBarProps> = ({ spots, page, match, subSections, scan }) => {
  const shouldRenderDropdown = useCallback(
    (spot: Spot) => {
      return subSections.some(subsection => subsection.name === spot.name)
    },
    [subSections],
  )

  const renderDropdown = useCallback(
    (spot: Spot) => {
      return subSections
        .filter(s => s.name === spot.name)
        .map(s => (
          <WrappedSection
            spot={spot}
            type={s.sectionType}
            key={s.id}
            section={s}
            scan={scan}
            page={page}
            match={match}
          />
        ))
    },
    [subSections, scan, page, match],
  )

  const renderLink = useCallback(
    (spot: Spot) => {
      return (
        <a
          key={spot.id}
          href={linkToPage(spot, match)}
          className={`${style.listItem} ${spot.linkedPageId === page.id ? style.activeNav : ''}`}
        >
          <span>{spot.name}</span>
        </a>
      )
    },
    [match, page],
  )

  const renderSpot = useCallback(
    (spot: Spot) => {
      return shouldRenderDropdown(spot) ? renderDropdown(spot) : renderLink(spot)
    },
    [shouldRenderDropdown, renderDropdown, renderLink],
  )

  const renderSpots = useMemo(() => spots.map(renderSpot), [spots, renderSpot])

  return (
    <div className={style.root}>
      <div className={style.menuLinks}>
        <a className={style.logo}>
          <img alt="Megogo" data-selenium="site-logo" src={logo} />
        </a>
        {renderSpots}
      </div>
    </div>
  )
}

export default withRouter(NavBar)
