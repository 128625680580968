import React from 'react'
import PropTypes from 'prop-types'
import WrappedSection from 'Containers/WrappedSection'
import { Spinner } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { getScrollingSpotId, getGetMerchandizingSpotsById } from 'Selectors'
import style from './Page.module.scss'

function AmcTheatres(props) {
  const { page, scan, rootSections, selectedSpotId, selectedSpot } = props

  return (
    <div className={style.page}>
      <div>
        {selectedSpotId && !selectedSpot && (
          <div className={style.transparentBackground}>
            <div className={style.transparentSpinner}>
              <Spinner />
            </div>
          </div>
        )}
      </div>
      {rootSections.valueSeq().map((s, i) => (
        <React.Fragment key={s.id}>
          <WrappedSection type={s.sectionType} key={s.id} scan={scan} page={page} section={s} index={i} />
        </React.Fragment>
      ))}
    </div>
  )
}

AmcTheatres.propTypes = {
  rootSections: PropTypes.object,
  page: PropTypes.object,
  scan: PropTypes.object,
  selectedSpotId: PropTypes.string,
  selectedSpot: PropTypes.object,
}

const mapStateToProps = state => {
  const selectedSpotId = getScrollingSpotId(state)
  const selectedSpot = getGetMerchandizingSpotsById(state, {
    id: selectedSpotId,
  })
  return {
    selectedSpotId,
    selectedSpot,
  }
}

export default connect(mapStateToProps)(AmcTheatres)
