import React from 'react'
import style from './Item.module.scss'
import { MetadataPrice } from 'Interfaces/interfaces'

type RenderItemProp = {
  price: MetadataPrice
  availability: string
}

const RenderItemBadge: React.FC<RenderItemProp> = ({ price, availability }) => {
  if (price) {
    return <span className={style.priceBadge}>{`${price.priceType} ${price.price} ${price.amountCurrency}`}</span>
  }

  if (availability) {
    return <span className={style.availability}>{availability}</span>
  }
  return null
}

export default RenderItemBadge
