import React from 'react'
import cx from 'classnames'
import assignIn from 'lodash.assignin'
import styles from './styles.module.scss'

const GhostPrice = ({ client }) => {
  const classNames = cx(styles.priceGhostRow, {
    [styles.seasonPrice]: client && client.titleTypeSeason,
  })

  const variants = assignIn({ hd: true, sd: true }, client.variants)

  const buyColumnClasses = cx(styles.priceColumn, styles.buyColumn, {
    [styles.extendPriceItemFullWidth]: client.titleTypeSeason,
  })

  return (
    <div className={classNames}>
      <div className={buyColumnClasses}>
        {Object.keys(variants)
          .filter(key => variants[key])
          .map(key => (
            <div key={key} className={styles.priceItem}></div>
          ))}
      </div>
      {client && !client.titleTypeSeason && (
        <div className={cx(styles.priceColumn, styles.rentColumn)}>
          {Object.keys(variants)
            .filter(key => variants[key])
            .map(key => (
              <div key={key} className={styles.priceItem}></div>
            ))}
        </div>
      )}
    </div>
  )
}

export default GhostPrice
