import React from 'react'
import style from './Menu.module.scss'
import WrappedSection from 'Containers/WrappedSection'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { linkToPage } from '../utils'
import PropTypes from 'prop-types'

class MenuItem extends React.Component {
  renderSubsections(spot) {
    const { subSections, scan, page } = this.props
    const classnames = cx(style.dropdownItem)

    const isDropdown = subSections.some(subsection => subsection.name === spot.name)
    if (isDropdown) {
      return (
        subSections &&
        subSections.map(
          s =>
            s &&
            s.name === spot.name && (
              <WrappedSection spot={spot} type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />
            ),
        )
      )
    }

    return (
      <li key={spot.id} className={classnames}>
        <a href={linkToPage(spot, this.props.match)}>{spot.name}</a>
      </li>
    )
  }

  renderDropdown() {
    const { spot, subSections, page } = this.props

    const classnames = cx(style.listItem, {
      [style.active]: page.name.includes(spot.name),
    })

    return (
      <Popover
        id="dropdown"
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.LEFT}
        popoverClassName={cx('bp5-popover-display-none full-width-popover')}
        minimal={true}
        content={<div className={style.dropdown}>{subSections && subSections.map(s => this.renderSubsections(s))}</div>}
      >
        <li className={classnames}>
          {spot && (
            <a id={`spot_${spot.id}`} className={style.listItemNav} href={linkToPage(spot, this.props.match)}>
              {spot.name}
            </a>
          )}
        </li>
      </Popover>
    )
  }

  componentDidMount() {
    const element = document.getElementById('dropdown')
    if (element) {
      console.log(element.offsetLeft)
    }
  }

  render() {
    return this.renderDropdown()
  }
}

MenuItem.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
  spot: PropTypes.object,
  match: PropTypes.object,
}

const ConnectedNavbarDropdownWithRouter = withRouter(MenuItem)
export default ConnectedNavbarDropdownWithRouter
