import { AUDIT_ACTIONS, DATA, LINKS, META } from './utils'
import { Map } from 'immutable'
export default function auditActionReducer(state, action) {
  switch (action.type) {
    case 'LOAD_AUDIT_ACTIONS': {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([AUDIT_ACTIONS, DATA], action.auditActions)
          : s.mergeIn([AUDIT_ACTIONS, DATA], action.auditActions)

        s.setIn([AUDIT_ACTIONS, LINKS], action.links)
        s.mergeIn([AUDIT_ACTIONS, META], action.meta)
      })
    }
    case 'LOAD_AUDIT_ACTIONS_PAGE': {
      const { page } = action
      return state.withMutations(s => {
        s.setIn([AUDIT_ACTIONS, META], new Map({ page: page }))
      })
    }
    case 'LOAD_AUDIT_ACTIONS_TOTAL': {
      const { totalCount } = action
      return state.withMutations(s => {
        s.mergeIn([AUDIT_ACTIONS, META], new Map({ totalCount: totalCount }))
      })
    }
    case 'LOAD_AUDIT_ACTION': {
      const { auditAction } = action
      return state.withMutations(s => {
        s.setIn([AUDIT_ACTIONS, DATA, auditAction.id], auditAction)
      })
    }
    case 'UPDATE_AUDIT_ACTION_STATUS': {
      const { status } = action
      return state.withMutations(s => {
        s.mergeIn([AUDIT_ACTIONS, DATA, action.actionId], { status })
      })
    }
    case 'REMOVE_AUDIT_ACTION': {
      const { actionId } = action
      return state.withMutations(s => {
        s.deleteIn([AUDIT_ACTIONS, DATA, actionId])
      })
    }
    default:
      return state
  }
}
