import React from 'react'

import { Route, Switch } from 'react-router-dom'

import { ScanPage } from 'Containers/ScanPage.tsx'
import ArtworkPage from 'Containers/ArtworkPage.tsx'
import OverviewPage from 'Containers/OverviewPage.tsx'
import ContainerPage from 'Containers/ContainerPage.tsx'
import CompetitorsPage from 'Containers/CompetitorsPage.tsx'
import MerchandizingPageAdmin from 'Containers/MerchandizingPage.tsx'
import MpvAnalyticsPage from 'Containers/MpvAnalyticsPage.tsx'
import Nav from 'Containers/Nav'
import AppToaster from 'Components/AppToaster'
import { ClientProvider } from 'Contexts/ClientContext.tsx'
import { matchPath } from 'react-router'
import { Nav as ContainerNav } from 'Components/ContainerPage/Nav'

class Data extends React.Component {
  constructor(props) {
    super(props)
    this.renderMpvAnalyticsPage = this.renderMpvAnalyticsPage.bind(this)
  }
  renderScanPage(props) {
    const {
      match: {
        params: { id, scanId },
      },
    } = props

    return <ScanPage {...props} clientId={id} scanId={scanId} />
  }
  renderArtworkPage(props) {
    const {
      match: {
        params: { id, scanId },
      },
    } = props

    return <ArtworkPage {...props} clientId={id} scanId={scanId} />
  }
  renderOverview(props) {
    const {
      match: {
        params: { id, scanId },
      },
    } = props

    return <OverviewPage {...props} clientId={id} scanId={scanId} key={scanId} />
  }
  renderCompetitorsPage(props) {
    const {
      match: {
        params: { id, scanId },
      },
    } = props

    return <CompetitorsPage {...props} clientId={id} scanId={scanId} competitors />
  }
  renderContainersPage(props) {
    const {
      match: {
        params: { clientId },
      },
    } = props

    return <ContainerPage {...props} clientId={clientId} />
  }
  renderMerchandizingPage(props) {
    const {
      match: {
        params: { clientId, scanId, pageId },
      },
    } = props

    return <MerchandizingPageAdmin key={pageId} {...props} clientId={clientId} scanId={scanId} pageId={pageId} />
  }
  renderMpvAnalyticsPage(props) {
    const {
      match: {
        params: { id, scanId },
      },
    } = props

    return <MpvAnalyticsPage key={scanId} clientId={id} scanId={scanId} {...props} />
  }
  render() {
    return (
      <>
        <AppToaster />
        <ClientProvider>
          <Route
            render={({ location }) =>
              matchPath(location.pathname, { path: '/v2/clients/:id/containers/', strict: false }) ? (
                <ContainerNav />
              ) : (
                <Nav />
              )
            }
          />
          <Switch>
            <Route path="/v2/clients/:id/scans/:scanId/overview" render={this.renderOverview} />
            <Route path="/v2/clients/:id/scans/:scanId/artwork" render={this.renderArtworkPage} />
            <Route path="/v2/clients/:id/scans/:scanId/competitors" render={this.renderCompetitorsPage} />
            <Route path="/v2/clients/:id/scans/:scanId/mpv/analytics" render={this.renderMpvAnalyticsPage} />
            <Route path="/v2/clients/:id/scans/:scanId/" render={this.renderScanPage} />
            <Route path="/v2/clients/:clientId/containers/" render={this.renderContainersPage} />
            <Route
              path="/v2/clients/:clientId/merchandizing/scans/:scanId/pages/:pageId"
              render={this.renderMerchandizingPage}
            />
            <Route path="/v2/merchandizing/scans/:scanId/pages/:pageId" render={this.renderMerchandizingPage} />
            <Route path="/v2/clients/:clientId/merchandizing/scans/:scanId" render={this.renderMerchandizingPage} />
          </Switch>
        </ClientProvider>
      </>
    )
  }
}

export default Data
