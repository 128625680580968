import React from 'react'
import style from './SubMenu.module.scss'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { Spot, PageProps, Match } from 'Interfaces/interfaces'
import { linkToPage } from '../utils'
import cx from 'classnames'

interface SubMenuProps {
  spot: Spot
  spots: Spot[]
  page: PageProps
  match: Match
}

const DropdownItem: React.FC<{ spot: Spot; match: Match; page: PageProps }> = ({ spot, match, page }) => (
  <li className={style.listItem} key={spot.id}>
    <a href={linkToPage(spot, match)} className={cx('activeLink', spot.linkedPageId === page.id)}>
      <span>{spot.name}</span>
    </a>
  </li>
)

const DropdownList: React.FC<{ spots: Spot[]; match: Match; page: PageProps }> = ({ spots, match, page }) => (
  <ul className={style.dropdownList}>
    {spots
      .filter(s => !!s)
      .map(s => (
        <DropdownItem key={s.id} spot={s} match={match} page={page} />
      ))}
  </ul>
)

const renderDropdown = (spot: Spot, spots: Spot[], match: Match, page: PageProps) => (

  <div className={style.dropdownWrapper}>

    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      position={Position.BOTTOM}
      popoverClassName="bp5-popover-display-none"
      content={<div className={style.dropdown}>
        <DropdownList spots={spots} match={match} page={page} />
      </div>}
    >
      <a href={linkToPage(spot, match)} className={style.dropdownItem} id={`spot_${spot.id}`}>
        {spot.name}
      </a>
    </Popover>
  </div>
)

const SubMenu: React.FC<SubMenuProps> = ({ spot, spots, page, match }) => {
  return renderDropdown(spot, spots, match, page)
}

export default SubMenu
