import React, { useMemo, useRef } from 'react'
import Slider from 'react-slick'
import Item from './Item'
import Arrow from './Arrow'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import { PageItems, Spot } from 'Interfaces/interfaces'
import { RouteComponentProps } from 'react-router-dom'
import style from './Container.module.scss'
import { CanalPlusSectionTypes } from '../enums'
import { renderSpotsWithItem, sliderSettings, computeContainerClasses } from '../helpers'
import { SHIFT_UP, SEE_ALL } from './constants'

type ContainerProps = RouteComponentProps & PageItems

const Container: React.FC<ContainerProps> = ({
  section,
  isMerchandiseFilteringEnabled,
  spots,
  selectedStudios,
  match,
}) => {
  const sliderRef = useRef<Slider>(null)
  const seeMore = useMemo(() => spots.find((spot: Spot) => spot?.label?.toLowerCase() === SEE_ALL), [spots])
  const filteredSpots = useMemo(() => spots.filter((spot: Spot) => spot?.label?.toLowerCase() !== SEE_ALL), [spots])
  const { subtitle, label } = JSON.parse(section.metadata as string)

  const containerClasses = useMemo(() => computeContainerClasses(section, style, 3), [section])

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.containerItem]: section.sectionType === CanalPlusSectionTypes._Container,
        [style.pageContainerItem]: section.sectionType === CanalPlusSectionTypes._PageContainer,
        [style.largeContainerItem]: section.sectionType === CanalPlusSectionTypes._LargeContainer,
        [style.portraitContainerItem]: section.sectionType === CanalPlusSectionTypes._PortraitContainer,
        [style.largePortraitContainerItem]: section.sectionType === CanalPlusSectionTypes._LargePortraitContainer,
        [style.xlContainerItem]: section.sectionType === CanalPlusSectionTypes._XLContainer,
        [style.wideScreenContainerItem]: section.sectionType === CanalPlusSectionTypes._WideScreenContainer,
        [style.chartContainerItem]: section.sectionType === CanalPlusSectionTypes._ChartContainer,
      }),
    [section],
  )

  const renderSpots = renderSpotsWithItem({
    spots: filteredSpots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const spotsPerSlider = {
    [CanalPlusSectionTypes._Container]: 6,
    [CanalPlusSectionTypes._PageContainer]: 9,
    [CanalPlusSectionTypes._LargeContainer]: 4,
    [CanalPlusSectionTypes._LargePortraitContainer]: 7,
    [CanalPlusSectionTypes._PortraitContainer]: 9,
    [CanalPlusSectionTypes._XLContainer]: 3,
    [CanalPlusSectionTypes._WideScreenContainer]: 4,
    [CanalPlusSectionTypes._ChartContainer]: 5,
  }

  return (
    <div
      className={cx(containerClasses, {
        [style.shiftUp]: label === SHIFT_UP,
      })}
    >
      <div className={style.heading}>
        <p className={style.headerText}>
          {section.name}
          {seeMore && <a href={linkToPage(seeMore, match)}>{seeMore.name}</a>}
        </p>
        <p className={style.subtitle}>{subtitle}</p>
      </div>
      <Slider
        ref={sliderRef}
        {...sliderSettings({
          slidesToScroll: spotsPerSlider[section.sectionType],
          slidesToShow: spotsPerSlider[section.sectionType],
          arrow: true,
          swipe: true,
          swipeToSlide: true,
          nextArrow: <Arrow right section={section.sectionType} />,
          prevArrow: <Arrow left section={section.sectionType} />,
        })}
        className={style.alignSpotsLeft}
      >
        {renderSpots}
      </Slider>
    </div>
  )
}

export default withRouter(Container)
