import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

class LeftRight extends React.Component {
  render() {
    const { children, className } = this.props
    return <div className={cx(styles.root, className)}>{children}</div>
  }
}

LeftRight.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
}

export default LeftRight
