import AuditExceptionRecord from 'Records/auditExceptionRecord'
import { Map, OrderedMap, fromJS } from 'immutable'
import { apiRequest, readEndpoint } from 'Actions/apiActions'

import { LOAD_AUDIT_EXCEPTIONS, LOAD_AUDIT_EXCEPTION, REMOVE_AUDIT_EXCEPTION } from 'Reducers/app/auditExceptionReducer'
import queryString from 'query-string'

export const loadAuditExceptions = (auditExceptions, reset) => ({
  type: LOAD_AUDIT_EXCEPTIONS,
  auditExceptions,
  reset,
})

export const loadAuditException = auditException => ({
  type: LOAD_AUDIT_EXCEPTION,
  auditException,
})

export const removeAuditException = auditExceptionId => ({
  type: REMOVE_AUDIT_EXCEPTION,
  auditExceptionId,
})

export const deleteAuditException = (auditException, audit) => dispatch => {
  dispatch(
    apiRequest(`audit_exceptions/${auditException.id}`, 'DELETE', {
      id: auditException.id,
      // FIXME used only for caching, remove once not needed
      auditId: audit.id,
    }),
  ).then(() => {
    dispatch(removeAuditException(auditException.id))
  })
}

export const retrieveAuditExceptionsForTitleAudits = (scanId, titleAuditsIds) => dispatch => {
  const options = {
    'filter[title_audits]': titleAuditsIds.join(','),
    'filter[scan_id]': scanId,
  }

  const query = queryString.stringify(options)

  return readEndpoint('audit_exceptions?' + query).then(response => {
    if (!response.auditExceptions) return

    const auditExceptions = response.auditExceptions.reduce((memo, item) => {
      return memo.set(item.id, new AuditExceptionRecord({ ...item }))
    }, new OrderedMap())

    dispatch(loadAuditExceptions(auditExceptions))
  })
}

export const auditExceptionRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new AuditExceptionRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    }),
  )
}
