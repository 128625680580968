import React, { useState, useMemo, useCallback } from 'react'
import Slider from 'react-slick'
import Item from './ChartItem'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { linkToPage } from '../utils'
import { PageItems, Spot } from 'Interfaces/interfaces'
import style from './ChartContainerWithBackground.module.scss'
import { renderSpotsWithItem, sliderSettings } from '../helpers'
import shopIcon from './shop.svg'
import ownIcon from './own.svg'
import icon12 from './12.png'
import icon15 from './15.png'
import icon18 from './18.png'
import iconPg from './pg.png'
import iconU from './u.png'
import Arrow from './Arrow'
import { ADDS_ICON } from '../constants'
import { Icon } from '@blueprintjs/core'

type Props = RouteComponentProps & PageItems

const renderRating = (rating: number) => {
  const stars = []
  for (let i = 1; i <= 5; i++) {
    stars.push(<Icon key={i} icon="star" color={`${i <= rating ? 'gold' : 'white'}`} size={22} />)
  }
  return stars
}

const renderOfferIcon = (icon: string, offerIcons: Record<string, string>) => {
  if (icon && Object.keys(offerIcons).includes(icon)) {
    return <img className={style.shopIcon} src={offerIcons[icon]} width="24" height="24" />
  }
  return icon === ADDS_ICON ? <span className={style.offerIcon}>ads</span> : null
}

const renderCurrentRating = (currentRating: string, ratingIcons: Record<string, string>) => {
  return currentRating && Object.keys(ratingIcons).includes(currentRating) ? (
    <img className={style.ratingIcon} src={ratingIcons[currentRating]} width="33" height="33" />
  ) : (
    <span className={style.currentRating}>{currentRating}</span>
  )
}

const ratingIcons = {
  '12': icon12,
  '15': icon15,
  '18': icon18,
  PG: iconPg,
  U: iconU,
}

const offerIcons = {
  OFFER_ICON: shopIcon,
  ENTITLED_ICON: ownIcon,
}

const ChartContainerWithBackground: React.FC<Props> = ({
  selectedStudios,
  section,
  isMerchandiseFilteringEnabled,
  spots,
  match,
}) => {
  const [selectedSpot, setSelectedSpot] = useState<Spot | null>(null)

  const renderSpots = renderSpotsWithItem({
    spots: spots.filter((spot: Spot) => spot),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.item,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
      setSelectedSpot: setSelectedSpot,
    },
  })

  const metadata = useMemo(() => (selectedSpot ? selectedSpot.metadata : {}), [selectedSpot])
  const { coverUrl, variant, imdbRating, season, icon, currentRating, subtitle } = metadata

  const handleShadowBoxClick = useCallback(() => {
    window.location.href = linkToPage(selectedSpot, match)
  }, [selectedSpot, match])

  return (
    <>
      <h1 className={style.sectionHeader}>{section.name}</h1>
      <div
        className={style.container}
        style={{
          backgroundColor: 'black',
          backgroundImage: `url(${coverUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '100% 100%',
          backgroundSize: 'cover',
        }}
      >
        <div className={style.shadowBox} onClick={handleShadowBoxClick}>
          <div className={style.details}>
            {selectedSpot && (
              <>
                {variant ? (
                  <img className={style.logo} src={variant} />
                ) : (
                  <span className={style.title}>{selectedSpot.name}</span>
                )}
                <div className={style.ratingWrapper}>
                  {imdbRating && <span className={style.ratingStars}>{renderRating(Math.round(imdbRating))}</span>}
                  {renderCurrentRating(currentRating, ratingIcons)}
                  <span className={style.genre}>{selectedSpot.genre}</span>
                </div>
                <span className={style.season}>{season}</span>
                <div className={style.detailsBody}>
                  {renderOfferIcon(icon, offerIcons)}
                  {icon === ADDS_ICON && <span className={style.offerIcon}>ads</span>}
                  <span className={style.description}>{selectedSpot.description}</span>
                </div>
                {subtitle && <button className={style.subtitle}>{subtitle}</button>}
              </>
            )}
          </div>
          <div className={style.sliderContainer}>
            <Slider
              {...sliderSettings({
                slidesToScroll: 4,
                focusOnSelect: true,
                slidesToShow: 4,
                swipe: true,
                nextArrow: <Arrow right />,
                prevArrow: <Arrow left />,
              })}
            >
              {renderSpots}
            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(ChartContainerWithBackground)
