import { Record, Map } from 'immutable'

export default class LocationCandidateRecord extends Record({
  id: 0,
  bestMatch: false,
  score: 0,
  url: '',
  title: '',
  artworkUrl: '',
  platformTitleId: '',
  expired: false,
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
  selected: false,
}) {}
