import { useEffect } from 'react'
import { Dialog } from '@blueprintjs/core'
import { useClientContext } from 'Contexts/ClientContext'
import consumer from 'cable'
import cx from 'classnames'
import styles from './DownloadModal.module.scss'
import { Icon } from '@blueprintjs/core'
import moment from 'moment'
import { useDownloads, DownloadStates } from 'Hooks/useDownloads'

interface DownloadModalProps {
  isOpen: boolean
  onClose: () => void
}

export const statusLabelsMap = {
  [DownloadStates.Complete]: 'Downloaded',
  [DownloadStates.InProgress]: 'Downloading',
  [DownloadStates.Initialized]: 'Downloading',
  [DownloadStates.Cancelled]: 'Cancelled',
  [DownloadStates.Error]: 'Error',
}

const DownloadModal = ({ isOpen, onClose }: DownloadModalProps) => {
  const { clientId } = useClientContext()
  const { fetchDownloads, scanDownloads, hasDownloadFinshed, cancel, updateDownloads, handleDownload } = useDownloads()

  const configureCableSubscription = () => {
    try {
      consumer.subscriptions.create(
        { channel: 'ScanDownloadChannel', client_id: clientId },
        {
          received(payload) {
            updateDownloads(payload.scanDownload)
            hasDownloadFinshed(payload.scanDownload)
          },
        },
      )
    } catch (error) {
      console.error('Error handling WebSocket subscription:', error)
    }
  }

  useEffect(() => {
    if (!clientId || !isOpen) return

    fetchDownloads()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, isOpen])

  useEffect(() => {
    configureCableSubscription()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (


    <Dialog className={styles.dialogContainer} isOpen={isOpen}>
      <div className="bp5-dialog-header">
        <h5 className="bp5-heading">Downloads</h5>
        <button onClick={onClose} aria-label="Close" className="bp5-dialog-close-button bp5-button bp5-minimal bp5-icon-cross"></button>
      </div>
      <div className="bp5-dialog-body">
        <table className={cx('bp5-html-table bp5-html-table-striped bp5-compact', styles.table, styles.customStripedTable)}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Report Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {scanDownloads.map(download => (
              <tr key={download.id}>
                <td>{moment(download.createdAt).format('MMMM Do YYYY')}</td>
                <td>{download.name}</td>
                <td>
                  <span className={cx('bp5-tag', styles.status, styles[download.status])}>
                    {statusLabelsMap[download.status]}
                  </span>
                </td>
                <td>
                  {[DownloadStates.Initialized, DownloadStates.InProgress].includes(download.status) && (
                    <Icon onClick={() => cancel(download)} icon="cross" className={styles.statusIcon} />
                  )}
                  {DownloadStates.Complete === download.status && (
                    <Icon className={styles.statusIcon} onClick={() => handleDownload(download)} icon="import" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Dialog>
  )
}

export default DownloadModal
