import React, { ReactNode, useEffect, useMemo, createContext, useContext } from 'react'
import { matchPath, useLocation } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { retrieveClient, retrieveCurrentClient } from 'Actions/clientActions'
import { Client } from 'Records/clientRecord'
import { getClient } from 'Selectors'

export interface ClientProviderProps {
  children: ReactNode
}

export interface ClientContextValue {
  client: Client
  clientId: () => string
  isAdminRoute: any
}

const ClientContext = createContext<ClientContextValue>({
  client: null,
  clientId: () => '',
  isAdminRoute: false,
})

const ClientContextProvider = ClientContext.Provider

export const ClientProvider = ({ children }: ClientProviderProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const client = useSelector(state => getClient(state))

  const isAdminRoute: any = useMemo(() => {
    const clientScanPath = matchPath(location.pathname, { path: '/v2/clients/:id', strict: false })
    const merchandizingPath = matchPath(location.pathname, { path: '/v2/merchandizing/scans/:id', strict: false })

    return clientScanPath || merchandizingPath
  }, [location])

  const clientId = useMemo(() => {
    if (isAdminRoute) {
      return isAdminRoute.params.id
    } else {
      return client.id || ''
    }
  }, [isAdminRoute, client.id])

  useEffect(() => {
    if (!clientId) {
      if (!isAdminRoute) {
        dispatch(retrieveCurrentClient())
      }
      return
    }

    if (isAdminRoute) {
      dispatch(retrieveClient(clientId))
    } else {
      dispatch(retrieveClient(clientId))
    }
  }, [clientId, dispatch, isAdminRoute])

  const value = {
    client,
    clientId,
    isAdminRoute: !!isAdminRoute,
  }

  return <ClientContextProvider value={value}>{children}</ClientContextProvider>
}

export const useClientContext = () => {
  return useContext(ClientContext)
}
