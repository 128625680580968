import { TITLES, FILTERS } from './utils'

export const TOGGLE_MOVIE_FILTER = 'titles/TOGGLE_MOVIE_FILTER'
export const TOGGLE_SERIES_FILTER = 'titles/TOGGLE_SERIES_FILTER'
export const TOGGLE_ALL_FILTER = 'titles/TOGGLE_ALL_FILTER'
export const TOGGLE_ENABLED_FILTER = 'titles/TOGGLE_ENABLED_FILTER'

export const STATE_FILTER_SET = 'titles/STATE_FILTER_SET'
export const STATE_FILTER_CLEAR = 'titles/STATE_FILTER_CLEAR'

export const UPDATE_TITLE_QUERY = 'titles/UPDATE_TITLE_QUERY'
export const CLEAR_TITLE_QUERY = 'titles/CLEAR_TITLE_QUERY'
export const SET_LAST_USED_QUERY = 'titles/SET_LAST_USED_QUERY'
export const SET_LETTER_FILTER = 'titles/SET_LETTER_FILTER'
export const SET_NEW_CANDIDATES_FILTER = 'titles/SET_NEW_CANDIDATES_FILTER'
export const ENABLE_PROMOTED_FILTER = 'titles/ENABLE_PROMOTED_FILTER'
export const ENABLE_PRIORITY_FILTER = 'titles/ENABLE_PRIORITY_FILTER'
export const DISABLE_PROMOTED_FILTER = 'titles/DISABLE_PROMOTED_FILTER'

export default function titleFilterReducer(state, action) {
  switch (action.type) {
    case TOGGLE_MOVIE_FILTER: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.enableMovies())
    }

    case TOGGLE_SERIES_FILTER: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.enableTv())
    }

    case TOGGLE_ALL_FILTER: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.enableAll())
    }

    case TOGGLE_ENABLED_FILTER: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.toggleEnabledFilter())
    }

    case STATE_FILTER_CLEAR: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.setStateFilter(''))
    }
    case STATE_FILTER_SET: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.setStateFilter(action.state))
    }
    case SET_NEW_CANDIDATES_FILTER: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.setNewCandidatesFilter())
    }

    case UPDATE_TITLE_QUERY: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.updateTitleQuery(action.query))
    }

    case SET_LETTER_FILTER: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.setLetterFilter(action.letter))
    }

    case CLEAR_TITLE_QUERY: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.clearTitleQuery())
    }

    case SET_LAST_USED_QUERY: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.setLastUsedQuery(action.query))
    }
    case ENABLE_PROMOTED_FILTER: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.enablePromoted())
    }
    case ENABLE_PRIORITY_FILTER: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.enablePriority())
    }
    case DISABLE_PROMOTED_FILTER: {
      const filters = state.getIn([TITLES, FILTERS])
      return state.setIn([TITLES, FILTERS], filters.disablePromoted())
    }
    default:
      return state
  }
}
