import React from 'react'
import style from './Menu.module.scss'
import WrappedSection from 'Containers/WrappedSection'
import { withRouter } from 'react-router-dom'
import pathToRegexp from 'path-to-regexp'
import { linkToPage } from '../utils'
import { connect } from 'react-redux'
import logo from './logo.png'

class Menu extends React.Component {
  rootPageId() {
    const { associatedMerchandizingScan } = this.props

    return associatedMerchandizingScan && associatedMerchandizingScan.rootPageId
  }

  rootPageUrl() {
    const { match } = this.props
    const { path } = match
    const rootPageId = this.rootPageId()

    const toPath = pathToRegexp.compile(path)
    return rootPageId && toPath({ ...match.params, pageId: rootPageId })
  }

  renderLink = spot => {
    const { subSections, scan, page, match } = this.props

    const isDropdown = subSections.some(subsection => subsection.name === spot.name)
    if (isDropdown) {
      return (
        subSections &&
        subSections.map(
          s =>
            s &&
            s.name === spot.name && (
              <WrappedSection spot={spot} type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />
            ),
        )
      )
    }
    return (
      spot && (
        <a href={linkToPage(spot, match)} className={style.listItem}>
          {spot.name}
        </a>
      )
    )
  }

  renderSpots() {
    const { spots } = this.props
    return spots && spots.map(s => s && this.renderLink(s))
  }

  render() {
    return (
      <div className={style.root}>
        <div className={style.wrapper}>
          <a href={this.rootPageUrl()} className={style.logo}>
            <img alt="Kaleidescape" data-selenium="site-logo" src={logo} />
          </a>
          <div className={style.mediaNav}>{this.renderSpots()}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const associatedMerchandizingScan = ownProps.scan.associatedMerchandizingScan(state)

  return {
    associatedMerchandizingScan,
  }
}

const ConnectedNavbarMenu = connect(mapStateToProps)(Menu)
export default withRouter(ConnectedNavbarMenu)
