import React from 'react'
import Item from './Item'
import style from './Grid.module.scss'

function renderSpots(section, spots) {
  return spots.map(spot => {
    return (
      spot && (
        <div className={style.item} key={spot.id}>
          <Item spot={spot} section={section} />
          <h5>{spot.name}</h5>
        </div>
      )
    )
  })
}

function Grid(props) {
  const { section, spots } = props
  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h2>{section.name}</h2>
      </div>
      <div className={style.spotWrapper}>{renderSpots(section, spots)}</div>
    </div>
  )
}

export default Grid
