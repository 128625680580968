import React from 'react'
import style from './TwinHeroMenu.module.scss'
import WrappedSection from 'Containers/WrappedSection'

class TwinHeroMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: this.firstSpot(),
      section: this.firstSection(),
    }
  }

  setSubsection = spot => {
    this.setState({ name: spot.name })
    spot.position === 0
      ? this.setState({ section: this.firstSection() })
      : this.setState({ section: this.secondSection() })
  }

  render() {
    return (
      <div className={style.section}>
        <div className={style.heading}>
          <h2>{this.state.name}</h2>
        </div>
        <div className={style.menu}>
          <ul className={style.menuItems}>
            {this.links().map(
              spot =>
                spot && (
                  <li key={spot.id} onClick={() => this.setSubsection(spot)} className={style.item}>
                    {spot.name}
                  </li>
                ),
            )}
          </ul>
        </div>
        {this.renderSubSections(this.state.section)}
      </div>
    )
  }

  renderSubSections(section) {
    const { scan, page } = this.props
    return <WrappedSection type={section.sectionType} key={section.id} section={section} scan={scan} page={page} />
  }

  firstSection() {
    return this.props.subSections.first()
  }

  secondSection() {
    return this.props.subSections.find(section => section && section.position === 2)
  }

  firstSpot() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'link').first().name
  }

  links() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'link')
  }
}

export default TwinHeroMenu
