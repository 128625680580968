import { Record } from 'immutable'

interface LooperRecordProps {
  [key: string]: any
}

export function LooperRecord<T extends LooperRecordProps>(defaultValues: T) {
  return class extends Record(defaultValues) {
    constructor(values?: Partial<T>) {
      super(values)
    }
    get<K extends keyof T>(value: K): T[K] {
      return super.get(value as any)
    }
  }
}
