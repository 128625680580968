import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'

import rootReducer from '../reducers/'

export default function configureStore() {
  /* eslint-disable no-underscore-dangle */
  /* eslint-disable no-undef */
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsBlacklist: [],
      })
    : compose
  /* eslint-enable */

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer))

  return store
}
