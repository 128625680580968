import { captureConsoleIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [captureConsoleIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    normalizeDepth: 10,
  })
}

export default initSentry
