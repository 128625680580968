import React from 'react'
import style from './Item.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { linkToPage, scrollToNode } from '../utils'

class Item extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  renderItemWithName() {
    const { spot, studio, isMerchandiseFilteringEnabled, section, match } = this.props

    const classNames = cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !studio),
      [style.highlight]: spot.selected || studio,
    })

    const imageStyle = {
      background: `black url(${spot.artworkUrl}) center center no-repeat`,
      backgroundSize: '100% 100%',
      display: 'block',
    }

    const itemStyle = {}
    if (studio) {
      itemStyle.borderStyle = 'solid'
      itemStyle.borderColor = studio.color
      itemStyle.boxShadow = `0 0 10px ${studio.color}`
    }

    const target = spot && spot.linkedPageId ? null : '_blank'
    const url = linkToPage(spot, match) || (spot && spot.url)

    return spot.spotType === 'link' ? (
      <a target={target} href={url} style={itemStyle} className={classNames} ref={node => (this.node = node)}>
        <div id={`spot_${spot.id}`} style={imageStyle} className={style.itemImageContainer} />
        <div className={style.itemDescriptionContainer}>{spot.name}</div>
      </a>
    ) : (
      <div style={itemStyle} className={classNames} ref={node => (this.node = node)}>
        <div id={`spot_${spot.id}`} style={imageStyle} className={style.itemImageContainer} />
        <div className={style.itemDescriptionContainer}>{spot.name}</div>
      </div>
    )
  }

  renderItemWithoutName() {
    const { spot, studio, isMerchandiseFilteringEnabled, section, match } = this.props

    const classNames = cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !studio),
      [style.highlight]: spot.selected || studio,
    })

    const inlineStyle = {
      background: `black url(${spot.artworkUrl}) center center no-repeat`,
      backgroundSize: '100% 100%',
      display: 'block',
    }

    if (studio) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = studio.color
      inlineStyle.boxShadow = `0 0 10px ${studio.color}`
    }

    const target = spot && spot.linkedPageId ? null : '_blank'
    const url = linkToPage(spot, match) || (spot && spot.url)

    return spot.spotType === 'link' ? (
      <a
        id={`spot_${spot.id}`}
        className={classNames}
        style={inlineStyle}
        target={target}
        href={url}
        ref={node => (this.node = node)}
      />
    ) : (
      <div id={`spot_${spot.id}`} className={classNames} style={inlineStyle} ref={node => (this.node = node)} />
    )
  }

  render() {
    const { showTitleName } = this.props

    return showTitleName ? this.renderItemWithName() : this.renderItemWithoutName()
  }
}

Item.propTypes = {
  section: PropTypes.object,
  spot: PropTypes.object,
  match: PropTypes.object,
  studio: PropTypes.bool,
  isMerchandiseFilteringEnabled: PropTypes.bool,
  showTitleName: PropTypes.bool,
}

export default withRouter(Item)
