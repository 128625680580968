import { CHANNELS } from './utils'

export const LOAD_CHANNELS = 'channels/LOAD'

export default function channelReducer(state, action) {
  switch (action.type) {
    case LOAD_CHANNELS: {
      return state.withMutations(s => {
        action.reset ? s.setIn([CHANNELS], action.channels) : s.mergeIn([CHANNELS], action.channels)
      })
    }
    default:
      return state
  }
}
