import { readEndpoint, apiRequest } from 'Actions/apiActions'

import { Map, OrderedMap, fromJS } from 'immutable'
import TitleRecord from 'Records/titleRecord'
import {
  getClient,
  getTitleFilters,
  getSelectedScan,
  getSelectedTerritories,
  getSelectedPlatforms,
  getTitlesPage,
} from 'Selectors'

import { setLastUsedQuery } from 'Actions/titleFilterActions'
import { normalizeAndLoadPlatformTitles } from 'Actions/platformTitleActions'
import { UPDATE_TITLE } from 'Reducers/app/titleReducer'
import queryString from 'query-string'

export const titleRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new TitleRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    }),
  )
}

export const loadTitles = (titles, reset, hasMoreToLoad = true) => ({
  type: 'LOAD_TITLES',
  titles,
  reset,
  hasMoreToLoad,
})

export const loadTitlesPage = (page = 1) => ({
  type: 'LOAD_TITLES_PAGE',
  page,
})

export const reloadTitles = () => dispatch => {
  dispatch(retrieveTitles())
}

export const filterTitles = item => item.type === 'titles'

export const normalizeAndLoadTitles = (data, reset) => {
  const titles = data.reduce((memo, item) => {
    return memo.set(item.id, new TitleRecord({ ...item }))
  }, new OrderedMap())

  return loadTitles(titles, reset)
}

const loadTitlesResponse = (data, reset, loadTitlesResponse) => dispatch => {
  const titles = data.reduce((memo, item) => {
    return memo.set(
      item.id,
      new TitleRecord({
        ...item,
        platformTitlesIds: item.platformTitles.map(pt => pt.id),
      }),
    )
  }, new OrderedMap())
  const platformTitles = data
    .map(t => t.platformTitles)
    .flat()
    .filter(pt => !!pt)

  dispatch(loadTitles(titles, reset, loadTitlesResponse))
  dispatch(normalizeAndLoadPlatformTitles(platformTitles, reset))
}

export const searchForTitles = (query = '', titleIds) => (_dispatch, getState) => {
  const state = getState()
  const client = getClient(state)
  const scan = getSelectedScan(state)
  const url = `clients/${client.id}/titles?`
  const filters = getTitleFilters(state).toParams(state)

  const options = {
    ...filters,
    limit: 20,
    'filter[query]': query,
    'filter[scan_id]': scan.id,
    'filter[ids]': titleIds?.join(','),
  }

  const queryParams = queryString.stringify(options)

  return readEndpoint(url + queryParams).then(response => {
    return response
  })
}

export const retrieveTitles = (page = 1) => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)
  const filters = getTitleFilters(state).toParams(state)

  const options = {
    ...filters,
    'filter[platforms]': getSelectedPlatforms(state)
      .valueSeq()
      .map(p => p.id)
      .toArray()
      .join(','),
    'filter[territories]': getSelectedTerritories(state)
      .valueSeq()
      .map(t => t.id)
      .toArray()
      .join(','),
    limit: 20,
    page: page,
  }

  const queryParams = queryString.stringify(options)
  dispatch(setLastUsedQuery(getTitleFilters(state).query))
  dispatch(loadTitlesPage(page))
  return readEndpoint(`clients/${client.id}/titles?` + queryParams).then(response => {
    const hasMoreToLoad = response.titles.length >= 20
    dispatch(loadTitlesResponse(response.titles, true, hasMoreToLoad))
  })
}

export const retrieveNextTitles = () => (dispatch, getState) => {
  const state = getState()
  const currentTitlesPage = getTitlesPage(state) || 0

  dispatch(retrieveTitles(currentTitlesPage + 1))
}

export const retrievePrevTitles = () => (dispatch, getState) => {
  const state = getState()
  const currentTitlesPage = getTitlesPage(state) || 0

  if (currentTitlesPage <= 1) return Promise.resolve()

  dispatch(retrieveTitles(currentTitlesPage - 1))
}

export const updateTitle = (title, attributes) => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)

  return dispatch(
    apiRequest(`clients/${client.id}/titles/${title.id}`, 'PUT', {
      title: {
        name: attributes.name || title.name,
        name_stem: attributes.nameStem || title.nameStem,
      },
    }),
  ).then(() => {
    dispatch({
      type: UPDATE_TITLE,
      titleId: title.id,
      attributes,
    })
  })
}

export const relocateTitle = titleId => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)

  const url = `clients/${client.id}/titles/relocate`
  const filters = getTitleFilters(state).toBody(state)
  const body = {
    title_id: titleId,
    filter: {
      ...filters,
      platforms: getSelectedPlatforms(state)
        .valueSeq()
        .map(p => p.id)
        .toArray()
        .join(','),
    },
  }

  return dispatch(apiRequest(url, 'POST', body))
}

export const retryUnavailables = titleId => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)

  const url = `clients/${client.id}/titles/retry_unavailable_titles`

  const filters = getTitleFilters(state).toBody(state)
  const body = {
    title_id: titleId,
    filter: {
      ...filters,
      platforms: getSelectedPlatforms(state)
        .valueSeq()
        .map(p => p.id)
        .toArray()
        .join(','),
    },
  }

  return dispatch(apiRequest(url, 'POST', body))
}

export const bulkApprove = titleId => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)

  const url = `clients/${client.id}/titles/bulk_approve`

  const filters = getTitleFilters(state).toBody(state)
  const body = {
    title_id: titleId,
    filter: {
      ...filters,
      platforms: getSelectedPlatforms(state)
        .valueSeq()
        .map(p => p.id)
        .toArray()
        .join(','),
    },
  }

  return dispatch(apiRequest(url, 'POST', body))
}

export const refreshTitleArtworks = titleId => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)

  const url = `clients/${client.id}/titles/refresh_amazon_artworks`

  const filters = getTitleFilters(state).toBody(state)
  const body = {
    title_id: titleId,
    filter: {
      ...filters,
      platforms: getSelectedPlatforms(state)
        .valueSeq()
        .map(p => p.id)
        .toArray()
        .join(','),
    },
  }

  return dispatch(apiRequest(url, 'POST', body))
}
