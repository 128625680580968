import React from 'react'
import style from './Item.module.scss'
import { withRouter } from 'react-router-dom'
import { linkToPage, scrollToNode } from '../utils'
import cx from 'classnames'
class Item extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  isSeeMoreSpot() {
    const { spot } = this.props
    return spot.name && spot.name.includes('SEE ALL')
  }

  render() {
    const { spot, section, match } = this.props

    const classNames = cx(style.item, {
      [style.fade]: section.selected,
      [style.highlight]: spot.selected,
    })

    return (
      <a href={linkToPage(spot, match)} className={classNames} ref={node => (this.node = node)}>
        <div
          id={`spot_${spot.id}`}
          style={{
            background: `black url(${spot.artworkUrl}) center center no-repeat`,
            backgroundSize: 'cover',
          }}
        >
          {this.isSeeMoreSpot() && (
            <div className={style.seeMoreItem}>
              <span className={style.seeMoreWrapper}>
                <i className={style.rightArrow} />
              </span>
              <span className={style.seeMoreLabel}>SEE MORE</span>
            </div>
          )}
        </div>
      </a>
    )
  }
}

export default withRouter(Item)
