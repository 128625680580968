import { PRICES } from './utils'

export default function priceReducer(state, action) {
  switch (action.type) {
    case 'LOAD_PRICES': {
      return state.withMutations(s => {
        action.reset ? s.setIn([PRICES], action.prices) : s.mergeIn([PRICES], action.prices)
      })
    }
    default:
      return state
  }
}
