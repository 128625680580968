import { useMemo } from 'react'
import { OrderedMap } from 'immutable'
import Dinero from 'dinero.js'
import { matchPath, useLocation } from 'react-router-dom'
import getSymbolFromCurrency from 'currency-symbol-map'

export const humanize = str => {
  const column = str.split('.')[1]
  let frags = column ? column.split('_') : str.split('_')
  frags = frags.map(frag => frag.charAt(0).toUpperCase() + frag.slice(1))
  return frags.join(' ')
}

export const pluralize = (s, n) => {
  if (n === 1) return s
  if (s.endsWith('s')) return `${s}es`
  if (s.endsWith('y')) return `${s.slice(0, -1)}ies`
  return `${s}s`
}

export const getPageIdFromRootPageUrl = rootPageUrl => /pages\/([\w-]*)$/.exec(rootPageUrl)[1]

// checks if platform title is in valid state
export const isValidPlatformTitle = platformTitle =>
  platformTitle.approved || platformTitle.virtual || platformTitle.artworkRefreshError

// check if platform title is in state in which we show title url to the client
export const isValidPlatformTitleToShowUrl = platformTitle =>
  platformTitle.approved || platformTitle.artworkRefreshError

export const mergePlatformAndClientPlatofrmTypes = (client, platform) => {
  return {
    tvod: !client.toSeq().isEmpty() && client.platformTypes.tvod && platform && platform.platformTypes.tvod,
    svod: !client.toSeq().isEmpty() && client.platformTypes.svod && platform && platform.platformTypes.svod,
    avod: !client.toSeq().isEmpty() && client.platformTypes.avod && platform && platform.platformTypes.avod,
  }
}

export const sortPlatformGroupsByNameAndTerritory = platformGroups => {
  const sortedKeys = platformGroups.keySeq().sort((a, b) => {
    const upperA = a.toUpperCase()
    const upperB = b.toUpperCase()
    if (upperA < upperB) {
      return -1
    }
    if (upperA > upperB) {
      return 1
    }
    return 0
  })

  const sortedAndFilteredPlatformGroups = sortedKeys.reduce(
    (acc, key) => acc.set(key, platformGroups.get(key)),
    OrderedMap(),
  )

  return sortedAndFilteredPlatformGroups
}

/**
 * Sorts territories with a priority order of 'US' followed by 'CA', and then by name.
 * Territories with ISO codes 'US' and 'CA' are placed before others, and the remaining territories
 * are sorted alphabetically by their names.
 *
 * This function is used as a callback in the sort method to sort territories.
 *
 * @param {Immutable.Map} territoryA - First territory to compare
 * @param {Immutable.Map} territoryB - Second territory to compare
 * @returns {number} - Negative if territoryA should come before territoryB, positive if vice versa, 0 if equal
 */
export const territorySorter = (territoryA, territoryB) => {
  if (territoryA.get('isoCode') === 'US') return -1
  if (territoryB.get('isoCode') === 'US') return 1
  if (territoryA.get('isoCode') === 'CA') return -1
  if (territoryB.get('isoCode') === 'CA') return 1

  return territoryA.get('name') < territoryB.get('name') ? -1 : territoryA.get('name') > territoryB.get('name') ? 1 : 0
}

export const openLinkOnPopup = link => {
  // Popup revents links from working
  window.open(link, '_blank')
}

export const formatAmountCurrency = auditPrice => {
  const currencySymbol = getSymbolFromCurrency(auditPrice.amountCurrency) || auditPrice.amountCurrency
  const amount = Dinero({ amount: auditPrice.amountCents, currency: auditPrice.amountCurrency })
  const formattedAmount = amount.toFormat('0,0.00')
  return `${currencySymbol} ${formattedAmount}`
}

export const useMatchPathHelper = () => {
  const location = useLocation()

  return useMemo(() => {
    return path => matchPath(location.pathname, { path, strict: false })
  }, [location.pathname])
}

export const sortPlatformsByRegionAndName = platforms => {
  return platforms.sort((a, b) => {
    if (a.isoCode === "US" && b.isoCode !== "US") return -1;
    if (a.isoCode !== "US" && b.isoCode === "US") return 1;
    const nameA = a.platformName || a.name;
    const nameB = b.platformName || b.name;
    return nameA.localeCompare(nameB);
  })
}