import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import { Button } from '@blueprintjs/core'

import styles from './Pagination.module.css'

import { getTitles, getHasMoreTitlesToLoad } from 'Selectors'

import { retrieveNextTitles, retrievePrevTitles } from 'Actions/titleActions'

class Pagination extends React.Component {
  render() {
    const { titlesSize, hasMoreTitlesToLoad } = this.props
    return (
      <nav className={cx(styles.root, 'bp5-navbar')}>
        <div className="bp5-navbar-group bp5-align-left">
          <div className="bp5-button-group">
            <Button className="bp5-icon-chevron-left" onClick={() => this.props.retrievePrevTitlesPage()}>
              Prev
            </Button>
            <Button
              disabled={!hasMoreTitlesToLoad}
              className="bp5-icon-chevron-right"
              onClick={() => this.props.retrieveNextTitlesPage()}
            >
              Next
            </Button>
          </div>
          <div className="bp5-navbar-divider" />
          <div>Showing {titlesSize} titles</div>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => {
  const titles = getTitles(state)
  const hasMoreTitlesToLoad = getHasMoreTitlesToLoad(state)
  return {
    titlesSize: titles.size,
    hasMoreTitlesToLoad,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    retrieveNextTitlesPage: () => dispatch(retrieveNextTitles()),
    retrievePrevTitlesPage: () => dispatch(retrievePrevTitles()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination)
