import React from 'react'
import { connect } from 'react-redux'
import { Cell } from '@blueprintjs/table'

class SpotTableCell extends React.Component {
  render() {
    const { page, attribute, section, studioName, merchandizingPlatformTitle } = this.props
    if (attribute === 'PageNameCell') {
      return (
        <Cell {...this.props}>
          {page?.name}/{section?.name}
        </Cell>
      )
    }
    if (attribute === 'RowCell') {
      return <Cell {...this.props}>{section?.position}</Cell>
    }
    if (attribute === 'PlatformIdentifer') {
      return <Cell {...this.props}>{merchandizingPlatformTitle?.platformIdentifier}</Cell>
    }
    if (attribute === 'StudioCell') {
      return <Cell {...this.props}>{studioName}</Cell>
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const spot = ownProps.spot
  const page = spot?.page(state)
  const section = spot?.section(state)
  const scan = spot?.merchandizingScan(state)
  const merchandizingPlatformTitle = spot?.merchandizingPlatformTitle(state)
  const studioName =
    merchandizingPlatformTitle &&
    !merchandizingPlatformTitle.toSeq().isEmpty() &&
    merchandizingPlatformTitle.studioName(state)

  return {
    page,
    section,
    scan,
    merchandizingPlatformTitle,
    studioName,
  }
}

export default connect(mapStateToProps)(SpotTableCell)
