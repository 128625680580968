import React, { useState } from 'react'
import { Tooltip, Button, Popover, PopoverInteractionKind, Position, Menu, MenuItem } from '@blueprintjs/core'
import DownloadModal from 'Components/DownloadModal/DownloadModal'
import { useClientContext } from 'Contexts/ClientContext'

const HELP_URL = 'https://looperinsightscore.zendesk.com/hc/en-gb'

const RightButtons = () => {
  const { isAdminRoute } = useClientContext()
  const [downloadModalOpen, setDownloadModalOpen] = useState(false)
  const signOut = () => {
    window.location.href = `${process.env.REACT_APP_CORE_API_URL}/logout`
  }

  const handleClickOpenHelp = () => window.open(HELP_URL, '_blank')

  return (

    <div className="bp5-navbar-group bp5-align-right">

      <Tooltip content="Downloads" position={Position.BOTTOM}>
        <Button className="bp5-button bp5-minimal bp5-icon-document" onClick={() => setDownloadModalOpen(true)} />
      </Tooltip>
      {!isAdminRoute && (
        <>
          <span className="bp5-navbar-divider" />

          <Tooltip content="Help centre" position={Position.BOTTOM}>
            <Button className="bp5-button bp5-minimal bp5-icon-help" onClick={handleClickOpenHelp} />
          </Tooltip>
          <span className="bp5-navbar-divider" />

          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.BOTTOM_RIGHT}
            content={<Menu>
              <MenuItem text="Log Out" onClick={signOut} />
            </Menu>}>
            <button className="bp5-button bp5-minimal bp5-icon-user" />
          </Popover>
        </>
      )}
      <DownloadModal isOpen={downloadModalOpen} onClose={() => setDownloadModalOpen(false)} />
    </div>
  )
}

export default RightButtons
