import React from 'react'
import style from './SquareCarousel.module.scss'
import Slider from 'react-slick'
import Item from './Item'
import cx from 'classnames'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

class SquareCarousel extends React.Component {
  render() {
    const { section } = this.props
    const metadata = section.metadata || {}

    const sliderSettings = {
      dots: false,
      infinite: false,
      centerMode: false,
      centerPadding: 0,
      slidesToScroll: 7,
      slidesToShow: 7,
      variableWidth: true,
      arrows: true,
      swipe: false,
      initialSlide: 0,
      nextArrow: <Arrow right />,
      prevArrow: <Arrow left />,
    }
    return (
      <div className={style.container}>
        <div className={style.heading}>
          <h2>{section.name}</h2>
          {metadata.subtitle && <h3>{metadata.subtitle}</h3>}
        </div>
        <Slider {...sliderSettings}>{this.renderSpots()}</Slider>
      </div>
    )
  }

  renderSpots() {
    const { section, isMerchandiseFilteringEnabled } = this.props
    return this.spots().map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <Item
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={this.isStudioSelected(spot.id)}
              section={section}
            />
          </div>
        ),
    )
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.name !== 'See more')
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }
}

export default SquareCarousel
