import React from 'react'
import style from './Header.module.scss'
import Item from '../GenericScan/Item'

function Header({ spots, section }) {
  return (
    <div className={style.root}>
      <ul>
        {spots.map(
          spot =>
            spot && (
              <li key={spot.id}>
                <Item spot={spot} section={section} />
              </li>
            ),
        )}
      </ul>
    </div>
  )
}

export default Header
