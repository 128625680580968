import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router'
import cx from 'classnames'
import moment from 'moment'

import ClientLogo from 'Containers/ClientLogo'
import { getSelectedScan } from 'Selectors'
import { useClientContext } from 'Contexts/ClientContext'
import logo from 'Components/Nav/logo.png'
import styles from 'Components/Nav/styles.module.scss'
import { parseSpotId } from 'Utils/query'
import { findPath } from 'Utils/path'
import NavLinks from 'Components/Nav/NavLinks'
import HistoryPopover from 'Components/Nav/HistoryPopover'
import RightButtons from 'Components/Nav/RightButtons'

const Nav = () => {
  const location = useLocation()
  const history = useHistory()
  const { client, isAdminRoute } = useClientContext()
  const hideNavbar = parseSpotId()
  const pageName = findPath(location.pathname)?.name

  const [isNavOpen, setIsNavOpen] = useState(false)
  const [isHistoryOpen, setIsHistoryOpen] = useState(false)
  const { scan } = useSelector(state => ({
    scan: getSelectedScan(state),
  }))

  const handleHomeClick = () => {
    if (!isAdminRoute) {
      history.push(`/scans/${scan.id}/dashboard`)
      return
    }

    const url = client?.id ? `${process.env.REACT_APP_CORE_API_URL}/clients/${client.id}/scans/${scan.id}` : `${process.env.REACT_APP_CORE_API_URL}/merchandizing/scans`
    window.open(url, '_self')
  }

  if (hideNavbar) return

  return (
    <nav className={cx('bp5-navbar', 'bp5-fixed-top', styles.navBar)}>
      <div className={styles.navigation}>
        <div className="bp5-navbar-group bp5-align-left">
          <a
            style={{
              backgroundImage: `url(${logo})`,
              width: '50px',
              height: '40px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              marginRight: '10px',
            }}
            onClick={handleHomeClick}
            title={isAdminRoute ? 'Home' : 'Dashboard'}
          />
        </div>
        <div className={styles.popoverContainer}>
          {scan?.id && client?.id && <NavLinks open={isNavOpen} onOpenClick={setIsNavOpen} />}
          <h5 onClick={() => setIsNavOpen(!isNavOpen)}>{pageName}</h5>
        </div>
        <div className={styles.popoverContainer}>
          {client.id && <HistoryPopover open={isHistoryOpen} onOpenClick={setIsHistoryOpen} />}
          <h5 onClick={() => setIsHistoryOpen(!isHistoryOpen)}>
            {scan &&
              moment(scan.scheduledAt || scan.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z').format('MMMM Do YYYY')}
          </h5>
        </div>
      </div>
      <ClientLogo height={70}></ClientLogo>
      <RightButtons />
    </nav>
  )
}

export default Nav
