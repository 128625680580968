import pathToRegexp from 'path-to-regexp'
import { parseSpotId } from 'Utils/query'

export const linkToPage = (spot, match) => {
  if (spot && !spot.linkedPageId) {
    return spot.url
  }

  const { path } = match

  const toPath = pathToRegexp.compile(path)
  return spot && toPath({ ...match.params, pageId: spot.linkedPageId })
}

export const linkToRootPage = (scan, match) => {
  const { path } = match

  const toPath = pathToRegexp.compile(path)

  return scan.rootPageId && toPath({ ...match.params, pageId: scan.rootPageId })
}

export const scrollToNode = node => {
  const behavior = parseSpotId() ? 'instant' : 'smooth'
  // Previously this was set to block: 'center', but that was not working in Chrome
  node.scrollIntoView({ behavior, block: 'nearest' })
}