import { combineReducers } from 'redux'
import appReducer from './app'
import uiReducer from './uiReducer'
import { default as csrfReducer } from 'redux-csrf'

const rootReducer = combineReducers({
  app: appReducer,
  ui: uiReducer,
  _csrf: csrfReducer,
})

export default rootReducer
