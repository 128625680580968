import { PLATFORM_TITLES, DATA, META } from './utils'
import { deselectPlatformTitles, removePlatformTitle, updateRecords } from './utils'

export default function platformTitleReducer(state, action) {
  switch (action.type) {
    case 'LOAD_PLATFORM_TITLES': {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([PLATFORM_TITLES, DATA], action.platformTitles)
          : updateRecords(action.platformTitles, s, [PLATFORM_TITLES, DATA])
      })
    }
    case 'MERGE_PLATFORM_TITLES': {
      return state.withMutations(s => {
        updateRecords(action.platformTitles, s, [PLATFORM_TITLES, DATA])
      })
    }
    case 'SELECT_PLATFORM_TITLE': {
      return state.withMutations(s => {
        deselectPlatformTitles({ mutable: s })
        s.setIn([PLATFORM_TITLES, DATA, action.platformTitleId, 'selected'], true)
      })
    }
    case 'EDIT_PLATFORM_TITLE': {
      return state.withMutations(s => {
        s.setIn([PLATFORM_TITLES, DATA, action.platformTitleId, 'editing'], true)
      })
    }
    case 'CANCEL_EDIT_PLATFORM_TITLE': {
      return state.withMutations(s => {
        s.setIn([PLATFORM_TITLES, DATA, action.platformTitleId, 'editing'], false)
      })
    }
    case 'UPDATE_PLATFORM_TITLE': {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([PLATFORM_TITLES, DATA, action.platformTitleId], action.data)
          : s.mergeIn([PLATFORM_TITLES, DATA, action.platformTitleId], action.data)
      })
    }
    case 'DESELECT_PLATFORM_TITLES': {
      return state.withMutations(s => {
        deselectPlatformTitles({ mutable: s })
      })
    }
    case 'UPDATING_STATES': {
      return state.withMutations(s => {
        const updating = action.updating
        s.setIn([PLATFORM_TITLES, META], { updating: updating })
      })
    }
    case 'REMOVE_PLATFORM_TITLE': {
      const { platformTitleId } = action
      return state.withMutations(s => {
        removePlatformTitle({
          mutable: s,
          platformTitleId,
        })
      })
    }
    default:
      return state
  }
}
