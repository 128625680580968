import React from 'react'
import cx from 'classnames'

const TerritoryMenuItem = ({ item, onClick }) => {
  const menuClassnames = cx('bp5-menu-item', {
    'bp5-icon-small-tick': item.selected,
  })

  return (
    <li>
      <a className={menuClassnames} onClick={onClick}>
        {item.name}
        <span className="bp5-menu-item-label">{item.isoCode}</span>
      </a>
    </li>
  )
}

export default TerritoryMenuItem
