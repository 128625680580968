import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Button, Collapse, Tooltip, Position } from '@blueprintjs/core'
import styles from './Download.module.scss'
import cx from 'classnames'
import PlatformAndTerritoryFilter from 'Components/PlatformAndTerritoryFilter'
import { DownloadType, useDownloads } from 'Hooks/useDownloads'
import { Scan } from 'Interfaces/*'

type DownloadTypes = RouteComponentProps & {
  scan: Scan
}

const Download: React.FC<DownloadTypes> = ({ scan }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { startDownload } = useDownloads()

  const handleDownload = () => {
    startDownload(DownloadType.Merchandizing, scan)
  }

  const showDownloads = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={cx('bp5-navbar-group', styles.downloadCollapse)}>
      <Tooltip content="Choose Regions To Select" position={Position.BOTTOM_LEFT}>
        <Button onClick={showDownloads}>
          Downloads
          <span
            className={
              isOpen
                ? cx('bp5-icon-standard', 'bp5-icon-chevron-up', 'bp5-align-right')
                : cx('bp5-icon-standard', 'bp5-icon-chevron-down', 'bp5-align-right')
            }
          ></span>
        </Button>
      </Tooltip>
      <Collapse isOpen={isOpen}>
        <pre className={styles.preCollapse}>
          <PlatformAndTerritoryFilter newUI regionsAndPlatforms />
          <Button onClick={handleDownload} className={styles.downloadBtn}>
            Download
          </Button>
        </pre>
      </Collapse>
    </div>
  )
}

export default withRouter(Download)
