import React from 'react'
import Slider from 'react-slick'
import Item from './Item'
import cx from 'classnames'
import style from './BannerCarousel.module.scss'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

class BannerCarousel extends React.Component {
  render() {
    const sliderSettings = {
      dots: false,
      infinite: false,
      centerMode: false,
      centerPadding: 0,
      slidesToScroll: 4,
      slidesToShow: 4,
      variableWidth: true,
      arrows: true,
      swipe: false,
      initialSlide: 0,
      nextArrow: <Arrow right />,
      prevArrow: <Arrow left />,
    }
    return (
      <div className={style.container}>
        <div className={style.heading}></div>
        <Slider {...sliderSettings}>{this.renderSpots()}</Slider>
      </div>
    )
  }

  renderSpots() {
    const { section } = this.props

    const containerClassnames = cx({
      [style.item]: section && section.sectionType !== 'google_play::banner_section',
      [style.bannerItem]: section && section.sectionType === 'google_play::banner_section',
    })

    return this.spots().map(
      spot =>
        spot && (
          <div className={containerClassnames} key={spot.id}>
            <Item spot={spot} section={section} hideTitle />
          </div>
        ),
    )
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'content')
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }
}

export default BannerCarousel
