import React from 'react'
import PropTypes from 'prop-types'
import { default as ReactSelect } from 'react-select'

const TagSelect = props => {
  return (
    <ReactSelect
      {...props}
      inputId={props.id}
      options={props.options}
      onChange={selected => props.onChange(selected)}
    />
  )
}

TagSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
}

export default TagSelect
