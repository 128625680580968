import React from 'react'
import { Tooltip, Button, Position } from '@blueprintjs/core'

class ViewSwitcher extends React.Component {
  render() {
    const { scanView, showScanAsList, showScanAsGrid } = this.props
    return (
      <div className="bp5-button-group">
        <Tooltip content="Title List" position={Position.BOTTOM_LEFT}>
          <Button className="bp5-icon-grid" active={scanView === 'grid'} onClick={() => showScanAsGrid()} />
        </Tooltip>

        <Tooltip content="Action List" position={Position.BOTTOM}>
          <Button className="bp5-icon-list" active={scanView === 'list'} onClick={() => showScanAsList()} />
        </Tooltip>
      </div>
    )
  }
}

export default ViewSwitcher
