import { Record } from 'immutable'
export default class CompetitorFilterRecord extends Record({
  all: true,
  movies: false,
  tv: false,

  state: '',
  query: '',
  lastQuery: '',
  active: false,
  competitors: true,
}) {
  stateFilters() {
    return ['pending', 'located', 'approved', 'error', 'unavailable']
  }
  toParams(_state) {
    return {
      'filter[state]': this.state,
      'filter[type]': [this.all && 'movie,tv_season', this.movies && 'movie', this.tv && 'tv_season'].filter(x => x),
      'filter[query]': this.query,
      'filter[competitors]': this.competitors,
    }
  }

  enableAll() {
    return this.withMutations(s => {
      s.tv = false
      s.movies = false
      s.all = true
    })
  }

  enableMovies() {
    return this.withMutations(s => {
      s.tv = false
      s.movies = true
      s.all = false
    })
  }

  enableTv() {
    return this.withMutations(s => {
      s.tv = true
      s.movies = false
      s.all = false
    })
  }

  updateCompetitorQuery(query) {
    return this.withMutations(s => {
      s.query = query
    })
  }

  clearCompetitorQuery() {
    return this.withMutations(s => {
      s.query = ''
    })
  }

  hasActiveQuery() {
    return this.lastQuery.trim().length > 0
  }

  setLastUsedQuery(query) {
    return this.withMutations(s => {
      s.lastQuery = query
    })
  }

  setStateFilter(state) {
    return this.withMutations(s => {
      s.state = state
    })
  }
}
