import React from 'react'
import { Icon, Dialog, Button, Intent } from '@blueprintjs/core'
import { LIMIT_REACHED, LIMIT_NOTIFICATION, OKAY } from './constants'
import style from './index.module.scss'

type PlatformLimitDialogTypes = {
  showDialog: boolean
  setShowDialog: (dialog: boolean) => void
}

const PlatformLimitDialog: React.FC<PlatformLimitDialogTypes> = ({ showDialog, setShowDialog }) => {
  return (
    <Dialog
      isOpen={showDialog}
      onClose={() => setShowDialog(false)}
      title={
        <>
          <Icon icon="issue" className={style.errorIcon} /> {`${LIMIT_REACHED}`}
        </>
      }
    >
      <div className="bp5-dialog-body">{`${LIMIT_NOTIFICATION}`}</div>
      <div className="bp5-dialog-footer">
        <div className="bp5-dialog-footer-actions">
          <Button intent={Intent.PRIMARY} onClick={() => setShowDialog(false)} text={OKAY} />
        </div>
      </div>
    </Dialog>
  )
}

export default PlatformLimitDialog
