import React from 'react'
import WrappedSection from 'Containers/WrappedSection'
import style from './Header.module.scss'

function Header(props) {
  const { subSections, scan, page, section } = props

  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h1>{section.name}</h1>
      </div>
      {subSections &&
        subSections.map(
          s => s && <WrappedSection spot={s} type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />,
        )}
    </div>
  )
}

export default Header
