import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Tooltip, Button, Popover, PopoverInteractionKind, Position, Icon } from '@blueprintjs/core'
import styles from './Toolbar.module.scss'
import cx from 'classnames'

import Download from 'Components/Merchandizing/Download'
import Search from 'Containers/Search'
import SpotTableSearch from 'Components/Merchandizing/SpotsTable/SpotTableSearch'
import { groupAssociatedMerchScansByPlatform } from './helpers'
import { CHEVRON_UP, CHEVRON_DOWN } from './constants'
import AssociatedMerchScansForPlatform from './AssociatedMerchScansForPlatform'
import SingleScan from './SingleScan'

class Toolbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      platformButtonIcon: 'bp5-icon-standard bp5-icon-caret-down bp5-align-right',
      selectedPlatform: null,
      selectedAms: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.associatedMerchScans !== prevProps.associatedMerchScans || this.props.scan !== prevProps.scan) &&
      !!this.props.scan
    ) {
      this.updateSelectedAms()
    }
  }

  updateSelectedAms() {
    const { associatedMerchScans, scan } = this.props
    if (!scan || !associatedMerchScans || associatedMerchScans?.isEmpty()) return

    const selectedAms =
      associatedMerchScans.find(ams => ams.merchandizingScanId === scan.id) ||
      associatedMerchScans.find(ams => ams.platform === scan.platformName)
    this.setState({ selectedAms })
    this.selectPlatform(selectedAms?.platform)
  }

  selectPlatform(platform) {
    this.setState({ selectedPlatform: platform })
  }

  navigateToPath = path => {
    this.props.history.push(path)
  }

  render() {
    const {
      scan,
      auditScan,
      linkToRoot,
      associatedMerchScans,
      dragonflyEnabled,
      dragonflyMerchUrl,
      history,
      client,
      merchandisingScanView,
      showMerchandizingScanAsGrid,
      showMerchandizingScanAsList,
      updateTitleQuery,
      clearTitleQuery,
      retrieveScanSpots,
      filter,
      spotsLoading,
      pageDataLoading,
      resetSpots,
      location,
    } = this.props

    const { selectedAms } = this.state

    const groupedAssociatedMerchScans = groupAssociatedMerchScansByPlatform(associatedMerchScans)

    const isGridViewDisabled = merchandisingScanView === 'list' && spotsLoading
    const isListViewDisabled = merchandisingScanView === 'grid' && pageDataLoading

    const MerchandizingViewButton = ({ type }) => {
      const tooltipLabel = type === 'grid' ? 'Spot Grid' : 'Spot List'
      const tooltipPosition = type === 'grid' ? Position.BOTTOM_LEFT : Position.BOTTOM
      const disabled = type === 'grid' ? isGridViewDisabled : isListViewDisabled
      const active = type === 'grid' ? merchandisingScanView === 'grid' : merchandisingScanView === 'list'
      const action = type === 'grid' ? showMerchandizingScanAsGrid : showMerchandizingScanAsList
      const iconClassName = type === 'grid' ? 'bp5-icon-grid' : 'bp5-icon-list'

      return (
        <Tooltip content={tooltipLabel} position={tooltipPosition}>
          <Button
            className={cx(styles.buttonStyle, iconClassName)}
            disabled={disabled}
            active={active}
            onClick={() => {
              resetSpots()
              action()
            }}
          />
        </Tooltip>
      )
    }

    const changeButtonIcon = () => {
      this.setState(prevState => ({
        platformButtonIcon: prevState.platformButtonIcon === 'bp5-icon-standard bp5-icon-caret-down bp5-align-right'
          ? 'bp5-icon-standard bp5-icon-caret-up bp5-align-right'
          : 'bp5-icon-standard bp5-icon-caret-down bp5-align-right',
      }))
    }

    const renderAssociatedMerchScans = groupedAssociatedMerchScans =>
      Object.keys(groupedAssociatedMerchScans).map(platform => (
        <div key={platform} className="bp5-menu">
          <span onClick={() => this.selectPlatform(platform)}>
            {platform}
            <Icon
              icon={this.state.selectedPlatform === platform ? CHEVRON_UP : CHEVRON_DOWN}
              className={styles.chevronIcon}
            />
          </span>
          {this.state.selectedPlatform === platform && (
            <AssociatedMerchScansForPlatform
              associatedMerchScans={groupedAssociatedMerchScans[platform]}
              onChange={this.navigateToPath}
              pathName={location.pathname}
            />
          )}
        </div>
      ))

    return (
      <nav className={cx(styles.header, 'bp5-navbar')}>
        <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
          <button onClick={() => history.push(linkToRoot)} type="button" className="bp5-button bp5-icon-home">
            Home
          </button>
        </div>
        {(!client || client.isMerchandizingListEnabled) && (
          <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
            <div className={cx(styles.divider, 'bp5-navbar-divider')} />
            <MerchandizingViewButton type="grid" />
            <MerchandizingViewButton type="list" />
          </div>
        )}
        <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
          <div className={cx(styles.divider, 'bp5-navbar-divider')} />

          <div className="bp5-button-group">
            <Popover
              interactionKind={PopoverInteractionKind.CLICK}
              position={Position.BOTTOM_RIGHT}
              className={cx('bp5-align-right', styles.merchPopover)}
              modifiers={{
                offset: {
                  enabled: true,
                  offset: '100px, 10px',
                },
              }}
              content={<div data-testid="platformsMenu" className={styles.merchPtMenu}>
                {groupedAssociatedMerchScans && Object.keys(groupedAssociatedMerchScans).length ? (
                  renderAssociatedMerchScans(groupedAssociatedMerchScans)
                ) : (
                  <SingleScan scan={scan} />
                )}
              </div>}
            >
              <Tooltip content="Select Platform" position={Position.BOTTOM_LEFT}>
                <Button
                  data-testid="selectPlatform"
                  type="button"
                  className="bp5-button"
                  onClick={() => changeButtonIcon()}
                >
                  {selectedAms ? selectedAms.name : scan && `${scan.platformName}, ${scan.territoryCode}`}
                  <span className={this.state.platformButtonIcon}></span>
                </Button>
              </Tooltip>
            </Popover>
          </div>
        </div>
        {(!client || client.isConnectedDevicesEnabled) && (
          <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
            <div className={cx(styles.divider, 'bp5-navbar-divider')} />
            <Button
              type="button"
              className="bp5-button"
              onClick={() => {
                window.open(
                  process.env.REACT_APP_CONNECTED_DEVICES_LINK +
                  ((client?.identityProvider && `?provider=${client?.identityProvider}`) || ''),
                  '_blank',
                )
              }}
            >
              Connected Devices
            </Button>
          </div>
        )}
        {dragonflyEnabled && (
          <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
            <div className={cx(styles.divider, 'bp5-navbar-divider')} />
            <Tooltip content="Dragonfly Heatmap" position={Position.BOTTOM_LEFT}>
              <Button
                className="bp5-icon-eye-open"
                onClick={() => {
                  window.open(dragonflyMerchUrl, '_blank')
                }}
              >
                Dragonfly
              </Button>
            </Tooltip>
          </div>
        )}

        {merchandisingScanView && merchandisingScanView === 'list' && (!client || client.isScreenshotEnabled) && (
          <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
            <div className={cx(styles.divider, 'bp5-navbar-divider')} />
            <Download scan={scan} />
          </div>
        )}

        <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-right')}>
          {auditScan && auditScan.merchandizingReportUrl && (
            <Link to="/dashboard/merchandizing" className="bp5-button bp5-icon-heat-grid">
              Merchandising Report
            </Link>
          )}
        </div>

        <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-right')}>
          {merchandisingScanView && merchandisingScanView === 'grid' ? (
            <Search />
          ) : (
            <SpotTableSearch
              filter={filter}
              retrieveScanSpots={retrieveScanSpots}
              clearTitleQuery={clearTitleQuery}
              updateTitleQuery={updateTitleQuery}
            />
          )}
          <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        </div>
      </nav>
    )
  }
}

export default withRouter(Toolbar)
