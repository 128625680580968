import { Map } from 'immutable'
import { initialState, TITLES_FOR_CORRECTION, DATA, META } from './utils.js'

export default function titlesForCorrectionReducer(state, action) {
  switch (action.type) {
    case 'LOADING_TITLES_FOR_CORRECTION': {
      return state.withMutations(s => {
        s.setIn([TITLES_FOR_CORRECTION, META], new Map({ loading: true }))
      })
    }
    case 'LOAD_TITLES_FOR_CORRECTION': {
      return state.withMutations(s => {
        s.mergeIn([TITLES_FOR_CORRECTION, DATA], action.titlesForCorrection)
        s.setIn([TITLES_FOR_CORRECTION, META], new Map({ loading: false }))
      })
    }
    case 'LOAD_TITLE_FOR_CORRECTION': {
      return state.withMutations(s => {
        const titleForCorrection = action.titleForCorrection
        const titles = s.getIn([TITLES_FOR_CORRECTION, DATA])
        const titlesForCorrection = titles.set(titleForCorrection.id, titleForCorrection)
        return s.setIn([TITLES_FOR_CORRECTION, DATA], titlesForCorrection)
      })
    }
    case 'ADD_AUDIT_ACTION_TO_TITLE_FOR_CORRECTION': {
      return state.withMutations(s => {
        const correctionData = s.getIn([
          TITLES_FOR_CORRECTION,
          DATA,
          action.title.id,
          'relationships',
          'auditActions',
          'data',
        ])
        const data = correctionData
          .push(new Map({ id: action.actionId, type: 'auditActions' }))
          .toSet()
          .toList()

        return s.mergeIn([TITLES_FOR_CORRECTION, DATA, action.title.id, 'relationships', 'auditActions', 'data'], data)
      })
    }
    default:
      return state || initialState
  }
}
