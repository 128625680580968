import React from 'react'
import style from './LinksSection.module.scss'
import GenericSection from './GenericSection'

class LinksSection extends GenericSection {
  constructor(props) {
    super(props)
  }
  render() {
    return <div className={style.container}>{this.renderSubSections()}</div>
  }
}

export default LinksSection
