import React, { useMemo } from 'react'
import Item from './Item'
import cx from 'classnames'
import { PageItems } from 'Interfaces/interfaces'
import style from './Grid.module.scss'
import { RakutenTvSectionTypes } from '../enums'
import { renderSpotsWithItem, computeContainerClasses } from '../helpers'

const Grid: React.FC<PageItems> = ({ spots, selectedStudios, section, isMerchandiseFilteringEnabled }) => {
  const headingClassNames = useMemo(
    () =>
      cx({
        [style.sectionHeader]: [RakutenTvSectionTypes._Grid, RakutenTvSectionTypes._LandscapeGrid].includes(
          section.sectionType as RakutenTvSectionTypes,
        ),
        [style.saleHeader]: section.sectionType === RakutenTvSectionTypes._SaleGrid,
      }),
    [section],
  )

  const containerClasses = useMemo(() => computeContainerClasses(section, style, 4), [section])

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.gridItem]: [RakutenTvSectionTypes._Grid, RakutenTvSectionTypes._SaleGrid].includes(
          section.sectionType as RakutenTvSectionTypes,
        ),
        [style.landscapeGridItem]: section.sectionType === RakutenTvSectionTypes._LandscapeGrid,
      }),
    [section],
  )

  const spotsRender = renderSpotsWithItem({
    spots: spots.filter(spot => spot),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  return (
    <div className={containerClasses}>
      <div className={headingClassNames}>
        <h3>{section.name}</h3>
      </div>
      <div className={style.spotWrapper}>{spotsRender}</div>
    </div>
  )
}

export default Grid
