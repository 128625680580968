import React from 'react'

const CheckboxInput = ({ checked, onChange, label, disabled }) => (
  <label className="bp5-label">
    <input className="bp5-checkbox" type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
    {label}
  </label>
)

export default CheckboxInput
