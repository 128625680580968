import { Record, Map } from 'immutable'

export default class ScanDownloadRecord extends Record({
  id: 0,
  name: '',
  url: '',
  status: '',
  links: new Map(),
  relationships: new Map(),
}) {}
