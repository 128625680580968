import React from 'react'

import { Button, Popover, PopoverInteractionKind, Position, Menu, MenuItem, Tooltip } from '@blueprintjs/core'

import styles from './Toolbar.module.scss'
import cx from 'classnames'
import inflection from 'inflection'
import TitleNameSelector from './TitleNameSelector'
import PlatformAndTerritoryFilter from 'Components/PlatformAndTerritoryFilter'
import useToolbarActions from 'Components/ScanPage/useToolbarActions'
import { getTitleFilters } from 'Selectors'
import { useSelector } from 'react-redux'
import ActionConfirmationModal from './ActionConfirmationModal'
import useContainerActions from './hooks/useContainerActions'
import { PIN, UNPIN, FILTER } from './constants'

const Toolbar = ({ client, pinFilter, setPinFilter }) => {
  const { filter } = useSelector(state => ({
    filter: getTitleFilters(state),
  }))

  const {
    clearQuery,
    updateQuery,
    toggleMovie,
    toggleSeries,
    toggleAll,
    relocateTitles,
    enablePriority,
    retryUnavailableTitles,
    disablePromoted,
    enablePromoted,
    setNewCandidatesFilter,
    refreshAmazonArtworks,
    setLetterFilter,
    toggleEnabledFilter,
    markUnavailableForeverTitles,
    stateFilterClear,
    stateFilterSet,
    reloadTitles,
    markUnavailableTitles,
  } = useToolbarActions({})

  const { handleAction, handleCloseModal, handleSubmitAction, isModalOpen, triggeredActionName } = useContainerActions()

  const togglePin = () => {
    setPinFilter(prevState => !prevState)
  }

  return (
    <nav
      className={cx('bp5-navbar', {
        [styles.header]: !pinFilter,
        [styles.staticHeader]: pinFilter,
      })}
    >
      <div className="bp5-navbar-group bp5-align-left">
        <div className="bp5-button-group">
          <button
            className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.all })}
            onClick={toggleAll}
          >
            All
          </button>
          <button
            className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.movies })}
            onClick={toggleMovie}
          >
            Films
          </button>
          <button
            className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.tv })}
            onClick={toggleSeries}
          >
            TV Seasons
          </button>
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className="bp5-button-group">
          <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.state === '' && !filter.newCandidates })} onClick={stateFilterClear}>
            All
          </button>
          {filter.stateFilters().map(state => (
            <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.state === state })} onClick={() => stateFilterSet(state)} key={state}>
              {inflection.titleize(state)}
            </button>
          ))}
          <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.newCandidates })} onClick={setNewCandidatesFilter}>
            New Candidates
          </button>
        </div>
        <div className="bp5-navbar-group bp5-align-left">
          <div className={cx(styles.divider, 'bp5-navbar-divider')} />
          <div className="bp5-button-group">
            <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: !filter.promoted && !filter.priority })} onClick={disablePromoted}>
              All
            </button>
            <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.promoted })} onClick={enablePromoted}>
              Featured
            </button>
            {client && client.tagsEnabled && (
              <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.priority })} onClick={enablePriority}>
                Priority
              </button>
            )}
          </div>
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className="bp5-button-group">
          <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.enabled })} disabled={filter.enabled} onClick={toggleEnabledFilter}>
            Enabled
          </button>
          <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: !filter.enabled })} disabled={!filter.enabled} onClick={toggleEnabledFilter}>
            Disabled
          </button>
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          popoverClassName="bp5-popover-padding-none"
          position={Position.BOTTOM_RIGHT}
          content={<Menu className="bp5-menu">
            <MenuItem
              className="bp5-icon-search"
              text="Relocate all pending and errored"
              onClick={() => handleAction('relocate all pending and errored', relocateTitles)}
            />
            <MenuItem
              className="bp5-icon-automatic-updates"
              text="Retry all unavailable"
              onClick={() => handleAction('retry unavailable', retryUnavailableTitles)}
            />
            <MenuItem
              className="bp5-icon-one-column"
              text="Bulk Unavailable Forever"
              onClick={() => handleAction('mark unavailable forever', markUnavailableForeverTitles)}
            />
            <MenuItem
              className="bp5-icon-pause"
              text="Bulk Unavailable"
              onClick={() => handleAction('mark unavailable', markUnavailableTitles)}
            />
            <MenuItem
              className="bp5-icon-image-rotate-left"
              text="Refresh all amazon artworks"
              onClick={() => handleAction('refresh amazon artworks', refreshAmazonArtworks)}
            />
          </Menu>}
        >
          <Button text="Actions" className={cx('bp5-button', 'bp5-icon-cog', styles.btnDefault)} />
        </Popover>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className="bp5-input-group">
          <span className="bp5-icon bp5-icon-search" />
          <input
            className="bp5-round bp5-input"
            value={filter.query}
            placeholder="Search"
            dir="auto"
            onChange={e => updateQuery(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') reloadTitles()
            }}
          />
        </div>

        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM_RIGHT}
          content={<PlatformAndTerritoryFilter newUI regionsAndPlatforms />}>
          <Tooltip content="Territory/Platform Filter" position={Position.BOTTOM_LEFT}>
            <Button className={cx('bp5-icon-filter', styles.btnDefault)} />
          </Tooltip>
        </Popover>

        {filter.hasActiveQuery() && (
          <div className="bp5-navbar-group">
            <div className={cx(styles.divider, 'bp5-navbar-divider')} />
            <Button className={cx('bp5-icon-cross', styles.btnDefault)} onClick={clearQuery}>
              Clear Search
            </Button>
          </div>
        )}
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <TitleNameSelector selectedFilter={filter.letter} setLetterFilter={setLetterFilter} />
        <ActionConfirmationModal
          isOpen={isModalOpen}
          actionName={triggeredActionName}
          onSubmit={handleSubmitAction}
          onClose={handleCloseModal}
        />
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <Tooltip content={pinFilter ? `${UNPIN} ${FILTER}` : `${PIN} ${FILTER}`} position={Position.BOTTOM_LEFT}>
          <Button
            text={pinFilter ? UNPIN : PIN}
            className={cx(styles.btnDefault, {
              ['bp5-icon-pin']: !pinFilter,
              ['bp5-icon-unpin']: pinFilter,
            })}
            onClick={togglePin}
          />
        </Tooltip>
      </div>
    </nav>
  )
}

export default Toolbar
