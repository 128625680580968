import React from 'react'
import style from './GoogleBanner.module.scss'
import BannerCarousel from './BannerCarousel'

class GoogleBanner extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { section, spots } = this.props

    const metadata = section.metadata || {}

    return (
      <div
        className={style.container}
        style={{
          backgroundColor: `${metadata.background_color}`,
        }}
      >
        <div className={style.image}>
          <div
            className={style.posterImage}
            style={{
              backgroundImage: `url('${metadata.background_image}')`,
            }}
          >
            <div
              className={style.gradientLeft}
              style={{
                backgroundImage: `${metadata.background_left_gradient}`,
              }}
            ></div>
            <div className={style.heading}>
              <h2>{section.name}</h2>
              {metadata.subtitle && <h3>{metadata.subtitle}</h3>}
            </div>
            <div
              className={style.gradientRight}
              style={{
                backgroundImage: `${metadata.background_right_gradient}`,
              }}
            ></div>
          </div>
          <div className={style.slider}>
            <BannerCarousel section={section} spots={spots} />
          </div>
        </div>
      </div>
    )
  }
}

export default GoogleBanner
