/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'clients' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import configureStore from 'store/configureStore'
import ClientApp from 'applications/ClientApp'
import Data from 'Applications/Data2'
import { actions as csrfActions } from 'redux-csrf'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import './index.css'
import initSentry from './initSentry'
import { BlueprintProvider } from '@blueprintjs/core';
import { BrowserRouter as Router } from 'react-router-dom'

const store = configureStore()
window.store = store
const queryClient = new QueryClient()
initSentry()

const render = Component => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  // const csrf = document.getElementsByName('csrf-token')[0].content

  // store.dispatch(csrfActions.setCsrfToken(csrf))

  root.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BlueprintProvider>
          <Router>
            <ClientApp />
            <Data />
          </Router>
        </BlueprintProvider>
      </Provider>,
    </QueryClientProvider>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  // eslint-disable-line no-undef
  render()
})
