import React from 'react'
import GenericSection from './GenericSection'
import style from './Header271.module.scss'
class Header271 extends GenericSection {
  render() {
    const { section } = this.props
    const { name } = section
    return (
      <div>
        <h4 className={style.root}>{name}</h4>
        {this.renderSubSections()}
      </div>
    )
  }
}

export default Header271
