import React from 'react'
import style from './SeeMore.module.scss'
import SeeMoreItem from './SeeMoreItem'

class SeeMore extends React.Component {
  render() {
    const { section } = this.props
    return (
      <React.Fragment>
        <div className={style.sectionHeader}>{this.isFirstSection() && <h3>{section.name}</h3>}</div>
        <div className={style.container}>{this.renderSpots()}</div>
      </React.Fragment>
    )
  }

  renderSpots() {
    const { section, isMerchandiseFilteringEnabled } = this.props
    return this.spots().map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <SeeMoreItem
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={this.isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        ),
    )
  }

  // We devided page in sections in group of 4 spots, wee need only to show name of first section
  isFirstSection() {
    const { index } = this.props

    return index === 0
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'content')
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }
}

export default SeeMore
