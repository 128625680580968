import { Record, Map, List } from 'immutable'

export default class BrandOptimizationScoreRecord extends Record({
  id: 0,
  score: 0,
  brandName: 0,
  titlesCount: 0,
  sampleArtwork: new List(),
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {}
