import { Record, Map } from 'immutable'

export default class AssociatedMerchandizingScanRecord extends Record({
  id: 0,
  rootPageId: '',
  merchandizingScanId: '',
  linkToRootPage: '',
  platform: '',
  platformId: '',
  territory: '',
  default: false,
  isVisible: true,
  displayName: '',
  name: '',
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
  selected: false,
}) {}
