import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import styles from 'Components/ArtworkPage/PlatformHeader/styles.module.scss'
import { getPlatformById, getTerritoryById } from 'Selectors'
import { Tooltip } from '@blueprintjs/core'

const PlatformHeaderItem = ({ platform, territory, showFlag }) => {
  const classnames = cx(styles.platformItem, {
    [styles.withFlag]: showFlag,
  })
  return (
    platform && (
      <div className={classnames}>
        {territory && showFlag && (
          <div className={styles.flag} style={{ backgroundImage: `url(${territory.flagUrl})` }} />
        )}
        <Tooltip content={`${platform && platform.name}, ${territory && territory.name}`}>
          <div
            className={styles.platformLogo}
            style={{
              backgroundImage: `url(${platform.logoUrl})`,
            }}
          />
        </Tooltip>
      </div>
    )
  )
}

const mapStateToProps = (state, ownProps) => {
  const platform = getPlatformById(state, { id: ownProps.platformId })
  const territory = platform && getTerritoryById(state, { id: platform.territoryId })

  return {
    platform,
    territory,
  }
}

export default connect(mapStateToProps)(PlatformHeaderItem)
