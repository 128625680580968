import { AUDIT_EXCEPTIONS, DATA } from './utils'

export const LOAD_AUDIT_EXCEPTIONS = 'LOAD_AUDIT_EXCEPTIONS'
export const LOAD_AUDIT_EXCEPTION = 'LOAD_AUDIT_EXCEPTION'
export const REMOVE_AUDIT_EXCEPTION = 'REMOVE_AUDIT_EXCEPTION'

export default function auditExceptionReducer(state, action) {
  switch (action.type) {
    case LOAD_AUDIT_EXCEPTIONS: {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([AUDIT_EXCEPTIONS, DATA], action.auditExceptions)
          : s.mergeIn([AUDIT_EXCEPTIONS, DATA], action.auditExceptions)
      })
    }
    case LOAD_AUDIT_EXCEPTION: {
      const { auditException } = action
      return state.withMutations(s => {
        s.setIn([AUDIT_EXCEPTIONS, DATA, auditException.id], auditException)
      })
    }
    case REMOVE_AUDIT_EXCEPTION: {
      return state.withMutations(s => {
        s.deleteIn([AUDIT_EXCEPTIONS, DATA, action.auditExceptionId])
      })
    }
    default:
      return state
  }
}
