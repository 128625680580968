import React, { useMemo } from 'react'
import GridItem from './Item'
import cx from 'classnames'
import { PageItems } from 'Interfaces/interfaces'
import style from './Grid.module.scss'
import { YouTubeSectionTypes } from '../enums'
import { renderSpotsWithItem, computeContainerClasses } from '../helpers'

const Grid: React.FC<PageItems> = ({ spots, selectedStudios, section, isMerchandiseFilteringEnabled }) => {
  const filteredSpots = useMemo(() => spots.filter(spot => spot && ['link', 'content'].includes(spot.spotType)), [
    spots,
  ])

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.gridItem]: section.sectionType === YouTubeSectionTypes._Grid,
        [style.landScapeGridItem]: section.sectionType === YouTubeSectionTypes._LandscapeGrid,
      }),
    [section.sectionType],
  )

  const containerClasses = useMemo(() => computeContainerClasses(section, style, 3), [section])

  const spotsRender = renderSpotsWithItem({
    spots: filteredSpots,
    ItemComponent: GridItem,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const shouldRenderHeader = [YouTubeSectionTypes._Grid, YouTubeSectionTypes._LandscapeGrid].includes(
    section?.sectionType as YouTubeSectionTypes,
  )

  return (
    <div className={containerClasses}>
      {shouldRenderHeader && (
        <div className={style.sectionHeader}>
          <h3>{section.name}</h3>
        </div>
      )}
      <div className={style.spotWrapper}>{spotsRender}</div>
    </div>
  )
}

export default Grid
