import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Autosuggest from 'react-autosuggest'
import { searchForTitles } from 'Actions/titleActions'
import { setTitleIds } from 'Actions/scanActions'
import style from 'Components/Search/style.module.scss'
import { getScanFilters, getSelectedScan, getClient } from 'Selectors'
import cx from 'classnames'
import debounce from 'lodash.debounce'
import Suggestion from './Search/Sugesstion'
import Input from './Search/Input'

const Search = () => {
  const dispatch = useDispatch()
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [selectedTitles, setSelectedTitles] = useState([])
  const [titleSuggestions, setTitleSuggestions] = useState([])
  const fetchTitles = (value, titleIds) => {
    dispatch(searchForTitles(value, titleIds)).then(response => {
      const mappedTitles = response.titles.map(data => ({
        id: data.id,
        name: data.name,
      }))

      setTitleSuggestions(mappedTitles)
      setSuggestions(mappedTitles)
      titleIds?.length && setSelectedTitles(mappedTitles)
    })
  }

  const { titleIds, scan, client } = useSelector(state => ({
    titleIds: getScanFilters(state).titleIds,
    scan: getSelectedScan(state),
    client: getClient(state),
  }))

  useEffect(() => {
    if (!titleIds.length || !scan?.id || !client?.id) return

    fetchTitles('', titleIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleIds, scan?.id, client?.id])

  const getSuggestions = () => titleSuggestions

  const getSuggestionValue = suggestion => suggestion.name

  const onSuggestionClick = suggestion => {
    const selectedTitlesIds = selectedTitles.map(st => st.id)
    if (selectedTitlesIds.includes(suggestion.id)) return

    const newSelectedTitles = [...selectedTitles, suggestion]
    dispatch(
      setTitleIds(
        newSelectedTitles.map(title => title.id),
        newSelectedTitles?.length,
      ),
    )
    setSelectedTitles(newSelectedTitles)
  }

  const onSuggestionSelected = () => {
    setValue('')
  }

  const removeSelectedTitle = id => {
    const newSelectedTitles = selectedTitles.filter(title => title.id !== id)
    setSelectedTitles(newSelectedTitles)
    dispatch(setTitleIds(newSelectedTitles.map(title => title.id)))
  }

  const debouncedFetchTitles = useRef(debounce(fetchTitles, 500)).current

  const onChange = (_event, { newValue, method }) => {
    if (method === 'click') {
      // value selected from dropdown
      onSuggestionSelected()
    } else {
      setValue(newValue)
    }

    if (newValue.length < 2) {
      setTitleSuggestions([])
      return
    }

    debouncedFetchTitles(newValue)
  }

  const onSuggestionsClearRequested = () => setSuggestions([])

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const inputProps = {
    placeholder: 'Search',
    value: value,
    onChange: onChange,
  }

  return (
    <div className={cx('bp5-navbar-group', style.navBar)}>
      <Autosuggest
        theme={style}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={props => (
          <Input
            selectedTitles={selectedTitles}
            onRemove={removeSelectedTitle}
            inputProps={{ ...inputProps, ...props }}
          />
        )}
        inputProps={inputProps}
        renderSuggestion={suggestion => <Suggestion suggestion={suggestion} onClick={onSuggestionClick} />}
        focusInputOnSuggestionClick={true}
        onSuggestionSelected={onSuggestionSelected}
      />
    </div>
  )
}

export default Search
