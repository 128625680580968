import React, { useRef, useMemo } from 'react'
import style from './Item.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import cx from 'classnames'
import { linkToPage } from '../utils'
import { SpotItems } from 'Interfaces/interfaces'
import { generateClientStyle } from '../helpers'
import { useScrollIntoView } from '../../../hooks/useScrollIntoView'
import SpotDetails from './SpotDetails'
import SliderDetails from './SliderDetails'
import { Icon } from '@blueprintjs/core'

type ItemProps = RouteComponentProps & SpotItems

const Item: React.FC<ItemProps> = ({ spot, client, isMerchandiseFilteringEnabled, section, match }) => {
  const nodeRef = useRef<HTMLAnchorElement | null>(null)
  const { sectionType } = section

  useScrollIntoView({ condition: !!spot.selected, ref: nodeRef })

  const inlineStyle = useMemo(() => generateClientStyle(client), [client])

  const classNames = useMemo(
    () =>
      cx(style.item, {
        [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
        [style.highlight]: spot.selected || client,
      }),
    [section.selected, isMerchandiseFilteringEnabled, client, spot.selected],
  )

  const isViewAll = useMemo(() => spot.label === 'view all', [spot])

  return (
    <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={nodeRef}>
      {!isViewAll && (
        <div
          className={style.spotBackground}
          id={`spot_${spot.id}`}
          style={{ backgroundImage: `url(${spot.artworkUrl})` }}
        >
          <SliderDetails spot={spot} sectionType={sectionType} />
        </div>
      )}
      {isViewAll && (
        <div id={`spot_${spot.id}`} className={style.viewAllSpot}>
          <span className={style.spotName}>{spot.name}</span>
          <span className={style.arrowRight}>
            {' '}
            <Icon icon="arrow-right" color="#FF6600" iconSize={20} />
          </span>
        </div>
      )}
      {!isViewAll && <SpotDetails spot={spot} sectionType={sectionType} />}
    </a>
  )
}

export default withRouter(Item)
