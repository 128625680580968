import React from 'react'
import ContainerDimensions from 'react-container-dimensions'

import styles from './styles.module.scss'
import PlatformHeaderItem from 'Containers/ContainerPlatformHeaderItem'
import cx from 'classnames'

class PlatformHeader extends React.PureComponent {
  render() {
    const { platformsWithFlags, paddingWidth, markAllAsVirtualPlatformTitles, pinFilter } = this.props

    const gridCellWidth = 141
    return (
      <ContainerDimensions>
        {({ width }) => {
          const headerWidth = width - parseInt(paddingWidth)
          const ghostCells =
            platformsWithFlags && Math.trunc((headerWidth - platformsWithFlags.length * gridCellWidth) / gridCellWidth)
          return (
            <div
              className={cx({
                [styles.platformHeader]: !pinFilter,
                [styles.stickyPlatformHeader]: pinFilter,
              })}
            >
              <div className={cx(styles.platformItem, styles.emptyItem)} />
              {platformsWithFlags &&
                platformsWithFlags.map(({ platform, showFlag }) => {
                  return (
                    platform && (
                      <PlatformHeaderItem
                        platformId={platform.id}
                        key={platform.id}
                        showFlag={showFlag}
                        markAllAsVirtualPlatformTitles={markAllAsVirtualPlatformTitles}
                      />
                    )
                  )
                })}

              {ghostCells &&
                Array(Math.max(0, ghostCells))
                  .fill()
                  .map((id, index) => <PlatformHeaderItem key={`ghost-${index}`} ghost />)}
            </div>
          )
        }}
      </ContainerDimensions>
    )
  }
}

export default PlatformHeader