import { useQuery } from '@tanstack/react-query'
import { readEndpoint } from 'Actions/apiActions'

const useFetchScan = (scanId: string) => {
  return useQuery({
    queryKey: [`scan-${scanId}`],
    queryFn: async () => {
      return await readEndpoint(`scans/${scanId}`)
    },
  })
}

export default useFetchScan
