import React from 'react'
import style from './Card.module.scss'
import cx from 'classnames'
import { scrollToNode } from '../utils'

class Card extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, isMerchandiseFilteringEnabled, client, section } = this.props
    const metadata = spot.metadata || {}
    const classnames = cx(style.root, {
      [style.tallCover]: metadata.cover_type === 'tall-cover',
      [style.squareCover]: metadata.cover_type === 'square-cover',
      [style.halfCover]: metadata.cover_type === 'half-cover',
      [style.sectionFade]: section.selected || (isMerchandiseFilteringEnabled && !client),
      [style.spotHighlight]: spot.selected || client,
    })

    const inlineStyle = {}
    if (client) inlineStyle.boxShadow = `0 2px 8px ${client.color}`

    return (
      <div style={inlineStyle} className={classnames} ref={node => (this.node = node)}>
        <div className={style.cover}>
          <div className={style.coverImageContainer}>
            <div className={style.coverOuterAlign}>
              <div className={style.coverInnerAlign}>
                <img className={style.coverImage} src={spot.artworkUrl} />
              </div>
            </div>
          </div>
        </div>

        <div className={style.details}>
          <div className={style.title}>
            {spot.name}
            <span className={style.paragraphEnd} />
          </div>
          <div className={style.subtitle}>
            {metadata.subtitle}
            <span className={style.paragraphEnd} />
          </div>
        </div>

        <div className={style.reason}>
          <div className={style.ratingContainer}>
            <div className={style.rating}>
              {<div className={style.currentRating} style={{ width: `${metadata.currentRating}%` }} />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Card
