import React from 'react'
import styles from './AuditArtwork.module.scss'
import cssVariables from './variables.scss'
import { isValidPlatformTitle } from '../utils'
import ArtworkPopoverImage from './ArtworkPopoverImage'

class ArtworkPopover extends React.Component {
  render() {
    const { title, keyArtwork, artworkComparison, platformTitle, previousArtwork } = this.props

    let missingArtwork = keyArtwork === undefined

    if (keyArtwork) {
      missingArtwork = !keyArtwork.width && !keyArtwork.url
    }

    const defaultWidth = cssVariables.popoverWidth
    const defaultHeight =
      title.titleTypeCode === 'movie' ? cssVariables.moviePopoverHeight : cssVariables.tvPopoverHeight

    const width = keyArtwork && (keyArtwork.width || defaultWidth)
    const height = keyArtwork && (keyArtwork.height || defaultHeight)
    const url = keyArtwork && keyArtwork.url
    const previousUrl = previousArtwork && previousArtwork.url
    const changeDetected = artworkComparison && artworkComparison.changeDetected

    const displayWidth =
      title && title.titleTypeCode === 'movie' ? cssVariables.moviePosterWidth : cssVariables.tvPosterWidth

    const displayHeight =
      title && title.titleTypeCode === 'movie' ? cssVariables.moviePosterHeight : cssVariables.tvPosterHeight

    const displayWidthValue = parseInt(displayWidth)
    const displayHeightValue = parseInt(displayHeight)

    const containerRatio = displayHeightValue / displayWidthValue
    const imgRatio = height / width
    let finalHeight
    let finalWidth

    if (containerRatio > imgRatio) {
      finalHeight = displayHeightValue
      finalWidth = displayHeightValue / imgRatio
    } else {
      finalWidth = displayWidthValue
      finalHeight = displayWidthValue * imgRatio
    }

    const overlayStyle = {
      backgroundImage: `url('${url}')`,
      width: finalWidth * 1.5,
      height: finalHeight * 1.5,
    }

    const comparisonStyle = {
      backgroundImage: `url('${previousUrl}')`,
      width: finalWidth * 1.5,
      height: finalHeight * 1.5,
    }

    if (missingArtwork && isValidPlatformTitle(platformTitle)) {
      return <div className={styles.popover}>Missing</div>
    }

    if (changeDetected) {
      return (
        <div className={styles.popover}>
          <ArtworkPopoverImage
            artwork={keyArtwork}
            changeIndicator={'OLD'}
            style={comparisonStyle}
            imageUrl={previousUrl}
          />
          <div style={{ marginRight: '15px' }} />
          <ArtworkPopoverImage artwork={keyArtwork} style={overlayStyle} imageUrl={url} changeIndicator={'NEW'} />
        </div>
      )
    }

    return (
      <div className={styles.popover}>
        {isValidPlatformTitle(platformTitle) ? (
          <div>
            <ArtworkPopoverImage artwork={keyArtwork} style={overlayStyle} imageUrl={url} />
          </div>
        ) : (
          'Not available'
        )}
      </div>
    )
  }
}

export default ArtworkPopover
