import React, { useRef, useMemo } from 'react'
import style from './Item.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import cx from 'classnames'
import { linkToPage } from '../utils'
import { SpotItems } from 'Interfaces/interfaces'
import { PatheSectionTypes } from '../enums'
import { generateClientStyle } from '../helpers'
import { useScrollIntoView } from '../../../hooks/useScrollIntoView'
import SpotDetails from './RenderSpotDetails'

type ItemProps = RouteComponentProps & SpotItems

const Item: React.FC<ItemProps> = ({ spot, client, isMerchandiseFilteringEnabled, section, match }) => {
  const nodeRef = useRef<HTMLAnchorElement | null>(null)
  const sectionType = section?.sectionType

  useScrollIntoView({ condition: !!spot.selected, ref: nodeRef })

  const inlineStyle = useMemo(() => generateClientStyle(client), [client])

  const classNames = useMemo(
    () =>
      cx(style.item, {
        [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
        [style.highlight]: spot.selected || client,
      }),
    [section.selected, isMerchandiseFilteringEnabled, client, spot.selected],
  )

  const shouldRenderSpotName = useMemo(
    () =>
      [PatheSectionTypes._Container, PatheSectionTypes._PortraitContainer, PatheSectionTypes._Grid].includes(
        sectionType as PatheSectionTypes,
      ),
    [sectionType],
  )

  return (
    <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={nodeRef}>
      <div
        className={style.spotBackground}
        id={`spot_${spot.id}`}
        style={{ backgroundImage: `url(${spot.artworkUrl})` }}
      >
        <SpotDetails spot={spot} sectionType={sectionType as PatheSectionTypes} />
      </div>
      {shouldRenderSpotName && <p className={style.spotName}>{spot.name}</p>}
    </a>
  )
}

export default withRouter(Item)
