import { Record, Map } from 'immutable'

import { getStudios } from 'Selectors'

export default class MerchandizingPlatformTitleRecord extends Record({
  id: 0,
  platformId: 0,
  platformIdentifier: '',
  selected: false,
  studioId: '',
  selfStudioId: '',
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {
  selfStudio(state) {
    const studios = getStudios(state)
    if (!studios || studios.isEmpty()) return new Map()

    return studios.get(this.selfStudioId)
  }

  studio(state) {
    const studios = getStudios(state)
    if (!studios || studios.isEmpty()) return new Map()

    return studios.get(this.studioId)
  }

  studioName(state) {
    if (this.studioId === '') {
      return ''
    }
    if (this.selfStudioId === '') {
      return ''
    }

    if (this.studioId === this.selfStudioId) {
      return this.studio(state) && this.studio(state).name
    }

    return this.studio(state) && this.selfStudio(state) && `${this.studio(state).name} > ${this.selfStudio(state).name}`
  }
}
