import React, { useState } from 'react'
import cx from 'classnames'
import { connect } from 'react-redux'
import ReactInfinitScroller from 'react-vertical-infinite-scrolling'
import PropTypes from 'prop-types'
import style from './MatchPanel.module.scss'
import Match from 'Containers/Match'
import { Spinner } from '@blueprintjs/core'
import { retrieveMoreMerchandizingMatchesForPage } from 'Actions/merchandizing/matchActions'
import { List } from 'immutable'

export const MATCHES_PER_PAGE = 100

function MatchPanel({
  matches,
  areMerchandisingMatchesLoading,
  deselectMerchandizingSpot,
  deselectMerchandizingSection,
  retrieveMoreMerchandizingMatchesForPage,
}) {
  const offsetPage = 1
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true)

  const onClick = () => {
    deselectMerchandizingSpot()
    deselectMerchandizingSection()
  }

  const handleInfiniteLoad = () => {
    if (matches.size === MATCHES_PER_PAGE) {
      setHasMoreToLoad(false)
      retrieveMoreMerchandizingMatchesForPage(offsetPage)
    }
  }

  return (
    <ReactInfinitScroller pageStart={0} loadMore={handleInfiniteLoad} hasMore={hasMoreToLoad}>
      <div className={cx(style.matchPanel, 'bp5-navbar')} onClick={onClick}>
        <div className={style.matchContainer}>
          {areMerchandisingMatchesLoading && (
            <div className={style.matchSpinnerContainer}>
              <Spinner />
            </div>
          )}
          {matches.map(m => (
            <Match key={m.id} match={m} />
          ))}
        </div>
      </div>
    </ReactInfinitScroller>
  )
}

MatchPanel.propTypes = {
  retrieveMoreMerchandizingMatchesForPage: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
  const { page } = ownProps
  const matches = ((page && page.getMatches(state)) || new List())
    .valueSeq()
    .sort((a, b) => a.title(state) && a.title(state).name.localeCompare(b.title(state)?.name))
    .toList()

  return { matches }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { page } = ownProps

  return {
    retrieveMoreMerchandizingMatchesForPage: offset =>
      dispatch(retrieveMoreMerchandizingMatchesForPage(page.id, offset)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchPanel)