import React from 'react'
import GenericSection from './GenericSection'
import styles from './MainContainer.module.scss'

class MainContainer extends GenericSection {
  render() {
    return <div className={styles.container}>{this.renderSubSections()}</div>
  }
}

export default MainContainer
