import React from 'react'
import Tabs from './Tabs'
import SingleSelect from './SingleSelect'
import BulkSelector from './BulkSelector'
import TerritoryRecordType from 'Records/territoryRecord'
import CheckboxList from './CheckboxList'
import { REGION, REGIONS, PLATFORM, PLATFORMS_AND_REGIONS } from './constants'

import {
  Match,
  SelectAllPlatformsFunction,
  DeselectAllPlatformsFunction,
  DeselectAllTerritoriesFunction,
  SelectAllTerritoriesFunction,
  Platform,
} from 'Interfaces/*'

import style from './index.module.scss'
import MultiSelect from './MultiSelect'

type PlatformAndRegionWithTabsProps = {
  activeTab: string
  handleTabChange: (tab: string) => void
  onTerritoryChange: (territory: TerritoryRecordType) => void
  sortedTerritories: any
  selectAllPlatforms: SelectAllPlatformsFunction
  deselectAllPlatforms: DeselectAllPlatformsFunction
  selectAllTerritories?: SelectAllTerritoriesFunction
  deselectAllTerritories?: DeselectAllTerritoriesFunction
  isOnScansPage: Match | null
  isOnMerchPage: Match | null
  sortedPlatformGroups: any
  togglePlatforms: (platformIds: string[], isOnScansPage: Match, isOnMerchPage: Match, competitors?: any) => void
  platformsInSelectedTerritory: (platformGroup: any) => Platform | undefined
  children: any
  availableTabs: Array<string>
}

const PlatformAndRegionWithTabs: React.FC<PlatformAndRegionWithTabsProps> = ({
  activeTab,
  handleTabChange,
  onTerritoryChange,
  sortedTerritories,
  selectAllPlatforms,
  deselectAllPlatforms,
  isOnScansPage,
  isOnMerchPage,
  sortedPlatformGroups,
  togglePlatforms,
  platformsInSelectedTerritory,
  children,
  availableTabs = [PLATFORM, REGION],
  selectAllTerritories,
  deselectAllTerritories,
}) => {
  return availableTabs.length > 1 ? (
    <Tabs activeTab={activeTab} onClick={handleTabChange}>
      <li className="bp5-tab-panel" role="tabpanel">
        {activeTab === REGION && availableTabs.includes(REGION) && (
          <SingleSelect onChange={onTerritoryChange} list={sortedTerritories} header={REGIONS}>
            <>
              <BulkSelector
                onSelect={() => selectAllPlatforms(null, isOnScansPage, isOnMerchPage)}
                onDeselect={deselectAllPlatforms}
              />
              {sortedPlatformGroups.map((platformGroup: any, index: number) => (
                <CheckboxList
                  key={`${platformGroup.first()?.get('id')}-${index}`}
                  platformGroup={platformGroup}
                  isOnScansPage={isOnScansPage}
                  isOnMerchPage={isOnMerchPage}
                  togglePlatforms={togglePlatforms}
                  platformsInSelectedTerritory={platformsInSelectedTerritory}
                />
              ))}
            </>
          </SingleSelect>
        )}
        {activeTab === PLATFORM && availableTabs.includes(PLATFORM) && (
          <>
            <h5 className={style.header}>{PLATFORMS_AND_REGIONS}</h5>
            <div className={style.platformList}>{children}</div>
          </>
        )}
      </li>
    </Tabs>
  ) : (
    <>
      {availableTabs.includes(PLATFORM) && (
        <div>
          <div className={style.bulkSelectorContainer}>
            <BulkSelector
              onSelect={() => selectAllPlatforms(null, isOnScansPage, isOnMerchPage)}
              onDeselect={deselectAllPlatforms}
            />
          </div>
          <div className={style.platformList}>{children}</div>
        </div>
      )}
      {availableTabs.includes(REGION) && (
        <div>
          <h5 className={style.listHeader}>{'Regions'}</h5>
          <BulkSelector onSelect={() => selectAllTerritories(null, isOnScansPage, isOnMerchPage)} onDeselect={deselectAllTerritories} />
          <MultiSelect onChange={onTerritoryChange} list={sortedTerritories}></MultiSelect>
        </div>
      )}
    </>
  )
}

export default PlatformAndRegionWithTabs