import React from 'react'
import CustomTooltip from './CustomTooltip'
import { PlatformsByRegion } from './interfaces'
import styles from './styles.module.scss'

type RenderPlatformProps = {
  platformsByRegion: PlatformsByRegion
  platformName: string
}

const RenderPlatform: React.FC<RenderPlatformProps> = ({ platformsByRegion, platformName }) => (
  <div className={styles.platformWrapper} key={platformName}>
    <span className={styles.platformLogo}>
      <CustomTooltip content={`${platformName}`}>
        <img
          className={styles.platformsItemImage}
          src={platformsByRegion[platformName].logoUrl}
          alt={`${platformName} platform logo`}
        />
      </CustomTooltip>
    </span>
    <ul className={styles.territories}>
      {platformsByRegion[platformName].territories.map(territory => (
        <li className={styles.territoriesItem} key={territory.flagUrl}>
          {territory.flagUrl && (
            <CustomTooltip content={<span>{territory.name}</span>}>
              <img
                src={territory.flagUrl}
                alt={`Flag for ${territory.flagUrl}`}
                className={styles.territoriesItemImage}
              />
            </CustomTooltip>
          )}
        </li>
      ))}
    </ul>
  </div>
)

export default RenderPlatform
