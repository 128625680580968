import React from 'react'
import GenericSection from './GenericSection'
import FlowcaseItem from './FlowcaseItem.jsx'
import style from './Flowcase.module.scss'
import styled from 'styled-components'
import SeeAll from './SeeAll'
import FormattingContext from './FormattingContext'

const FlowcaseWrapper = styled.div`
  width: calc((${props => props.spotWidth} + 20px) * ${props => props.spotCount});
`

class Flowcase extends GenericSection {
  constructor(props) {
    super(props)

    const { section } = this.props
    const { metadata } = section
    this.metadata = JSON.parse(metadata)
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'content')
  }

  spotsForRow(row) {
    return this.spots().filter((spot, index) => index % this.rowCount() === row)
  }

  spotColumnCount() {
    const spots = this.spots()

    return spots.size / this.rowCount()
  }

  rowCount() {
    const { rows } = this.metadata
    return rows || this.defaultRowCount()
  }

  defaultRowCount() {
    const { section } = this.props
    const { primary } = section
    return primary ? 0 : 1
  }

  spotKind() {
    const spots = this.spots()
    return (spots.first() && spots.first().kind) || 'movie'
  }

  spotWidth() {
    return this.spotKind() === 'movie' ? '105px' : '125px'
  }

  title() {
    const { section } = this.props
    const { name, primary } = section
    return section.sectionType === 'itunes::83' && !primary ? name : null
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  render() {
    const { section } = this.props

    const spots = this.spots()
    const rows = this.rowCount()
    const calculatedSpotWidth = this.spotWidth()

    return (
      <FormattingContext.Consumer>
        {formatting => {
          return (
            <div className={style.container}>
              {this.seeAll() && (
                <SeeAll withTitle={this.title()} titleTextColor={formatting.titleTextColor} spot={this.seeAll()} />
              )}

              {this.title() && (
                <h4 className={style.headline} style={{ color: formatting.titleTextColor }}>
                  {this.title()}
                </h4>
              )}
              {rows === 0 &&
                spots.map(
                  spot =>
                    spot && (
                      <FlowcaseItem
                        formatting={formatting}
                        key={spot.id}
                        spot={spot}
                        isMerchandiseFilteringEnabled={this.props.isMerchandiseFilteringEnabled}
                        client={this.isStudioSelected(spot.id)}
                        className={style.lockerRoomItem}
                        section={section}
                      />
                    ),
                )}
              <div className={style.scrollWrapper}>
                {rows === 1 && (
                  <FlowcaseWrapper
                    className={style.inner}
                    spotCount={this.spotColumnCount()}
                    spotWidth={calculatedSpotWidth}
                    spotKind={this.spotKind()}
                  >
                    {spots.map(
                      spot =>
                        spot && (
                          <FlowcaseItem
                            formatting={formatting}
                            key={spot.id}
                            spot={spot}
                            isMerchandiseFilteringEnabled={this.props.isMerchandiseFilteringEnabled}
                            client={this.isStudioSelected(spot.id)}
                            section={section}
                          />
                        ),
                    )}
                  </FlowcaseWrapper>
                )}

                {rows === 2 && (
                  <div>
                    <FlowcaseWrapper
                      className={style.inner}
                      spotCount={this.spotColumnCount()}
                      spotWidth={calculatedSpotWidth}
                    >
                      {this.spotsForRow(0).map(
                        spot =>
                          spot && (
                            <FlowcaseItem
                              formatting={formatting}
                              key={spot.id}
                              spot={spot}
                              isMerchandiseFilteringEnabled={this.props.isMerchandiseFilteringEnabled}
                              client={this.isStudioSelected(spot.id)}
                              section={section}
                            />
                          ),
                      )}
                    </FlowcaseWrapper>
                    <FlowcaseWrapper
                      className={style.inner}
                      spotCount={this.spotColumnCount()}
                      spotWidth={calculatedSpotWidth}
                    >
                      {this.spotsForRow(1).map(
                        spot =>
                          spot && (
                            <FlowcaseItem
                              formatting={formatting}
                              key={spot.id}
                              spot={spot}
                              isMerchandiseFilteringEnabled={this.props.isMerchandiseFilteringEnabled}
                              client={this.isStudioSelected(spot.id)}
                              section={section}
                            />
                          ),
                      )}
                    </FlowcaseWrapper>
                  </div>
                )}
              </div>
            </div>
          )
        }}
      </FormattingContext.Consumer>
    )
  }

  seeAll() {
    const { spots } = this.props

    return spots.find(spot => spot && spot.spotType === 'link')
  }
}

export default Flowcase
