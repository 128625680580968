import React from 'react'

import style from './SeeAll.module.scss'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import { linkToPage } from '../utils'

class SeeAll extends React.Component {
  render() {
    const { titleTextColor, withTitle, match, spot } = this.props
    const classnames = cx(style.root, {
      [style.withTitle]: withTitle,
    })

    return (
      <a href={linkToPage(spot, match)} className={classnames} style={{ color: titleTextColor }}>
        See All &gt;
      </a>
    )
  }
}

const ConnectedSeeMoreWithRouter = withRouter(SeeAll)
export default ConnectedSeeMoreWithRouter
