import React from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

class FluidTop extends React.Component {
  render() {
    const { children, className } = this.props
    return <div className={cx(styles.fluidTop, className)}>{children}</div>
  }
}

export default FluidTop
