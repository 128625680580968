import React from 'react'
import { Spot } from 'Interfaces/*'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './LinkExternal.module.scss'

type LinkExternalProps = RouteComponentProps & {
  spots: Spot[]
}

const LinkExternal: React.FC<LinkExternalProps> = ({ spots, match }) => {
  const spot = [...spots][0]
  const backgroundStyle = {
    backgroundImage: `url(${decodeURIComponent(spot?.artworkUrl)})`,
  }

  return (
    <div className={style.linkExternalContainer}>
      <a href={linkToPage(spot, match)}>
        <div className={style.backgroundImage} style={backgroundStyle}></div>
      </a>
    </div>
  )
}

export default withRouter(LinkExternal)
