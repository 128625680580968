import { Record, List } from 'immutable'
import { getBrandOptimizationScores, getOptimizationScores } from 'Selectors'
import { getRelationshipData } from './utils'

export default class ScanSummaryRecord extends Record({
  auditPriceActionsCount: 0,
  previousAuditPriceActionsCount: 0,
  auditHoldbackActionsCount: 0,
  auditAvailActionsCount: 0,
  previousAuditAvailActionsCount: 0,
  auditArtworkUberActionsCount: 0,
  previousAuditArtworkUberActionsCount: 0,
  optimizationScore: 0,
  auditActionsCount: 0,
  titlesCount: 0,
  downloads: [],
  territories: new List(),
  scanTitle: '',
  clientLogo: '',
  changeAmount: '',
  changeDescription: '',
  platforms: new List(),
  mostOptimizedBrandsIds: [],
  mostOptimizedTitlesIds: [],
  leastOptimizedBrandsIds: [],
  leastOptimizedTitlesIds: [],
}) {
  mostOptimizedBrands(state) {
    const brandOptimizationScores = getBrandOptimizationScores(state)

    return getRelationshipData(brandOptimizationScores, this.mostOptimizedBrandsIds)
  }

  leastOptimizedBrands(state) {
    const brandOptimizationScores = getBrandOptimizationScores(state)

    return getRelationshipData(brandOptimizationScores, this.leastOptimizedBrandsIds)
  }

  mostOptimizedTitles(state) {
    const optimizationScores = getOptimizationScores(state)

    return getRelationshipData(optimizationScores, this.mostOptimizedTitlesIds)
  }

  leastOptimizedTitles(state) {
    const optimizationScores = getOptimizationScores(state)

    return getRelationshipData(optimizationScores, this.leastOptimizedTitlesIds)
  }

  uniquePlatformsCount() {
    return new Set(this.platforms.map(platform => platform.name)).size
  }
}
