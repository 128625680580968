import React from 'react'
import { OrangeSectionTypes } from '../enums'
import style from './Container.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { linkToPage } from '../utils'
import { Spot, Section } from 'Interfaces/interfaces'
import cx from 'classnames'

type ContainerHeaderProps = RouteComponentProps & {
  seeMore: Spot | undefined
  section: Section
  hasBackgroundColor: boolean
}

const HeaderContent: React.FC<{
  section: Section
  seeMore: Spot | undefined
  match: any
  hasBackgroundColor: boolean
}> = ({ section, seeMore, match, hasBackgroundColor }) => {
  return (
    <div
      className={cx({ [style.headingLight]: !hasBackgroundColor, [style.headingDark]: hasBackgroundColor })}
      data-testid={`${section.sectionType.toLowerCase()}-header`}
    >
      <a className={style.headerText} href={seeMore ? linkToPage(seeMore, match) : ''}>
        <span className={style.highlighted}>{section.name.slice(0, 2)}</span>
        {section.name.slice(2)}
      </a>
      {seeMore && (
        <a className={style.viewAll} href={linkToPage(seeMore, match)}>
          {seeMore.name}
          <i className={style.rightArrow} />
        </a>
      )}
    </div>
  )
}

const ContainerHeader: React.FC<ContainerHeaderProps> = ({ section, seeMore, match, hasBackgroundColor }) => {
  switch (section.sectionType) {
    case OrangeSectionTypes._Container:
    case OrangeSectionTypes._LandscapeContainer:
    case OrangeSectionTypes._ChartContainer:
      return <HeaderContent section={section} seeMore={seeMore} match={match} hasBackgroundColor={hasBackgroundColor} />
    default:
      return null
  }
}

export default withRouter(ContainerHeader)
