import React from 'react'
import style from './TabMenu.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { Spot, Match, PageProps } from 'Interfaces/interfaces'
import { linkToPage } from '../utils'

type TabMenuProps = RouteComponentProps & {
  spots: Spot[]
  match: Match
  page: PageProps
}

const TabMenu: React.FC<TabMenuProps> = ({ spots, match }) => {
  const currentSpots = [...spots]

  return (
    <div className={style.wrapper}>

      <Popover
        interactionKind={PopoverInteractionKind.CLICK}
        position={Position.BOTTOM}
        popoverClassName="bp5-popover-display-none"
        content={<div className={style.dropdown}>
          <ul className={style.dropdownList}>
            {spots
              .filter(spot => !!spot)
              .map((spot, index) => (
                <li className={style.listItem} key={index}>
                  <a href={linkToPage(spot, match)}>{spot.name}</a>
                </li>
              ))}
          </ul>
        </div>}
      >
        <div className={style.firstItem}>
          <a>
            {currentSpots[0]?.name} <i className={style.arrowDown} data-testid="arrow-icon" />
          </a>
        </div>
      </Popover>
    </div>
  )
}

export default withRouter(TabMenu)
