import React from 'react'
import GenericSection from './GenericSection'

class Menu264 extends GenericSection {
  render() {
    return <div>{this.renderSubSections()}</div>
  }
}

export default Menu264
