import React from 'react'
import cx from 'classnames'
import styles from './Menu263.module.scss'
import GenericSection from './GenericSection'

class Menu263 extends GenericSection {
  render() {
    return <div className={cx(styles.root)}>{this.renderSubSections()}</div>
  }
}

export default Menu263
