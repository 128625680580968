import React from 'react'
import { Match } from 'Interfaces/*'
import Checkbox from './Checkbox'

const CheckboxList: React.FC<{
  platformGroup: any
  isOnScansPage: Match
  isOnMerchPage: Match
  platformsInSelectedTerritory: (platformGroup: any) => void
  togglePlatforms: (platformIds: string[], isOnScansPage: Match, isOnMerchPage: Match) => void
}> = ({ platformGroup, isOnScansPage, isOnMerchPage, platformsInSelectedTerritory, togglePlatforms }) => {
  const selectedPlatform = platformsInSelectedTerritory(platformGroup) as any
  return selectedPlatform ? (
    <Checkbox
      checked={selectedPlatform.selected}
      onChange={() => togglePlatforms(platformGroup.map(i => i.id).valueSeq(), isOnScansPage, isOnMerchPage)}
      label={selectedPlatform.name}
      key={selectedPlatform.get('id')}
    />
  ) : null
}

export default CheckboxList
