import { TERRITORIES } from './utils.js'

export const TOGGLE_TERRITORY = 'territory/TOGGLE_TERRITORY'
export const SELECT_TERRITORY = 'territory/SELECT_TERRITORY'
export const SELECT_ALL_TERRITORIES = 'territory/SELECT_ALL_TERRITORIES'
export const DESELECT_ALL_TERRITORIES = 'territory/DESELECT_ALL_TERRITORIES'
export const SELECT_TERRITORIES = 'territory/SELECT_TERRITORIES'
export const DESELECT_TERRITORY = 'territory/DESELECT_TERRITORY'

export default function territoryReducer(state, action) {
  switch (action.type) {
    case 'LOAD_TERRITORIES': {
      return state.withMutations(s => {
        s.set(TERRITORIES, action.territories)
      })
    }
    case SELECT_TERRITORY: {
      const { territoryId } = action
      return state.withMutations(s => {
        s.setIn([TERRITORIES, territoryId, 'selected'], true)
      })
    }
    case TOGGLE_TERRITORY: {
      const { territoryId, isOnScansPage } = action
      const territory = state.getIn([TERRITORIES, territoryId])
      const allterritory = state.getIn([TERRITORIES])

      return state.withMutations(s => {
        if (isOnScansPage) {
          allterritory.forEach(territory => {
            s.setIn([TERRITORIES, territory.id, 'selected'], false)
          })
        }
        s.setIn([TERRITORIES, territoryId, 'selected'], !territory.selected)
      })
    }
    case SELECT_ALL_TERRITORIES: {
      const allTerritories = state.getIn([TERRITORIES])

      return state.withMutations(s => {
        allTerritories.forEach(territory => {
          s.setIn([TERRITORIES, territory.id, 'selected'], true)
        })
      })
    }
    case DESELECT_ALL_TERRITORIES: {
      const allTerritories = state.getIn([TERRITORIES])

      return state.withMutations(s => {
        allTerritories.forEach(territory => {
          s.setIn([TERRITORIES, territory.id, 'selected'], false)
        })
      })
    }
    case SELECT_TERRITORIES: {
      const { territoryIds } = action
      const allTerritories = state.getIn([TERRITORIES])
      return state.withMutations(s => {
        allTerritories.forEach(territory => {
          if (territoryIds.includes(territory.id)) {
            s.setIn([TERRITORIES, territory.id, 'selected'], true)
          }
        })
      })
    }
    case DESELECT_TERRITORY: {
      const { territoryId } = action
      return state.withMutations(s => {
        s.setIn([TERRITORIES, territoryId, 'selected'], false)
      })
    }
    default:
      return state
  }
}
