import PlatformRecord from 'Records/platformRecord'
import { Map, OrderedMap } from 'immutable'
import { TOGGLE_PLATFORMS, SELECT_ALL_PLATFORMS, DESELECT_ALL_PLATFORMS } from 'Reducers/app/platformReducer'
import { reloadScan } from 'Actions/scanActions'
import { getPlatformById, getClient } from 'Selectors'
import { apiRequest } from 'Actions/apiActions'
import { platformTitlesUpdatingState } from 'Actions/platformTitleActions'
import { reloadTitles } from 'Actions/titleActions'
import { readEndpoint } from 'Actions/apiActions'
import queryString from 'query-string'

export const loadPlatforms = platforms => ({
  type: 'LOAD_PLATFORMS',
  platforms,
})

export const platformRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new PlatformRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: new Map(item.relationships),
    }),
  )
}

export const retrievePlatforms = clientId => async dispatch => {
  const response = await readEndpoint(`clients/${clientId}/platforms`)

  dispatch(normalizeAndLoadPlatforms(response.platforms))
}

export const normalizeAndLoadPlatforms = data => {
  const { platformIds } = queryString.parse(window.location.search)
  const selectedPlatformIds = platformIds?.split(',') || []

  const selectedPlatformCodes = data.reduce((memo, item) => {
    if (!selectedPlatformIds.length) return memo

    if (!memo.includes(item.code) && selectedPlatformIds.includes(item.id.toString())) memo.push(item.code)

    return memo
  }, [])

  const platforms = data.reduce((memo, item) => {
    if (data.some(p => selectedPlatformCodes.includes(p.code))) {
      item.selected = selectedPlatformCodes.includes(item.code)
    }
    return memo.set(item.id, new PlatformRecord({ ...item }))
  }, new OrderedMap())

  return loadPlatforms(platforms)
}

export const selectAllPlatforms = (competitors, isOnScansPage, isOnMerchPage) => dispatch => {
  dispatch({
    type: SELECT_ALL_PLATFORMS,
  })

  dispatch(reloadData(competitors, isOnScansPage, isOnMerchPage))
}

export const deselectAllPlatforms = () => dispatch => {
  dispatch({
    type: DESELECT_ALL_PLATFORMS,
  })
}

export const togglePlatforms = (platformIds, competitors, isOnScansPage, isOnMerchPage) => dispatch => {
  dispatch({
    type: TOGGLE_PLATFORMS,
    platformIds,
  })

  dispatch(reloadData(competitors, isOnScansPage, isOnMerchPage))
}

const reloadData = (competitors, isOnScansPage, isOnMerchPage) => dispatch => {
  if (isOnMerchPage) return
  if (isOnScansPage) return dispatch(reloadScan(competitors))

  dispatch(reloadTitles())
}

export const markAllAsVirtualPlatformTitles = platformId => (dispatch, getState) => {
  const state = getState()
  const platfrom = getPlatformById(state, { id: platformId })
  const client = getClient(state)

  const markAllAsVirtualUrl = platfrom.getIn(['links', 'mark_all_as_virtual'])
  dispatch(platformTitlesUpdatingState(true))
  return dispatch(apiRequest(markAllAsVirtualUrl, 'POST', { clientId: client.id })).then(() => {
    dispatch(platformTitlesUpdatingState(false))
  })
}
