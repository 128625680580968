import React from 'react'
import style from './Deals.module.scss'

function HeaderImage(props) {
  const metadata = JSON.parse(props.section.metadata)

  return <div className={style.headerImage} style={{ backgroundImage: `url(${metadata.artwork})` }}></div>
}

export default HeaderImage
