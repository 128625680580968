import React from 'react'
import WrappedSection from 'Containers/WrappedSection'
import style from './Page.module.scss'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { isFandangoScan } from '../helpers'

function Page({ page, scan, rootSections }) {
  const { platformCode } = scan
  const vuduPage = ['vudu', 'fandango_at_home'].includes(platformCode)

  const classNames = cx(style.page, {
    [style.vuduPage]: vuduPage,
    [style.tubiPage]: platformCode.includes('tubi'),
    [style.cineplexPage]: platformCode.includes('cineplex'),
    [style.newVuduPage]: isFandangoScan(scan) || platformCode.includes('fandango_at_home'),
  })

  const containerClass = cx({
    [style.pageContainer]: ['vudu', 'tubi', 'xbox', 'fandango_at_home'].includes(platformCode),
    [style.cineplexPageContainer]: scan.platformCode.includes('cineplex'),
  })

  return (
    <div className={classNames}>
      <div className={containerClass}>
        {rootSections.valueSeq().map(s => (
          <React.Fragment key={s.id}>
            {!page.isRoot && vuduPage && (
              <div className={style.vuduPageName}>
                <h1>{page.name}</h1>
              </div>
            )}
            <WrappedSection type={s.sectionType} scan={scan} page={page} section={s} />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

Page.propTypes = {
  page: PropTypes.object,
  scan: PropTypes.object,
  rootSections: PropTypes.object,
}

export default Page
