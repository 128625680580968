import React from 'react'
import style from './ChannelItem.module.scss'
import cx from 'classnames'
import { scrollToNode } from '../utils'

class ChannelItem extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, isMerchandiseFilteringEnabled, client, className, section } = this.props
    const inlineStyle = {}

    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    const classNames = cx(className, style.item, {
      [style.highlight]: spot.highlight,
      [style.sectionFade]: section.selected || (isMerchandiseFilteringEnabled && !client),
      [style.spotHighlight]: spot.selected || client,
    })

    return (
      <div key={spot.id} className={classNames} style={inlineStyle} ref={node => (this.node = node)}>
        <div className={style.artwork} style={{ backgroundImage: `url(${spot.artworkUrl})` }}></div>
        <div className={style.info}>
          <h5>{spot.name}</h5>
          <p>Genre: {spot.genre}</p>
          <p>
            {spot.description.substring(0, 500)}
            {spot.description.length > 500 && '...'}
          </p>
        </div>
      </div>
    )
  }
}

export default ChannelItem
