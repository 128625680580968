import React, { useMemo } from 'react'
import Item from './Item'
import { PageItems } from 'Interfaces/interfaces'
import style from './Grid.module.scss'
import { renderSpotsWithItem, computeContainerClasses } from '../helpers'
import cx from 'classnames'
import { OrangeSectionTypes } from '../enums'

const Grid: React.FC<PageItems> = ({ spots, selectedStudios, section, isMerchandiseFilteringEnabled }) => {
  const containerClasses = useMemo(() => computeContainerClasses(section, style, 4), [section])

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.gridItem]: section.sectionType === OrangeSectionTypes._Grid,
        [style.landscapeGridItem]: section.sectionType === OrangeSectionTypes._LandscapeGrid,
      }),
    [section],
  )

  const spotsRender = renderSpotsWithItem({
    spots: spots.filter(spot => spot),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  return (
    <div className={containerClasses}>
      <p className={style.header}>
        <span className={style.highlighted}>{section.name.slice(0, 2)}</span>
        {section.name.slice(2)}
      </p>
      <div className={style.spotWrapper}>{spotsRender}</div>
    </div>
  )
}

export default Grid
