import React from 'react'
import { Popover, PopoverInteractionKind, Position, Classes } from '@blueprintjs/core'
import styles from './styles.module.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import cx from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'

interface PopoverItem {
  id?: string
  url: string
  title: string
}

interface NavPopoverProps {
  isOpen: boolean
  setIsOpen: (_state: boolean) => void
  items: PopoverItem[]
  next?: () => void
  hasMore?: boolean
  iconClassName: string
  historyPopover?: boolean
  onItemClick?: (_id) => void
}

const NavPopover = ({
  isOpen,
  setIsOpen,
  items,
  next = () => { },
  historyPopover = false,
  hasMore = false,
  iconClassName,
  onItemClick,
}: NavPopoverProps) => {
  const location = useLocation()

  return (
    <Popover
      interactionKind={PopoverInteractionKind.CLICK}
      isOpen={isOpen}
      onInteraction={state => setIsOpen(state)}
      popoverClassName="bp5-popover-display-none fixed-list"
      placement={Position.BOTTOM}
      content={<InfiniteScroll dataLength={items.length || 0} next={next} hasMore={hasMore} style={{ maxHeight: 210 }} loader={<div>Loading...</div>}>
        <div className={styles.navigationList}>
          {items.map(item => (
            <NavLink
              onClick={() => onItemClick?.(item.id)}
              key={item.id}
              className={isActive =>
                cx({ [styles.rotating]: isActive }, 'bp5-button bp5-minimal', Classes.POPOVER_DISMISS)
              }
              to={{
                pathname: item.url,
                search: location.search,
              }}
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </InfiniteScroll>}
    >
      <button
        className={cx('bp5-button', styles.navButton, iconClassName, {
          [styles.historyButton]: historyPopover,
        })}
      />

    </Popover>
  )
}

export default NavPopover
