import { Map, fromJS, OrderedMap } from 'immutable'

export const filterRecord = type => item => item.type === type

export const recordMapper = Record => (memo, item) => {
  return memo.set(
    item.id,
    new Record({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    }),
  )
}

export const resetData = loadMethod => loadMethod(new OrderedMap(), true)

export const removeProperties = (obj, props) => {
  const newObj = { ...obj }
  props.forEach(prop => delete newObj[prop])
  return newObj
}