import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './Links.module.scss'

function Links(props) {
  const { spots, match, section } = props
  const metadata = JSON.parse(section.metadata)

  return spots.map(spot => {
    if (spot) {
      const linkUrl = section.sectionType === 'redbox::link_external' ? spot.url : linkToPage(spot, match)
      return (
        <a
          key={spot.id}
          href={linkUrl}
          className={style.linkWrapper}
          style={{ background: `black url(${metadata.artwork}) center center no-repeat`, backgroundSize: '100% 100%' }}
        ></a>
      )
    }
  })
}

export default withRouter(Links)
