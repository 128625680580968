import { Record, Map, List } from 'immutable'
import { getMerchandizingSections, getMerchandizingMatches } from 'Selectors'
import { createSelector } from 'reselect'

export default class PageRecord extends Record({
  id: 0,
  selected: false,
  scanId: false,
  isRoot: true,
  rootSectionsCount: 0,
  displayName: null,
  sectionIds: [],
  name: '',
  metadata: null,
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {
  // All sections for page
  sections(state) {
    const sections = getMerchandizingSections(state)
    if (!sections || sections.isEmpty()) return new Map()

    const sectionsRel = this.getIn(['relationships', 'sections', 'data']) || new Map()

    return (
      sectionsRel &&
      sectionsRel.map(item => sections.get(item.get('id')) && sections.get(item.get('id')).toList(state)).flatten(true)
    )
  }

  // All spots for page
  spots(state) {
    const sections = this.sections(state)
    if (!sections || sections.isEmpty()) return new Map()
    let spots = new List()

    sections &&
      sections.forEach(section => {
        spots = spots.concat(section.spots(state))
      })

    return spots
  }

  // Sections without parent
  rootSections(state) {
    const sections = getMerchandizingSections(state)
    if (!sections || sections.isEmpty()) return new Map()

    return new List(this.sectionIds.map(id => sections.get(id)).filter(s => s && s.isRoot))
  }

  linkToAdmin() {
    const { links } = this
    return links.get('page')
  }

  getMatches(state) {
    this.getOurMatches =
      this.getOurMatches ||
      createSelector(
        getMerchandizingMatches,
        (state, props) => props.pageId,
        (matches, pageId) => matches.filter(m => m.pageId.toString() === pageId.toString()).toList(),
      )
    return this.getOurMatches(state, { pageId: this.id })
  }
}
