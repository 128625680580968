import React from 'react'
import TwinHeroItem from './TwinHeroItem'

class GoogleTwinheroCarousel extends React.Component {
  render() {
    const { spots, client, section, hideTitle, isMerchandiseFilteringEnabled } = this.props

    return spots.map(
      spot =>
        spot && (
          <TwinHeroItem
            key={spot.id}
            spot={spot}
            isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
            client={client}
            section={section}
            hideTitle={hideTitle}
          />
        ),
    )
  }
}

export default GoogleTwinheroCarousel
