import React from 'react'
import { Tooltip, TooltipProps } from '@blueprintjs/core'

interface CustomTooltipProps extends TooltipProps {
  children: React.ReactNode
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ children, ...props }) => {
  return <Tooltip {...props}>{children}</Tooltip>
}

export default CustomTooltip
