import { useState } from 'react'

const useContainerActions = () => {
  const [modalOptions, setModalOptions] = useState({
    triggeredActionName: null,
    triggeredAction: null,
    isOpen: false,
  })

  const handleAction = (triggeredActionName, triggeredAction) => {
    setModalOptions({
      triggeredActionName,
      triggeredAction,
      isOpen: true,
    })
  }

  const handleCloseModal = () => {
    setModalOptions({
      triggeredActionName: null,
      triggeredAction: null,
      isOpen: false,
    })
  }

  const handleSubmitAction = () => {
    modalOptions.triggeredAction()
    handleCloseModal()
  }

  return {
    triggeredActionName: modalOptions.triggeredActionName,
    triggeredAction: modalOptions.triggeredAction,
    isModalOpen: modalOptions.isOpen,
    handleAction,
    handleCloseModal,
    handleSubmitAction,
  }
}

export default useContainerActions
