import React from 'react'
import cx from 'classnames'
import styles from './ContainerBadge.module.scss'
import inflection from 'inflection'

class ContainerBadge extends React.Component {
  render() {
    const { platformTitle, className } = this.props

    const states = [
      'approved',
      'locating',
      'pending',
      'error',
      'artwork_refresh_error',
      'unavailable',
      'unavailable_forever',
      'located',
      'virtual',
    ]

    const badgeClasses = states.reduce((memo, state) => {
      memo[styles[state]] = platformTitle && platformTitle.currentState === state
      return memo
    }, {})

    return (
      <span className={cx(className, badgeClasses, styles.badge)}>
        {inflection.humanize(platformTitle.currentState)}
      </span>
    )
  }
}

export default ContainerBadge
