import { matchPath } from 'react-router'

const CLIENT_PATH = `/v2/clients`
export const ADMIN_P_A_TITLE_PATH = {
  url: `${CLIENT_PATH}/:id/scans/:scanId/title/:titleId`,
  name: `Pricing & Availability`,
}
export const CLIENT_P_A_TITLE_PATH = {
  url: `/scans/:scanId/dashboard/availability_pricing/title/:titleId`,
  name: `Pricing & Availability`,
}

export const ADMIN_P_A_PATH = { url: `${CLIENT_PATH}/:id/scans/:scanId`, name: `Pricing & Availability` }
export const CLIENT_P_A_PATH = { url: `/scans/:scanId/dashboard/availability_pricing`, name: `Pricing & Availability` }

export const ADMIN_DASHBOARD_PATH = { url: `${CLIENT_PATH}/:id/scans/:scanId/overview`, name: `Dashboard` }
export const CLIENT_DASHBOARD_PATH = { url: `/scans/:scanId/dashboard`, name: `Dashboard` }

export const ADMIN_ARTWORK_PATH = { url: `${CLIENT_PATH}/:id/scans/:scanId/artwork`, name: `Artwork` }
export const CLIENT_ARTWORK_PATH = { url: '/scans/:scanId/dashboard/artwork', name: `Artwork` }

export const ADMIN_MERCHANDISING_PATH = {
  url: `${CLIENT_PATH}/:clientId/merchandizing/scans/:scanId/pages/:pageId`,
  name: `Merchandising`,
}
export const CLIENT_MERCHANDISING_PATH = {
  url: `/dashboard/merch/:scanId/pages/:pageId`,
  name: `Merchandising`,
}

export const ADMIN_ANALYTICS_PATH = { url: `${CLIENT_PATH}/:id/merchandizing/report`, name: `Analytics` }

export const ADMIN_COMPETITORS_PATH = { url: `${CLIENT_PATH}/:id/scans/:scanId/competitors`, name: `Competitors` }
export const CLIENT_COMPETITORS_PATH = { url: `/scans/:scanId/dashboard/competitors`, name: `Competitors` }

export const ADMIN_MPV_ANALYTICS = { url: `${CLIENT_PATH}/:id/scans/:scanId/mpv/analytics`, name: 'MPV Analytics' }
export const CLIENT_MPV_ANALYTICS = { url: '/scans/:scanId/mpv/analytics', name: 'MPV Analytics' }

export const ALL_PATHS = [
  ADMIN_P_A_TITLE_PATH,
  CLIENT_P_A_TITLE_PATH,
  ADMIN_P_A_PATH,
  CLIENT_P_A_PATH,
  ADMIN_DASHBOARD_PATH,
  CLIENT_DASHBOARD_PATH,
  ADMIN_ARTWORK_PATH,
  CLIENT_ARTWORK_PATH,
  ADMIN_MERCHANDISING_PATH,
  CLIENT_MERCHANDISING_PATH,
  ADMIN_ANALYTICS_PATH,
  ADMIN_COMPETITORS_PATH,
  CLIENT_COMPETITORS_PATH,
  ADMIN_MPV_ANALYTICS,
  CLIENT_MPV_ANALYTICS,
]

export const findPath = pathname => {
  return ALL_PATHS.find(path => {
    return matchPath(pathname, {
      path: path.url,
      exact: true,
    })
  })
}
