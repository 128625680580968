import React from 'react'
import GenericSection from './GenericSection'
import style from './Flowcase.module.scss'
import cx from 'classnames'
import { scrollToNode } from '../utils'

class FlowcaseItem extends GenericSection {
  isTV() {
    const { spot } = this.props
    return spot.kind === 'tvEpisode' || spot.kind === 'tvSeason'
  }

  caption() {
    const { spot } = this.props

    if (this.isTV()) {
      return spot.name.split(',')[0]
    } else {
      return spot.name
    }
  }

  subCaption() {
    const { spot } = this.props

    if (this.isTV()) {
      return spot.collectionName || spot.name.split(',')[1] || spot.name
    } else {
      return null
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { formatting, spot, isMerchandiseFilteringEnabled, client, className, section } = this.props
    const inlineStyle = {
      backgroundImage: `url(${spot.artworkUrl})`,
    }

    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    const classNames = cx(className, style.item, {
      // [style.highlight]: spot.highlight,
      [style.tvEpisode]: this.isTV(),
      [style.sectionFade]: section.selected || (isMerchandiseFilteringEnabled && !client),
      [style.spotHighlight]: spot.selected || client,
    })

    return (
      <div className={classNames} ref={node => (this.node = node)}>
        <div id={`spot_${spot.id}`} className={style.artwork} style={inlineStyle} />
        <div className={style.caption} style={{ color: formatting.primaryTextColor }}>
          {this.caption()}
        </div>
        <div className={style.subCaption}>{this.subCaption()}</div>
      </div>
    )
  }
}

export default FlowcaseItem
