import React from 'react'
import style from './AccordionMenu.module.scss'
import PropTypes from 'prop-types'
import WrappedSection from 'Containers/WrappedSection'
import AccordionMenuItem from 'Components/Merchandizing/Xbox/AccordionMenuItem'

function AccordionMenu(props) {
  const { section, spots } = props
  return section.isRoot ? renderSubSections(props) : <AccordionMenuItem spots={spots} section={section} />
}

const renderSubSections = props => {
  const { subSections, scan, page } = props
  return (
    subSections && (
      <div className={style.accordionContainer}>
        {subSections.map(
          s => s && <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />,
        )}
      </div>
    )
  )
}

AccordionMenu.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
}

export default AccordionMenu
