import React from 'react'
import MerchandizingMatchDetail from 'Containers/MerchandizingMatchDetail'
import cx from 'classnames'
import styles from './styles.module.scss'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Spinner } from '@blueprintjs/core'
import MerchandizingFilter from './MerchandizingFilter'
import TerritoryRecord from 'Records/territoryRecord'
import PlatformRecord from 'Records/platformRecord'

class MerchPanel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedPlatformId: null,
      selectedPlatformsIds: [],
      selectedTerritoriesIds: [],
    }
  }

  onSelectPlatform = event => {
    this.setState({ selectedPlatformId: event.target.value })
  }

  setSelectedPlatofrmIds = platformIds => {
    this.setState({ selectedPlatformsIds: platformIds })
  }

  setSelectedTerritoriesIds = territoryIds => {
    this.setState({ selectedTerritoriesIds: territoryIds })
  }

  renderMerchandizingMatches = merchandizingMatches => {
    const { titleAudit, scan } = this.props
    return merchandizingMatches.map(match => {
      return (
        <MerchandizingMatchDetail
          match={match}
          titleAudit={titleAudit}
          key={match.id}
          selectedPlatformId={this.state.selectedPlatformId}
          scan={scan}
        />
      )
    })
  }

  render() {
    const { platforms, territories, isTitleAuditLoading, scan } = this.props
    let { merchandizingMatches } = this.props

    if (this.state.selectedPlatformsIds.length) {
      merchandizingMatches = merchandizingMatches.filter(match =>
        this.state.selectedPlatformsIds.includes(match.platformId),
      )
    }

    return (
      <div className={styles.merchWrapper}>
        <table className={cx('bp5-table bp5-striped bp5-condensed', styles.table)}>
          <thead>
            <tr>
              <th>
                <div className={styles.filterContainer}>
                  Platform
                  <MerchandizingFilter
                    selectedPlatformsIds={this.state.selectedPlatformsIds}
                    selectedTerritoriesIds={this.state.selectedTerritoriesIds}
                    territories={territories.map(
                      t =>
                        new TerritoryRecord({
                          ...t.toJS(),
                          selected: this.state.selectedTerritoriesIds.includes(t.id),
                        }),
                    )}
                    platforms={platforms.map(
                      p =>
                        new PlatformRecord({ ...p.toJS(), selected: this.state.selectedPlatformsIds.includes(p.id) }),
                    )}
                    setSelectedPlatofrmIds={this.setSelectedPlatofrmIds}
                    setSelectedTerritoriesIds={this.setSelectedTerritoriesIds}
                  />
                </div>
              </th>
              <th>Territory</th>
              <th>Page</th>
              <th>Page Depth</th>
              <th>Section</th>
              <th>Section Position</th>
              {scan.isMpvCalculated && <th>MPV</th>}
              <th>Spot Type</th>
              <th>Position</th>
              <th>Total Spots</th>
            </tr>
          </thead>
          <tbody>
            {isTitleAuditLoading ? <Spinner /> : this.renderMerchandizingMatches(merchandizingMatches)}
            { }
          </tbody>
        </table>
      </div>
    )
  }
}

MerchPanel.propTypes = {
  titleAudit: PropTypes.object,
  platforms: PropTypes.instanceOf(List),
  merchandizingMatches: PropTypes.instanceOf(List),
}

export default MerchPanel
