import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRootPath } from 'Actions/uiActions'
import { RouteComponentProps } from 'react-router-dom'
import { getSelectedScan, getClient } from 'Selectors'
import { retrieveSalesSummary } from 'Actions/clientActions'
import Overview from 'Containers/OverviewPageAdmin'
import useScan from 'Hooks/useScan'

interface Props extends RouteComponentProps<{ clientId: string; scanId: string }> {
  clientId: number
  scanId: number
}

const OverviewPage = ({ match, clientId, scanId }: Props) => {
  const dispatch = useDispatch()
  const client = useSelector(state => getClient(state))
  const scan = useSelector(state => getSelectedScan(state))

  useScan(scanId)

  useEffect(() => {
    dispatch(setRootPath(match.url))
  }, [dispatch, match.url])

  useEffect(() => {
    if (!client.toSeq().isEmpty() && scan) {
      dispatch(retrieveSalesSummary())
    }
  }, [client, scan, dispatch])

  return <div>{scan && <Overview scanId={scanId} clientId={clientId || client.id} />}</div>
}

export default OverviewPage
