import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

class TopBottom extends React.Component {
  render() {
    const { children, className } = this.props
    return <div className={cx(styles.root, className)}>{children}</div>
  }
}

TopBottom.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
}

export default TopBottom
