import React, { useCallback, useMemo } from 'react'
import Item from './Item'
import { PageItems, Section, PageProps, Scan } from 'Interfaces/interfaces'
import style from './Grid.module.scss'
import { renderSpotsWithItem, computeContainerClasses } from '../helpers'
import WrappedSection from 'Containers/WrappedSection'

type GridProps = PageItems & {
  subSections: Section[]
  page: PageProps
  scan: Scan
}

const Grid: React.FC<GridProps> = ({
  spots,
  selectedStudios,
  section,
  subSections,
  scan,
  page,
  isMerchandiseFilteringEnabled,
}) => {
  const containerClasses = useMemo(() => computeContainerClasses(section, style, 4), [section])
  const { name, metadata } = section
  const { subtitle, artwork: backroundImage } = JSON.parse((metadata as unknown) as string)

  const spotsRender = renderSpotsWithItem({
    spots: spots.filter(spot => spot),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.gridItem,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const renderDropdown = useCallback(() => {
    return subSections.map(s => <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
  }, [subSections, scan, page])

  return (
    <>
      {backroundImage && (
        <div
          style={{ backgroundImage: `url(${decodeURIComponent(backroundImage)})` }}
          className={style.sectionBackgroundImage}
        />
      )}
      <div className={containerClasses}>
        <div className={style.sectionHeader}>
          <h1>{name}</h1>
          <p>{subtitle}</p>
        </div>
        {renderDropdown()}
        <div className={style.spotWrapper}>{spotsRender}</div>
      </div>
    </>
  )
}

export default Grid
