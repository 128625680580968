import React from 'react'
import { connect } from 'react-redux'
import { getTerritoryById, getPlatformById } from 'Selectors'
import styles from 'Components/TitleDetail/styles.module.scss'
import cx from 'classnames'
import { isValidPlatformTitleToShowUrl, openLinkOnPopup } from '../components/utils'

class PlatformTitleDetail extends React.Component {
  render() {
    const { platform, territory, platformTitle } = this.props
    const classnames = cx(styles.platformItem)
    if (platformTitle && !isValidPlatformTitleToShowUrl(platformTitle)) {
      return null
    }
    return (
      <tr className={classnames}>
        <td>{platform && platform.name}</td>
        <td>{territory && territory.isoCode}</td>
        <td>
          {platformTitle && isValidPlatformTitleToShowUrl(platformTitle) && (
            <a onClick={() => openLinkOnPopup(platformTitle.url)}>Visit</a>
          )}
        </td>
      </tr>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { titleAudit, platformId } = ownProps
  const platform = getPlatformById(state, { id: platformId })
  const audit = titleAudit.getAuditForPlatform(state, platformId)
  const platformTitle = audit && audit.platformTitle(state)

  const territory = platform && getTerritoryById(state, { id: platform.territoryId })
  return {
    platform,
    territory,
    audit,
    platformTitle,
  }
}

export default connect(mapStateToProps)(PlatformTitleDetail)
