import React from 'react'
import style from './Item.module.scss'
const imdbLogo = require('./imdb.png')

type ContainerDetailsProps = {
  imdbRating: string | number
  year: string | number
  season: string
}

const ContainerDetails: React.FC<ContainerDetailsProps> = ({ imdbRating, year, season }) => (
  <span className={style.containerDetails}>
    {imdbRating && (
      <>
        <img src={imdbLogo} alt="imdb" /> <span>{imdbRating}</span>
      </>
    )}
    {!!year && <span> | {year}</span>}
    {!!season && <span> | {season}</span>}
  </span>
)

export default ContainerDetails
