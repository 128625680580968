import { Record, Map } from 'immutable'
import { getArtworkComparisons } from 'Selectors'

export default class AuditArtworkRecord extends Record({
  id: 0,
  url: '',
  auditId: 0,
  artworkType: '',
  width: 0,
  height: 0,
  duplicate: 0,
  distance: 0,
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
  selected: false,
  artworkComparisonId: 0,
}) {
  isKey() {
    return this.artworkType === 'key'
  }

  artworkComparison(state) {
    const artworkComparisons = getArtworkComparisons(state)

    return artworkComparisons.get(this.artworkComparisonId)
  }
}
