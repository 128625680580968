import React from 'react'
import cx from 'classnames'
import { connect } from 'react-redux'
import style from 'Components/Merchandizing/Match.module.scss'
import { selectMerchandizingSpot } from 'Actions/merchandizing/spotActions'

import { selectMerchandizingSection } from 'Actions/merchandizing/sectionActions'

import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'

class Match extends React.Component {
  artworkUrl() {
    const { artwork } = this.props

    return artwork && artwork.url
  }

  onClick(e) {
    const { selectMerchandizingSection, match, selectMerchandizingSpot, spot } = this.props

    selectMerchandizingSection(match.sectionId)
    selectMerchandizingSpot(spot)
    e.stopPropagation()
  }

  render() {
    const { title, spot } = this.props
    const posterStyle = {
      backgroundImage: `url(${this.artworkUrl()})`,
    }
    const classnames =
      spot &&
      cx(style.match, {
        [style.selected]: spot.selected,
      })
    return (
      <div className={classnames} onClick={this.onClick.bind(this)}>
        <div className={style.imageContainer}>
          <Popover
            useSmartArrowPositioning={true}
            interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
            popoverClassName="bp5-dark bp5-popover-content-sizing"
            position={Position.TOP}
            content={<div>{title && title.name}</div>}
          >
            <div className={style.posterImage} style={posterStyle} />
          </Popover>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps
  return {
    titleAudit: match && match.titleAudit(state),
    title: match && match.title(state),
    artwork: match.artwork(state),
    spot: match.spot(state),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectMerchandizingSpot: spot => dispatch(selectMerchandizingSpot(spot)),
    selectMerchandizingSection: sectionId => dispatch(selectMerchandizingSection(sectionId)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Match)
