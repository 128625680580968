import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'

import moment from 'moment'
import Panel from 'Components/Panel'
import PanelSection from 'Components/PanelSection'
import BigNumber from 'Components/BigNumber'
import { getTitlesForCorrection } from 'Selectors'
import styles from 'Components/ScanPage/SidePanel.module.scss'
import { showScanAsList } from 'Actions/uiActions'
import cx from 'classnames'
import { ignoreAction } from 'Actions/auditActions'
import PriceLegendItem from 'Components/ScanPage/SidePanel/PriceLegendItem'

class SidePanel extends React.Component {
  getSubmittedDate() {
    const { scan, submittedActionDate } = this.props
    if (moment(scan.srpExportDate).isValid()) {
      return moment(scan.srpExportDate).format('MMMM Do YYYY')
    }

    return moment(submittedActionDate).format('MMMM Do YYYY')
  }

  render() {
    const {
      scanSummary,
      showScanStats,
      showScanAsList,
      scan,
      platforms,
      titlesForCorrectionReady,
      titlesForCorrectionSubmitted,
      readyActionCount,
      submittedActionCount,
    } = this.props

    return (
      <div className={styles.root}>
        {(submittedActionCount > 0 || readyActionCount > 0) && (
          <Panel title="Pricing & Avails Updates">
            {scan && readyActionCount > 0 && titlesForCorrectionReady && (
              <PanelSection flex>
                <div>
                  <div className={styles.exportHeader}>
                    <h5 className={styles.actionCount}>{readyActionCount}</h5>
                    <p>
                      Due to be submitted{' '}
                      {moment()
                        .isoWeekday(5)
                        .format('MMMM Do YYYY')}
                    </p>
                  </div>
                  {titlesForCorrectionReady.valueSeq().map(title => {
                    return <p key={title.get('id')}>{title.get('name')}</p>
                  })}
                </div>
              </PanelSection>
            )}
            {scan && submittedActionCount > 0 && titlesForCorrectionSubmitted && (
              <PanelSection flex>
                <div>
                  <div className={styles.exportHeader}>
                    <h5 className={cx(styles.actionCount, styles.submittedCount)}>{submittedActionCount}</h5>
                    <p>Submitted on {this.getSubmittedDate()}</p>
                  </div>
                  {titlesForCorrectionSubmitted.valueSeq().map(title => {
                    return <p key={title.get('id')}>{title.get('name')}</p>
                  })}
                </div>
              </PanelSection>
            )}
          </Panel>
        )}
        {scan && (
          <Panel title="Audit Report Overview" date={scan.scheduledAt || scan.createdAt} onClose={showScanStats}>
            <PanelSection fullWidth>
              <PanelSection flex noPadding>
                {scan && <BigNumber number={scanSummary.titlesCount} figure="Titles" />}
              </PanelSection>
              <PanelSection flex noPadding>
                {platforms && <BigNumber number={platforms.size} figure="Platforms" />}
              </PanelSection>
              <PanelSection flex noPadding>
                {scan && (
                  <BigNumber
                    number={
                      scanSummary.auditPriceActionsCount +
                      scanSummary.auditHoldbackActionsCount +
                      scanSummary.auditAvailActionsCount
                    }
                    figure="Actions"
                  />
                )}
              </PanelSection>
            </PanelSection>
          </Panel>
        )}
        <Panel showScanAsList={showScanAsList} title="Actions Breakdown">
          <PanelSection fullWidth>
            <PanelSection flex noPadding>
              <BigNumber number={scanSummary.auditPriceActionsCount} figure="Pricing" />
            </PanelSection>
            <PanelSection flex noPadding>
              <BigNumber number={scanSummary.auditHoldbackActionsCount} figure="Holdbacks" />
            </PanelSection>
            <PanelSection flex noPadding>
              <BigNumber number={scanSummary.auditAvailActionsCount} figure="Avails" />
            </PanelSection>
          </PanelSection>
        </Panel>
        <Panel className={styles.legend} title="Price Legend">
          <PanelSection fullWidth>
            <PanelSection className={styles.legendSection} flex noPadding>
              <PriceLegendItem
                circleStyle={styles.priceAction}
                text="Pricing does not match its SRP and needs updating"
              />
              <PriceLegendItem
                circleStyle={styles.appliedException}
                text="Title is under TPR and is applied in store"
              />
              <PriceLegendItem
                circleStyle={styles.notAppliedException}
                text="Title should be under TPR but is not applied in store"
              />
              <PriceLegendItem
                circleStyle={styles.supress}
                text="Actions have been suppressed for this title for a specified period"
              />
              <div className={cx(styles.legendRow, styles.naLegendRow)}>
                <div className={styles.naLegend}>N/A</div>
                <div>Title is Unavailable in this format on this platform</div>
              </div>
            </PanelSection>
          </PanelSection>
        </Panel>
      </div>
    )
  }
}

const mapStateToProps = state => {
  let readyActionCount = 0
  let submittedActionCount = 0
  let submittedActionDate = ''

  const titlesForCorrection = getTitlesForCorrection(state)

  const titlesForCorrectionReady = titlesForCorrection.filter(titleForCorrection => {
    const auditActions = titleForCorrection.auditActions(state)

    const titlesForCorrectionReady = auditActions.filter(action => action && action.status === 'ready').size
    readyActionCount += titlesForCorrectionReady

    // leave only those that are ready
    return titlesForCorrectionReady
  })

  const titlesForCorrectionSubmitted = titlesForCorrection.filter(titleForCorrection => {
    const auditActions = titleForCorrection.auditActions(state)

    const titlesForCorrectionSubmitted = auditActions.filter(action => action && action.status === 'submitted').size

    if (titlesForCorrectionSubmitted) {
      submittedActionDate =
        submittedActionDate ||
        auditActions.find(action => action && action.status === 'submitted').statusLastTransitionDate
    }
    submittedActionCount += titlesForCorrectionSubmitted

    // leave only those that are submitted
    return titlesForCorrectionSubmitted
  })

  return {
    titlesForCorrectionReady,
    titlesForCorrectionSubmitted,
    readyActionCount,
    submittedActionCount,
    submittedActionDate,
  }
}

SidePanel.propTypes = {
  scanSummary: PropTypes.object,
  client: PropTypes.object,
  showScanStats: PropTypes.func,
  scan: PropTypes.object,
  platforms: PropTypes.object,
  titlesForCorrectionReady: PropTypes.object,
  titlesForCorrectionSubmitted: PropTypes.object,
  readyActionCount: PropTypes.number,
  submittedActionCount: PropTypes.number,
  submittedActionDate: PropTypes.string,
  showScanAsList: PropTypes.func,
  ignoreAction: PropTypes.func,
}

const mapDispatchToProps = dispatch => {
  return {
    showScanAsList: () => dispatch(showScanAsList()),
    ignoreAction: (auditAction, startDate, endDate) => dispatch(ignoreAction(auditAction, startDate, endDate)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel)
