import { CLIENTS, CLIENT_DASHBOARD, CLIENT } from './utils'
export const LOAD_CLIENT_DASHBOARD = 'clients/LOAD_DASHBOARD'
export const SELECT_CLIENTS = 'platform/SELECT_CLIENTS'

export default function clientReducer(state, action) {
  switch (action.type) {
    case LOAD_CLIENT_DASHBOARD: {
      return state.withMutations(s => {
        s.set(CLIENT_DASHBOARD, action.clientDashboard)
      })
    }
    case 'LOAD_CLIENTS': {
      return state.withMutations(s => {
        const clients = state.get(CLIENTS)
        clients.size === 0 ? s.set(CLIENTS, action.clients) : s.mergeIn([CLIENTS], action.clients)
      })
    }
    case 'LOAD_CLIENT': {
      return state.withMutations(s => {
        s.set(CLIENT, action.client)
      })
    }
    case 'SET_CLIENT': {
      return state.withMutations(s => {
        s.setIn([CLIENTS, action.clientId, 'selected'], true)
      })
    }
    case SELECT_CLIENTS: {
      const { clientIds } = action
      return state.withMutations(s => {
        clientIds.forEach(id => {
          s.updateIn([CLIENTS, id, 'selected'], selected => !selected)
        })
      })
    }
    default:
      return state
  }
}
