import { initialState, MERCHANDIZING, SCAN_DOWNLOADS, removeScanDownload } from '../utils'
export default function merchandizingScanDownloadReducer(state, action) {
  switch (action.type) {
    case 'merchandizing/LOAD_SCAN_DOWNLOADS': {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([MERCHANDIZING, SCAN_DOWNLOADS], action.scanDownloads)
          : s.mergeIn([MERCHANDIZING, SCAN_DOWNLOADS], action.scanDownloads)
      })
    }
    case 'DELETE_SCAN_DOWNLOAD': {
      const { scanDownloadId } = action
      return state.withMutations(s => {
        removeScanDownload({
          mutable: s,
          scanDownloadId,
        })
      })
    }
    default:
      return state || initialState
  }
}
