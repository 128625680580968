import React from 'react'

import style from './ActionBarSpacer.module.scss'

class ActionBarSpacer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div className={style.item} />
  }
}

export default ActionBarSpacer
