import React, { useRef } from 'react'
import Slider from 'react-slick'
import Item from './Item'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PageItems } from 'Interfaces/interfaces'
import style from './HeroCarousel.module.scss'
import { renderSpotsWithItem, sliderSettings } from '../helpers'
import Arrow from './Arrow'

type HeroCarouselProps = RouteComponentProps & PageItems

const HeroCarousel: React.FC<HeroCarouselProps> = ({
  selectedStudios,
  section,
  isMerchandiseFilteringEnabled,
  spots,
}) => {
  const sliderRef = useRef<Slider | null>(null)
  const renderSpots = renderSpotsWithItem({
    spots: spots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.item,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  return (
    <div className={style.container}>
      <Slider
        ref={sliderRef}
        {...sliderSettings({
          slidesToScroll: 1,
          slidesToShow: 1,
          arrow: true,
          dots: true,
          nextArrow: <Arrow right section={section.sectionType} />,
          prevArrow: <Arrow left section={section.sectionType} />,
          appendDots: dots => {
            const customDots = React.Children.map(dots, dot => {
              if (dot.props['className'] === 'slick-active') {
                return React.cloneElement(dot, {
                  className: style.slickActive,
                })
              } else {
                return React.cloneElement(dot, {
                  className: style.slickNormal,
                })
              }
            })
            return (
              <div style={{ bottom: '10px' }}>
                <ul style={{ paddingLeft: '7px' }}>{customDots}</ul>
              </div>
            )
          },
          customPaging: (i: number) => {
            return <button onClick={() => sliderRef.current?.slickGoTo(i)}></button>
          },
        })}
      >
        {renderSpots}
      </Slider>
    </div>
  )
}

export default withRouter(HeroCarousel)
