import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Spot, Match } from 'Interfaces/interfaces'
import style from './HeroCarousel.module.scss'
import { linkToPage } from '../utils'

type HeroCarouselProps = RouteComponentProps & {
  spots: Spot[]
  match: Match
}

const HeroCarousel: React.FC<HeroCarouselProps> = ({ spots, match }) => {
  const spot = [...spots][0]

  const backgroundStyle = { backgroundImage: `url(${decodeURIComponent(spot.artworkUrl)})` }

  return (
    <div className={style.container}>
      <div className={style.heroCarouselWrapper} style={backgroundStyle}>
        <div className={style.carouselDetails}>
          <h2>{spot?.name}</h2>
          <span className={style.subtitle}>{spot.metadata?.subtitle}</span>
          <span className={style.button}>
            <a href={linkToPage(spot, match)}>{spot?.label}</a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default withRouter(HeroCarousel)
