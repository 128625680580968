import { Record } from 'immutable'

export default class SpotFilterRecord extends Record({
  spotType: 'content',
  studioIds: [],
  searchName: '',
  spotsLoading: false,
}) {
  toParams() {
    const filter = {}

    filter['filter[spotType]'] = this.spotType
    filter['filter[searchName]'] = this.searchName

    if (this.studioIds.length) {
      filter['filter[studioIds]'] = this.studioIds.join(',')
    }

    return filter
  }

  toggleStudio(studio) {
    return this.withMutations(s => {
      const studioExistsAt = s.studioIds.findIndex(studioId => studioId === studio.id)
      if (studioExistsAt >= 0) {
        s.studioIds.splice(studioExistsAt, 1)
      } else {
        s.studioIds.push(studio.id)
      }
    })
  }
  updateTitleQuery(query) {
    return this.withMutations(s => {
      s.searchName = query
    })
  }

  clearTitleQuery() {
    return this.withMutations(s => {
      s.searchName = ''
    })
  }

  hasActiveQuery() {
    return this.searchName.trim().length > 0
  }
}
