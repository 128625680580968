import React from 'react'
import { PatheSectionTypes } from '../enums'
import { Icon } from '@blueprintjs/core'
import style from './Item.module.scss'
import { Spot } from 'Interfaces/interfaces'

const SpotDetails: React.FC<{ spot: Spot; sectionType: PatheSectionTypes }> = ({ spot, sectionType }) => {
  const { subtitle } = spot?.metadata

  switch (sectionType) {
    case PatheSectionTypes._HeroCarousel:
      return (
        <div className={style.heroCarouselWrapper}>
          <div className={style.heroCarouselDetails}>
            <p className={style.subtitle}>{subtitle}</p>
            <h1 className={style.header}>{spot.name}</h1>
            <p className={style.description}>{spot.description}</p>
            <button>
              <Icon icon="play" /> {spot.label}
            </button>
          </div>
        </div>
      )
    case PatheSectionTypes._LandscapeContainer:
      return (
        <div className={style.landscapeDetails}>
          <h2>{spot.name}</h2>
          <p>{subtitle}</p>
        </div>
      )
    case PatheSectionTypes._Collection:
      return (
        <div className={style.collectionDetails}>
          <p>{subtitle}</p>
          <h1>{spot.name}</h1>
          <button>
            <Icon icon="play" /> {spot.label}
          </button>
        </div>
      )
    default:
      return null
  }
}

export default SpotDetails
