import React from 'react'
import { Tooltip, Button, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import LeterMultiSelectList from './LeterMultiSelectList'
import styles from './Toolbar.module.scss'

const TitleNameSelector = ({ selectedFilter, setLetterFilter }) => {
  return (
    <div>
      <Popover
        interactionKind={PopoverInteractionKind.CLICK}
        position={Position.BOTTOM_RIGHT}
        content={<LeterMultiSelectList selectedFilter={selectedFilter} setLetterFilter={setLetterFilter} />}>
        <Tooltip content="Filter By Letter Or Number" position={Position.BOTTOM_LEFT}>
          <Button text="ABC" className={styles.btnDefault} />
        </Tooltip>
      </Popover>
    </div>
  )
}

export default TitleNameSelector
