import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List } from 'immutable'
import queryString from 'query-string'

import { retrieveStudios } from 'Actions/studioActions'
import { retrieveMerchandizingScan, runMerchandizingScreenshootService } from 'Actions/merchandizing/scanActions'
import { retrieveMerchandizingPage } from 'Actions/merchandizing/pageActions'
import { parseSpotId } from 'Utils/query'

import LeftRight from 'Components/LeftRight'
import FluidLeft from 'Components/LeftRight/FluidLeft'
import TopBottom from 'Components/TopBottom'
import FluidTop from 'Components/TopBottom/FluidTop'
import SpotTable from 'Components/Merchandizing/SpotsTable'

import { setRootPath, showMerchandizingScanAsList, showMerchandizingScanAsGrid } from 'Actions/uiActions'

import {
  deselectMerchandizingSpot,
  retrieveScanSpots,
  clearTitleQuery,
  updateTitleQuery,
  setScrollingSpot,
  selectMerchandizingSpot,
  retrieveSpotsNextPage,
  resetSpots,
} from 'Actions/merchandizing/spotActions'

import { deselectMerchandizingSection, selectMerchandizingSection } from 'Actions/merchandizing/sectionActions'

import Toolbar from 'Components/Merchandizing/Toolbar'
import styles from 'Components/Merchandizing/styles.module.scss'
import cx from 'classnames'
import {
  getUI,
  getMerchandizingScanById,
  getMerchandizingPageById,
  getSelectedScan,
  getClient,
  areMerchandisingMatchesLoading,
  getSpotFilters,
  getMerchandizingSpots,
  isSpotsLoading,
  loadingPageData,
  getHasMoreSpotsToLoad,
} from 'Selectors'
import WrappedPage from 'Components/Merchandizing/WrappedPage'
import MatchPanel from 'Components/Merchandizing/MatchPanel'
import { retrieveScan, getSelectedScanFromLocalStorage } from 'Actions/scanActions'
import { updateSpotUrl } from '../actions/merchandizing/spotActions'
import { RouteComponentProps } from 'react-router-dom'
import { useClientContext } from 'Contexts/ClientContext'

interface Props extends RouteComponentProps<{}> {
  scanId: string
  pageId: string
  clientVersion: boolean
}

const MerchandizingPage = ({ match, scanId, pageId, clientVersion }: Props) => {
  const dispatch = useDispatch()
  const merchandisingScanView = useSelector(state => getUI(state).get('merchandisingScanView'))
  const { clientId } = useClientContext()

  // When accessing the view for a merch scan, client won't be sent
  // /v2/merchandizing/scans/:scanId/pages/:pageId
  let client = null
  let dragonflyEnabled = true
  let dragonflyMerchUrl = true
  let auditScan = null
  let associatedMerchScans = null
  let defaultScan = null
  let linkToRoot = false
  let areMerchMatchesLoading = false

  // When accessing the view for an audit or scan client view, set client
  // /v2/clients/:clientId/merchandizing/scans/:scanId
  // /v2/clients/:clientId/merchandizing/scans/:scanId/pages/:pageId
  client = useSelector(state => (clientId || clientVersion ? getClient(state) : null))
  dragonflyEnabled = client && client.dragonflyEnabled
  dragonflyMerchUrl = client && client.dragonflyMerchUrl
  auditScan = useSelector(state => (clientId || clientVersion ? getSelectedScan(state) : null))
  associatedMerchScans = useSelector(
    state => (auditScan && auditScan.associatedMerchandizingScans(state)) || List(),
  )
  defaultScan = associatedMerchScans.size && associatedMerchScans.valueSeq().find(ams => ams && ams.default)
  linkToRoot = defaultScan && defaultScan.linkToRootPage
  areMerchMatchesLoading = useSelector(state =>
    clientId || clientVersion ? areMerchandisingMatchesLoading(state) : false,
  )

  const filter = useSelector(state => getSpotFilters(state))
  const scan = useSelector(state => getMerchandizingScanById(state, { id: scanId }))
  const page = useSelector(state => getMerchandizingPageById(state, { id: pageId }))
  const rootSections = useSelector(state => (page && page.rootSections(state)) || List())
  const spots = useSelector(state => getMerchandizingSpots(state))
  const hasMoreSpotsToLoad = useSelector(state => getHasMoreSpotsToLoad(state))
  const spotsLoading = useSelector(state => isSpotsLoading(state))
  const pageDataLoading = useSelector(state => loadingPageData(state))
  const urlSpotId = parseSpotId()

  useEffect(() => {
    dispatch(retrieveMerchandizingScan(scanId, clientId)).then(() => {
      if (merchandisingScanView === 'grid') {
        dispatch(retrieveMerchandizingPage(scanId, pageId)).then(() => {
          if (urlSpotId) dispatch(selectMerchandizingSpot({ id: urlSpotId, pageId: pageId }))
        })
      } else if (merchandisingScanView === 'list') {
        dispatch(retrieveScanSpots(scanId, clientId))
      }
    })
  }, [clientId, dispatch, merchandisingScanView, pageId, scanId, urlSpotId])

  useEffect(() => {
    const parsedQuery = queryString.parse(window.location.search)
    const auditScanId = getSelectedScanFromLocalStorage() || parsedQuery.scan_id

    auditScanId && dispatch(retrieveScan(auditScanId))
    dispatch(setRootPath(match.url))
    dispatch(retrieveStudios())
  }, [dispatch, match.url])

  const classnames = cx(styles.root, 'bp5-focus-disabled', {
    [styles.removeTopPadding]: !!urlSpotId,
  })

  return (
    <div>
      <div className={classnames}>
        {!urlSpotId && (
          <Toolbar
            filter={filter}
            page={page}
            scan={scan}
            merchandisingScanView={merchandisingScanView}
            linkToRoot={linkToRoot}
            auditScan={auditScan}
            dragonflyEnabled={dragonflyEnabled}
            dragonflyMerchUrl={dragonflyMerchUrl}
            showMerchandizingScanAsList={() => dispatch(showMerchandizingScanAsList())}
            showMerchandizingScanAsGrid={() => dispatch(showMerchandizingScanAsGrid())}
            resetSpots={() => dispatch(resetSpots())}
            associatedMerchScans={associatedMerchScans}
            navigateToHierarhy={() => { }}
            client={client}
            clearTitleQuery={() => dispatch(clearTitleQuery())}
            updateTitleQuery={query => dispatch(updateTitleQuery(query))}
            retrieveScanSpots={() => dispatch(retrieveScanSpots(scanId, clientId))}
            pageDataLoading={pageDataLoading}
            spotsLoading={spotsLoading}
          />
        )}
        {merchandisingScanView === 'grid' && scan && page && (
          <WrappedPage page={page} scan={scan} type={scan.platformCode} rootSections={rootSections} />
        )}
        {merchandisingScanView === 'list' && (
          <LeftRight>
            <FluidLeft>
              <TopBottom>
                <FluidTop>
                  <SpotTable
                    page={page}
                    showMerchandizingScanAsGrid={() => dispatch(showMerchandizingScanAsGrid())}
                    setScrollingSpot={spot => dispatch(setScrollingSpot(spot))}
                    selectMerchandizingSpot={spot => dispatch(selectMerchandizingSpot(spot))}
                    spots={spots}
                    spotsLoading={spotsLoading}
                    filter={filter}
                    retrieveSpotsNextPage={offset => dispatch(retrieveSpotsNextPage(scanId, clientId, offset))}
                    runMerchandizingScreenshootService={runMerchandizingScreenshootService}
                    scan={scan}
                    client={client}
                    auditScan={auditScan}
                    updateSpotUrl={updateSpotUrl}
                    hasMoreSpotsToLoad={hasMoreSpotsToLoad}
                  />
                </FluidTop>
              </TopBottom>
            </FluidLeft>
          </LeftRight>
        )}
        {merchandisingScanView === 'grid' && scan && page && !urlSpotId && (
          <MatchPanel
            page={page}
            deselectMerchandizingSpot={() => dispatch(deselectMerchandizingSpot())}
            deselectMerchandizingSection={() => dispatch(deselectMerchandizingSection())}
            areMerchandisingMatchesLoading={areMerchMatchesLoading}
            selectMerchandizingSpot={spot => dispatch(selectMerchandizingSpot(spot))}
            selectMerchandizingSection={sectionId => dispatch(selectMerchandizingSection(sectionId))}
          />
        )}
      </div>
    </div>
  )
}

export default MerchandizingPage
