import React from 'react'
import style from './HeaderImage.module.scss'
import { Section } from 'Interfaces/*'

type HeaderImageProps = {
  section: Section
}

const HeaderImage: React.FC<HeaderImageProps> = ({ section }) => {
  const { artwork } = JSON.parse(section?.metadata as string)
  return <div className={style.container} style={{ backgroundImage: `url(${artwork})` }}></div>
}

export default HeaderImage
