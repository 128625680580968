import React from 'react'
import style from './InfiniteGrid.module.scss'
import Item from './Item'

function InfiniteGrid(props) {
  const spots = () => {
    const { spots } = props
    return spots.filter(spot => spot && spot)
  }

  const isStudioSelected = id => {
    return props.selectedStudios[id]
  }

  const { section, isMerchandiseFilteringEnabled } = props

  return (
    <div className={style.root}>
      {spots().map(spot => (
        <div key={spot.id} className={style.infiniteGridItem}>
          <Item
            section={section}
            isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
            key={spot.id}
            spot={spot}
            studio={isStudioSelected(spot.id)}
          />
        </div>
      ))}
    </div>
  )
}

export default InfiniteGrid
