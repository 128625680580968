import React from 'react'
import style from './Menu.module.scss'
import { Popover, PopoverInteractionKind, Icon, Position } from '@blueprintjs/core'
import WrappedSection from 'Containers/WrappedSection'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'

class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  handleInteraction(nextOpenState) {
    this.setState({ isOpen: nextOpenState })
  }

  renderLink = spot => {
    const { subSections, scan, page } = this.props

    const isDropdown = subSections.some(subsection => subsection.name === spot.name)
    if (isDropdown) {
      return (
        subSections &&
        subSections.map(
          s =>
            s &&
            s.name === spot.name && (
              <WrappedSection spot={spot} type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />
            ),
        )
      )
    }
    return (
      spot && (
        <li key={spot.id}>
          <a href={linkToPage(spot, this.props.match)}>{spot.name}</a>
        </li>
      )
    )
  }

  render() {
    const { spots } = this.props
    const buttonIcon = this.state.isOpen ? 'menu-open' : 'menu'

    const popoverClasses =
      'bp5-popover-content-sizing bp5-popover-display-none bp5-popover-padding-none bp5-margin-top-none bp5-border-radius-none'

    return (
      <div className={style.menu}>
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM_LEFT}
          isOpen={this.state.isOpen}
          onInteraction={state => this.handleInteraction(state)}
          popoverClassName={popoverClasses}
          content={<div className={style.dropdownMenu}>
            <ul>{spots.map(spot => spot && this.renderLink(spot))}</ul>
          </div>}
        >
          <div>
            <div className={style.navbarButton}>
              <Icon icon={buttonIcon} color="#fff" />
            </div>
          </div>
        </Popover>
        <div className={style.rowEightLogo}></div>
      </div>
    )
  }
}

export default withRouter(NavBar)
