import React, { useMemo } from 'react'
import cx from 'classnames'
import OptimizationScore from 'Containers/OptimizationScore'
import { TerritoryRecordType } from '../../records/territoryRecord'
import { TitleAudit } from 'Interfaces/*'
import { List } from 'immutable'
import { territorySorter } from 'Components/utils'
import styles from './styles.module.scss'

type OptimizationScorePanelType = {
  territories: List<TerritoryRecordType>
  titleAudit: TitleAudit
}

const OptimizationScorePanel: React.FC<OptimizationScorePanelType> = ({ territories, titleAudit }) => {
  const sortedTerritories = useMemo(() => territories.valueSeq().sort(territorySorter), [territories])

  return (
    <table className={cx('bp5-table bp5-striped bp5-condensed', styles.table)}>
      <thead>
        <tr>
          <th>Territory</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        <>
          {sortedTerritories.map(territory => (
            <tr key={territory.id}>
              <td>{territory.name}</td>
              <td>
                <OptimizationScore titleAudit={titleAudit} territory={territory} noPadding />
              </td>
            </tr>
          ))}
        </>
      </tbody>
    </table>
  )
}

export default OptimizationScorePanel
