import { Record, Map } from 'immutable'

export default class AuditExceptionRecord extends Record({
  id: 0,
  priceCents: 0,
  price: 0,
  name: '',
  message: '',
  platformTitleId: '',
  startDate: new Date(),
  endDate: new Date(),
  license: '',
  variant: '',
  type: '',
  actionType: '',
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {}
