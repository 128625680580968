import React from 'react'
import cx from 'classnames'
import style from './Arrow.module.scss'
import { SliderArrow } from 'Interfaces/*'

const Arrow: React.FC<SliderArrow> = ({ currentSlide, onClick, left, right }) => {
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return (
    <i
      className={classnames}
      onClick={event => {
        event.stopPropagation()
        onClick && onClick()
      }}
    />
  )
}

export default Arrow
