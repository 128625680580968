import React from 'react'
import styles from './styles.module.scss'
import cx from 'classnames'
import moment from 'moment'
import { Popover, PopoverInteractionKind, Icon, Position, Tooltip, Classes } from '@blueprintjs/core'
import IgnoreActionDialog from './IgnoreActionDialog'
import { formatAmountCurrency } from '../utils'

class PriceItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isIgnoreActionDialogOpen: false,
      oppenedAction: null,
      auditException: null,
    }
  }

  toggleIgnoreActionDialog = oppenedAction => {
    this.setState({
      isIgnoreActionDialogOpen: !this.state.isIgnoreActionDialogOpen,
      oppenedAction: oppenedAction,
    })
  }

  toggleDeleteExceptionDialog = auditException => {
    this.setState({
      isIgnoreActionDialogOpen: !this.state.isIgnoreActionDialogOpen,
      auditException: auditException,
    })
  }

  render() {
    const {
      actionHighlightMode,
      auditPrice,
      price,
      className,
      actions,
      auditExceptions,
      saveAuditActionState,
      title,
      competitors,
      applyEmaPriceToAll,
      titleAuditActions,
      platformTitle,
      ignoreAction,
      autobotEnabled,
      deleteAuditException,
      scan,
      purchaseOptions,
    } = this.props

    const textHighlightMode = actionHighlightMode === 'text'
    const triangleHighlightMode = actionHighlightMode === 'triangle'

    const priceExceptions = auditExceptions.filter(
      p => auditPrice && p && p.priceCents === auditPrice.amountCents && p.type === 'AuditPriceException',
    )

    const notAppliedPriceExceptions = auditExceptions.filter(
      p => auditPrice && p && p.priceCents !== auditPrice.amountCents && p.type === 'AuditPriceException',
    )

    const actionExceptions = auditExceptions.filter(p => p.type === 'AuditActionException')

    const titleReady = autobotEnabled && actions.find(act => act.status === 'ready')
    const titlePending = autobotEnabled && actions.some(act => act.status === 'pending')
    const titleSubmited =
      autobotEnabled &&
      actions.some(act => act.status === 'submitted' || act.status === 'completed' || act.status === 'failed')

    const notAvailableClass = cx(styles.notAvailableWrapper, {
      [styles.noActions]: autobotEnabled && !competitors && !actions.isEmpty(),
      [styles.availabilityReady]: autobotEnabled && !competitors && !actions.isEmpty() && titleReady,
      [styles.availabilitySubmited]: autobotEnabled && !competitors && !actions.isEmpty() && titleSubmited,
      [styles.notAvailable]: actions.isEmpty(),
      [styles.hasActionCell]: !actions.isEmpty() && textHighlightMode,
      [styles.hasActionExceptionCell]: !actionExceptions.isEmpty() && textHighlightMode,
    })

    const classNames = cx(styles.priceItem, className, {
      [styles.extendPriceItemFullWidth]: purchaseOptions.length < 2,
      [styles.hasActionCell]: !actions.isEmpty() && auditExceptions.isEmpty() && textHighlightMode,
      [styles.hasNotAppliedPriceExceptionCell]: !notAppliedPriceExceptions.isEmpty() && textHighlightMode,
      [styles.hasPriceExceptionCell]: !priceExceptions.isEmpty() && textHighlightMode,
      [styles.hasActionExceptionCell]: !actionExceptions.isEmpty() && textHighlightMode,
      [styles.submited]: autobotEnabled && !competitors && titleSubmited,
      [styles.applied]: autobotEnabled && !competitors && titleReady,
      [styles.pending]: autobotEnabled && !competitors && titlePending && auditExceptions.isEmpty(),
      [styles.correctPrice]: autobotEnabled && !competitors && actions.isEmpty() && auditExceptions.isEmpty(),
      [styles.preorderPrice]:
        !competitors && (platformTitle?.isPreorder || auditPrice?.isPreorder) && auditExceptions.isEmpty(),
    })

    const preorderText = 'Pre-Order'

    const getActionFullName = name => {
      if (platformTitle?.isPreorder || auditPrice?.isPreorder) return name + ` (${preorderText})`
      return name
    }

    const renderIgnoredActionButton = auditAction => {
      return (
        <Tooltip content="Don't flag any longer" className={styles.ignoreActionButton} position={Position.TOP_LEFT}>
          <Icon
            className={Classes.POPOVER_DISMISS}
            onClick={() => this.toggleIgnoreActionDialog(auditAction)}
            icon="disable"
          />
        </Tooltip>
      )
    }

    const renderDeleteActionExceptionButton = auditException =>
      auditException.type === 'AuditActionException' && (
        <Tooltip content="Delete Suppress Action" className={styles.ignoreActionButton} position={Position.TOP_LEFT}>
          <Icon
            className={Classes.POPOVER_DISMISS}
            onClick={() => this.toggleDeleteExceptionDialog(auditException)}
            icon="disable"
          />
        </Tooltip>
      )

    const renderReadyTitle = () => {
      return (
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          popoverClassName="bp5-popover-content-sizing"
          position={Position.BOTTOM}
          disabled={actions.isEmpty()}
          content={<div>
            {actions.valueSeq().map(auditAction => (
              <div key={auditAction.id}>
                <div className={styles.actionText} onClick={() => saveAuditActionState(auditAction, 'pending', title)}>
                  Cancel{' '}
                  <div className={styles.actionIconContainer}>
                    <Icon className={styles.actionIcon} icon="cross" />
                  </div>
                </div>
              </div>
            ))}
          </div>}
        >
          <div>
            {titleReady && autobotEnabled && price && (
              <div className={cx(styles.emaAction, styles.emaApplied)}>{price.amount}</div>
            )}
            <div className={styles.priceWrapper}>{formatAmountCurrency(auditPrice)}</div>
          </div>
        </Popover>
      )
    }

    const renderSubmittedTitle = () => {
      return (
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          popoverClassName="bp5-popover-content-sizing"
          position={Position.BOTTOM}
          disabled={auditExceptions.isEmpty() && actions.isEmpty()}
          content={<div>
            {actions.valueSeq().map(action => (
              <div key={action.id}>Submitted on {moment(action.statusLastTransitionDate).format('MMMM Do YYYY')}</div>
            ))}
          </div>}
        >
          <div>
            {autobotEnabled && titleSubmited && price && (
              <div className={cx(styles.emaAction, styles.emaSubmited)}>{price.amount}</div>
            )}
            <div className={styles.priceWrapper}>{formatAmountCurrency(auditPrice)}</div>
          </div>
        </Popover>
      )
    }

    const renderPendingTitle = () => {
      return (
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          popoverClassName={cx('bp5-popover-content-sizing', {
            'bp5-popover-padding-none': !actions.isEmpty() || !auditExceptions.isEmpty(),
          })}
          position={Position.BOTTOM}
          disabled={auditExceptions.isEmpty() && actions.isEmpty() && !auditPrice.isPreorder}
          content={<div>
            {auditExceptions.isEmpty() && actions.isEmpty() && auditPrice.isPreorder && <div>{preorderText}</div>}
            {actions.valueSeq().map(auditAction => (
              <React.Fragment key={auditAction.id}>
                {price ? (
                  <div className={styles.srpPopover}>
                    <div className={styles.srpInfo}>
                      SRP {price.amount} ({price.license} {price.format}){renderIgnoredActionButton(auditAction)}
                    </div>
                    <div onClick={() => saveAuditActionState(auditAction, 'ready', title)}>Update to SRP</div>
                    <div onClick={() => applyEmaPriceToAll(price, titleAuditActions, title)}>
                      <p>
                        Update <span>all</span> to SRP
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.popoverContent}>SRP price needed</div>
                )}
                {auditPrice.isPreorder && <div className={styles.preorderPopover}>{preorderText}</div>}
              </React.Fragment>
            ))}
          </div>}
        >
          {<div className={styles.priceWrapper}>{formatAmountCurrency(auditPrice)}</div>}
        </Popover>
      )
    }

    const renderNotAvailableTitle = () => {
      return (
        <div className={classNames}>
          <Popover
            interactionKind={PopoverInteractionKind.HOVER}
            popoverClassName="bp5-popover-content-sizing"
            position={Position.BOTTOM}
            content={<>{!actions.valueSeq().isEmpty() &&
              actions.valueSeq().map(auditAction =>
                price ? (
                  <div key={auditAction.id}>
                    {titlePending && (
                      <div>
                        <div className={styles.ignoredButtonContainer}>{renderIgnoredActionButton(auditAction)}</div>
                        <div
                          className={styles.actionText}
                          onClick={() => saveAuditActionState(auditAction, 'ready', title)}
                        >
                          Make Available{' '}
                          <div className={styles.actionIconContainer}>
                            <Icon className={styles.actionIcon} icon="tick" />
                          </div>
                        </div>
                      </div>
                    )}
                    {titleReady && (
                      <div
                        className={styles.actionText}
                        onClick={() => saveAuditActionState(auditAction, 'pending', title)}
                      >
                        Cancel{' '}
                        <div className={styles.actionIconContainer}>
                          <Icon className={styles.actionIcon} icon="cross" />
                        </div>
                      </div>
                    )}
                    {titleSubmited &&
                      actions.map(action => (
                        <div key={action.id}>
                          Submitted on {moment(action.statusLastTransitionDate).format('MMMM Do YYYY')}
                        </div>
                      ))}
                  </div>
                ) : (
                  <div key={auditAction.id}>SRP price needed</div>
                ),
              )}
            </>}
          >
            <div className={notAvailableClass}>
              {actions.valueSeq().isEmpty() ? (
                '-'
              ) : titlePending ? (
                <div className={styles.notAvailbleExclamation}>!</div>
              ) : (
                <div> Made Available </div>
              )}
              <p className={styles.notAvailableText}>Not Available</p>
            </div>
          </Popover>
        </div>
      )
    }

    const renderWhenAutobotEnabled = () => {
      return auditPrice && !auditPrice.nullPrice ? (
        auditExceptions.isEmpty() ? (
          <div className={classNames}>
            {!titleReady && !titleSubmited && renderPendingTitle()}
            {titleSubmited && renderSubmittedTitle()}
            {titleReady && renderReadyTitle()}
          </div>
        ) : (
          <div className={classNames}>
            <Popover
              interactionKind={PopoverInteractionKind.HOVER}
              popoverClassName="bp5-popover-content-sizing"
              position={Position.BOTTOM}
              disabled={auditExceptions.isEmpty() && actions.isEmpty()}
              content={<div>
                {actionExceptions.valueSeq().map(actionException => (
                  <div key={actionException.id}>
                    <h5>
                      {actionException.name}
                      {renderDeleteActionExceptionButton(actionException)}
                    </h5>
                    {actionException.message}
                  </div>
                ))}
                {priceExceptions.isEmpty()
                  ? notAppliedPriceExceptions.valueSeq().map(notAppliedPriceException => (
                    <div key={notAppliedPriceException.id}>
                      <h5>{notAppliedPriceException.name}</h5>
                      {notAppliedPriceException.message}
                    </div>
                  ))
                  : priceExceptions.valueSeq().map(priceException => (
                    <div key={priceException.id}>
                      <h5>{priceException.name}</h5>
                      {priceException.message}
                    </div>
                  ))}
              </div>}
            >
              <div>
                <div className={styles.priceWrapper}>{formatAmountCurrency(auditPrice)}</div>
              </div>
            </Popover>
          </div>
        )
      ) : (
        renderNotAvailableTitle()
      )
    }

    const renderWhenAutobotDisabled = () => {
      return auditPrice && !auditPrice.nullPrice ? (
        <div className={classNames}>
          <Popover
            interactionKind={PopoverInteractionKind.HOVER}
            popoverClassName="bp5-popover-content-sizing"
            disabled={auditExceptions.isEmpty() && actions.isEmpty() && !auditPrice.isPreorder}
            content={<>{auditExceptions.isEmpty() && actions.isEmpty() && auditPrice.isPreorder && <div>{preorderText}</div>}
              {auditExceptions.isEmpty() ? (
                <div>
                  {actions.valueSeq().map(auditAction => (
                    <div key={auditAction.id}>
                      <h5>
                        {getActionFullName(auditAction.name)}
                        {renderIgnoredActionButton(auditAction)}
                      </h5>
                      {auditAction.message}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {actionExceptions.valueSeq().map(actionException => (
                    <div key={actionException.id}>
                      <h5>
                        {actionException.name}
                        {renderDeleteActionExceptionButton(actionException)}
                      </h5>
                      {actionException.message}
                    </div>
                  ))}
                  {priceExceptions.isEmpty()
                    ? notAppliedPriceExceptions.valueSeq().map(notAppliedPriceException => (
                      <div key={notAppliedPriceException.id}>
                        <h5>{notAppliedPriceException.name}</h5>
                        {notAppliedPriceException.message}
                      </div>
                    ))
                    : priceExceptions.valueSeq().map(priceException => (
                      <div key={priceException.id}>
                        <h5>{priceException.name}</h5>
                        {priceException.message}
                      </div>
                    ))}
                </div>
              )}</>}
          >
            <div className={styles.priceWrapper}>{formatAmountCurrency(auditPrice)}</div>
          </Popover>
          {triangleHighlightMode && !actions.isEmpty() && <div className={styles.hasAction} />}
        </div>
      ) : (
        <div className={classNames}>
          <Popover
            interactionKind={PopoverInteractionKind.HOVER}
            popoverClassName="bp5-popover-content-sizing"
            disabled={actions.isEmpty() && actionExceptions.isEmpty()}
            content={<div>
              {actionExceptions.isEmpty() ? (
                <div>
                  {actions.valueSeq().map(auditAction => (
                    <div key={auditAction.id}>
                      <h5>
                        {getActionFullName(auditAction.name)}
                        {renderIgnoredActionButton(auditAction)}
                      </h5>
                      {auditAction.message}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {actionExceptions.valueSeq().map(actionException => (
                    <div key={actionException.id}>
                      <h5>
                        {actionException.name}
                        {renderDeleteActionExceptionButton(actionException)}
                      </h5>
                      {actionException.message}
                    </div>
                  ))}
                </div>
              )}
            </div>}
          >
            <div className={notAvailableClass}>{actions.isEmpty() && actionExceptions.isEmpty() ? '-' : 'N/A'}</div>
          </Popover>
          {triangleHighlightMode && !actions.isEmpty() && <div className={styles.hasAction} />}
        </div>
      )
    }

    return (
      <React.Fragment>
        {autobotEnabled && !competitors ? renderWhenAutobotEnabled() : renderWhenAutobotDisabled()}
        {this.state.isIgnoreActionDialogOpen && (
          <IgnoreActionDialog
            action={this.state.oppenedAction}
            isOpen={this.state.isIgnoreActionDialogOpen}
            ignoreAction={ignoreAction}
            toggleIgnoreActionDialog={this.toggleIgnoreActionDialog}
            toggleDeleteExceptionDialog={this.toggleDeleteExceptionDialog}
            auditException={this.state.auditException}
            deleteAuditException={deleteAuditException}
            scan={scan}
            audit={this.props.audit}
          />
        )}
      </React.Fragment>
    )
  }
}

export default PriceItem
