import React from 'react'
import style from './ShowcasePage.module.scss'
import Item from './Item'
import { withRouter } from 'react-router-dom'

class ShowcasePage extends React.Component {
  renderSpots() {
    const { section, isMerchandiseFilteringEnabled } = this.props
    return this.spots().map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <Item
              vertical
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={this.isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        ),
    )
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'content')
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  render() {
    return <div className={style.container}>{this.renderSpots()}</div>
  }
}

export default withRouter(ShowcasePage)
