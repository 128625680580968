import React from 'react'
import WrappedSection from 'Containers/WrappedSection'

import style from './GoogleNavBarItem.module.scss'

import { withRouter } from 'react-router-dom'
import pathToRegexp from 'path-to-regexp'

class GoogleNavBarItem extends React.Component {
  constructor(props) {
    super(props)
  }

  linkToPage() {
    const { match } = this.props
    const spot = this.props.spots.first()

    if (spot && !spot.linkedPageId) {
      return ''
    }

    const { path } = match

    const toPath = pathToRegexp.compile(path)
    return toPath({ ...match.params, pageId: spot.linkedPageId })
  }

  renderSpot() {
    const spot = this.props.spots.first()

    const url = this.linkToPage() || (spot && spot.url)

    return <a href={url}>{spot && spot.name}</a>
  }

  renderSubSections() {
    const { subSections, scan, page } = this.props
    return (
      subSections &&
      subSections.map(s => <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
    )
  }

  render() {
    const spot = this.props.spots.first()

    return <div className={style.item}>{spot ? this.renderSpot() : this.renderSubSections()}</div>
  }
}

const ConnectedActionBarItemWithRouter = withRouter(GoogleNavBarItem)
export default ConnectedActionBarItemWithRouter
