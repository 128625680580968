import { apiRequest, readEndpoint } from 'Actions/apiActions'
import { List, Map, OrderedMap, fromJS } from 'immutable'
import {
  getScanFilters,
  getSelectedScan,
  getScanById,
  getSelectedTerritories,
  getSelectedPlatforms,
  getSelectedTags,
  getTitleForCorrectionById,
  getAuditActionsPage,
} from 'Selectors'

import AuditRecord from 'Records/auditRecord'
import { normalizeAndLoadAuditArtworks } from 'Actions/auditArtworkActions'
import { loadPlatformTitles, normalizeAndLoadPlatformTitles } from 'Actions/platformTitleActions'

import AuditActionRecord from 'Records/auditActionRecord'
import { loadTitles, normalizeAndLoadTitles } from 'Actions/titleActions'
import { setLastUsedQuery, retrieveScanSummary } from 'Actions/scanActions'
import { loadPrices, normalizeAndLoadPrices } from 'Actions/priceActions'
import { loadAuditPrices, normalizeAndLoadAuditPrices } from 'Actions/auditPriceActions'
import { loadTitleForCorrection, addAuditActionToTitleForCorrection } from 'Actions/titlesForCorrectionActions'
import AuditExceptionRecord from 'Records/auditExceptionRecord'
import { loadAuditException } from 'Actions/auditExceptionActions'
import queryString from 'query-string'
import { batch } from 'react-redux'
import { batchActions } from 'redux-batched-actions'
import { resetData } from './utils'

export const auditRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new AuditRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    }),
  )
}

const DEFAULT_FETCHABLE_FIELDS = [
  'message',
  'priceId',
  'auditPriceId',
  'titleAuditId',
  'auditId',
  'channelId',
  'name',
  'actionClass',
  'format',
  'license',
  'new',
  'firstDetectedAt',
  'platformTitleId',
]

const FETCHABLE_FIELDS_FOR_AUTOBOT = DEFAULT_FETCHABLE_FIELDS.concat('status', 'statusLastTransitionDate')

const visibleStatusActions = ['', 'pending', 'ready', 'submitted', 'completed', 'failed']
const filterVisibleActions = action => visibleStatusActions.includes(action.status)

export const loadAudits = (audits, reset) => ({
  type: 'LOAD_AUDITS',
  audits,
  reset,
})

export const filterAuditActions = item => item.type === 'auditActions'

export const loadAuditActions = (auditActions, reset) => ({
  type: 'LOAD_AUDIT_ACTIONS',
  auditActions,
  reset,
})

export const loadAuditAction = auditAction => ({
  type: 'LOAD_AUDIT_ACTION',
  auditAction,
})

export const auditActionRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new AuditActionRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    }),
  )
}

export const normalizeAndLoadAuditActions = data => dispatch => {
  const auditActions = data
    .reduce((memo, item) => {
      return memo.set(item.id, new AuditActionRecord({ ...item }))
    }, new OrderedMap())
    .filter(filterVisibleActions)

  dispatch(loadAuditActions(auditActions))
}

const loadAuditActionsResponse = (auditActionsResponse, reset) => dispatch => {
  const platformTitles = auditActionsResponse.map(audit => audit.platformTitle)
  const auditPrices = auditActionsResponse.map(audit => audit.auditPrice).filter(a => !!a)
  const prices = auditActionsResponse.map(audit => audit.price).filter(p => !!p)
  const titles = platformTitles.map(pt => pt.title)

  const auditActions = auditActionsResponse
    .reduce((memo, item) => {
      return memo.set(item.id, new AuditActionRecord({ ...item }))
    }, new OrderedMap())
    .filter(filterVisibleActions)

  dispatch(loadAuditActions(auditActions, reset))
  dispatch(normalizeAndLoadPlatformTitles(platformTitles, reset))
  dispatch(normalizeAndLoadTitles(titles, reset))
  dispatch(normalizeAndLoadPrices(prices, reset))
  dispatch(normalizeAndLoadAuditPrices(auditPrices, reset))
}

export const reloadAuditActions = () => (dispatch, getState) => {
  const state = getState()
  const scan = getSelectedScan(state)

  return dispatch(retrieveAuditActions(scan.id))
}

export const resetIncludedData = () => dispatch => {
  dispatch(
    batchActions([
      resetData(loadTitles),
      resetData(loadPlatformTitles),
      resetData(loadPrices),
      resetData(loadAuditPrices),
    ]),
  )
}

const resetAuditActions = () => dispatch => {
  const emptyList = new List()

  dispatch(loadAuditActions(emptyList, true))
  dispatch(resetIncludedData())
}

export const retrieveAuditActionsForTitleAudits = (scanId, titleAuditsIds) => (dispatch, getState) => {
  const state = getState()
  const scan = getScanById(state, { id: scanId })

  const filter = getScanFilters(state)
  const url = `scans/${scanId}/audit_actions?`

  const fields = scan.isAutobotEnabled ? FETCHABLE_FIELDS_FOR_AUTOBOT : DEFAULT_FETCHABLE_FIELDS
  const options = {
    'fields[auditActions]': fields.join(','),
    'filter[title_audits]': titleAuditsIds.join(','),
    'filter[actionType]': [
      filter.allActions && '',
      filter.holdbackActions && 'AuditHoldbackAction',
      filter.priceActions && 'AuditPriceAction,AuditPriceParityAction,AuditPriceOutlierAction',
      filter.availActions && 'AuditAvailAction,AuditChannelAction,AuditAvodAction',
    ].filter(x => x),
  }

  const query = queryString.stringify(options)

  return readEndpoint(url + query).then(response => {
    if (!response.auditActions) return
    const auditActions = response.auditActions
      .reduce((memo, item) => {
        return memo.set(item.id, new AuditActionRecord({ ...item }))
      }, new OrderedMap())
      .filter(filterVisibleActions)
    dispatch(loadAuditActions(auditActions))
  })
}

export const retrieveAuditActions = (scanId, competitors, page = 1) => (dispatch, getState) => {
  const state = getState()

  const url = `scans/${scanId}/audit_actions?scan_list=true&`
  const competitor = competitors || false

  const filter = getScanFilters(state)
  const options = {
    'filter[type]': [filter.all && 'movie,tv_season', filter.movies && 'movie', filter.tv && 'tv_season'].filter(
      x => x,
    ),
    'filter[actionType]': [
      filter.allActions &&
        'AuditHoldbackAction,AuditPriceAction,AuditAvailAction,AuditPriceParityAction,AuditPriceOutlierAction,AuditAvodAction',
      filter.holdbackActions && 'AuditHoldbackAction',
      filter.priceActions && 'AuditPriceAction,AuditPriceParityAction,AuditPriceOutlierAction',
      filter.availActions && 'AuditAvailAction',
    ].filter(x => x),
    'filter[licenseType]': [
      filter.licenseTypeAll && '',
      filter.licenseTypeRent && 'rent',
      filter.licenseTypeBuy && 'buy',
    ].filter(x => x),
    'filter[format]': [filter.formatAll && '', filter.formatHD && 'hd', filter.formatSD && 'sd'].filter(x => x),
    'filter[query]': filter.query,
    'filter[competitors]': competitor,
    'filter[platforms]': getSelectedPlatforms(state)
      .valueSeq()
      .map(p => p.id)
      .toArray()
      .join(','),
    'filter[territories]': getSelectedTerritories(state)
      .valueSeq()
      .map(t => t.id)
      .toArray()
      .join(','),
    'filter[tags]': getSelectedTags(state)
      .valueSeq()
      .map(t => t.id)
      .toArray()
      .join(','),
    'filter[title_ids]': filter.titleIds.join(','),
    sort: filter.titleAuditSort(),
    page: page,
    totalActionsCount: true,
  }

  const query = queryString.stringify(options)

  dispatch(resetAuditActions())
  dispatch(setLastUsedQuery(filter.query))
  dispatch(loadAuditActionsPage(page))
  return readEndpoint(url + query)
    .then(response => {
      dispatch(loadAuditActionsResponse(response.auditActions, true))
      response.meta && dispatch(loadAuditActionsTotalCount(response.meta.totalActionsCount))
    })
    .then(() => {
      return dispatch(retrieveScanSummary(scanId))
    })
}

export const normalizeAndLoadAudits = (data, reset) => dispatch => {
  const platformTitles = data.map(audit => audit.platformTitle).filter(pt => !!pt)
  const prices = data
    .map(audit => audit.prices)
    .flat()
    .filter(p => !!p)
  const artworks = data
    .map(audit => audit.artworks)
    .flat()
    .filter(a => !!a)

  const audits = data.reduce((memo, item) => {
    if (item.prices) item.pricesIds = item.prices.map(p => p.id)
    if (item.artworks) item.artworksIds = item.artworks.map(a => a.id)

    return memo.set(
      item.id,
      new AuditRecord({
        ...item,
      }),
    )
  }, new OrderedMap())

  return batch(() => {
    dispatch(normalizeAndLoadPlatformTitles(platformTitles, reset))
    dispatch(normalizeAndLoadAuditPrices(prices, reset))
    dispatch(normalizeAndLoadAuditArtworks(artworks, reset))
    dispatch(loadAudits(audits, reset))
  })
}

export const retrieveAuditActionsNextPage = () => (dispatch, getState) => {
  const state = getState()
  const scan = getSelectedScan(state)
  const currentAuditActionsPage = getAuditActionsPage(state) || 0

  dispatch(retrieveAuditActions(scan.id, false, currentAuditActionsPage + 1))
}

export const retrieveAuditActionsPrevPage = () => (dispatch, getState) => {
  const state = getState()
  const scan = getSelectedScan(state)
  const currentAuditActionsPage = getAuditActionsPage(state) || 0

  if (currentAuditActionsPage <= 1) return Promise.resolve()

  dispatch(retrieveAuditActions(scan.id, false, currentAuditActionsPage - 1))
}

export const saveAuditActionState = (action, status, title) => (dispatch, getState) => {
  const url = action.links.get('self')
  return dispatch(
    apiRequest(url, 'PUT', {
      type: 'audit-actions',
      id: action.id,
      attributes: {
        status,
      },
    }),
  ).then(() => {
    const exists = getTitleForCorrectionById(getState(), { id: title.id })
    dispatch(updateAuditActionStatus(action.id, status))

    if (exists) {
      dispatch(addAuditActionToTitleForCorrection(title, action.id))
    } else {
      dispatch(loadTitleForCorrection(title, action.id))
    }
  })
}

export const updateAuditActionStatus = (actionId, status) => ({
  type: 'UPDATE_AUDIT_ACTION_STATUS',
  actionId,
  status,
})

export const ignoreAction = (auditAction, startDate, endDate) => (dispatch, getState) => {
  const scan = getSelectedScan(getState())
  const url = `scans/${scan.id}/audit_actions/${auditAction.id}/ignore_action`

  return dispatch(apiRequest(url, 'POST', { startDate, endDate })).then(response => {
    const { auditActionException } = response
    const auditException = new AuditExceptionRecord({
      id: auditActionException.id,
      ...auditActionException,
    })

    dispatch(loadAuditException(auditException))
    dispatch(removeAuditAction(auditAction.id))
  })
}

export const removeAuditAction = actionId => ({
  type: 'REMOVE_AUDIT_ACTION',
  actionId,
})

export const loadAuditActionsPage = (page = 1) => ({
  type: 'LOAD_AUDIT_ACTIONS_PAGE',
  page,
})
export const loadAuditActionsTotalCount = totalCount => ({
  type: 'LOAD_AUDIT_ACTIONS_TOTAL',
  totalCount,
})
