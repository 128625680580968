import React from 'react'
import { connect } from 'react-redux'
import { getTitleAuditById, getTerritoryById, getPlatformById, getSelectedScan, getClient, getUI } from 'Selectors'
import styles from 'Components/ScanPage/styles.module.scss'
import cx from 'classnames'
import { List } from 'immutable'
import PriceItem from 'Components/ScanPage/PriceItem'
import AvodItem from 'Components/ScanPage/AvodItem'

import { saveAuditActionState, ignoreAction } from 'Actions/auditActions'
import { applyEmaPriceToAll } from 'Actions/priceActions'
import { deleteAuditException } from 'Actions/auditExceptionActions'
import Channel from 'Containers/Channel'
import { mergePlatformAndClientPlatofrmTypes } from '../components/utils.js'

class AuditPrices extends React.Component {
  findAuditPrice(offerType, variant) {
    const { auditPrices } = this.props
    return auditPrices.find(p => p && p.offerType === offerType && p.variant === variant)
  }

  findPrice(offerType, variant) {
    const { prices } = this.props
    return prices.find(p => p && p.offerType === offerType && p.variant === variant)
  }

  findActions(offerType, variant) {
    const { actions } = this.props
    return actions.filter(
      p => p && (p.license || '').toLowerCase() === offerType && (p.format || '').toLowerCase() === variant,
    )
  }

  findAuditExceptions(offerType, variant) {
    const { auditExceptions } = this.props

    return auditExceptions.filter(
      p => p && (p.license || '').toLowerCase() === offerType && (p.variant || '').toLowerCase() === variant,
    )
  }

  findChannelActions() {
    const { actions } = this.props

    return actions.filter(action => action.name === 'Channel')
  }

  findAvodActions() {
    const { actions } = this.props

    return actions.filter(action => action.name === 'TitleAvod')
  }

  render() {
    const {
      audit,
      platform,
      territory,
      actionHighlightMode,
      client,
      saveAuditActionState,
      title,
      competitors,
      applyEmaPriceToAll,
      titleAuditActions,
      variants,
      platformTitle,
      ignoreAction,
      autobotEnabled,
      deleteAuditException,
      scan,
      channels,
      isAvod,
    } = this.props

    const platformTypes = mergePlatformAndClientPlatofrmTypes(client, platform)
    const platformTypeExists = Object.keys(platformTypes).some(element => platformTypes[element])
    if (!(platform && platform.selected && territory && territory.selected)) {
      return null
    }
    const classNames = cx(styles.price, {
      [styles.seasonPrice]: client && client.titleTypeSeason,
    })

    const tvodColumnWidth = cx({
      [styles.halfTvodColumn]: platform.purchaseOptions.length < 2,
      [styles.tvodColumn]: platform.purchaseOptions.length >= 2,
    })

    const buyColumnClasses = cx(styles.priceColumn, styles.buyColumn, {
      [styles.extendPriceItemFullWidth]: platform.purchaseOptions.length < 2,
    })

    const rentColumnClasses = cx(styles.priceColumn, styles.rentColumn, {
      [styles.extendPriceItemFullWidth]: platform.purchaseOptions.length < 2,
    })

    return (
      <div className={classNames}>
        {platformTypeExists && (
          <table className={styles.platformItemTable}>
            <tbody>
              <tr>
                {platformTypes && platformTypes.tvod && (
                  <td className={tvodColumnWidth}>
                    {platform.buyEnabled() && (
                      <div className={buyColumnClasses}>
                        {variants.map(key => (
                          <PriceItem
                            key={key}
                            title={title}
                            client={client}
                            actionHighlightMode={actionHighlightMode}
                            auditPrice={this.findAuditPrice('buy', key)}
                            price={this.findPrice('buy', key)}
                            actions={this.findActions('buy', key)}
                            auditExceptions={this.findAuditExceptions('buy', key)}
                            saveAuditActionState={saveAuditActionState}
                            competitors={competitors}
                            applyEmaPriceToAll={applyEmaPriceToAll}
                            titleAuditActions={titleAuditActions}
                            platformTitle={platformTitle}
                            ignoreAction={ignoreAction}
                            autobotEnabled={autobotEnabled}
                            deleteAuditException={deleteAuditException}
                            scan={scan}
                            audit={audit}
                            purchaseOptions={platform.purchaseOptions}
                          />
                        ))}
                      </div>
                    )}
                    {platform.rentEnabled() && (
                      <div className={rentColumnClasses}>
                        {variants.map(key => (
                          <PriceItem
                            key={key}
                            title={title}
                            actionHighlightMode={actionHighlightMode}
                            auditPrice={this.findAuditPrice('rent', key)}
                            price={this.findPrice('rent', key)}
                            actions={this.findActions('rent', key)}
                            auditExceptions={this.findAuditExceptions('rent', key)}
                            saveAuditActionState={saveAuditActionState}
                            competitors={competitors}
                            applyEmaPriceToAll={applyEmaPriceToAll}
                            titleAuditActions={titleAuditActions}
                            platformTitle={platformTitle}
                            ignoreAction={ignoreAction}
                            autobotEnabled={autobotEnabled}
                            deleteAuditException={deleteAuditException}
                            scan={scan}
                            audit={audit}
                            purchaseOptions={platform.purchaseOptions}
                          />
                        ))}
                      </div>
                    )}
                  </td>
                )}
                {platformTypes && platformTypes.svod && (
                  <td className={styles.svodColumn}>
                    <Channel channels={channels} actions={this.findChannelActions()} />
                  </td>
                )}
                {platformTypes && platformTypes.avod && (
                  <td className={styles.avodColumn}>
                    <AvodItem audit={audit} client={client} isAvod={isAvod} actions={this.findAvodActions()} />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        )}
      </div>
    )
  }
}

const makeMapStateToProps = () => {
  const emptyList = new List()
  const mapStateToProps = (state, ownProps) => {
    const platform = getPlatformById(state, { id: ownProps.platformId })
    const territory = getTerritoryById(state, { id: platform.territoryId })
    const titleAudit = getTitleAuditById(state, { id: ownProps.titleAuditId })
    const audit = titleAudit?.getAuditForPlatform(state, platform.id)
    const auditPrices = audit?.prices(state) || emptyList

    const actions = audit?.actions(state) || emptyList
    const title = titleAudit?.getTitle(state)
    const auditExceptions = audit?.auditExceptions(state) || emptyList

    const scan = getSelectedScan(state)
    const client = getClient(state)
    const actionHighlightMode = getUI(state).get('actionHighlightMode')
    const variants = client && Object.keys(client.variants).filter(k => client.variants[k])

    const platformTitle = audit && audit.platformTitle(state)
    const prices = platformTitle?.prices(state) || emptyList
    const channels = platformTitle?.channels(state) || emptyList
    const isAvod = platformTitle?.isAvod

    return {
      territory,
      platform,
      titleAudit,
      audit,
      auditPrices,
      prices,
      actions,
      auditExceptions,
      scan,
      client,
      actionHighlightMode,
      title,
      variants,
      platformTitle,
      channels,
      isAvod,
    }
  }

  return mapStateToProps
}

const mapDispatchToProps = dispatch => {
  return {
    saveAuditActionState: (action, state, title) => dispatch(saveAuditActionState(action, state, title)),
    applyEmaPriceToAll: (price, actions, title) => dispatch(applyEmaPriceToAll(price, actions, title)),
    ignoreAction: (auditAction, startDate, endDate) => dispatch(ignoreAction(auditAction, startDate, endDate)),
    deleteAuditException: (auditAction, audit) => dispatch(deleteAuditException(auditAction, audit)),
  }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(AuditPrices)
