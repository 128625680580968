import React from 'react'
import styles from './ContainerDetailPanel.module.scss'

class ContainerDetailPanel extends React.Component {
  render() {
    const { platformTitle } = this.props

    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <label className="bp5-label">
            URL
            <input
              className="bp5-input bp5-fill"
              type="text"
              placeholder="Not specified"
              dir="auto"
              value={platformTitle.url || ''}
              autoFocus
              readOnly
            />
          </label>
          <label className="bp5-label">
            Main Platform Identifier
            <input
              className="bp5-input bp5-fill"
              type="text"
              placeholder="Not specified"
              dir="auto"
              value={platformTitle.platformIdentifier || ''}
              autoFocus
              readOnly
            />
          </label>
          <label className="bp5-label">
            Additional Platform Identifiers
            {platformTitle?.additionalPlatformIdentifiers.map(platformIdentifier => (
              <input
                key={platformIdentifier}
                className="bp5-input bp5-fill"
                type="text"
                placeholder="Not specified"
                dir="auto"
                value={platformIdentifier || ''}
                autoFocus
                readOnly
              />
            ))}
          </label>
          <label className="bp5-label">
            Platform Cdid
            <input
              className="bp5-input bp5-fill"
              type="text"
              placeholder="Not specified"
              dir="auto"
              value={platformTitle.platformCdid || ''}
              autoFocus
              readOnly
            />
          </label>
          <label className="bp5-label">
            Vendor Identifier
            <input
              className="bp5-input bp5-fill"
              type="text"
              placeholder="Not specified"
              dir="auto"
              value={platformTitle.vendorIdentifier || ''}
              autoFocus
              readOnly
            />
          </label>
          <label className="bp5-label">
            Artwork URL
            <input
              className="bp5-input bp5-fill"
              type="text"
              placeholder="Not specified"
              dir="auto"
              value={platformTitle.artworkCdnUrl || ''}
              autoFocus
              readOnly
            />
          </label>
        </div>
      </div>
    )
  }
}

export default ContainerDetailPanel
