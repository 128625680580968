import { CLIENT_SALES_SUMMARY } from './utils'

export const LOAD_CLIENT_SALES_SUMMARY = 'client_sales_summary/LOAD'

export default function clientSalesSummaryReducer(state, action) {
  switch (action.type) {
    case LOAD_CLIENT_SALES_SUMMARY: {
      return state.withMutations(s => {
        s.set(CLIENT_SALES_SUMMARY, action.clientSalesSummary)
      })
    }
    default:
      return state
  }
}
