import React from 'react'
import Item from './Item'
import style from './Grid.module.scss'
import cx from 'classnames'

function renderSpots(section, spots) {
  const classnames = cx({
    [style.item]: section.sectionType === 'redbox::grid',
    [style.collection]: section.sectionType === 'redbox::collection',
  })

  return spots.map(spot => {
    return (
      spot && (
        <div className={classnames} key={spot.id}>
          <Item spot={spot} section={section} />
        </div>
      )
    )
  })
}

function Grid(props) {
  const { section, spots } = props
  return (
    <div className={style.container}>
      {section.name && (
        <div className={style.heading}>
          <h2>{section.name}</h2>
        </div>
      )}
      <div className={style.spotWrapper}>{renderSpots(section, spots)}</div>
    </div>
  )
}

export default Grid
