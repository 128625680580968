import React from 'react'
import WrappedSection from 'Containers/WrappedSection'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'

import style from './Menu.module.scss'
class Menu extends React.Component {
  constructor(props) {
    super(props)
  }

  renderSubSections() {
    const { subSections, scan, page } = this.props
    return (
      subSections &&
      subSections.map(s => <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
    )
  }

  render() {
    const { section } = this.props

    return (
      <div className={style.menu}>
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM_LEFT}
          popoverClassName={style.dropdown}
          content={<div>{this.renderSubSections()}</div>}
        >
          <div className={style.button}>
            {section.name}
            <div className={style.dropdownIcon} />
          </div>
        </Popover>
      </div>
    )
  }
}

export default Menu
