import React from 'react'
import { Card, Button } from '@blueprintjs/core'

import AmCharts from '@amcharts/amcharts3-react'

import styles from './SalesGraph.module.scss'
import shared from './styles.module.scss'

class SalesGraph extends React.Component {
  constructor(props) {
    super(props)
    const { salesSummary } = this.props

    if (salesSummary.type === 'value') {
      this.state = { isUnitsData: false }
    } else {
      this.state = { isUnitsData: true }
    }
  }

  data() {
    const { salesSummary } = this.props

    return salesSummary.data
  }

  setUnitsView(isUnitsData) {
    this.setState({ isUnitsData: isUnitsData })
  }

  graphs() {
    const { salesSummary } = this.props
    const id = this.state.isUnitsData ? 'uId' : 'vId'

    return salesSummary.labels.map(label => ({
      id: `g${label[id]}`,
      balloon: {
        drop: false,
        adjustBorderColor: false,
        color: '#ffffff',
      },
      bullet: 'round',
      bulletBorderAlpha: 1,
      bulletColor: '#FFFFFF',
      bulletSize: 5,
      hideBulletsCount: 50,
      lineThickness: label.thickness || 1,
      title: label.name,
      useLineColorForBulletBorder: true,
      valueField: label[id],
      balloonText: label.balloonText || `<span style='font-size:12px;'>${label.name}: [[value]]</span>`,
      valueAxis: label.axis,
      lineColor: label.color,
      legendValueText: label.legendValueText,
    }))
  }

  render() {
    const { salesSummary } = this.props

    if (salesSummary.data.length === 0) {
      return null
    }

    return (
      <div>
        <div className={shared.pageHeaderContainer}>
          <h4 className={shared.pageHeader}>Sales Units Performance</h4>
          {salesSummary.type === 'both' && (
            <React.Fragment>
              <Button active={this.state.isUnitsData} onClick={() => this.setUnitsView(true)}>
                Units
              </Button>
              <Button active={!this.state.isUnitsData} onClick={() => this.setUnitsView(false)}>
                Values
              </Button>
            </React.Fragment>
          )}
        </div>
        <Card className={[styles.root]}>
          <AmCharts.React
            style={{
              width: '100%',
              height: '370px',
            }}
            options={{
              type: 'serial',
              theme: 'light',
              marginRight: 40,
              marginLeft: 70,
              autoMarginOffset: 20,
              zoomOutText: '',
              valueAxes: [
                {
                  id: 'v1',
                  axisAlpha: 0,
                  position: 'left',
                  ignoreAxisWidth: true,
                },
                {
                  id: 'v2',
                  axisAlpha: 0,
                  position: 'right',
                  ignoreAxisWidth: true,
                  minimum: 0,
                },
              ],
              balloon: {
                borderThickness: 1,
                shadowAlpha: 0,
              },
              graphs: this.graphs(),
              chartScrollbar: {
                graph: 'gtotal',
                oppositeAxis: false,
                offset: 30,
                scrollbarHeight: 30,
                backgroundAlpha: 0,
                selectedBackgroundAlpha: 0.1,
                selectedBackgroundColor: '#888888',
                graphFillAlpha: 0.5,
                graphLineAlpha: 0.5,
                selectedGraphFillAlpha: 0,
                selectedGraphLineAlpha: 1,
                autoGridCount: true,
                color: '#AAAAAA',
              },
              chartCursor: {
                pan: true,
                valueLineEnabled: true,
                valueLineBalloonEnabled: true,
                cursorAlpha: 1,
                cursorColor: '#258cbb',
                limitToGraph: 'g1',
                valueLineAlpha: 0.2,
                valueZoomable: true,
              },
              legend: {
                useGraphSettings: true,
                align: 'center',
              },
              categoryField: 'date',
              categoryAxis: {
                parseDates: true,
                dashLength: 1,
                minorGridEnabled: true,
              },
              dataProvider: this.data(),
            }}
          />
        </Card>
      </div>
    )
  }
}

export default SalesGraph
