import React from 'react'

import style from './HeaderImage.module.scss'

function HeaderImage(props) {
  const { section } = props
  const metadata = JSON.parse(section.metadata)

  return <div className={style.container} style={{ backgroundImage: `url(${metadata.artwork})` }}></div>
}

export default HeaderImage
