import React from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'
import ActionRow from './ActionRow'

class ActionsPanel extends React.Component {
  render() {
    const { actions, client } = this.props

    return (
      <table className={cx('bp5-table bp5-striped bp5-condensed', styles.table)}>
        <thead>
          <tr>
            <th>Platform</th>
            <th>Territory</th>
            <th>License</th>
            <th>Format</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {actions.map(action => {
            // disable uber actions for tv studios
            if (!client.uberActions) {
              return action.name !== 'Uber Image' && <ActionRow action={action} key={action && action.id} />
            } else {
              return <ActionRow action={action} key={action && action.id} />
            }
          })}
        </tbody>
      </table>
    )
  }
}

export default ActionsPanel
