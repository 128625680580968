import React from 'react'
import style from './MultiRowContainer.module.scss'
import MultiRowCarousel from './MultiRowCarousel'
import Slider from 'react-slick'
import cx from 'classnames'
import { linkToPage } from '../utils'

function Arrow({ currentSlide, onClick, left, right }) {
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

function MultiRowContainer(props) {
  const spots = () => {
    const { spots } = props

    return spots
      .filter(spot => spot)
      .reduce((acc, item) => {
        let lastGroup = acc.pop()
        const newGroup = []
        if (!lastGroup) {
          lastGroup = [item]
        } else if (lastGroup.length < 3) {
          lastGroup.push(item)
        } else if (lastGroup.length >= 3) {
          newGroup.push(item)
        }

        const result = [...acc, lastGroup]

        if (newGroup.length) {
          result.push(newGroup)
        }

        return result
      }, [])
  }

  const isStudioSelected = id => {
    return props.selectedStudios[id]
  }

  const renderSpots = () => {
    const { section, isMerchandiseFilteringEnabled } = props

    return spots().map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <MultiRowCarousel
              key={spot.id}
              spots={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        ),
    )
  }

  const seeMore = () => {
    const { spots } = props
    return spots.find(spot => spot && spot.name === 'View all')
  }

  const { section, match } = props
  const seeMoreSpot = seeMore()
  const sliderSettings = {
    dots: false,
    infinite: false,
    centerMode: false,
    centerPadding: 0,
    slidesToScroll: 3,
    slidesToShow: 1,
    variableWidth: true,
    arrows: true,
    swipe: false,
    initialSlide: 0,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow left />,
  }

  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h1>
          {section.name} {seeMoreSpot && <a href={linkToPage(seeMoreSpot, match)}>{seeMoreSpot.name}</a>}
        </h1>
      </div>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots()}
      </Slider>
    </div>
  )
}

export default MultiRowContainer
