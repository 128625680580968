import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PlatformLimitDialog from './PlatformLimitDialog'
import PlatformWithRegionGroup from './PlatformWithRegionGroup'
import PlatformAndRegionWithTabs from './PlatformAndRegionWithTabs'
import ChekboxInput from './Checkbox'
import {
  REGION,
  PLATFORM,
  REGION_SCANS_PATH,
  REGIONS_SCANS_PATH,
  MERCHANDIZING_SCANS_PATH,
  MERCH_PAGES_PATH,
  MAX_ALLOWD_PLATFORMS,
  REGIONS,
  PLATFORMS,
} from './constants'
import {
  Match,
  SelectAllTerritoriesFunction,
  DeselectAllTerritoriesFunction,
  SelectAllPlatformsFunction,
  DeselectAllPlatformsFunction,
  MerchandizingScan,
} from 'Interfaces/*'
import { sortPlatformGroupsByNameAndTerritory, territorySorter, useMatchPathHelper } from '../utils'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import cx from 'classnames'
import style from './index.module.scss'
import TerritoryRecordType from 'Records/territoryRecord'
import PlatformRecordType from 'Records/platformRecord'
import { List } from 'immutable'
import BulkSelector from './BulkSelector'
import MultiSelect from './MultiSelect'

type PlatformAndTerritoryListProps = RouteComponentProps & {
  territories?: List<TerritoryRecordType>
  associatedMerchandizingScans?: MerchandizingScan[]
  toggleTerritory?: (territory: any, isOnScansPage: Match, isOnMerchPage: Match, competitors?: any) => void
  selectAllTerritories?: SelectAllTerritoriesFunction
  deselectAllTerritories?: DeselectAllTerritoriesFunction
  selectAllPlatforms?: SelectAllPlatformsFunction
  deselectAllPlatforms?: DeselectAllPlatformsFunction
  togglePlatforms?: (platformIds: string[], isOnScansPage: Match, isOnMerchPage: Match, competitors?: any) => void
  platformGroups?: List<PlatformRecordType>
  selectTerritories?: (territoryIds: string[], isOnScansPage: Match, isOnMerchPage: Match, competitors?: any) => void
  selectPlatformsForSelectedTerritories?: () => void
  selectTerritoriesForSelectedPlatforms?: () => void
  withTabs?: boolean
  selectedPlatformsCount?: number
  regionsAndPlatforms?: boolean
  availableTabs?: Array<string>
}

const PlatformAndTerritoryList: React.FC<PlatformAndTerritoryListProps> = ({
  territories,
  associatedMerchandizingScans,
  toggleTerritory,
  selectAllTerritories,
  deselectAllTerritories,
  selectAllPlatforms,
  deselectAllPlatforms,
  togglePlatforms,
  platformGroups,
  selectTerritories,
  selectPlatformsForSelectedTerritories,
  selectTerritoriesForSelectedPlatforms,
  withTabs,
  selectedPlatformsCount,
  regionsAndPlatforms,
  availableTabs = [PLATFORM, REGION],
}) => {
  const [activeTab, setActiveTab] = useState<string>(() => localStorage.getItem('activeTab') || REGION)
  const [openPlatforms, setOpenPlatforms] = useState<{ [key: string]: boolean }>({})
  const [isOnScansPage, setIsOnScansPage] = useState<Match>(null)
  const [isOnMerchPage, setIsOnMerchPage] = useState<Match>(null)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [disableSelection, setDisableSelection] = useState<boolean>(false)
  const matchPathHelper = useMatchPathHelper()

  useEffect(() => {
    const scanPage = matchPathHelper(REGION_SCANS_PATH) || matchPathHelper(REGIONS_SCANS_PATH)
    const merchPage = matchPathHelper(MERCHANDIZING_SCANS_PATH) || matchPathHelper(MERCH_PAGES_PATH)
    setIsOnScansPage(scanPage)
    setIsOnMerchPage(merchPage)
  }, [matchPathHelper])

  useEffect(() => {
    withTabs && localStorage.setItem('activeTab', activeTab)
  }, [activeTab, withTabs])

  useEffect(() => {
    if (selectedPlatformsCount >= MAX_ALLOWD_PLATFORMS && activeTab === PLATFORM && withTabs) {
      setShowDialog(true)
      setDisableSelection(true)
    } else {
      setDisableSelection(false)
    }
  }, [selectedPlatformsCount, activeTab, withTabs])

  const onTerritoryChange = useCallback(
    (territory: any) => {
      toggleTerritory(territory, isOnScansPage, isOnMerchPage)
      selectPlatformsForSelectedTerritories()
    },
    [toggleTerritory, isOnScansPage, isOnMerchPage, selectPlatformsForSelectedTerritories],
  )

  const selectAll = () => {
    selectAllTerritories(null, isOnScansPage, isOnMerchPage)
    selectAllPlatforms(null, isOnScansPage, isOnMerchPage)
  }

  const deselectAll = () => {
    deselectAllTerritories()
    deselectAllPlatforms()
  }

  const sortedTerritories = useMemo(() => territories.valueSeq().sort(territorySorter), [territories])

  const sortedPlatformGroups = useMemo(() => sortPlatformGroupsByNameAndTerritory(platformGroups).valueSeq(), [
    platformGroups,
  ])

  const platformsInSelectedTerritory = useMemo(() => {
    const selectedTerritory = territories.find(territory => territory.selected)
    return (platformGroup: any) =>
      platformGroup.filter(p => p.territoryId === selectedTerritory?.id).first() || undefined
  }, [territories])

  const getTerritoryById = useMemo(() => {
    return (id: number) => territories.find((t: any) => t.id === id)
  }, [territories])

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
    deselectAll()
  }

  const togglePlatformGroup = (id: string) => {
    setOpenPlatforms(prev => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  const handleBulkSelect = (platformGroup: any) => {
    selectTerritories(platformGroup.map(p => p.territoryId).valueSeq(), isOnScansPage, isOnMerchPage)
    togglePlatforms(
      platformGroup
        .filter(p => !p.selected)
        .map(i => i.id)
        .valueSeq(),
      isOnScansPage,
      isOnMerchPage,
    )
  }

  const handleBulkDeselect = (platformGroup: any) => {
    togglePlatforms(
      platformGroup
        .filter(p => p.selected)
        .map(i => i.id)
        .valueSeq(),
      isOnScansPage,
      isOnMerchPage,
    )
    selectTerritoriesForSelectedPlatforms()
  }

  const handlePlatformChange = (platform: any) => {
    if (!platform.selected) {
      selectTerritories([platform.territoryId], isOnScansPage, isOnMerchPage)
    }
    togglePlatforms([platform.id], isOnScansPage, isOnMerchPage)
    if (platform.selected) {
      selectTerritoriesForSelectedPlatforms()
    }
  }

  const renderPlatformGroups = () => {
    return sortedPlatformGroups.map((platformGroup: any) => {
      const id = platformGroup.first()?.get('id')
      const isOpen = openPlatforms[id]

      return (
        <PlatformWithRegionGroup
          key={id}
          listGroup={platformGroup}
          isOpen={isOpen}
          toggleList={togglePlatformGroup}
          handleBulkSelect={handleBulkSelect}
          handleBulkDeselect={handleBulkDeselect}
          getTerritoryById={getTerritoryById}
          handleOnChange={handlePlatformChange}
          disableSelection={disableSelection}
        />
      )
    })
  }

  const isEnabled = (platformGroup: any) => {
    const platformsWithVisibleMerchScans = (associatedMerchandizingScans || [])
      .filter(ams => ams.isVisible)
      .map(ams => ams.platformId)

    return !!territories.filter(t => {
      if (!t.selected) return false

      const platformGroupEnabled = platformGroup
        .map(pg => pg.territoryId)
        .toArray()
        .includes(t.id)

      if (!isOnMerchPage) return platformGroupEnabled

      return platformGroup
        .filter(pg => platformsWithVisibleMerchScans.includes(pg.id))
        .map(pg => pg.territoryId)
        .toArray()
        .includes(t.id)
    }).size
  }

  const isSelected = (platformGroup: any) => {
    if (!isEnabled(platformGroup)) return false
    const selectedTerritories = territories.filter(territory => !!territory.selected).map(territory => territory.id)
    return !!platformGroup.find(p => selectedTerritories.includes(p.territoryId))?.selected
  }

  return (
    <ul className={cx('bp5-menu', style.platfomrAndTerritoryList)}>
      {withTabs && (
        <PlatformAndRegionWithTabs
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          sortedTerritories={sortedTerritories}
          onTerritoryChange={onTerritoryChange}
          selectAllPlatforms={selectAllPlatforms}
          deselectAllPlatforms={deselectAllPlatforms}
          isOnScansPage={isOnScansPage}
          isOnMerchPage={isOnMerchPage}
          sortedPlatformGroups={sortedPlatformGroups}
          togglePlatforms={togglePlatforms}
          platformsInSelectedTerritory={platformsInSelectedTerritory}
          deselectAllTerritories={deselectAllTerritories}
          selectAllTerritories={selectAllTerritories}
          availableTabs={availableTabs}
        >
          {renderPlatformGroups()}
        </PlatformAndRegionWithTabs>
      )}
      {regionsAndPlatforms && (
        <>
          <BulkSelector onSelect={selectAll} onDeselect={deselectAll} />
          <MultiSelect
            header={REGIONS}
            list={sortedTerritories}
            onChange={(territory: any) => {
              toggleTerritory(territory, isOnScansPage, isOnMerchPage)
              selectPlatformsForSelectedTerritories()
            }}
            disableSelection={disableSelection}
          >
            <BulkSelector
              onSelect={() => selectAllTerritories(null, isOnScansPage, isOnMerchPage)}
              onDeselect={() => deselectAllTerritories()}
            />
          </MultiSelect>
          <div>
            <h3>{PLATFORMS}</h3>
            <BulkSelector
              onSelect={() => selectAllPlatforms(null, isOnScansPage, isOnMerchPage)}
              onDeselect={() => deselectAllPlatforms()}
            />
            {sortedPlatformGroups.map((platformGroup: any) => {
              const isPlatformEnabled = isEnabled(platformGroup)
              const isPlatformSelected = isSelected(platformGroup)
              const selectedPlatform = platformGroup.filter(p => p.selected).first() || platformGroup.first()
              return (
                <ChekboxInput
                  checked={isPlatformSelected}
                  onChange={() =>
                    togglePlatforms(platformGroup.map(i => i.id).valueSeq(), isOnScansPage, isOnMerchPage)
                  }
                  label={selectedPlatform.name}
                  disabled={!isPlatformEnabled}
                  key={selectedPlatform.get('id')}
                />
              )
            })}
          </div>
        </>
      )}
      <PlatformLimitDialog showDialog={showDialog} setShowDialog={setShowDialog} />
    </ul>
  )
}

export default withRouter(PlatformAndTerritoryList)
