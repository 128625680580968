import React from 'react'

type RenderItemLinkProp = {
  isLink: boolean
  name: string
  url: string
  className: string
}

const RenderLinkItem: React.FC<RenderItemLinkProp> = ({ isLink, name, url, className }) => {
  return isLink ? (
    <a className={className} href={url}>
      {name}
    </a>
  ) : (
    <span className={className}>{name}</span>
  )
}

export default RenderLinkItem
