import React from 'react'
import style from './Page.module.scss'
import { Scan, PageProps } from 'Interfaces/interfaces'
import { Map } from 'immutable'
import SectionRenderer from '../SectionRenderer'

interface Props {
  page: PageProps
  scan: Scan
  rootSections: Map<string, any>
}

const Page: React.FC<Props> = ({ page, scan, rootSections }) => {
  return (
    <div className={style.page}>
      <div className={style.container}>
        <SectionRenderer rootSections={rootSections} scan={scan} page={page} />
      </div>
    </div>
  )
}

export default Page
