import { Record, Map, List } from 'immutable'
import { getAssociatedMerchandizingScans, getPublishers, getPlatforms, getTerritories } from 'Selectors'
import { getRelationshipData } from './utils'

export default class ScanRecord extends Record({
  id: 0,
  selected: false,
  titleAuditsCount: 0,
  enableMerchandizing: false,
  isMpvCalculated: false,
  merchandizingReportUrl: '',
  hasArtworkReport: false,
  createdAt: 0,
  scheduledAt: 0,
  srpExportDate: 0,
  links: new Map(),
  platformIds: [],
  territoryIds: [],
  associatedMerchandizingScansIds: [],
  publisherIds: [],
  isLastDelivered: false,
  isAutobotEnabled: false,
  artworkExportPath: '',
  artworkDownloadState: '',
}) {
  publishers(state) {
    const publishers = getPublishers(state)

    return getRelationshipData(publishers, this.publisherIds)
  }

  platforms(state) {
    const platforms = getPlatforms(state)

    return getRelationshipData(platforms, this.platformIds)
  }

  territories(state) {
    const territories = getTerritories(state)

    return getRelationshipData(territories, this.territoryIds)
  }

  associatedMerchandizingScans(state) {
    const ams = getAssociatedMerchandizingScans(state)

    return new List(
      this.associatedMerchandizingScansIds.map(id => ams.get(id)).filter(ams => ams && ams.isVisible === true),
    )
  }

  defaultAssocMerchandizingScan(state) {
    return this.associatedMerchandizingScans(state).find(a => a && a.default)
  }
}
