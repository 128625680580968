import React, { useMemo } from 'react'
import Item from './Item'
import { PageItems } from 'Interfaces/interfaces'
import style from './SymmetricMosaic.module.scss'
import { renderSpotsWithItem, computeContainerClasses } from '../helpers'

const SymmetricMosaic: React.FC<PageItems> = ({ spots, selectedStudios, section, isMerchandiseFilteringEnabled }) => {
  const containerClasses = useMemo(() => computeContainerClasses(section, style, 4), [section])

  const filteredSpots = useMemo(() => spots.filter(spot => spot?.label?.toLowerCase() !== 'view all'), [spots])

  const rednerCollectionSpots = renderSpotsWithItem({
    spots: filteredSpots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.symmetricMosaicItem,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  }).toArray()

  const orderedMosaicSpots = [
    rednerCollectionSpots[0],
    rednerCollectionSpots[2],
    rednerCollectionSpots[3],
    rednerCollectionSpots[4],
    rednerCollectionSpots[1],
  ]

  return (
    <div className={containerClasses}>
      <h1 className={style.header}>{section.name}</h1>
      <div className={style.symmetricMosaic}>{orderedMosaicSpots}</div>
    </div>
  )
}

export default SymmetricMosaic
