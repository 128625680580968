import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import consumer from '../cable'
import { retrieveTitles } from 'Actions/titleActions'
import { showContainerView } from 'Actions/uiActions'
import { getTitles, getClient, getSelectedPlatforms, getSelectedTerritories } from 'Selectors'
import { markAllAsVirtualPlatformTitles } from 'Actions/platformActions'
import { connect } from 'react-redux'
import PlatformHeader from 'Components/ContainerPage/PlatformHeader/PlatformHeader'
import cssVariables from 'Components/ContainerPage/variables.scss'

import { updatePlatformTitleFromCable } from 'Actions/platformTitleActions'

import { setRootPath } from 'Actions/uiActions'

import Grid from 'Containers/ContainerGrid'
import Toolbar from 'Components/ContainerPage/Toolbar'
import styles from 'Components/ContainerPage/styles.module.scss'
import cx from 'classnames'
import { retrievePlatforms } from 'Actions/platformActions'
import { retrieveTerritories } from 'Actions/territoryActions'
import { territorySorter } from '../components/utils'
import { Match, Title, Platform } from 'Interfaces/*'

interface PlatformWithFlag {
  platform: Platform
  showFlag: boolean
}

type MarkAllAsVirtualPlatformTitlesType = (platformId: string | number) => (dispatch: any) => void
type RetrieveTitles = (scanId: string) => (dispatch: any) => void
type ShowContainerView = () => (dispatch: any) => void

interface Props {
  clientId: string
  match: Match
  titles: Title[]
  platforms: Platform[]
  platformsWithFlags: PlatformWithFlag[]
  markAllAsVirtualPlatformTitles: MarkAllAsVirtualPlatformTitlesType
  retrieveTitles: RetrieveTitles
  showContainerView: ShowContainerView
}

const ContainerPage = ({
  match,
  clientId,
  titles,
  platforms,
  platformsWithFlags,
  markAllAsVirtualPlatformTitles,
  retrieveTitles,
  showContainerView,
}: Props) => {
  const dispatch = useDispatch()
  const client = useSelector(state => getClient(state))
  const [pinFilter, setPinFilter] = useState(() => JSON.parse(localStorage.getItem('pinFilter')) || false)

  const configureCableSubscription = () => {
    try {
      consumer.subscriptions.create(
        { channel: 'PlatformTitleCableChannel' },
        {
          received(payload) {
            dispatch(updatePlatformTitleFromCable(payload))
          },
        },
      )
    } catch (error) {
      console.error('Error handling WebSocket subscription:', error)
    }
  }

  useEffect(() => {
    dispatch(setRootPath(match.url))
    dispatch(retrievePlatforms(clientId))
    dispatch(retrieveTerritories(clientId))
    configureCableSubscription()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])

  useEffect(() => localStorage.setItem('pinFilter', pinFilter), [pinFilter])

  return (
    <div>
      <div className={cx(styles.root, 'bp5-focus-disabled')}>
        <Toolbar client={client} pinFilter={pinFilter} setPinFilter={setPinFilter} />
        <PlatformHeader
          platforms={platforms}
          paddingWidth={cssVariables.rowHeaderWidth}
          platformsWithFlags={platformsWithFlags}
          markAllAsVirtualPlatformTitles={markAllAsVirtualPlatformTitles}
          pinFilter={pinFilter}
        />
        {!client.toSeq().isEmpty() && (
          <div className={cx({ [styles.gridWithMargin]: pinFilter })}>
            <Grid
              titles={titles}
              platforms={platforms}
              platformsWithFlags={platformsWithFlags}
              retrieveTitles={retrieveTitles}
              showContainerView={showContainerView}
              markAllAsVirtualPlatformTitles={markAllAsVirtualPlatformTitles}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const getSortedPlatformsWithFlags = (platforms, territoriesSorted) => {
  return (
    territoriesSorted &&
    platforms &&
    platforms.first() &&
    territoriesSorted
      .map(territory => {
        return [
          ...platforms
            .valueSeq()
            .filter(p => p.territoryId === territory.id)
            .map((p, index) => (index === 0 ? { platform: p, showFlag: true } : { platform: p, showFlag: false })),
        ]
      })
      .flat()
  )
}

const mapStateToProps = state => {
  const client = getClient(state)
  const platforms = !client.toSeq().isEmpty() && getSelectedPlatforms(state)
  const territoriesSorted = getSelectedTerritories(state)
    .valueSeq()
    .sort(territorySorter)
    .toArray()

  const sortedPlatformsWithFlags = getSortedPlatformsWithFlags(platforms, territoriesSorted)
  const titles = getTitles(state)

  return {
    client,
    titles,
    platforms,
    platformsWithFlags: sortedPlatformsWithFlags,
  }
}

const mapDispatchToProps = dispatch => ({
  showContainerView: () => dispatch(showContainerView()),
  retrieveTitles: scanId => dispatch(retrieveTitles(scanId)),
  markAllAsVirtualPlatformTitles: platformId => dispatch(markAllAsVirtualPlatformTitles(platformId)),
})

const ConnectedContainerPage = connect(mapStateToProps, mapDispatchToProps)(ContainerPage)
export default ConnectedContainerPage
