import React from 'react'
import style from './Container.module.scss'
import Slider from 'react-slick'
import Item from './Item'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'

function Arrow(props) {
  const { onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
  })

  return (
    <div
      className={cx({
        [style.arrowWrapperLeft]: left === true,
        [style.arrowWrapperRight]: right === true,
      })}
      onClick={onClick}
    >
      <i className={classnames} />
    </div>
  )
}

function seeMore(spots) {
  return spots.find(spot => spot && spot.spotType === 'link')
}

function renderSpots(spots, section) {
  const sectionType = section.sectionType
  return spots.map(spot => {
    return (
      spot && (
        <div className={cx({ [style.item]: spot.name !== 'See All' })} key={spot.id}>
          <Item spot={spot} section={section} />
          {sectionType === 'amctheatres::container' && spot.name !== 'See All' && (
            <span>
              <h3>{spot.name}</h3>
            </span>
          )}
        </div>
      )
    )
  })
}

function Container(props) {
  const { spots, match, section } = props

  const seeMoreSpot = seeMore(spots)

  const sliderSettings = {
    dots: false,
    infinite: false,
    centerPadding: 0,
    slidesToScroll: 6,
    slidesToShow: 6,
    arrows: true,
    swipe: true,
    initialSlide: 0,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow left />,
  }
  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h2>
          {section.name}{' '}
          {seeMoreSpot && (
            <a href={linkToPage(seeMoreSpot, match)}>
              {seeMoreSpot.name}
              <i className={style.seeAllArrow} />
            </a>
          )}
        </h2>
      </div>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots(spots, section)}
      </Slider>
    </div>
  )
}

export default withRouter(Container)
