import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'

const SubMenu = props => {
  const { spots, match } = props

  const renderLink = spot => {
    return (
      spot && (
        <li key={spot.id}>
          <a href={linkToPage(spot, match)}>{spot.name}</a>
        </li>
      )
    )
  }

  return <ul>{spots.map(spot => spot && renderLink(spot))}</ul>
}

export default withRouter(SubMenu)
