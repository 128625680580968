import React from 'react'
import cx from 'classnames'
import { SliderArrow } from 'interfaces'
import style from './Swoosh.module.scss'

const Arrow: React.FC<SliderArrow> = ({ currentSlide, onClick, left, right }) => {
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

export default Arrow
