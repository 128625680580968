import React from 'react'
import style from './Item.module.scss'
import { OrangeSectionTypes } from '../enums'
import SpotDetails from './SpotDetails'
import { Spot } from 'Interfaces/interfaces'

type SpotBackgroundProps = {
  spot: Spot
  sectionType: string
}

const SpotBackground: React.FC<SpotBackgroundProps> = ({ spot, sectionType }) => {
  const linearGradient = `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%), url(${spot.artworkUrl})`
  switch (sectionType) {
    case OrangeSectionTypes._ChartContainer:
      return (
        <div className={style.chartWrapper}>
          <div className={style.chartCount}>
            <span>{spot.position}</span>
          </div>
          <div
            className={style.chartSpotBackground}
            id={`spot_${spot.id}`}
            style={{ backgroundImage: `url(${spot.artworkUrl})` }}
            onMouseEnter={e => (e.currentTarget.style.backgroundImage = linearGradient)}
            onMouseLeave={e => (e.currentTarget.style.backgroundImage = `url(${spot.artworkUrl})`)}
          >
            <SpotDetails spot={spot} sectionType={sectionType as OrangeSectionTypes} />
          </div>
          <div className={style.spotName}>
            <p>
              {spot.name}
              <br />
              <span>{spot.genre}</span>
            </p>
          </div>
        </div>
      )
    default:
      return (
        <div
          className={style.spotBackground}
          id={`spot_${spot.id}`}
          style={{ backgroundImage: `url(${spot.artworkUrl})` }}
          onMouseEnter={e => (e.currentTarget.style.backgroundImage = linearGradient)}
          onMouseLeave={e => (e.currentTarget.style.backgroundImage = `url(${spot.artworkUrl})`)}
        >
          <SpotDetails spot={spot} sectionType={sectionType as OrangeSectionTypes} />
        </div>
      )
  }
}

export default SpotBackground
