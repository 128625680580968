import React from 'react'
import { connect } from 'react-redux'
import { Cell } from '@blueprintjs/table'

import { makeGetAuditActionForRowIndex } from 'Selectors'

class TitleCell extends React.Component {
  render() {
    const { title, attribute } = this.props

    return <Cell {...this.props}>{title && title.get(attribute)}</Cell>
  }
}

const makeMapStateToProps = () => {
  const getAuditActionForRowIndex = makeGetAuditActionForRowIndex()

  const mapStateToProps = (state, ownProps) => {
    const auditAction = getAuditActionForRowIndex(state, {
      rowIndex: ownProps.rowIndex,
    })
    const platformTitle = auditAction && auditAction.platformTitle(state)
    const title = platformTitle && platformTitle.title(state)
    return {
      auditAction,
      title,
    }
  }

  return mapStateToProps
}

export default connect(makeMapStateToProps)(TitleCell)
