import React from 'react'
import GenericSection from './GenericSection'
import style from './MenuLink.module.scss'
import cx from 'classnames'
import { scrollToNode } from '../utils'

class MenuLink extends GenericSection {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, path, className, section } = this.props
    const classNames = cx(className, style.item, {
      [style.sectionFade]: section.selected,
      [style.spotHighlight]: spot.selected,
    })
    return (
      <li id={spot.id} className={classNames} ref={node => (this.node = node)}>
        <a href={path || spot.url} target={path ? '_self' : '_blank'} className={style.text} rel="noreferrer">
          <span className={style.label}>{spot.label}</span>
        </a>
      </li>
    )
  }
}

export default MenuLink
