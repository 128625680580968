import { Record } from 'immutable'

export default class TitleFilterRecord extends Record({
  all: true,
  movies: false,
  tv: false,
  enabled: true,
  state: '',
  query: '',
  lastQuery: '',
  active: false,
  newCandidates: false,
  promoted: false,
  priority: false,
  letter: '',
}) {
  stateFilters() {
    return ['pending', 'located', 'approved', 'error', 'unavailable']
  }
  toParams(_state) {
    return {
      'filter[state]': this.state,
      'filter[newCandidates]': this.newCandidates,
      'filter[type]': [this.all && 'movie,tv_season', this.movies && 'movie', this.tv && 'tv_season'].filter(x => x),
      'filter[query]': this.query,
      'filter[enabled]': this.enabled,
      'filter[letters]': this.letter,
      'filter[promoted]': this.promoted,
      'filter[priority]': this.priority,
    }
  }

  toBody() {
    return {
      state: this.state,
      newCandidates: this.newCandidates,
      type: [this.all && 'movie,tv_season', this.movies && 'movie', this.tv && 'tv_season'].filter(x => x),
      query: this.query,
      enabled: this.enabled,
      letters: this.letter,
      promoted: this.promoted,
      priority: this.priority,
    }
  }

  enableAll() {
    return this.withMutations(s => {
      s.tv = false
      s.movies = false
      s.all = true
    })
  }

  enableMovies() {
    return this.withMutations(s => {
      s.tv = false
      s.movies = true
      s.all = false
    })
  }

  enableTv() {
    return this.withMutations(s => {
      s.tv = true
      s.movies = false
      s.all = false
    })
  }

  setLetterFilter(letter) {
    return this.withMutations(s => {
      s.letter = letter
    })
  }

  updateTitleQuery(query) {
    return this.withMutations(s => {
      s.query = query
    })
  }

  clearTitleQuery() {
    return this.withMutations(s => {
      s.query = ''
    })
  }

  hasActiveQuery() {
    return this.lastQuery.trim().length > 0
  }

  setLastUsedQuery(query) {
    return this.withMutations(s => {
      s.lastQuery = query
    })
  }

  setStateFilter(state) {
    return this.withMutations(s => {
      s.state = state
      s.newCandidates = false
    })
  }

  setNewCandidatesFilter() {
    return this.withMutations(s => {
      s.state = ''
      s.newCandidates = true
    })
  }

  toggleEnabledFilter() {
    return this.withMutations(s => {
      s.enabled = !s.enabled
    })
  }

  enablePromoted() {
    return this.withMutations(s => {
      s.promoted = true
      s.priority = false
    })
  }

  enablePriority() {
    return this.withMutations(s => {
      s.priority = true
      s.promoted = false
    })
  }

  disablePromoted() {
    return this.withMutations(s => {
      s.promoted = false
      s.priority = false
    })
  }
}
