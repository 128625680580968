import React from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'

const GhostColumn = type => {
  const columnWidth = cx({
    [styles.ghostSvod]: type.type === 'svod',
    [styles.ghostAvod]: type.type === 'avod',
  })
  return <div className={columnWidth} />
}
export default GhostColumn
