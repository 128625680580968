import React from 'react'
import { SFAnytimeSectionTypes } from '../enums'
import style from './Item.module.scss'
import { Spot } from 'Interfaces/interfaces'

const SpotDetails: React.FC<{ spot: Spot; sectionType: SFAnytimeSectionTypes }> = ({ spot, sectionType }) => {
  const { coverUrl, subtitle } = spot?.metadata

  switch (sectionType) {
    case SFAnytimeSectionTypes._HeroCarousel:
      return (
        <div className={style.heroCarouselDetails}>
          <img src={coverUrl} />
          <p className={style.description}>{spot.description}</p>
          <div className={style.buttonWrapper}>
            <button>{spot.name}</button>
            <span>{spot.label}</span>
          </div>
        </div>
      )
    case SFAnytimeSectionTypes._ChartContainer:
      return (
        <div className={style.chartWrapper}>
          <span className={style.spotChart}>{spot.position}</span>
          {subtitle && <span className={style.spotSubtitile}>{subtitle}</span>}
        </div>
      )
    case SFAnytimeSectionTypes._Container:
      return subtitle && <span className={style.spotSubtitile}>{subtitle}</span>
    default:
      return null
  }
}

export default SpotDetails
