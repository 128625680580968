import { Record } from 'immutable'

export default class StudioRecord extends Record({
  id: 0,
  name: 0,
  presentInMerchandisingFilter: false,
  color: '',
  higlightedTitles: false,
  loadingMerchandizingPlatformTitles: false,
}) {}
