import { Record, Map, List } from 'immutable'
import { getMerchandizingSections, getMerchandizingSpots } from 'Selectors'

export default class SectionRecord extends Record({
  id: 0,
  selected: false,
  name: null,
  platformIdentifier: null,
  isRoot: false,
  sectionType: 0,
  primary: false,
  position: 0,
  spotsIds: [],
  childSectionIds: [],
  hidden: false,
  metadata: '',
  parentId: 0,
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {
  toList(state) {
    const sections = getMerchandizingSections(state)

    const sectionsRel = this.getIn(['relationships', 'sections', 'data'])
    if (!sectionsRel) return new List()

    if (!sectionsRel) return new List([this])
    return new List([this]).concat(
      sectionsRel
        .map(item => {
          const subSection = sections.get(item.get('id'))
          return subSection && subSection.toList(state)
        })
        .flatten(true),
    )
  }

  subSections(state) {
    const sections = getMerchandizingSections(state)

    return new List(sections.filter(s => s.parentId === this.id).toArray())
  }

  spots(state) {
    const spots = getMerchandizingSpots(state)

    return new List(this.spotsIds.map(id => spots.get(id)).filter(s => !!s))
  }
}
