import { OrderedMap, List } from 'immutable'
import { readEndpoint } from 'Actions/apiActions'
import { getGetMerchandizingSpotsById, getSpotFilters, getMerchandizingPageById } from 'Selectors'
import queryString from 'query-string'

import SpotRecord from 'Records/merchandizing/spotRecord'
import {
  LOAD_MERCHANDIZING_SPOTS,
  SELECT_MERCHANDIZING_SPOT,
  DESELECT_MERCHANDIZING_SPOT,
  TOGGLE_STUDIO_FILTER,
  UPDATE_TITLE_QUERY,
  CLEAR_TITLE_QUERY,
  SET_SCROLLING_SPOT,
  SPOTS_LOADING,
  UPDATE_SPOT_URL,
  LOAD_SPOTS_HAS_MORE_TO_LOAD,
} from 'Reducers/app/merchandizing/spotReducer'

import { normalizeAndLoadMerchandizingPages } from 'Actions/merchandizing/pageActions'
import { normalizeAndLoadMerchandizingPlatformTitles } from 'Actions/merchandizing/platformTitleActions'
import { normalizeAndLoadStudios } from 'Actions/studioActions'
import { selectMerchandizingSection, normalizeAndLoadMerchandizingSections } from 'Actions/merchandizing/sectionActions'
import { Intent } from '@blueprintjs/core'
import { enqueueToast } from '../uiActions'
import { retrieveMoreMerchandizingSections } from './sectionActions'

export const normalizeAndLoadMerchandizingSpots = (data, reset) => dispatch => {
  const merchandizingSpots = data.reduce((memo, item) => {
    return memo.set(
      item.id,
      new SpotRecord({
        ...item,
      }),
    )
  }, new OrderedMap())

  dispatch(loadMerchandizingSpots(merchandizingSpots, reset))
  dispatch(spotsLoading(false))
}

export const retrieveSpots = (parentName, parentId, clientId, page = 1, reset = true) => (dispatch, getState) => {
  dispatch(spotsLoading(true))
  dispatch(setScrollingSpot(true))
  const state = getState()
  const limit = 50

  const url = `merchandizing/${parentName}/${parentId}/spots?page=${page}&limit=${limit}&`
  const params = getSpotFilters(state).toParams()
  if (clientId) params['filter[clientId]'] = clientId

  const query = queryString.stringify(params)

  return readEndpoint(url + query).then(response => {
    const { merchandizingSpots } = response
    const hasMoreToLoad = merchandizingSpots.length >= limit

    const merchandizingPlatformTitles = merchandizingSpots.map(s => s.merchandizingPlatformTitle).filter(pt => !!pt)
    const merchandizingSections = merchandizingSpots.map(s => s.section).filter(s => !!s)
    const merchandizingPages = merchandizingSpots.map(s => s.page).filter(p => !!p)
    const studios = merchandizingPlatformTitles.map(pt => pt.studio).filter(s => !!s)

    dispatch(normalizeAndLoadMerchandizingSpots(merchandizingSpots, reset))
    dispatch(normalizeAndLoadMerchandizingPlatformTitles(merchandizingPlatformTitles, false))
    dispatch(normalizeAndLoadMerchandizingPages(merchandizingPages, false))
    dispatch(normalizeAndLoadMerchandizingSections(merchandizingSections, false))
    dispatch(normalizeAndLoadStudios(studios, false))
    dispatch(loadSpotsHasMoreToLoad(hasMoreToLoad))
  })
}

export const retrievePageSpots = (pageId, clientId) => dispatch => {
  dispatch(retrieveSpots('pages', pageId, clientId))
}

export const retrieveScanSpots = (scanId, clientId) => dispatch => {
  dispatch(retrieveSpots('scans', scanId, clientId))
}

export const loadMerchandizingSpots = (spots, reset = false) => ({
  type: LOAD_MERCHANDIZING_SPOTS,
  spots,
  reset,
})

export const findSpotOnPage = id => (dispatch, getState) => {
  const state = getState()
  const spot = getGetMerchandizingSpotsById(state, { id: id })
  if (spot) dispatch(selectMerchandizingSpot(spot))
}

export const retrieveSpotsNextPage = (scanId, clientId, offset) => dispatch => {
  return dispatch(retrieveSpots('scans', scanId, clientId, offset, false))
}

export const resetSpots = () => dispatch => {
  const emptyList = new List()

  dispatch(loadMerchandizingSpots(emptyList, true))
}

export const selectMerchandizingSpot = spot => (dispatch, getState) => {
  const state = getState()
  const newSpot = spot.id && getGetMerchandizingSpotsById(state, { id: spot.id })

  if (newSpot) {
    dispatch(selectMerchandizingSection(newSpot.sectionId))
    return dispatch({
      type: SELECT_MERCHANDIZING_SPOT,
      spotId: newSpot.id,
    })
  } else {
    dispatch(retrieveMoreMerchandizingSections(spot.pageId)).then(() => {
      const page = getMerchandizingPageById(state, { id: spot.pageId })
      const rootSections = (page && page.rootSections(state)) || new List()
      const allSectionsLoaded = page.rootSectionsCount <= rootSections.count()

      if (allSectionsLoaded) {
        return dispatch(
          enqueueToast({
            iconName: 'bp5-icon-symbol-triangle-up',
            intent: Intent.WARNING,
            message: 'Spot is not present on this page!',
          }),
        )
      }
      dispatch(selectMerchandizingSpot(spot))
    })
  }
}

export const deselectMerchandizingSpot = () => ({
  type: DESELECT_MERCHANDIZING_SPOT,
})

export const toggleStudio = studio => dispatch => {
  dispatch({
    type: TOGGLE_STUDIO_FILTER,
    studio,
  })
}

export const updateTitleQuery = query => dispatch => {
  dispatch({
    type: UPDATE_TITLE_QUERY,
    query,
  })
}

export const updateSpotUrl = (spotId, url) => dispatch => {
  dispatch({
    type: UPDATE_SPOT_URL,
    spotId: spotId,
    url,
  })
}

export const clearTitleQuery = () => dispatch => {
  dispatch({
    type: CLEAR_TITLE_QUERY,
  })
}

export const setScrollingSpot = spot => dispatch => {
  dispatch({
    type: SET_SCROLLING_SPOT,
    spotId: spot.id,
  })
}

const spotsLoading = isLoading => ({
  type: SPOTS_LOADING,
  isLoading,
})

export const loadSpotsHasMoreToLoad = hasMoreToLoad => ({
  type: LOAD_SPOTS_HAS_MORE_TO_LOAD,
  hasMoreToLoad,
})
