import React from 'react'
import { Button } from '@blueprintjs/core'

class TitleTypeFilter extends React.Component {
  render() {
    const { filter } = this.props
    return (
      <div className="bp5-button-group">
        <Button active={filter.all} onClick={() => this.props.toggleAllFilter()}>
          All
        </Button>
        <Button active={filter.movies} onClick={() => this.props.toggleMovieFilter()}>
          Films
        </Button>
        <Button active={filter.tv} onClick={() => this.props.toggleSeriesFilter()}>
          TV Seasons
        </Button>
      </div>
    )
  }
}

export default TitleTypeFilter
