import React from 'react'
import style from './SubMenu.module.scss'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { Spot, PageProps, Match } from 'Interfaces/interfaces'
import { linkToPage } from '../utils'
import cx from 'classnames'

interface SubMenuProps {
  spot?: Spot
  spots: Spot[]
  page: PageProps
  match: Match
}

const DropdownList = ({ spots, match, page }: SubMenuProps) =>
  spots
    .filter(s => !!s)
    .map(s => (
      <div className={style.listItem} key={s.id}>
        <a href={linkToPage(s, match)} className={cx('activeLink', s.linkedPageId === page.id)}>
          <span>{s.name}</span>
        </a>
      </div>
    ))

const renderDropdown = ({ spot, spots, match, page }: SubMenuProps) => (
  <div className={style.dropdownWrapper}>

    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      position={Position.BOTTOM}
      popoverClassName="bp5-popover-display-none"
      content={<div className={style.dropdown}>
        <DropdownList spots={spots} match={match} page={page} />
      </div>}
    >
      <a className={style.dropdownItem} id={`spot_${spot?.id}`}>
        {spot?.name} <i className={style.arrowDown} data-testid="arrow-icon" />
      </a>
    </Popover>
  </div>
)

const SubMenu: React.FC<SubMenuProps> = ({ spot, spots, page, match }) => {
  return renderDropdown({ spot, spots, match, page })
}

export default SubMenu
