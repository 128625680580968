import { useEffect } from 'react'

interface UseScrollIntoViewParams {
  condition: boolean
  ref: React.RefObject<HTMLElement>
}

export const useScrollIntoView = ({ condition, ref }: UseScrollIntoViewParams) => {
  useEffect(() => {
    if (condition && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [condition, ref])
}
