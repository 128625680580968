import React, { useCallback, useMemo } from 'react'
import style from './PageContainerTabs.module.scss'
import { Spot, PageProps, Section } from 'Interfaces/interfaces'
import cx from 'classnames'

type PageContainerTabsProps = {
  spots: Spot[]
  page: PageProps
  section: Section
}

const PageContainerTabs: React.FC<PageContainerTabsProps> = ({ spots, section }) => {
  const handleLinkClick = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    const targetId = event.currentTarget.getAttribute('href')?.substring(1)
    if (targetId) {
      const targetElement = document.getElementById(targetId)
      targetElement?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const renderLink = useCallback(
    (spot: Spot) => {
      return (
        <a
          key={spot.id}
          className={cx(style.tabItem, { [style.activeTab]: spot.name === section.name })}
          href={`#${spot.name}`}
          onClick={handleLinkClick}
        >
          {spot.name}
        </a>
      )
    },
    [section, handleLinkClick],
  )

  const renderSpots = useMemo(() => spots.map(renderLink), [spots, renderLink])

  return (
    <div className={style.tabMenuContainer}>
      <h1 className={style.heading}>Топ 10: {section.name}</h1>
      <div className={style.tabWrapper} id={section.name}>
        {renderSpots}
      </div>
    </div>
  )
}
export default PageContainerTabs
