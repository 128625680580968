import React from 'react'
import style from './AccordionMenu.module.scss'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import PropTypes from 'prop-types'

function AccordionMenuItem({ spots, section, match }) {
  return (
    <div className={style.root}>
      <details>
        <summary>{section.name}</summary>
        <ul>
          {spots.map(
            spot =>
              spot && (
                <li key={spot.id}>
                  <a href={linkToPage(spot, match)}>{spot.name}</a>
                </li>
              ),
          )}
        </ul>
      </details>
    </div>
  )
}

AccordionMenuItem.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(AccordionMenuItem)
