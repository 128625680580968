import React from 'react'
import { List } from 'immutable'

import Carousel from 'Containers/Carousel'
import CarouselItem from 'Components/Merchandizing/Itunes/CarouselItem'
import Header271 from 'Components/Merchandizing/Itunes/Header271'
import BrickHero from 'Components/Merchandizing/Itunes/BrickHero'
import BrickHeroContainer from 'Containers/BrickHeroContainer'
import GenericSection from 'Components/Merchandizing/Itunes/GenericSection'
import Flowcase from 'Components/Merchandizing/Itunes/Flowcase'
import MainContainer from 'Components/Merchandizing/Itunes/MainContainer'
import ItunesPageContainer from 'Components/Merchandizing/Itunes/PageContainer'
import Menu263 from 'Components/Merchandizing/Itunes/Menu263'
import Menu264 from 'Components/Merchandizing/Itunes/Menu264'
import Menu265 from 'Components/Merchandizing/Itunes/Menu265'
import Menu18 from 'Components/Merchandizing/Itunes/Menu18'
import HeaderImage257 from 'Components/Merchandizing/Itunes/HeaderImage257'
import Genres from 'Components/Merchandizing/Itunes/Genres'
import LinksSection from 'Components/Merchandizing/Itunes/LinksSection'
import ChannelSection from 'Components/Merchandizing/Itunes/ChannelSection'

import Menu from 'Components/Merchandizing/GooglePlay/Menu'
import GenericContainer from 'Components/Merchandizing/GooglePlay/GenericContainer'
import ActionBar from 'Components/Merchandizing/GooglePlay/ActionBar'
import ActionBarItem from 'Components/Merchandizing/GooglePlay/ActionBarItem'
import ActionBarSpacer from 'Components/Merchandizing/GooglePlay/ActionBarSpacer.jsx'
import Submenu from 'Components/Merchandizing/GooglePlay/Submenu.jsx'
import SubmenuColumn from 'Components/Merchandizing/GooglePlay/SubmenuColumn.jsx'
import GoogleNavBar from 'Components/Merchandizing/GooglePlay/V1/GoogleNavBar'
import GoogleStandardCarousel from 'Components/Merchandizing/GooglePlay/V1/GoogleStandardCarousel'
import GoogleNavBarItem from 'Components/Merchandizing/GooglePlay/V1/GoogleNavBarItem'
import GoogleBanner from 'Components/Merchandizing/GooglePlay/V1/GoogleBanner'
import GoogleTwinHero from 'Components/Merchandizing/GooglePlay/V1/GoogleTwinHero'
import TwinHeroMenu from 'Components/Merchandizing/GooglePlay/V1/TwinHeroMenu.jsx'

import AmazonCarousel from 'Components/Merchandizing/Amazon/Carousel.jsx'
import AmazonNavBar from 'Components/Merchandizing/Amazon/NavBar.jsx'
import AmazonNavBarItem from 'Components/Merchandizing/Amazon/NavBarItem.jsx'
import AmazonLargeContainer from 'Components/Merchandizing/Amazon/LargeContainer.jsx'
import AmazonContainer from 'Components/Merchandizing/Amazon/Container.jsx'
import AmazonContainerWithBackground from 'Components/Merchandizing/Amazon/ContainerWithBackground.jsx'
import AmazonChartContainerWithBackground from 'Components/Merchandizing/Amazon/ChartContainerWithBackground.tsx'
import AmazonGrid from 'Components/Merchandizing/Amazon/Grid.jsx'
import AmazonHeader from 'Components/Merchandizing/Amazon/Header.jsx'
import SuperHero from 'Components/Merchandizing/Amazon/V1/SuperHero.jsx'
import NavBar from 'Components/Merchandizing/Amazon/V1/NavBar.jsx'
import NavBarItem from 'Components/Merchandizing/Amazon/V1/NavBarItem.jsx'
import TwinHero from 'Components/Merchandizing/Amazon/V1/TwinHero.jsx'
import StandardCarousel from 'Components/Merchandizing/Amazon/V1/StandardCarousel.jsx'
import SquareCarousel from 'Components/Merchandizing/Amazon/V1/SquareCarousel.jsx'
import SeasonCarousel from 'Components/Merchandizing/Amazon/V1/SeasonCarousel.jsx'
import SeeMore from 'Components/Merchandizing/Amazon/V1/SeeMore.jsx'

import ShowcaseCarousel from 'Components/Merchandizing/Chili/ShowcaseCarousel'
import ShowcasePage from 'Components/Merchandizing/Chili/ShowcasePage'
import GenresDropdown from 'Components/Merchandizing/Chili/GenresDropdown'
import ChiliNavBar from 'Components/Merchandizing/Chili/NavBar'
import ChiliHeader from 'Components/Merchandizing/Chili/Header'

import CarouselList from 'Components/Merchandizing/FandangoNow/CarouselList'
import ToutList from 'Components/Merchandizing/FandangoNow/ToutList'
import NavbarMenu from 'Components/Merchandizing/FandangoNow/NavbarMenu'
import NavbarMenuItem from 'Components/Merchandizing/FandangoNow/NavbarMenuItem'
import NavbarDropdown from 'Components/Merchandizing/FandangoNow/NavbarDropdown'
import MarketingBanner from 'Components/Merchandizing/FandangoNow/MarketingBanner'

import SpotLight from 'Components/Merchandizing/AppleTv/SpotLight'
import AppleNavBar from 'Components/Merchandizing/AppleTv/NavBar'
import AppleSubMenu from 'Components/Merchandizing/AppleTv/SubMenu'
import Swoosh from 'Components/Merchandizing/AppleTv/Swoosh.tsx'
import Brick2 from 'Components/Merchandizing/AppleTv/Brick2'
import InfiniteGrid from 'Components/Merchandizing/AppleTv/InfiniteGrid'
import AppleMultiRowContainer from 'Components/Merchandizing/AppleTv/MultiRowContainer'
import AppleDetailGrid from 'Components/Merchandizing/AppleTv/DetailGrid'
import SingleSpotHeader from 'Components/Merchandizing/AppleTv/SingleSpotHeader'

import Header from 'Components/Merchandizing/Xbox/Header'
import AccordionMenu from 'Components/Merchandizing/Xbox/AccordionMenu'
import XboxMenu from 'Components/Merchandizing/Xbox/Menu'
import ShopNow from 'Components/Merchandizing/Xbox/ShopNow'

import ScrollableSection from 'Components/Merchandizing/GenericScan/ScrollableSection'
import StaticSection from 'Components/Merchandizing/GenericScan/StaticSection'
import HeaderCarousel from 'Components/Merchandizing/GenericScan/HeaderCarousel'

import Banner from 'Components/Merchandizing/Vudu/Banner'
import VuduNavbar from 'Components/Merchandizing/Vudu/Navbar'
import VuduHeader from 'Components/Merchandizing/Vudu/Header'
import VuduHeaderImage from 'Components/Merchandizing/Vudu/HeaderImage'
import VuduStaticSection from 'Components/Merchandizing/Vudu/StaticSection'
import VuduScrollableSection from 'Components/Merchandizing/Vudu/ScrollableSection'

import TubiNavbarMenu from 'Components/Merchandizing/Tubi/TubiNavbarMenu.jsx'

import GooglePlayBanner from 'Components/Merchandizing/GooglePlay/GooglePlayBanner'

import CineplexNavBar from 'Components/Merchandizing/Cineplex/CineplexNavBar'
import CineplexNavDropdown from 'Components/Merchandizing/Cineplex/CineplexNavDropdown'
import CineplexHeaderCarousel from 'Components/Merchandizing/Cineplex/CineplexHeaderCarousel'
import CineplexStandardCarousel from 'Components/Merchandizing/Cineplex/CineplexStandardCarousel'
import CineplexSlick from 'Components/Merchandizing/Cineplex/CineplexSlick'
import CineplexSeeMore from 'Components/Merchandizing/Cineplex/CineplexSeeMore'

import MoviesAnywhereNavBar from 'Components/Merchandizing/MoviesAnywhere/NavBar.jsx'
import MoviesAnywhereTabMenu from 'Components/Merchandizing/MoviesAnywhere/TabMenu.jsx'
import MoviesAnywhereHeroCarousel from 'Components/Merchandizing/MoviesAnywhere/HeroCarousel.jsx'
import MoviesAnywhereContainer from 'Components/Merchandizing/MoviesAnywhere/SquareCarousel.jsx'
import MoviesAnywhereLinks from 'Components/Merchandizing/MoviesAnywhere/Links.jsx'
import MoviesAnywhereGrid from 'Components/Merchandizing/MoviesAnywhere/Grid.jsx'
import MoviesAnywhereHeaderImage from 'Components/Merchandizing/MoviesAnywhere/HeaderImage.jsx'
import MoviesAnywhereVideoContainer from 'Components/Merchandizing/MoviesAnywhere/VideoContainer.jsx'

import AmcTheatresMainMenu from 'Components/Merchandizing/AmcTheatres/Menu.jsx'
import AmcTheatresSubMenu from 'Components/Merchandizing/AmcTheatres/MenuItem.jsx'
import AmcTheatresSubMenuLinks from 'Components/Merchandizing/AmcTheatres/SubMenuLinks.jsx'
import AmcTheatresCarousel from 'Components/Merchandizing/AmcTheatres/Carousel.jsx'
import AmcTheatresContainer from 'Components/Merchandizing/AmcTheatres/Container.jsx'
import AmcTheatresGrid from 'Components/Merchandizing/AmcTheatres/Grid.jsx'

import KaleidescapeMenu from 'Components/Merchandizing/Kaleidescape/Menu.jsx'
import KaleidescapeSubMenu from 'Components/Merchandizing/Kaleidescape/MenuItem.jsx'
import KaleidescapeHeroCarousel from 'Components/Merchandizing/Kaleidescape/HeroCarousel.jsx'
import KaleidescapeContainer from 'Components/Merchandizing/Kaleidescape/Container.jsx'
import KaleidescapeGrid from 'Components/Merchandizing/Kaleidescape/Grid.jsx'
import KaleidescapeSubMenuDropdown from 'Components/Merchandizing/Kaleidescape/SubMenuDropdown.jsx'

import RowEightMenu from 'Components/Merchandizing/RowEight/Menu.jsx'
import RowEightSubMenu from 'Components/Merchandizing/RowEight/MenuItem.jsx'
import RowEightHeroCarousel from 'Components/Merchandizing/RowEight/HeroCarousel.jsx'
import RowEightContainer from 'Components/Merchandizing/RowEight/Container.jsx'
import RowEightGrid from 'Components/Merchandizing/RowEight/Grid.jsx'
import RedBoxMenu from 'Components/Merchandizing/RedBox/Menu.jsx'
import RedBoxSubMenu from 'Components/Merchandizing/RedBox/MenuItem.jsx'
import RedBoxTabMenu from 'Components/Merchandizing/RedBox/TabMenu.jsx'
import RedHeroCarousel from 'Components/Merchandizing/RedBox/HeroCarousel.jsx'
import RedBoxContainer from 'Components/Merchandizing/RedBox/Container.jsx'
import RedBoxGrid from 'Components/Merchandizing/RedBox/Grid.jsx'
import RedBoxLinks from 'Components/Merchandizing/RedBox/Links.jsx'
import RedBoxDeals from 'Components/Merchandizing/RedBox/Deals.jsx'
import RedBoxHeaderImage from 'Components/Merchandizing/RedBox/HeaderImage.jsx'
import RedBoxHeader from 'Components/Merchandizing/RedBox/Header.jsx'

import YouTubeNavBar from 'Components/Merchandizing/YouTube/NavBar.tsx'
import YouTubeHeroCarousel from 'Components/Merchandizing/YouTube/HeroCarousel.tsx'
import YouTubeContainer from 'Components/Merchandizing/YouTube/Container.tsx'
import YouTubeGrid from 'Components/Merchandizing/YouTube/Grid.tsx'

import RakutenTvNavBar from 'Components/Merchandizing/RakutenTv/NavBar.tsx'
import RakutenTvTabMenu from 'Components/Merchandizing/RakutenTv/TabMenu.tsx'
import RakutenTvSubMenu from 'Components/Merchandizing/RakutenTv/SubMenu.tsx'
import RakutenHeroCarousel from 'Components/Merchandizing/RakutenTv/HeroCarousel.tsx'
import RakutenContainer from 'Components/Merchandizing/RakutenTv/Container.tsx'
import RakutenGrid from 'Components/Merchandizing/RakutenTv/Grid.tsx'

import PatheNavBar from 'Components/Merchandizing/Pathe/NavBar.tsx'
import PatheSubMenu from 'Components/Merchandizing/Pathe/SubMenu.tsx'
import PatheTabMenu from 'Components/Merchandizing/Pathe/TabMenu.tsx'
import PatheHeroCarousel from 'Components/Merchandizing/Pathe/HeroCarousel.tsx'
import PatheContainer from 'Components/Merchandizing/Pathe/Container.tsx'
import PatheGrid from 'Components/Merchandizing/Pathe/Grid.tsx'

import SFAnytimeNavBar from 'Components/Merchandizing/SFAnytime/NavBar.tsx'
import SFAnytimeTabMenu from 'Components/Merchandizing/SFAnytime/TabMenu.tsx'
import SFAnytimeSubMenu from 'Components/Merchandizing/SFAnytime/SubMenu.tsx'
import SFAnytimeHeroCarousel from 'Components/Merchandizing/SFAnytime/HeroCarousel.tsx'
import SFAnytimeContainer from 'Components/Merchandizing/SFAnytime/Container.tsx'
import SFAnytimeGrid from 'Components/Merchandizing/SFAnytime/Grid.tsx'
import SFSubHeadingContainer from 'Components/Merchandizing/SFAnytime/SubHeadingContainer.tsx'

import ViaplayNavBar from 'Components/Merchandizing/Viaplay/NavBar.tsx'
import ViaplayTabMenu from 'Components/Merchandizing/Viaplay/TabMenu.tsx'
import ViaplaySubMenu from 'Components/Merchandizing/Viaplay/SubMenu.tsx'
import ViaplayHeroCarousel from 'Components/Merchandizing/Viaplay/HeroCarousel.tsx'
import ViaplayCategoryHeroCarousel from 'Components/Merchandizing/Viaplay/CategoryHeroCarousel.tsx'
import ViaplayContainer from 'Components/Merchandizing/Viaplay/Container.tsx'
import ViaplayGrid from 'Components/Merchandizing/Viaplay/Grid.tsx'
import ViaplayCollectionGrid from 'Components/Merchandizing/Viaplay/CollectionGrid.tsx'
import ViaplayDetailContainer from 'Components/Merchandizing/Viaplay/DetailContainer.tsx'
import ViaplayHeaderImage from 'Components/Merchandizing/Viaplay/HeaderImage.tsx'
import ViaplaySymmetricMosaic from 'Components/Merchandizing/Viaplay/SymmetricMosaic.tsx'

import OrangeNavBar from 'Components/Merchandizing/Orange/NavBar.tsx'
import OrangeContainer from 'Components/Merchandizing/Orange/Container.tsx'
import OrangeGird from 'Components/Merchandizing/Orange/Grid.tsx'

import CanalPlusNavBar from 'Components/Merchandizing/CanalPlus/NavBar.tsx'
import CanalPlusTabMenu from 'Components/Merchandizing/CanalPlus/TabMenu.tsx'
import CanalPlusHeroCarousel from 'Components/Merchandizing/CanalPlus/HeroCarousel.tsx'
import CanalPlusLinkExternalWithText from 'Components/Merchandizing/CanalPlus/LinkExternalWithText.tsx'
import CanalPlusSubheadingContainer from 'Components/Merchandizing/CanalPlus/SubheadingContainer.tsx'
import CanalPlusContainer from 'Components/Merchandizing/CanalPlus/Container.tsx'
import CanalPlusGrid from 'Components/Merchandizing/CanalPlus/Grid.tsx'
import CanalPlusBannerImage from 'Components/Merchandizing/CanalPlus/BannerImage.tsx'
import CanalPlusLinkExternal from 'Components/Merchandizing/CanalPlus/LinkExternal.tsx'
import CanalPlusMosaic from 'Components/Merchandizing/CanalPlus/Mosaic.tsx'

import MegogoNavBar from 'Components/Merchandizing/Megogo/NavBar.tsx'
import MegogoSubmenu from 'Components/Merchandizing/Megogo/SubMenu.tsx'
import MegogoHeroCarousel from 'Components/Merchandizing/Megogo/HeroCarousel.tsx'
import MegogoContainer from 'Components/Merchandizing/Megogo/Container.tsx'
import MegogoGrid from 'Components/Merchandizing/Megogo/Grid.tsx'
import MegogoHeaderImage from 'Components/Merchandizing/Megogo/HeaderImage.tsx'
import MegogoPageContainer from 'Components/Merchandizing/Megogo/PageContainer.tsx'
import MegogoPageContainerTabs from 'Components/Merchandizing/Megogo/PageContainerTabs.tsx'
import MegogoTabMenu from 'Components/Merchandizing/Megogo/TabMenu.tsx'

import { connect } from 'react-redux'
import {
  getStudios,
  getMerchandizingPlatformTitles,
  getSelectedMerchandizingSection,
  getScrollingSpotId,
} from 'Selectors'

const componentMap = {
  'itunes::255': ItunesPageContainer,
  'itunes::256': MainContainer,
  'itunes::258': Carousel,
  'itunes::259': CarouselItem,
  'itunes::271': Header271,
  'itunes::261': BrickHeroContainer,
  'itunes::262': BrickHero,
  'itunes::260': Flowcase,
  'itunes::83': Flowcase,
  'itunes::263': Menu263,
  'itunes::264': Menu264,
  'itunes::18': Menu18,
  'itunes::19': Menu18,
  'itunes::21': Menu18,
  'itunes::257': HeaderImage257,
  'itunes::267': Genres,
  'itunes::265': Menu265,
  'itunes::266': LinksSection,
  'itunes::channel': ChannelSection,
  // 'itunes::266': QuickLinks,
  'google_play::nav': GoogleNavBar,
  'google_play::twin_item': GoogleTwinHero,
  'google_play::nav_item': GoogleNavBarItem,
  'google_play::standard': GoogleStandardCarousel,
  'google_play::menu': Menu,
  'google_play::twinhero': TwinHeroMenu,
  'google_play::container': GenericContainer,
  'google_play::banner_section': GoogleBanner,
  'google_play::banner': GooglePlayBanner,
  'google_play::action_bar': ActionBar,
  'google_play::action_bar_item': ActionBarItem,
  'google_play::action_bar_spacer': ActionBarSpacer,
  'google_play::submenu': Submenu,
  'google_play::submenu::column': SubmenuColumn,
  'amazon::hero_carousel': AmazonCarousel,
  'amazon::main_menu': AmazonNavBar,
  'amazon::submenu': AmazonNavBarItem,
  'amazon::large_carousel': AmazonLargeContainer,
  'amazon::container': AmazonContainer,
  'amazon::container_with_background': AmazonContainerWithBackground,
  'amazon::chart_container_with_background': AmazonChartContainerWithBackground,
  'amazon::grid': AmazonGrid,
  'amazon::h1': AmazonHeader,
  'amazon::SuperHero': SuperHero,
  'amazon::NavBar': NavBar,
  'amazon::NavBarItem': NavBarItem,
  'amazon::TwinHero': TwinHero,
  'amazon::Carousel': StandardCarousel,
  'amazon::SquareCarousel': SquareCarousel,
  'amazon::SeasonCarousel': SeasonCarousel,
  'amazon::SeeMore': SeeMore,
  'chili::showcase': ShowcaseCarousel,
  'chili::showcase_page': ShowcasePage,
  'chili::showcase_filter': GenresDropdown,
  'chili::navigation': ChiliNavBar,
  'chili::header': ChiliHeader,
  'fandango_now::carouselList': CarouselList,
  'fandango_now::defaultList': StaticSection,
  'fandango_now::toutList': ToutList,
  'fandango_now::navbarMenu': NavbarMenu,
  'fandango_now::navbarMenuItem': NavbarMenuItem,
  'fandango_now::navbarMenuItemDropdown': NavbarDropdown,
  'fandango_now::marketingBanner': MarketingBanner,
  'apple_tv::navbar': AppleNavBar,
  'apple_tv::submenu': AppleSubMenu,
  'apple_tv::spotlight': SpotLight,
  'apple_tv::swoosh': Swoosh,
  'apple_tv::brick2': Brick2,
  'apple_tv::notesLockup': Swoosh,
  'apple_tv::themedLockup': Swoosh,
  'apple_tv::infiniteGrid': InfiniteGrid,
  'apple_tv::navBrick2': Brick2,
  'apple_tv::epicInline': Swoosh,
  'apple_tv::gridlockup': Swoosh,
  'apple_tv::channelUpsell': SingleSpotHeader,
  'apple_tv::channelLockup': Brick2,
  'apple_tv::multirow_container': AppleMultiRowContainer,
  'apple_tv::grid_with_text': AppleDetailGrid,
  'apple_tv::chart': Swoosh,
  'apple_tv::noteslockupdetailed': Swoosh,
  'xbox::carousel': Header,
  'xbox::category': ScrollableSection,
  'xbox::featured': StaticSection,
  'xbox::home_page': StaticSection,
  'xbox::top': StaticSection,
  'xbox::shop_now': ShopNow,
  'xbox::refinement': AccordionMenu,
  'xbox::menu': XboxMenu,
  'vudu::contentllist': VuduStaticSection,
  'vudu::primary': VuduStaticSection,
  'vudu::poster': VuduScrollableSection,
  'vudu::banner': Banner,
  'vudu::carousel': HeaderCarousel,
  'vudu::uxnav': VuduNavbar,
  'vudu::grid': VuduStaticSection,
  'vudu::h1': VuduHeader,
  'vudu::header_image': VuduHeaderImage,
  'xfinity::apps': ScrollableSection,
  'xfinity::on_demand': ScrollableSection,
  'xfinity::on_demand_home': ScrollableSection,
  'xfinity::saved_favorites': StaticSection,
  'xfinity::saved_movies': StaticSection,
  'xfinity::saved_tv': StaticSection,
  'xfinity::search': ScrollableSection,
  'xfinity::search_relativity': ScrollableSection,
  'xfinity::tv': ScrollableSection,
  'xfinity::tv_primary': ScrollableSection,
  'tubi_tv::category': ScrollableSection,
  'tubi_tv::carousel': StaticSection,
  'tubi_tv::featured': HeaderCarousel,
  'tubi_tv::menu': TubiNavbarMenu,
  'cineplex::NavBar': CineplexNavBar,
  'cineplex::NavBarItem': CineplexNavDropdown,
  'cineplex::herocarousel': CineplexHeaderCarousel,
  'cineplex::cardscarousel': CineplexStandardCarousel,
  'cineplex::slicksgrid': CineplexSlick,
  'cineplex::cardsgrid': CineplexSeeMore,
  'movies_anywhere::menu': MoviesAnywhereNavBar,
  'movies_anywhere::tabmenu': MoviesAnywhereTabMenu,
  'movies_anywhere::hero_carousel': MoviesAnywhereHeroCarousel,
  'movies_anywhere::container': MoviesAnywhereContainer,
  'movies_anywhere::link_internal': MoviesAnywhereLinks,
  'movies_anywhere::video_container': MoviesAnywhereVideoContainer,
  'movies_anywhere::link_external': MoviesAnywhereLinks,
  'movies_anywhere::page_container': MoviesAnywhereContainer,
  'movies_anywhere::grid': MoviesAnywhereGrid,
  'movies_anywhere::header_image': MoviesAnywhereHeaderImage,
  'amctheatres::main_menu': AmcTheatresMainMenu,
  'amctheatres::submenu': AmcTheatresSubMenu,
  'amctheatres::submenu_links': AmcTheatresSubMenuLinks,
  'amctheatres::submenu_image': AmcTheatresSubMenuLinks,
  'amctheatres::carousel': AmcTheatresCarousel,
  'amctheatres::container': AmcTheatresContainer,
  'amctheatres::grid': AmcTheatresGrid,
  'kaleidescape::main_menu': KaleidescapeMenu,
  'kaleidescape::submenu': KaleidescapeSubMenu,
  'kaleidescape::submenu_dropdown': KaleidescapeSubMenuDropdown,
  'kaleidescape::hero_carousel': KaleidescapeHeroCarousel,
  'kaleidescape::container': KaleidescapeContainer,
  'kaleidescape::grid': KaleidescapeGrid,
  'row8::main_menu': RowEightMenu,
  'row8::submenu': RowEightSubMenu,
  'row8::hero_carousel': RowEightHeroCarousel,
  'row8::container': RowEightContainer,
  'row8::rectangle_container': RowEightContainer,
  'row8::grid': RowEightGrid,
  'redbox::menu': RedBoxMenu,
  'redbox::submenu': RedBoxSubMenu,
  'redbox::hero_carousel': RedHeroCarousel,
  'redbox::container': RedBoxContainer,
  'redbox::grid': RedBoxGrid,
  'redbox::collection': RedBoxGrid,
  'redbox::link_internal': RedBoxLinks,
  'redbox::link_external': RedBoxLinks,
  'redbox::deals': RedBoxDeals,
  'redbox::tabmenu': RedBoxTabMenu,
  'redbox::header_image': RedBoxHeaderImage,
  'redbox::h1': RedBoxHeader,
  'youtube::main_menu': YouTubeNavBar,
  'youtube::hero_carousel': YouTubeHeroCarousel,
  'youtube::container': YouTubeContainer,
  'youtube::category': YouTubeContainer,
  'youtube::landscape_container': YouTubeContainer,
  'youtube::grid': YouTubeGrid,
  'youtube::landscape_grid': YouTubeGrid,
  'rakuten::menu': RakutenTvNavBar,
  'rakuten::submenu': RakutenTvSubMenu,
  'rakuten::tabmenu': RakutenTvTabMenu,
  'rakuten::hero_carousel': RakutenHeroCarousel,
  'rakuten::container': RakutenContainer,
  'rakuten::chart_container': RakutenContainer,
  'rakuten::sale_container': RakutenContainer,
  'rakuten::landscape_container': RakutenContainer,
  'rakuten::video_container': RakutenContainer,
  'rakuten::category': RakutenContainer,
  'rakuten::grid': RakutenGrid,
  'rakuten::landscape_grid': RakutenGrid,
  'rakuten::sale_grid': RakutenGrid,
  'pathe::main_menu': PatheNavBar,
  'pathe::submenu': PatheSubMenu,
  'pathe::tabmenu': PatheTabMenu,
  'pathe::hero_carousel': PatheHeroCarousel,
  'pathe::container': PatheContainer,
  'pathe::portrait_container': PatheContainer,
  'pathe::landscape_container': PatheContainer,
  'pathe::collection': PatheContainer,
  'pathe::grid': PatheGrid,
  'sfanytime::main_menu': SFAnytimeNavBar,
  'sfanytime::submenu': SFAnytimeSubMenu,
  'sfanytime::tabmenu': SFAnytimeTabMenu,
  'sfanytime::hero_carousel': SFAnytimeHeroCarousel,
  'sfanytime::container': SFAnytimeContainer,
  'sfanytime::subheading_container': SFSubHeadingContainer,
  'sfanytime::chart_container': SFAnytimeContainer,
  'sfanytime::landscape_container': SFAnytimeContainer,
  'sfanytime::large_landscape_container': SFAnytimeContainer,
  'sfanytime::grid': SFAnytimeGrid,
  'viaplay::main_menu': ViaplayNavBar,
  'viaplay::submenu': ViaplaySubMenu,
  'viaplay::tabmenu': ViaplayTabMenu,
  'viaplay::hero_carousel': ViaplayHeroCarousel,
  'viaplay::category_hero_carousel': ViaplayCategoryHeroCarousel,
  'viaplay::container': ViaplayContainer,
  'viaplay::chart_container': ViaplayContainer,
  'viaplay::landscape_container': ViaplayContainer,
  'viaplay::portrait_container': ViaplayContainer,
  'viaplay::portrait_grid': ViaplayGrid,
  'viaplay::large_landscape_container': ViaplayContainer,
  'viaplay::grid': ViaplayGrid,
  'viaplay::detail_container': ViaplayDetailContainer,
  'viaplay::collection': ViaplayGrid,
  'viaplay::header_image': ViaplayHeaderImage,
  'viaplay::mosaic_container': ViaplayGrid,
  'viaplay::collection_grid': ViaplayCollectionGrid,
  'viaplay::symmetric_mosaic_container': ViaplaySymmetricMosaic,
  'fandango_at_home::contentllist': VuduStaticSection,
  'fandango_at_home::primary': VuduStaticSection,
  'fandango_at_home::poster': VuduScrollableSection,
  'fandango_at_home::banner': Banner,
  'fandango_at_home::carousel': HeaderCarousel,
  'fandango_at_home::uxnav': VuduNavbar,
  'fandango_at_home::grid': VuduStaticSection,
  'fandango_at_home::h1': VuduHeader,
  'fandango_at_home::header_image': VuduHeaderImage,
  'orange::main_menu': OrangeNavBar,
  'orange::hero_carousel': OrangeContainer,
  'orange::container': OrangeContainer,
  'orange::landscape_container': OrangeContainer,
  'orange::large_container': OrangeContainer,
  'orange::chart': OrangeContainer,
  'orange::grid': OrangeGird,
  'orange::landscape_grid': OrangeGird,
  'canalplus::menu': CanalPlusNavBar,
  'canalplus::tabmenu': CanalPlusTabMenu,
  'canalplus::hero_carousel': CanalPlusHeroCarousel,
  'canalplus::link_external_with_text': CanalPlusLinkExternalWithText,
  'canalplus::subheading_container': CanalPlusSubheadingContainer,
  'canalplus::page_container': CanalPlusContainer,
  'canalplus::container': CanalPlusContainer,
  'canalplus::large_container': CanalPlusContainer,
  'canalplus::portrait_container': CanalPlusContainer,
  'canalplus::large_portrait_container': CanalPlusContainer,
  'canalplus::grid': CanalPlusGrid,
  'canalplus::portrait_grid': CanalPlusGrid,
  'canalplus::banner_image': CanalPlusBannerImage,
  'canalplus::link_external': CanalPlusLinkExternal,
  'canalplus::header_image': CanalPlusBannerImage,
  'canalplus::mosaic_container': CanalPlusMosaic,
  'canalplus::xl_container': CanalPlusContainer,
  'canalplus::widescreen_container': CanalPlusContainer,
  'canalplus::chart_container': CanalPlusContainer,
  'megogo::main_menu': MegogoNavBar,
  'megogo::submenu': MegogoSubmenu,
  'megogo::hero_carousel': MegogoHeroCarousel,
  'megogo::container': MegogoContainer,
  'megogo::collection': MegogoContainer,
  'megogo::large_landscape_container': MegogoContainer,
  'megogo::landscape_container': MegogoContainer,
  'megogo::portrait_container': MegogoContainer,
  'megogo::grid': MegogoGrid,
  'megogo::landscape_grid': MegogoGrid,
  'megogo::header_image': MegogoHeaderImage,
  'megogo::page_container': MegogoPageContainer,
  'megogo::page_tabmenu': MegogoPageContainerTabs,
  'megogo::tabmenu': MegogoTabMenu,
  'megogo::collection_grid': MegogoGrid,
}

function WrappedSection(props) {
  const { type, ...rest } = props
  const url = window.location.href
  if (url.includes('dev') || url.includes('test') || url.includes('ditto') || url.includes('localhost')) {
    console.debug(
      `%c🚀🚀🚀🚀 ~SECTION TYPE: ${props.section.sectionType}, SECTION NAME: ${props.section.name}`,
      'color: #de5f16',
    )
    console.debug('PROPS: ', props)
  }
  const Component = componentMap[type] || GenericSection
  return <Component {...rest} />
}

const mapStateToProps = (state, ownProps) => {
  const { section } = ownProps

  const spots = (section && section.spots(state)) || new List()
  // TODO figure out an optimized way to connect client to spot
  let selectedStudios = {}
  let isMerchandiseFilteringEnabled = getStudios(state).filter(c => c.higlightedTitles)
  if (isMerchandiseFilteringEnabled.size > 0) {
    isMerchandiseFilteringEnabled.valueSeq().forEach(studio => {
      const selectedStudio = findSelectedStudios(spots, studio, state)
      selectedStudios = { ...selectedStudios, ...selectedStudio }
    })
  } else {
    isMerchandiseFilteringEnabled = undefined
  }
  const subSections = (section && section.subSections(state)) || new List()
  const spotsWithLinkedPages = spots && spots.filter(spot => spot && spot.linkedPageId)
  const selectedSection = getSelectedMerchandizingSection(state)
  const scrollingSpotId = getScrollingSpotId(state)

  return {
    subSections,
    spots,
    selectedStudios,
    isMerchandiseFilteringEnabled,
    spotsWithLinkedPages,
    selectedSection,
    scrollingSpotId,
  }
}

// TODO is this the propper way of fetching child dependencies??
const findSelectedStudios = (spots, studio, state) => {
  const merchandizingPlatformTitles = getMerchandizingPlatformTitles(state)

  const studioPlatformTitles = merchandizingPlatformTitles.valueSeq().filter(p => p.studioId === studio.id)

  const selected = {}
  studioPlatformTitles.forEach(platformTitle => {
    const spot = spots.find(spot => platformTitle.id === spot.merchandizingPlatformTitleId)
    if (spot) selected[spot.id] = studio
  })

  return selected
}

export default connect(mapStateToProps)(WrappedSection)
