import React from 'react'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from '@blueprintjs/core'
import PropTypes from 'prop-types'
import ContainerDimensions from 'react-container-dimensions'
import WrappedSection from 'Containers/WrappedSection'
import { retrieveMoreMerchandizingSections } from 'Actions/merchandizing/sectionActions'
import { getScrollingSpotId, getGetMerchandizingSpotsById } from 'Selectors'
import style from './Page.module.scss'

class AmazonPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasMoreToLoad: true,
    }
  }

  elementInfiniteLoad = () => {
    return (
      <div className={style.pageSpinner}>
        <Spinner />
      </div>
    )
  }

  handleInfiniteLoad = () => {
    const { retrieveMoreMerchandizingSections, page, rootSections } = this.props
    retrieveMoreMerchandizingSections().then(() => {
      if (page.rootSectionsCount <= rootSections.count()) {
        this.setState({ hasMoreToLoad: false })
      }
    })
  }

  render() {
    const { page, scan, rootSections, selectedSpotId, selectedSpot } = this.props

    return (
      <div className={style.page}>
        <ContainerDimensions>
          {({ height }) =>
            height && (
              <InfiniteScroll
                dataLength={rootSections.count()}
                next={this.handleInfiniteLoad}
                hasMore={this.state.hasMoreToLoad}
                height={height}
                loader={this.elementInfiniteLoad()}
              >
                <React.Fragment>
                  <div>
                    {selectedSpotId && !selectedSpot && (
                      <div className={style.transparentBackground}>
                        <div className={style.transparentSpinner}>
                          <Spinner />
                        </div>
                      </div>
                    )}
                  </div>
                  {rootSections.valueSeq().map((s, i) => {
                    return (
                      <WrappedSection type={s.sectionType} key={s.id} scan={scan} page={page} section={s} index={i} />
                    )
                  })}
                </React.Fragment>
              </InfiniteScroll>
            )
          }
        </ContainerDimensions>
      </div>
    )
  }
}

AmazonPage.propTypes = {
  rootSections: PropTypes.object,
  page: PropTypes.object,
  scan: PropTypes.object,
  retrieveMoreMerchandizingSections: PropTypes.func,
  selectedSpotId: PropTypes.string,
  selectedSpot: PropTypes.object,
}

const mapStateToProps = state => {
  const selectedSpotId = getScrollingSpotId(state)
  const selectedSpot = getGetMerchandizingSpotsById(state, { id: selectedSpotId })
  return {
    selectedSpotId,
    selectedSpot,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { page } = ownProps
  return {
    retrieveMoreMerchandizingSections: () => dispatch(retrieveMoreMerchandizingSections(page.id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AmazonPage)
