import React from 'react'
import style from './Navbar.module.scss'
import WrappedSection from 'Containers/WrappedSection'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import pathToRegexp from 'path-to-regexp'
import { connect } from 'react-redux'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { linkToPage } from '../utils'
import { isFandangoScan } from '../helpers'
import cx from 'classnames'
import logo from './fandango-at-home-logo.svg'

class Navbar extends React.Component {
  render() {
    const { section } = this.props

    return section.isRoot ? this.renderNavbarContainer() : this.renderDropdown()
  }

  renderNavbarContainer() {
    const { scan } = this.props

    return (
      <div className={cx(style.root, { [style.newRoot]: isFandangoScan(scan) })}>
        {isFandangoScan(scan) ? (
          <a href={this.rootPageUrl()} className={style.logo}>
            <img alt="Fandango At Home" data-selenium="site-logo" src={logo} />
          </a>
        ) : (
          <a href={this.rootPageUrl()} className={style.logo}>
            <h3>VUDU</h3>
          </a>
        )}
        <ul className={style.mediaNav}>{this.renderSubSections()}</ul>
      </div>
    )
  }

  renderDropdown() {
    const { spots, section } = this.props

    const mainLink = spots.first()
    const textLinks = spots.filter(s => s && !s.artworkUrl && s.name !== mainLink.name)
    const imgLinks = spots.filter(s => s && s.artworkUrl && s.name !== mainLink.name)

    return (
      <Popover
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.BOTTOM_LEFT}
        popoverClassName="bp5-popover-display-none"
        content={<div className={style.dropdown}>
          <ul className={style.mainLink}>{mainLink && this.renderSpot(mainLink)}</ul>
          <ul className={style.textLinks}>{textLinks.map(link => link && this.renderSpot(link))}</ul>
          <ul className={style.imgLinks}>{imgLinks.map(imgLink => imgLink && this.renderSpot(imgLink, true))}</ul>
        </div>}
      >
        <li className={style.listItem}>{section.name}</li>
      </Popover>
    )
  }

  renderSpot(spot, hasImage = false) {
    const classnames = cx(style.listItem, style.dropdownItem)

    return (
      <li key={spot.id} className={classnames}>
        {hasImage ? (
          <a href={linkToPage(spot, this.props.match)}>
            <img src={spot.artworkUrl}></img>
            <h4>{spot.name}</h4>
          </a>
        ) : (
          <a href={linkToPage(spot, this.props.match)}>{spot.name}</a>
        )}
      </li>
    )
  }

  rootPageUrl() {
    const { match } = this.props
    const { path } = match
    const rootPageId = this.rootPageId()

    const toPath = pathToRegexp.compile(path)
    return rootPageId && toPath({ ...match.params, pageId: rootPageId })
  }

  rootPageId() {
    const { associatedMerchandizingScan } = this.props

    return associatedMerchandizingScan && associatedMerchandizingScan.rootPageId
  }

  renderSubSections() {
    const { subSections, scan, page } = this.props
    return (
      subSections &&
      subSections.map(s => s && <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const associatedMerchandizingScan = ownProps.scan.associatedMerchandizingScan(state)

  return {
    associatedMerchandizingScan,
  }
}

Navbar.propTypes = {
  scan: PropTypes.object,
  page: PropTypes.object,
  section: PropTypes.object,
  subSections: PropTypes.object,
  spots: PropTypes.object,
  associatedMerchandizingScan: PropTypes.object,
  match: PropTypes.object,
}

const ConnectedNavbar = connect(mapStateToProps)(Navbar)
const ConnectedNavbarWithRouter = withRouter(ConnectedNavbar)
export default ConnectedNavbarWithRouter
