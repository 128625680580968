import React, { useMemo, useState } from 'react'
import { Button, Tooltip, AnchorButton, Position } from '@blueprintjs/core'
import { Scan } from 'Interfaces/*'
import { DownloadType, useDownloads } from 'Hooks/useDownloads'
import useToolbarActions from './useToolbarActions'
import styles from './Toolbar.module.scss'
import cx from 'classnames'
import moment from 'moment'

interface ToolbarActionsProps {
  scan: Scan
  scanStats: boolean
  competitors: boolean
  titlesForCorrection: Set<any>
}

const ToolbarActions: React.FC<ToolbarActionsProps> = ({ scan, scanStats, competitors, titlesForCorrection }) => {
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false)

  const { reloadScan, exportReadyTitlesForScan, showScanStats } = useToolbarActions({ competitors })

  const { startDownload } = useDownloads()

  let submitButtonDisabled: any = true
  const submitButtonText = useMemo(() => {
    if (!scan.isLastDelivered) return "You can't submit a historical scan!"

    return scan.srpExportDate
      ? `Submitted on ${moment(scan.srpExportDate).format('MMMM Do YYYY')}`
      : 'Please select a title to be submitted'
  }, [scan.isLastDelivered, scan.srpExportDate])

  if (!competitors) {
    submitButtonDisabled =
      titlesForCorrection.size === 0 || scan.srpExportDate || submitButtonClicked || !scan.isLastDelivered
  }

  const submitUpdates = () => {
    setSubmitButtonClicked(true)
    exportReadyTitlesForScan()
  }

  const handleExport = () => {
    startDownload(DownloadType.Audit)
  }

  return (
    <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-right')}>
      <Button onClick={handleExport}>Export Report</Button>
      {scan &&
        scan.isAutobotEnabled &&
        !competitors &&
        titlesForCorrection.size > 0 &&
        (submitButtonDisabled ? (

          <Tooltip content={submitButtonText} position={Position.BOTTOM}>
            <AnchorButton onClick={submitUpdates} disabled={submitButtonDisabled} text="Submit"></AnchorButton>
          </Tooltip>
        ) : (
          <Button onClick={submitUpdates} disabled={submitButtonDisabled}>
            Submit
          </Button>
        ))}
      <div className="bp5-navbar-divider" />

      <Tooltip content="Refresh" position={Position.BOTTOM}>
        <Button className="bp5-icon-refresh" onClick={reloadScan} />
      </Tooltip>
      <div className="bp5-navbar-divider" />

      <Tooltip content="Show overview" position={Position.BOTTOM_RIGHT}>
        <Button className="bp5-icon-panel-stats" active={scanStats} onClick={showScanStats} />
      </Tooltip>
    </div>
  )
}

export default ToolbarActions
