import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './styles.module.scss'

class PanelSection extends React.Component {
  render() {
    const { children, fullWidth, extraPadding, withBorder, flex, withBottomBorder, noPadding, className } = this.props

    const classnames = cx(styles.root, className, {
      [styles.fullWidth]: fullWidth,
      [styles.extraPadding]: extraPadding,
      [styles.flex]: flex,
      [styles.withBorder]: withBorder,
      [styles.withBottomBorder]: withBottomBorder,
      [styles.noPadding]: noPadding,
    })
    return <div className={classnames}>{children}</div>
  }
}

PanelSection.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
}

export default PanelSection
