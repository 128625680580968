import React from 'react'
import GenericSection from './GenericSection'
import styles from './PageContainer.module.scss'

class PageContainer extends GenericSection {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.inner}>{this.renderSubSections()}</div>
      </div>
    )
  }
}

export default PageContainer
