import { readEndpoint } from 'Actions/apiActions'
import { List, Map, OrderedMap, fromJS } from 'immutable'
import TitleAuditRecord from 'Records/titleAuditRecord'
import { resetData } from './utils'
import { LOADING_TITLE_AUDITS } from 'Reducers/app/titleAuditReducer'
import {
  getScanFilters,
  getSelectedScan,
  getScanById,
  getSelectedTerritories,
  getSelectedPlatforms,
  getSelectedTags,
  getTitleAuditsPage,
} from 'Selectors'

import { normalizeAndLoadTitles, loadTitles } from 'Actions/titleActions'
import {
  retrieveAuditActionsForTitleAudits,
  normalizeAndLoadAudits,
  normalizeAndLoadAuditActions,
  loadAudits,
  loadAuditActions,
} from 'Actions/auditActions'
import { loadAuditArtworks } from 'Actions/auditArtworkActions'
import { loadPrices } from 'Actions/priceActions'
import { loadAuditPrices } from 'Actions/auditPriceActions'
import { loadAuditExceptions, retrieveAuditExceptionsForTitleAudits } from 'Actions/auditExceptionActions'
import { loadPlatformTitles } from 'Actions/platformTitleActions'
import { loadOptimizationScores, normalizeAndLoadOptimizationScores } from 'Actions/optimizationScoreActions'
import { loadArtworkComparisons } from 'Actions/artworkComparisonActions'
import { loadMerchandizingMatches, normalizeAndLoadMerchandizingMatches } from 'Actions/merchandizing/matchActions'
import { retrieveTitlesForCorrection } from 'Actions/titlesForCorrectionActions'
import { normalizeAndLoadMerchandizingSections, loadMerchandizingSections } from 'Actions/merchandizing/sectionActions'
import { loadChannels } from 'Actions/channelActions'
import { loadTaggings } from 'Actions/taggingActions'
import { batchActions } from 'redux-batched-actions'
import queryString from 'query-string'

export const DEFAULT_INCLUDES = [
  'title',
  'audits',
  'audits.prices',
  'audits.platformTitle.channels',
  'audits.platformTitle.platformTitleAvods',
  'audits.platformTitle.taggings',
]

// For autobot request we need SRP prices
export const INCLUDES_FOR_AUTOBOT = DEFAULT_INCLUDES.concat(['audits.platformTitle.prices'])

export const ARTWORK_INCLUDES = [
  'title',
  'audits',
  'audits.artworks',
  'audits.platformTitle',
  'audits.artworks.artworkComparison',
  'audits.artworks.artworkComparison.previousArtwork',
].join(',')

export const COMPETITOR_INCLUDES = ['title', 'audits', 'audits.prices']

export const titleAuditRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new TitleAuditRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    }),
  )
}

export const normalizeAndLoadTitleAudits = (data, reset) => dispatch => {
  const audits = data
    .map(titleAudit => titleAudit.audits)
    .flat()
    .filter(a => !!a)
  const titleAudits = data.reduce((memo, item) => {
    if (item.audits) item.auditsIds = item.audits.map(a => a.id)

    return memo.set(item.id, new TitleAuditRecord({ ...item }))
  }, new OrderedMap())

  dispatch(normalizeAndLoadAudits(audits))
  return dispatch(loadTitleAudits(titleAudits, reset))
}

export const reloadTitleAudits = competitors => (dispatch, getState) => {
  const state = getState()
  const scan = getSelectedScan(state)
  dispatch({ type: 'LOADING_TITLES_FOR_CORRECTION' })

  return dispatch(retrieveTitleAuditsAndCorrections(scan.id, competitors))
}

export const retrieveTitleAudit = titleAudit => dispatch => {
  dispatch({ type: LOADING_TITLE_AUDITS })
  const url = `scans/${titleAudit.scanId}/title_audits/${titleAudit.id}`

  return readEndpoint(url).then(response => {
    const { audits, auditActions, merchandizingMatches, optimizationScores } = response.titleAudit
    const merchandizingSections = merchandizingMatches.map(m => m.section)
    const titleAudit = new TitleAuditRecord({
      ...response.titleAudit,
      auditsIds: audits.map(a => a.id),
      auditActionsIds: auditActions.map(a => a.id),
      merchandizingMatchesIds: merchandizingMatches.map(m => m.id),
      optimizationScoresIds: optimizationScores.map(o => o.id),
    })

    dispatch(normalizeAndLoadAuditActions(auditActions, false))
    dispatch(normalizeAndLoadMerchandizingSections(merchandizingSections, true))
    dispatch(normalizeAndLoadMerchandizingMatches(merchandizingMatches, false))
    dispatch(normalizeAndLoadOptimizationScores(optimizationScores, false))
    dispatch(updateTitleAudit(titleAudit))
  })
}

export const resetIncludedData = () => dispatch => {
  dispatch(
    batchActions([
      resetData(loadTitles),
      resetData(loadPlatformTitles),
      resetData(loadPrices),
      resetData(loadAudits),
      resetData(loadAuditArtworks),
      resetData(loadAuditPrices),
      resetData(loadChannels),
      resetData(loadOptimizationScores),
      resetData(loadArtworkComparisons),
      resetData(loadMerchandizingMatches),
      resetData(loadTaggings),
      resetData(loadMerchandizingSections),
      resetData(loadAuditExceptions),
      resetData(loadAuditActions),
    ]),
  )
}

export const updateTitleAudit = titleAudit => ({
  type: 'UPDATE_TITLE_AUDIT',
  titleAudit,
})

const normalizeAndLoadTitleAuditResponse = (titleAuditResponse, reset) => dispatch => {
  const audits = titleAuditResponse.map(titleAudit => titleAudit.audits).flat()
  const titles = titleAuditResponse.map(titleAudit => titleAudit.title)

  const titleAudits = titleAuditResponse.reduce((memo, item) => {
    return memo.set(
      item.id,
      new TitleAuditRecord({
        ...item,
        auditsIds: item.audits.map(a => a.id),
      }),
    )
  }, new OrderedMap())

  const hasMoreToLoad = titleAuditResponse.length >= 10

  dispatch(loadTitleAudits(titleAudits, reset, hasMoreToLoad))
  dispatch(normalizeAndLoadTitles(titles, reset))
  dispatch(normalizeAndLoadAudits(audits, reset))
}

export const resetTitleAudits = () => dispatch => {
  const emptyList = new List()
  dispatch(loadTitleAudits(emptyList, true))

  dispatch(resetIncludedData())
}

export const retrieveTitleAuditsAndCorrections = (scanId, competitors) => (dispatch, getState) => {
  dispatch(retrieveTitleAudits(scanId, competitors)).then(() => {
    const state = getState()
    const scan = scanId ? getScanById(state, { id: scanId }) : getSelectedScan(state)
    scan && scan.isAutobotEnabled && !competitors && dispatch(retrieveTitlesForCorrection())
  })
}

export const retrieveTitleAudits = (scanId, competitors, page = 1, reset = true) => (dispatch, getState) => {
  const state = getState()
  const url = `scans/${scanId}/title_audits?`

  const competitor = competitors || false

  const filter = getScanFilters(state)
  const isArtwokPageRequest = filter.includes && filter.includes.includes('audits.artworks')

  let queryParams = {
    'filter[type]': [filter.all && 'movie,tv_season', filter.movies && 'movie', filter.tv && 'tv_season'].filter(
      x => x,
    ),
    'filter[query]': filter.query,
    'filter[platforms]': getSelectedPlatforms(state)
      .valueSeq()
      .map(p => p.id)
      .toArray()
      .join(','),
    'filter[territories]': getSelectedTerritories(state)
      .valueSeq()
      .map(t => t.id)
      .toArray()
      .join(','),
    'filter[tags]': getSelectedTags(state)
      .valueSeq()
      .map(t => t.id)
      .toArray()
      .join(','),
    'filter[promoted]': filter.promoted,
    'filter[priority]': filter.priority,
    'filter[competitors]': competitor,
    'filter[title_ids]': filter.titleIds.join(','),
    sort: filter.titleAuditSort(),
    page: page,
    limit: filter.limit,
  }

  if (isArtwokPageRequest) {
    queryParams = {
      ...queryParams,
      view: 'artwork',
      'filter[artworkChanged]': filter.changedArtwork,
      'filter[blankArtwork]': filter.blankArtwork,
    }
  } else {
    queryParams = {
      ...queryParams,
      'filter[auditExceptions]': filter.auditExceptions,
      'filter[actionType]': [
        filter.allActions && '',
        filter.holdbackActions && 'AuditHoldbackAction',
        filter.priceActions && 'AuditPriceAction,AuditPriceParityAction,AuditPriceOutlierAction',
        filter.availActions && 'AuditAvailAction,AuditChannelAction,AuditAvodAction',
      ].filter(x => x),
      'filter[audit_actions_status]': filter.auditActionsStatus,
    }
  }
  const query = queryString.stringify(queryParams)

  return readEndpoint(url + query).then(response => {
    dispatch(normalizeAndLoadTitleAuditResponse(response.titleAudits, reset))
    dispatch(loadTitleAuditsPage(page))
    if (!competitor) dispatch(retrieveRelatedActionsAndExceptions(response.titleAudits))
  })
}

export const loadTitleAudits = (titleAudits, reset, hasMoreToLoad = true) => ({
  type: 'LOAD_TITLE_AUDITS',
  titleAudits,
  reset,
  hasMoreToLoad,
})

export const loadTitleAuditsPage = (page = 1) => ({
  type: 'LOAD_TITLE_AUDITS_PAGE',
  page,
})

export const retrieveMoreTitleAudits = (competitor = false) => (dispatch, getState) => {
  const state = getState()
  const scan = getSelectedScan(state)
  const currentTitleAuditsPage = getTitleAuditsPage(state) || 0

  return dispatch(retrieveTitleAudits(scan.id, competitor, currentTitleAuditsPage + 1, false))
}

const retrieveRelatedActionsAndExceptions = titleAuditReponse => (dispatch, getState) => {
  const state = getState()
  const scan = getSelectedScan(state)
  const titleAuditsIds = titleAuditReponse.map(titleAudit => titleAudit.id)
  dispatch(retrieveAuditActionsForTitleAudits(scan.id, titleAuditsIds))
  dispatch(retrieveAuditExceptionsForTitleAudits(scan.id, titleAuditsIds))
}
