export const SELECT_BY_REGION = 'Select by Region'
export const SELECT_BY_PLATFORM = 'Select by Platform'
export const REGION = 'region'
export const REGIONS = 'Regions'
export const PLATFORM = 'platform'
export const PLATFORMS = 'Platforms'
export const PLATFORMS_AND_REGIONS = 'Platforms & Regions'
export const REGION_SCANS_PATH = `/v2/clients/:id/scans`
export const REGIONS_SCANS_PATH = `/scans/:id`
export const MERCHANDIZING_SCANS_PATH = `/v2/clients/:id/merchandizing/scans`
export const MERCH_PAGES_PATH = `/merch/:scan_id/pages/:id`
export const CHEVRON_UP = `chevron-up`
export const CHEVRON_DOWN = `chevron-down`
export const LIMIT_REACHED = 'Limit reached'
export const MAX_ALLOWD_PLATFORMS = 20
export const LIMIT_NOTIFICATION = `You have reached the maximum limit for platform selection. Please deselect a platform before adding another. You can select up to ${MAX_ALLOWD_PLATFORMS} platforms in total at a time.`
export const OKAY = 'Okay'
