import React from 'react'
import style from './TabMenu.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { Spot, PageProps, Match } from 'Interfaces/interfaces'
import { linkToPage } from '../utils'
import cx from 'classnames'

type TabMenuProps = RouteComponentProps & {
  spots: Spot[]
  match: Match
  page: PageProps
}

const TabMenu: React.FC<TabMenuProps> = ({ spots, page, match }) => {
  const currentSpots = [...spots]

  return (
    <div className={style.wrapper}>

      <Popover
        interactionKind={PopoverInteractionKind.CLICK}
        position={Position.BOTTOM}
        popoverClassName="bp5-popover-display-none"
        content={<div className={style.dropdown}>
          <ul className={style.dropdownList}>
            {currentSpots
              .slice(1)
              .filter(spot => !!spot)
              .map((spot, index) => (
                <li className={cx(style.listItem, { [style.activeLink]: spot.linkedPageId === page.id })} key={index}>
                  <a href={linkToPage(spot, match)}>{spot.name}</a>
                </li>
              ))}
          </ul>
        </div>}
      >
        <a className={style.firstItem} id={`spot_${currentSpots[0]?.id}`}>
          {currentSpots[0]?.name} <i className={style.arrowDown} data-testid="arrow-icon" />
        </a>

      </Popover>
    </div>
  )
}

export default withRouter(TabMenu)
