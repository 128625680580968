import React from 'react'
import { Button } from '@blueprintjs/core'
import { useDownloads, DownloadType } from 'Hooks/useDownloads'

const DownloadButton = () => {
  const { startDownload } = useDownloads()

  const handleClickOnDownload = () => {
    startDownload(DownloadType.Artwork)
  }

  return (
    <div>
      <Button className="bp5-button" onClick={handleClickOnDownload}>
        Generate PDF
      </Button>
    </div>
  )
}

export default DownloadButton
