import React from 'react'
import style from './Item.module.scss'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import { linkToPage, scrollToNode } from '../utils'
import shopIcon from './shop.svg'
import ownIcon from './own.svg'
import icon12 from './12.png'
import icon15 from './15.png'
import icon18 from './18.png'
import iconPg from './pg.png'
import iconU from './u.png'
import { AmazonSectionTypes } from '../enums'

class Item extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  isSeeMoreSpot() {
    const { spot } = this.props
    return spot.name && spot.name.includes('See more')
  }

  renderButtonText(text) {
    if (!text) return null
    const formattedText = text.replace(/{lineBreak}/g, '<br/>')
    return <button dangerouslySetInnerHTML={{ __html: formattedText }} />
  }

  render() {
    const { spot, client, isMerchandiseFilteringEnabled, section, match } = this.props
    const { coverUrl, subtitle, currentRating, icon } = spot.metadata
    const inlineStyle = {}
    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    const ratingIcons = {
      '12': icon12,
      '15': icon15,
      '18': icon18,
      PG: iconPg,
      U: iconU,
    }

    const offerIcons = {
      OFFER_ICON: shopIcon,
      ENTITLED_ICON: ownIcon,
    }

    const classNames = cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
      [style.highlight]: spot.selected || client,
    })

    const shouldRenderShadow = section.sectionType === AmazonSectionTypes._HeroCarousel
    const shouldRenderSpotDetails = section.sectionType === AmazonSectionTypes._HeroCarousel

    return (
      <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={node => (this.node = node)}>
        {shouldRenderShadow && <div className={style.shadow}></div>}
        <div
          id={`spot_${spot.id}`}
          style={{
            background: `black url(${spot.artworkUrl}) center center no-repeat`,
            backgroundSize: 'cover',
            marginBottom: '8px',
          }}
        >
          {shouldRenderSpotDetails && (
            <div className={style.amazonDetails}>
              {coverUrl ? (
                <img className={style.itemImage} src={coverUrl} />
              ) : (
                <span className={style.title}>{spot.name}</span>
              )}
              <div className={style.amazonButtonWrapper}>
                <div className={style.amazonDescriptionWrapper}>
                  {icon && Object.keys(offerIcons).includes(icon) && (
                    <img className={style.shopIcon} src={offerIcons[icon]} width="24" height="24" />
                  )}
                  {icon === 'ADS_ICON' && <span className={style.offerIcon}>ads</span>}
                  <span className={style.description}>{spot.description}</span>
                  {currentRating && !Object.keys(ratingIcons).includes(currentRating) && (
                    <span className={style.currentRating}>{currentRating}</span>
                  )}
                  {currentRating && Object.keys(ratingIcons).includes(currentRating) && (
                    <img className={style.ratingIcon} src={ratingIcons[currentRating]} width="33" height="33" />
                  )}
                </div>
                {this.renderButtonText(subtitle)}
              </div>
            </div>
          )}
        </div>
        <span className={style.amazonCollection}>{spot.collectionName}</span>
      </a>
    )
  }
}

const ConnectedItemWithRouter = withRouter(Item)
export default ConnectedItemWithRouter
