import React, { useMemo } from 'react'
import Item from './Item'
import { PageItems } from 'Interfaces/interfaces'
import style from './Grid.module.scss'
import { renderSpotsWithItem, computeContainerClasses } from '../helpers'
import cx from 'classnames'
import { CanalPlusSectionTypes } from '../enums'

const Grid: React.FC<PageItems> = ({ spots, selectedStudios, section, isMerchandiseFilteringEnabled }) => {
  const { name, metadata } = section
  const { subtitle } = JSON.parse((metadata as unknown) as string)

  const containerClasses = useMemo(() => computeContainerClasses(section, style, 4), [section])

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.gridItem]: section.sectionType === CanalPlusSectionTypes._Grid,
        [style.portraitGridItem]: section.sectionType === CanalPlusSectionTypes._PortraitGrid,
      }),
    [section],
  )

  const gridWrapperClasses = useMemo(
    () =>
      cx({
        [style.gridWrapper]: section.sectionType === CanalPlusSectionTypes._Grid,
        [style.portraitGridWrapper]: section.sectionType === CanalPlusSectionTypes._PortraitGrid,
      }),
    [section],
  )

  const spotsRender = renderSpotsWithItem({
    spots: spots.filter(spot => spot),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  return (
    <div className={containerClasses}>
      <div className={style.sectionHeader}>
        <h1>{name}</h1>
        <p>{subtitle}</p>
      </div>
      <div className={gridWrapperClasses}>{spotsRender}</div>
    </div>
  )
}

export default Grid
