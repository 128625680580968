import React from 'react'
import style from './MultiRowItem.module.scss'
import cx from 'classnames'
import { scrollToNode } from '../utils'

class MultiRowItem extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, client, section } = this.props

    const sectionType = section.sectionType

    const imageclass = cx({
      [style.detailImage]: sectionType === 'apple_tv::grid_with_text',
      [style.multiRowImage]: sectionType !== 'apple_tv::grid_with_text',
    })

    const titleClass = cx({
      [style.detailTitle]: sectionType === 'apple_tv::grid_with_text',
      [style.multiRowTitle]: sectionType !== 'apple_tv::grid_with_text',
    })

    const inlineStyle = {}
    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    return (
      <div id={`spot_${spot.id}`} className={style.item} ref={node => (this.node = node)} style={inlineStyle}>
        <div className={style.container}>
          <div
            className={imageclass}
            style={{
              background: `black url('${spot.artworkUrl}') center center no-repeat`,
              backgroundSize: 'cover',
              borderRadius: '5px',
            }}
          ></div>
          <div className={style.position}>{spot.position}</div>
          <div className={titleClass}>
            <span>{spot.name && spot.name}</span>
          </div>
        </div>
      </div>
    )
  }
}

export default MultiRowItem
