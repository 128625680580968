import React from 'react'
import { connect } from 'react-redux'
import { getTitleById, getPlatformById, getTitleFilters } from 'Selectors'
import styles from 'Components/ContainerPage/Container.module.scss'
import cx from 'classnames'
import VisibilitySensor from 'react-visibility-sensor'
import { Popover, PopoverInteractionKind } from '@blueprintjs/core'
import ContainerDialog from 'Components/ContainerPage/ContainerDialog'
import NewCandidateBadge from 'Components/ContainerPage/NewCandidateBadge'
import { Route, Link, withRouter } from 'react-router-dom'
import ContainerBadge from 'Components/ContainerPage/ContainerBadge'
import {
  markAsUnavailablePlatformTitle,
  markAsUnavailableForeverPlatformTitle,
  retryPlatformTitle,
  abortPlatformTitle,
  deleteTransitionsPlatformTitle,
  updatePlatformTitle,
  updatePlatformTitleThenApprove,
  markAsVirtualPlatformTitle,
} from 'Actions/platformTitleActions'
class Container extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      visible: false,
      attempts: 0,
    }
    this.img = null
  }
  keyArtwork() {
    const { platformTitle } = this.props
    return platformTitle && platformTitle.artworkCdnUrl
  }
  onLoad = () => {
    this.setState({ loaded: true })
  }
  onVisible = isVisible => {
    if (isVisible) {
      this.setState({ visible: true }, () => {
        this.loadImage()
      })
    }
  }
  onError = () => {
    const { attempts } = this.state
    this.setState({ attempts: attempts + 1 }, () => {
      if (attempts < 10) {
        this.img = null
        this.timer = setTimeout(() => this.loadImage(), 5000)
      }
    })
  }
  loadImage() {
    const { loaded, visible } = this.state
    const url = this.keyArtwork()

    if (url && visible && !loaded && this.img === null) {
      this.img = new Image()
      this.img.onload = this.onLoad
      this.img.onerror = this.onError
      this.img.src = url
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.platformTitle &&
      prevProps.platformTitle &&
      this.props.platformTitle.id !== prevProps.platformTitle.id
    ) {
      const { artworkCdnUrl: currentArtworkUrl } = this.props.platformTitle
      const { artworkCdnUrl: prevArtworkUrl } = prevProps.platformTitle

      if (currentArtworkUrl !== prevArtworkUrl) {
        this.setState({ loaded: false, attempts: 0 }, () => {
          this.img = null
          this.loadImage()
        })
      }
    }
  }

  componentWillUnmount() {
    if (this.img) {
      this.img.onload = function () { }
    }
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  render() {
    const {
      title,
      platformTitle,
      filter,
      match,
      selectedLocationCandidate,
      locationCandidates,
      markAsUnavailablePlatformTitle,
      markAsUnavailableForeverPlatformTitle,
      retryPlatformTitle,
      abortPlatformTitle,
      deleteTransitionsPlatformTitle,
      updatePlatformTitleThenApprove,
      update,
      markAsVirtualPlatformTitle,
    } = this.props
    const { loaded, visible } = this.state

    const url = this.keyArtwork()

    const posterStyle = {
      backgroundImage: `url(${url})`,
    }

    this.loadImage()

    const sizingClasses = {
      [styles.moviePoster]: title && title.titleTypeCode === 'movie',
      [styles.tvPoster]: title && title.titleTypeCode === 'tv_season',
    }

    const posterClassnames = cx(sizingClasses, styles.posterImage, {
      [styles.loaded]: url && loaded,
      [styles.fade]:
        (platformTitle && filter.state != '' && filter.state != platformTitle.currentState) ||
        (platformTitle && filter.newCandidates && filter.newCandidates !== platformTitle.hasNewCandidates),
    })

    const detailUrl = `${match.url}/container/${platformTitle && platformTitle.id}`

    return (
      <VisibilitySensor onChange={this.onVisible} partialVisibility>
        <div className={styles.platformTitle}>
          <Popover
            useSmartArrowPositioning={true}
            interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
            popoverClassName="bp5-dark bp5-popover-content-sizing"
            tetherOptions={{
              constraints: [
                {
                  attachment: 'together',
                  pin: true,
                  to: 'window',
                },
              ],
            }}
          >
            <Link to={detailUrl}>
              <div className={cx(sizingClasses, styles.inner)}>
                {url && loaded && visible && <div className={posterClassnames} style={posterStyle} />}

                {platformTitle && (
                  <React.Fragment>
                    {platformTitle.hasNewCandidates && <NewCandidateBadge />}
                    <ContainerBadge platformTitle={platformTitle} className={styles.badge} />
                  </React.Fragment>
                )}
              </div>
            </Link>
            <div>
              {(selectedLocationCandidate && selectedLocationCandidate.title) ||
                (platformTitle && platformTitle.currentState)}
            </div>
          </Popover>
          <Route
            path={detailUrl}
            render={() => (
              <ContainerDialog
                key={platformTitle.id}
                platformTitleId={platformTitle.id}
                title={title}
                locationCandidates={locationCandidates}
                selectedLocationCandidate={selectedLocationCandidate}
                markAsUnavailablePlatformTitle={markAsUnavailablePlatformTitle}
                markAsUnavailableForeverPlatformTitle={markAsUnavailableForeverPlatformTitle}
                retryPlatformTitle={retryPlatformTitle}
                abortPlatformTitle={abortPlatformTitle}
                deleteTransitionsPlatformTitle={deleteTransitionsPlatformTitle}
                updatePlatformTitleThenApprove={updatePlatformTitleThenApprove}
                update={update}
                markAsVirtualPlatformTitle={markAsVirtualPlatformTitle}
              />
            )}
          />
        </div>
      </VisibilitySensor>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { titleId, platformId } = ownProps
  const platform = getPlatformById(state, { id: platformId })
  const title = getTitleById(state, { id: titleId })
  const platformTitle = title && title.platformTitleForPlatform(state, platformId)
  const selectedLocationCandidate = platformTitle && platformTitle.selectedLocationCandidate(state)
  const locationCandidates = platformTitle && platformTitle.locationCandidates(state)

  const filter = getTitleFilters(state)

  return {
    platform,
    title,
    platformTitle,
    filter,
    selectedLocationCandidate,
    locationCandidates,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    markAsUnavailablePlatformTitle: platformTitleId => dispatch(markAsUnavailablePlatformTitle(platformTitleId)),
    markAsUnavailableForeverPlatformTitle: platformTitleId =>
      dispatch(markAsUnavailableForeverPlatformTitle(platformTitleId)),
    markAsVirtualPlatformTitle: platformTitleId => dispatch(markAsVirtualPlatformTitle(platformTitleId)),
    retryPlatformTitle: platformTitleId => dispatch(retryPlatformTitle(platformTitleId)),
    abortPlatformTitle: platformTitleId => dispatch(abortPlatformTitle(platformTitleId)),
    deleteTransitionsPlatformTitle: platformTitleId => dispatch(deleteTransitionsPlatformTitle(platformTitleId)),
    updatePlatformTitleThenApprove: (platformTitle, attributes) =>
      dispatch(updatePlatformTitleThenApprove(platformTitle, attributes)),
    update: (platformTitle, attributes) => dispatch(updatePlatformTitle(platformTitle, attributes)),
  }
}

const ConnectedContainer = connect(mapStateToProps, mapDispatchToProps)(Container)
const ConnectedContainerWithRouter = withRouter(ConnectedContainer)
export default ConnectedContainerWithRouter
