import React from 'react'
import style from './Container.module.scss'
import Slider from 'react-slick'
import Item from './Item'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import arrow from './arrow.png'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return (
    <div
      className={cx({
        [style.leftWrapper]: left === true,
        [style.rightWrapper]: right === true,
      })}
      onClick={onClick}
    >
      <img className={classnames} alt="sliderArrow" src={arrow} />
    </div>
  )
}

function renderSpots(spots, section) {
  return spots.map(spot => {
    return (
      spot && (
        <div className={style.item} key={spot.id}>
          <Item spot={spot} section={section} />
        </div>
      )
    )
  })
}

function links(spots) {
  return spots.filter(spot => spot && spot.spotType === 'link')
}

function viewAll(spots, section) {
  return section.sectionType === 'kaleidescape::container'
    ? links(spots).find(spot => spot && ['View All'].includes(spot.name))
    : links(spots).first(spot => spot)
}

function Container(props) {
  const { spots, section, match } = props

  const viewAllSpot = viewAll(spots, section)

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 10,
    slidesToShow: 10,
    arrows: true,
    swipe: true,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow left />,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
    ],
  }
  return (
    <div className={style.container}>
      <div className={style.heading}>
        {viewAllSpot && (
          <h2>
            <a href={linkToPage(viewAllSpot, match)}>{section.name}</a>
          </h2>
        )}
      </div>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots(spots, section)}
      </Slider>
    </div>
  )
}

export default withRouter(Container)
