import React from 'react'
import Item from './Item'
import style from './Deals.module.scss'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'

function renderSpots(section, spots) {
  const classnames = cx({
    [style.quarterItem]: spots.length === 4,
    [style.halfItem]: spots.length === 2,
    [style.fullItem]: spots.length === 1,
  })
  return spots.map(spot => {
    return (
      spot && (
        <div className={classnames} key={spot.id}>
          <Item spot={spot} section={section} />
        </div>
      )
    )
  })
}

function seeMore(spots) {
  return spots.find(spot => spot && spot.spotType === 'link')
}

function Deals(props) {
  const { section, spots, match } = props
  const metadata = JSON.parse(props.section.metadata)
  const filteredSpots = [...spots.filter(s => s && s.spotType !== 'link')]
  const seeMoreSpot = seeMore(spots)

  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h3>
          {section.name}
          {seeMoreSpot && (
            <a href={linkToPage(seeMoreSpot, match)}>
              {seeMoreSpot.name} <i className={style.viewAllArrow} />
            </a>
          )}
        </h3>
      </div>
      <div className={style.spotWrapper}>
        {filteredSpots.length > 1 && metadata.artwork && (
          <div className={style.priceImage}>
            <img src={metadata.artwork} />
          </div>
        )}
        {renderSpots(section, filteredSpots)}
      </div>
    </div>
  )
}

export default withRouter(Deals)
