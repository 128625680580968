import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'

import {
  getSelectedScan,
  getPlatforms,
  getSelectedPlatforms,
  getSelectedTerritories,
} from 'Selectors'

import { setRootPath } from 'Actions/uiActions'
import Toolbar from 'Components/MpvAnalytics/Toolbar'
import styles from 'Components/MpvAnalytics/styles.module.scss'
import { COMPETITIOR_PERFORMANCE, PLATFORM_PERFORMANCE } from 'Components/MpvAnalytics/constants'
import { RouteComponentProps } from 'react-router-dom'
import useScan from 'Hooks/useScan'
import usePreserveFilter from 'Hooks/usePreserveFilter'
import { useClientContext } from 'Contexts/ClientContext'

interface Props extends RouteComponentProps<{ scanId: string }> {
  scanId: number
  competitors: boolean
}

const MpvAnalytics = ({ match, scanId, competitors }: Props) => {
  const [performancePage, setPerformancePage] = useState<string>(COMPETITIOR_PERFORMANCE);
  const dispatch = useDispatch()
  const { client } = useClientContext()
  const scan = useSelector(state => getSelectedScan(state))
  const distributors = useSelector(state => getPlatforms(state))
  const platforms = useSelector(state => getSelectedPlatforms(state))
  const territories = useSelector(state => getSelectedTerritories(state))

  useScan(scanId)

  usePreserveFilter({ enabled: !!scan })

  useEffect(() => {
    dispatch(setRootPath(match.url))
  }, [dispatch, match.path, match.url])

  const handleSetPerformancePage = useCallback((page: string) => {
    setPerformancePage(page);
  }, []);

  return (
    <div>
      <div className={cx('bp5-focus-disabled', styles.root)}>
        <Toolbar
          scan={scan}
          client={client}
          distributors={distributors}
          platforms={platforms}
          territories={territories}
          performancePage={performancePage}
          handleSetPerformancePage={handleSetPerformancePage}
        />
        {performancePage === COMPETITIOR_PERFORMANCE && <div className={styles.performanceWrapper}><h1>{COMPETITIOR_PERFORMANCE}</h1></div>}
        {performancePage === PLATFORM_PERFORMANCE && <div className={styles.performanceWrapper}><h1>{PLATFORM_PERFORMANCE}</h1></div>}
      </div>
    </div>
  )
}

export default MpvAnalytics