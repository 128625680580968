import React from 'react'
import { connect } from 'react-redux'
import GenericSection from 'Components/Merchandizing/Itunes/GenericSection'
import style from 'Components/Merchandizing/Itunes/Carousel.module.scss'
import Slider from 'react-slick'
import WrappedSection from 'Containers/WrappedSection'

class Carousel extends GenericSection {
  render() {
    const { selectedSection } = this.props
    const settings = {
      autoplay: !selectedSection,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 0,
      variableWidth: true,
      arrows: false,
      swipe: true,
    }

    return (
      <div className={style.container}>
        <Slider {...settings}>{this.renderCarouselSections()}</Slider>
      </div>
    )
  }
  reorderSubSections() {
    const { subSections } = this.props

    const secondItem = subSections.get(1)
    return subSections.delete(1).push(secondItem)
  }

  renderCarouselSections() {
    const { scan, page, fade } = this.props
    const subSections = this.reorderSubSections()
    return (
      subSections &&
      subSections.map(s => (
        <WrappedSection
          type={s.sectionType}
          key={s.id}
          section={s}
          scan={scan}
          page={page}
          style={{ width: 680 }}
          fade={fade}
        />
      ))
    )
  }
}

const mapStateToProps = (_state, ownProps) => {
  const { subSections, selectedSection } = ownProps
  return {
    fade: subSections.includes(selectedSection),
  }
}

export default connect(mapStateToProps)(Carousel)
