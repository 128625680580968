import { Record, Map } from 'immutable'
import { getPlatforms, getTerritories, getMerchandizingPages, getAssociatedMerchandizingScans } from 'Selectors'

export default class ScanRecord extends Record({
  id: 0,
  selected: false,
  platformCode: '',
  platformName: '',
  territoryCode: '',
  platformId: '',
  createdAt: '',
  scheduledAt: '',
  rootPageId: '',
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {
  rootPage(state) {
    const pages = this.pages(state)

    return pages.find(page => page && page.isRoot)
  }

  pages(state) {
    const pages = getMerchandizingPages(state)

    const pagesRel = this.getIn(['relationships', 'pages', 'data'])

    return pagesRel.map(item => pages.get(item.get('id')))
  }

  platform(state) {
    const platforms = getPlatforms(state)
    const { relationships } = this
    const id = relationships.getIn(['platform', 'data', 'id'])
    return platforms.get(id)
  }

  territory(state) {
    const territories = getTerritories(state)
    const { relationships } = this
    const id = relationships.getIn(['territory', 'data', 'id'])
    return territories.get(id)
  }

  associatedMerchandizingScan(state) {
    const associatedMerchandizingScans = getAssociatedMerchandizingScans(state)

    return associatedMerchandizingScans.find(ams => ams.merchandizingScanId === this.id)
  }
}
