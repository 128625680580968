import React from 'react'
import Infinite from 'react-infinite'
import ContainerDimensions from 'react-container-dimensions'
import { Spinner } from '@blueprintjs/core'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'

import TitleAuditRow from 'Containers/TitleAuditRow'
import PlatformHeader from './PlatformHeader/PlatformHeader'

import styles from './styles.module.scss'
import cssVariables from './variables.scss'
import PriceHeader from './PriceHeader'
import GhostPrice from './GhostPrice'
import GhostColumn from './GhostColumn'
import GhostTvod from './GhostTvod'
import { mergePlatformAndClientPlatofrmTypes } from 'components/utils.js'

const GridContainer = styled.div`
  min-width: calc(${cssVariables.totalRowHeaderWidth} + ${props => props.dimension}px);
`

const GhostRow = ({ ghostCells, children, client, platforms }) => {
  return (
    <div className={styles.row}>
      <div className={styles.card}>{children}</div>
      <PriceHeader fade />
      {platforms.valueSeq().map((platform, index) => {
        const tvodColumnWidth = cx({
          [styles.halfTvodColumn]: platform.purchaseOptions.length < 2,
          [styles.tvodColumn]: platform.purchaseOptions.length >= 2,
        })

        const platformTypes = mergePlatformAndClientPlatofrmTypes(client, platform)
        const platformTypeExists = Object.keys(platformTypes).some(element => platformTypes[element])
        return (
          <div key={index} className={styles.price}>
            {platformTypeExists && (
              <table className={styles.platformItemTable}>
                <tr>
                  {Object.keys(platformTypes).map(element => {
                    if (platformTypes[element]) {
                      if (element === 'tvod')
                        return (
                          <td className={tvodColumnWidth} key={`ghost-tvod-${index}`}>
                            <GhostTvod client={client} />
                          </td>
                        )
                      if (element === 'svod')
                        return (
                          <td className={styles.svodColumn} key={`ghost-svod-${index}`}>
                            <GhostColumn type={element} />
                          </td>
                        )
                      if (element === 'avod')
                        return (
                          <td className={styles.avodColumn} key={`ghost-avod-${index}`}>
                            <GhostColumn type={element} />
                          </td>
                        )
                    }
                  })}
                </tr>
              </table>
            )}
          </div>
        )
      })}
      {new Array(Math.max(0, ghostCells)).fill(0).map((i, index) => (
        <GhostPrice client={client} key={`ghost${index}`} />
      ))}
    </div>
  )
}

class Grid extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isInfiniteLoading: false,
    }
  }

  heightsForInfinite(ghostRows) {
    const { titleAudits } = this.props
    const ghostRowHeights = new Array(Math.max(0, ghostRows)).fill(parseInt(cssVariables.gridCellHeight))
    return titleAudits
      .valueSeq()
      .map(() => {
        const titleHeight = parseInt(cssVariables.gridCellHeight)
        return titleHeight || 0
      })
      .toArray()
      .concat(ghostRowHeights)
  }

  handleInfiniteLoad = () => {
    const { retrieveMoreTitleAudits, hasMoreTitleAuditsToLoad } = this.props

    if (!hasMoreTitleAuditsToLoad) return

    this.setState(
      {
        isInfiniteLoading: true,
      },
      () => {
        retrieveMoreTitleAudits().then(() => {
          this.setState({ isInfiniteLoading: false })
        })
      },
    )
  }

  elementInfiniteLoad = ghostCells => {
    const { client, platforms, hasMoreTitleAuditsToLoad } = this.props

    if (!hasMoreTitleAuditsToLoad) return

    return (
      <GhostRow ghostCells={ghostCells} client={client} platforms={platforms}>
        <Spinner />
      </GhostRow>
    )
  }

  render() {
    const {
      titleAudits,
      scan,
      platforms,
      client,
      platformsWithFlags,
      competitors,
      autobotEnabled,
      scanSummary,
    } = this.props
    const headerOffset = 100
    const { isInfiniteLoading } = this.state

    const gridCellWidth = parseInt(cssVariables.gridCellWidth)
    const gridCellHeight = parseInt(cssVariables.gridCellHeight)

    const platformWidth = platformsWithFlags.reduce((width, item) => {
      const platformTypes = mergePlatformAndClientPlatofrmTypes(client, item.platform)
      Object.keys(platformTypes).forEach(element => {
        if (platformTypes[element]) {
          if (element === 'tvod' && item.platform.buyEnabled()) {
            width += parseInt(cssVariables.seasonGridCellWidth)
          }
          if (element === 'tvod' && item.platform.rentEnabled()) {
            width += parseInt(cssVariables.tvodCellWidth)
          }
          if (element === 'svod') width += parseInt(cssVariables.svodCellWidth)
          if (element === 'avod') width += parseInt(cssVariables.avodCellWidth)
        }
      })
      return width
    }, 0)

    return (
      <GridContainer className={styles.gridContainer} dimension={platformWidth}>
        <ContainerDimensions>
          {({ width, height }) => {
            const rowBodyWidth = width - parseInt(cssVariables.rowHeaderWidth)
            const ghostCells = Math.trunc((rowBodyWidth - platformWidth) / gridCellWidth) + cssVariables.ghostCell
            const ghostRowCount = Math.max(
              0,
              Math.trunc((height - headerOffset - titleAudits.size * gridCellHeight) / gridCellHeight) +
              cssVariables.ghostCell,
            )

            const ghostRows = new Array(ghostRowCount)
              .fill(0)
              .map((s, index) => (
                <GhostRow client={client} key={`ghost${index}`} ghostCells={ghostCells} platforms={platforms} />
              ))

            return (
              height && (
                <div className={styles.gridContainer}>
                  <div className={styles.gridHeader}>
                    {scanSummary && (
                      <div className={styles.titlesCount}>
                        Titles: <span>{scanSummary.titlesCount}</span>
                      </div>
                    )}
                    <PlatformHeader
                      competitors={competitors}
                      paddingWidth={cssVariables.rowHeaderWidth}
                      client={client}
                      platformWidth={platformWidth}
                      platformsWithFlags={platformsWithFlags}
                      contenerDimension={width}
                    />
                  </div>
                  <Infinite
                    containerHeight={height - headerOffset}
                    elementHeight={this.heightsForInfinite(ghostRowCount)}
                    onInfiniteLoad={this.handleInfiniteLoad}
                    infiniteLoadBeginEdgeOffset={400}
                    isInfiniteLoading={isInfiniteLoading}
                    loadingSpinnerDelegate={this.elementInfiniteLoad(ghostCells)}
                    ghostRows={ghostRows}
                  >
                    {titleAudits
                      .valueSeq()
                      .map(titleAudit => (
                        <TitleAuditRow
                          titleAuditId={titleAudit.id}
                          key={titleAudit.id}
                          scanId={scan.id}
                          ghostCells={ghostCells}
                          competitors={competitors}
                          autobotEnabled={autobotEnabled}
                          client={client}
                          platforms={platforms}
                        />
                      ))
                      .concat(ghostRows)}
                  </Infinite>
                </div>
              )
            )
          }}
        </ContainerDimensions>
      </GridContainer>
    )
  }
}

const ConnectedGridWithRouter = withRouter(Grid)
export default ConnectedGridWithRouter
