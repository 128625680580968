import React from 'react'
import ContainerDimensions from 'react-container-dimensions'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import TitleRow from 'Containers/TitleRow.tsx'

import styles from 'Components/ContainerPage/Grid.module.scss'
import cssVariables from 'Components/ContainerPage/variables.scss'
import ErrorBoundary from 'Components/ErrorBoundary'
import GhostRow from 'Components/ContainerPage/GhostRow'
import ContainerPagination from 'Components/ContainerPage/Pagination'

const GridContainer = styled.div`
  min-width: calc(
    ${cssVariables.rowHeaderWidth} + ${cssVariables.gridCellTotalWidth} + ${cssVariables.gridCellTotalWidth} *
      ${props => props.cells}
  );
`

class Grid extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { retrieveTitles, showContainerView } = this.props
    retrieveTitles()
    showContainerView()
    window.scrollTo(0, 0)
  }

  render() {
    const { titles, platforms, platformsWithFlags } = this.props
    const gridCellWidth = parseInt(cssVariables.gridCellWidth)
    const gridCellHeight = parseInt(cssVariables.gridCellHeight)

    return (
      <GridContainer className={styles.gridContainer} cells={platforms.size}>
        <div className={styles.gridContainer}>
          <ErrorBoundary>
            <ContainerDimensions>
              {({ width, height }) => {
                const rowBodyWidth = width - parseInt(cssVariables.rowHeaderWidth)
                const ghostCells = Math.trunc((rowBodyWidth - platforms.size * gridCellWidth) / gridCellWidth) + 1
                const ghostRowCount = Math.max(
                  0,
                  Math.trunc((height - titles.size * gridCellHeight) / gridCellHeight) + 1,
                )

                const ghostRows = new Array(ghostRowCount)
                  .fill(0)
                  .map((s, index) => <GhostRow key={`ghost${index}`} ghostCells={ghostCells + platforms.size} />)

                return (
                  height && (
                    <React.Fragment>
                      {titles.valueSeq().map(title => (
                        <TitleRow
                          titleId={title.id}
                          key={title.id}
                          ghostCells={ghostCells}
                          platformsWithFlags={platformsWithFlags}
                        />
                      ))}
                      {ghostRows}
                    </React.Fragment>
                  )
                )
              }}
            </ContainerDimensions>
          </ErrorBoundary>
          <ContainerPagination />
        </div>
      </GridContainer>
    )
  }
}

const ConnectedGridWithRouter = withRouter(Grid)
export default ConnectedGridWithRouter
