import React, { useMemo } from 'react'
import Slider from 'react-slick'
import Item from './Item'
import Arrow from './Arrow'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import { PageItems, Spot } from 'Interfaces/interfaces'
import { RouteComponentProps } from 'react-router-dom'
import style from './Container.module.scss'
import { RakutenTvSectionTypes } from '../enums'
import { renderSpotsWithItem, sliderSettings } from '../helpers'

type ContainerProps = RouteComponentProps & PageItems

const Container: React.FC<ContainerProps> = ({
  section,
  isMerchandiseFilteringEnabled,
  spots,
  selectedStudios,
  match,
}) => {
  const seeMore = useMemo(() => spots.find((spot: Spot) => spot?.label === 'See all'), [spots])

  const containerClasses = useMemo(
    () =>
      cx(style.container, {
        [style.first]: section && section.position <= 3,
        [style.saleContainer]: section && section.sectionType === RakutenTvSectionTypes._SaleContainer,
      }),
    [section],
  )

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.containerItem]: [RakutenTvSectionTypes._Container, RakutenTvSectionTypes._SaleContainer].includes(
          section.sectionType as RakutenTvSectionTypes,
        ),
        [style.landscapeContainerItem]: section.sectionType === RakutenTvSectionTypes._LandscapeContainer,
        [style.videoContainerItem]: section.sectionType === RakutenTvSectionTypes._VideoContainer,
        [style.categoryItem]: section.sectionType === RakutenTvSectionTypes._Category,
        [style.chartContainerItem]: section.sectionType === RakutenTvSectionTypes._ChartContainer,
      }),
    [section.sectionType],
  )

  const headingClassNames = useMemo(
    () =>
      cx({
        [style.heading]: section.sectionType !== RakutenTvSectionTypes._SaleContainer,
        [style.saleHeading]: section.sectionType === RakutenTvSectionTypes._SaleContainer,
      }),
    [section],
  )

  const renderSpots = renderSpotsWithItem({
    spots: spots.filter((spot: Spot) => {
      if (!spot) return false
      if (section.sectionType === RakutenTvSectionTypes._Category) {
        return true
      }
      return spot.label !== 'See all'
    }),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const spotsPerSlider = {
    [RakutenTvSectionTypes._Container]: 9,
    [RakutenTvSectionTypes._SaleContainer]: 9,
    [RakutenTvSectionTypes._Category]: 9,
    [RakutenTvSectionTypes._LandscapeContainer]: 6,
    [RakutenTvSectionTypes._VideoContainer]: 7,
    [RakutenTvSectionTypes._ChartContainer]: 7,
  }
  const shouldRenderHeader = section.sectionType !== RakutenTvSectionTypes._Category

  return (
    <div className={containerClasses}>
      {shouldRenderHeader && (
        <div className={headingClassNames}>
          <h1>{section.name}</h1>
          {seeMore && (
            <a href={linkToPage(seeMore, match)}>
              {seeMore.name}
              <span>
                <i className={style.rightArrow} />
              </span>
            </a>
          )}
        </div>
      )}
      <Slider
        {...sliderSettings({
          slidesToScroll: spotsPerSlider[section.sectionType],
          slidesToShow: spotsPerSlider[section.sectionType],
          arrow: true,
          nextArrow: <Arrow right section={section.sectionType} />,
          prevArrow: <Arrow left section={section.sectionType} />,
          infinite: [...spots].length > spotsPerSlider[section.sectionType],
        })}
        className={style.alignSpotsLeft}
      >
        {renderSpots}
      </Slider>
    </div>
  )
}

export default withRouter(Container)
