import React from 'react'
import Infinite from 'react-infinite'
import ContainerDimensions from 'react-container-dimensions'
import { withRouter } from 'react-router-dom'
import { Spinner } from '@blueprintjs/core'

import styled from 'styled-components'

import styles from './Grid.module.scss'
import cssVariables from './variables.scss'

import ArtworkTitleAuditRow from 'Containers/ArtworkTitleAuditRow.jsx'
import PlatformHeader from './PlatformHeader'

const GridContainer = styled.div`
  min-width: calc(${cssVariables.rowHeaderWidth} + ${cssVariables.gridCellTotalWidth} * ${props => props.cells});
`

const GhostRow = ({ children }) => {
  return <div className={styles.ghostRow}>{children}</div>
}

class Grid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isInfiniteLoading: false,
    }
  }

  heightsForInfinite() {
    const { titles } = this.props

    return titles
      .valueSeq()
      .map(title => {
        const titleHeight = title && title.titleTypeCode === 'movie' ? 200 + 15 : 150 + 15
        return titleHeight || 0
      })
      .toArray()
  }

  elementInfiniteLoad = () => {
    const { hasMoreTitleAuditsToLoad } = this.props
    if (!hasMoreTitleAuditsToLoad) return

    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    )
  }

  handleInfiniteLoad = () => {
    const { retrieveMoreTitleAudits, hasMoreTitleAuditsToLoad, titleAudits } = this.props

    if (!hasMoreTitleAuditsToLoad || !titleAudits?.size) return

    this.setState(
      {
        isInfiniteLoading: true,
      },
      () => {
        retrieveMoreTitleAudits().then(() => {
          this.setState({ isInfiniteLoading: false })
        })
      },
    )
  }
  render() {
    const { titleAudits, scan, platforms, rootPath, client, platformsWithFlags } = this.props

    const { isInfiniteLoading } = this.state
    const headerOffset = 180

    const gridCellWidth = parseInt(cssVariables.gridCellWidth)
    const gridCellHeight = parseInt(cssVariables.gridCellHeight)

    return (
      <GridContainer className={styles.root} cells={platformsWithFlags.size}>
        <PlatformHeader platformsWithFlags={platformsWithFlags} />
        <ContainerDimensions>
          {({ width, height }) => {
            const rowBodyWidth = width - parseInt(cssVariables.rowHeaderWidth)
            const ghostCells = Math.trunc((rowBodyWidth - platforms.size * gridCellWidth) / gridCellWidth) + 1

            const ghostRowCount = Math.max(
              0,
              Math.trunc((height - headerOffset - titleAudits.size * gridCellHeight) / gridCellHeight) + 1,
            )

            const ghostRows = new Array(ghostRowCount)
              .fill(0)
              .map((s, index) => (
                <GhostRow client={client} key={`ghost${index}`} ghostCells={ghostCells + platforms.size} />
              ))
            return (
              height && (
                <Infinite
                  containerHeight={height - headerOffset}
                  elementHeight={this.heightsForInfinite()}
                  onInfiniteLoad={this.handleInfiniteLoad}
                  infiniteLoadBeginEdgeOffset={400}
                  isInfiniteLoading={isInfiniteLoading}
                  loadingSpinnerDelegate={this.elementInfiniteLoad()}
                  ghostRows={ghostRows}
                >
                  {titleAudits.valueSeq().map(titleAudit => (
                    <ArtworkTitleAuditRow
                      scan={scan}
                      platforms={platforms}
                      titleAudit={titleAudit}
                      key={titleAudit.id}
                      scanId={scan.id}
                      rootPath={rootPath}
                      client={client}
                    />
                  ))}
                </Infinite>
              )
            )
          }}
        </ContainerDimensions>
      </GridContainer>
    )
  }
}

const ConnectedGridWithRouter = withRouter(Grid)
export default ConnectedGridWithRouter
