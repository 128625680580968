import React from 'react'

import { Tooltip, Button, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import styles from './Toolbar.module.scss'
import cx from 'classnames'
import { humanize } from 'Components/utils'
import TitleSort from 'Components/TitleSort'
import PlatformAndTerritoryFilter from 'Components/PlatformAndTerritoryFilter'
import DownloadButton from 'Components/ArtworkPage/DownloadButton.tsx'
import Search from '../ScanPage/Toolbar/Search'
import useToolbarActions from '../ScanPage/useToolbarActions'
import TagFilter from 'Components/ScanPage/Toolbar/TagFilter'

const Toolbar = ({
  scan,
  client,
  allFilter,
  movieFilter,
  seriesFilter,
  filter,
  dragonflyEnabled,
  dragonflyArtworkUrl,
}) => {
  const {
    setTitleAuditsSortParams,
    toggleAllFilter,
    toggleMovieFilter,
    toggleSeriesFilter,
    toggleAllArtwork,
    toggleChangedArtworkFilter,
    toggleBlankArtworkFilter,
    disablePromotedFilter,
    enablePromotedFilter,
    enablePriorityFilter,
    reloadScan,
  } = useToolbarActions({})
  let titleAuditsSortColumnLabel = filter && humanize(filter.titleAuditsSortColumn)

  titleAuditsSortColumnLabel =
    titleAuditsSortColumnLabel === 'Production Year' ? 'Launch Date' : titleAuditsSortColumnLabel

  const sortingOptions = {}

  client &&
    !client.toSeq().isEmpty() &&
    Object.keys(client.sortOptions).forEach(sortColumn => {
      if (client.sortOptions[sortColumn] && client.sortOptions[sortColumn]['enabled']) {
        let listItemName = humanize(sortColumn)
        listItemName = listItemName === 'Production Year' ? 'Launch Date' : listItemName
        sortingOptions[listItemName] = sortColumn
      }
    })

  const titleTypeFilter = client &&
    client.titleTypeBoth && [
      <div className="bp5-button-group" key="0">
        <button
          className={cx('bp5-button', styles.btnDefault, { [styles.active]: allFilter })}
          onClick={toggleAllFilter}
        >
          All
        </button>
        <button
          className={cx('bp5-button', styles.btnDefault, { [styles.active]: movieFilter })}
          onClick={toggleMovieFilter}
        >
          Films
        </button>
        <button
          className={cx('bp5-button', styles.btnDefault, { [styles.active]: seriesFilter })}
          onClick={toggleSeriesFilter}
        >
          TV Seasons
        </button>
      </div>,
      <div className={cx(styles.divider, 'bp5-navbar-divider')} key="1" />,
    ]

  return (
    <nav className={cx(styles.header, 'bp5-navbar')}>
      <div className={cx('bp5-navbar-group bp5-align-left', styles.toolbarGroup)}>
        {titleTypeFilter}
        <div className="bp5-button-group">
          <button
            className={cx('bp5-button', styles.btnDefault, { [styles.active]: !filter.changedArtwork && !filter.blankArtwork })}
            onClick={toggleAllArtwork}
          >
            All Artwork
          </button>
          <button
            className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.changedArtwork })}
            onClick={toggleChangedArtworkFilter}
          >
            Changed
          </button>
          <button
            className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.blankArtwork })}
            onClick={toggleBlankArtworkFilter}
          >
            Missing
          </button>
        </div>
        {scan && scan.enableMerchandizing && (
          <div className="bp5-navbar-group bp5-align-left">
            <div className={cx(styles.divider, 'bp5-navbar-divider')} />
            <div className="bp5-button-group">
              <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: !filter.promoted && !filter.priority })} onClick={disablePromotedFilter}>
                All
              </button>
              <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: !filter.promoted && filter.promoted })} onClick={enablePromotedFilter}>
                Featured
              </button>
              {client && client.tagsEnabled && (
                <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.priority })} onClick={enablePriorityFilter}>
                  Priority
                </button>
              )}
            </div>
          </div>
        )}
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <Search />
        {
          <div className="bp5-navbar-group">
            <div className={cx(styles.divider, 'bp5-navbar-divider')} />
            <Popover
              interactionKind={PopoverInteractionKind.CLICK}
              position={Position.BOTTOM_RIGHT}
              content={<PlatformAndTerritoryFilter newUI competitors={false} withTabs />}>
              <Tooltip content="Territory/Platform Filter" position={Position.BOTTOM_LEFT}>
                <Button className={cx('bp5-button', 'bp5-icon-filter', styles.btnDefault)} />
              </Tooltip>
            </Popover>
            <div className="bp5-navbar-group bp5-align-left">
              <div className={cx(styles.divider, 'bp5-navbar-divider')} />
              <Popover
                interactionKind={PopoverInteractionKind.CLICK}
                position={Position.BOTTOM_RIGHT}
                content={<TitleSort
                  sortingOptions={sortingOptions}
                  filter={filter}
                  setTitleAuditsSortParams={setTitleAuditsSortParams}
                />}>
                <Tooltip content="Sort" position={Position.BOTTOM_LEFT}>
                  <Button className={cx('bp5-button', 'bp5-icon-sort', styles.btnDefault)}>{titleAuditsSortColumnLabel}</Button>
                </Tooltip>
              </Popover>
            </div>
          </div>
        }
        {dragonflyEnabled && (
          <div className="bp5-navbar-group bp5-align-left">
            <div className={cx(styles.divider, 'bp5-navbar-divider')} />
            <Tooltip content="Dragonfly Heatmap" position={Position.BOTTOM_LEFT}>
              <Button
                className="bp5-icon-eye-open"
                onClick={() => {
                  window.open(dragonflyArtworkUrl, '_blank')
                }}
              >
                Dragonfly
              </Button>
            </Tooltip>
          </div>
        )}

        {client && client.tagsEnabled && (
          <div className={cx('bp5-navbar-group bp5-align-left', styles.tagFilter)}>
            <TagFilter />
          </div>
        )}
      </div>

      <div className={cx('bp5-navbar-group bp5-align-right', styles.toolbarGroup)}>
        {scan && <DownloadButton />}
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <Button className="bp5-icon-refresh" onClick={() => reloadScan()} />
      </div>
    </nav>
  )
}

export default Toolbar
