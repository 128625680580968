import React, { Component } from 'react'
import cx from 'classnames'
import { Dialog } from '@blueprintjs/core'
import { getTags, getSelectedTags } from 'Selectors'
import { connect } from 'react-redux'
import { unlinkTag, toggleTag } from 'Actions/tagActions'
import TagSelect from '../TagSelect.jsx'
import styles from '../styles.module.scss'
import { components } from 'react-select'
import debounce from 'lodash.debounce'

interface Tag {
  id: number
  name: string
  selected?: boolean
}

interface TagFilterProps {
  allTags: Tag[]
  selectedTags: Tag[]
  unlinkTag: (tagId: number) => void
  toggleTag: (tags: Tag[]) => void
}

interface TagFilterState {
  showModal: boolean
  optionSelected: Tag[] | null
  tagId: number | null
}

class TagFilter extends Component<TagFilterProps, TagFilterState> {
  constructor(props: TagFilterProps) {
    super(props)
    this.state = {
      showModal: false,
      optionSelected: null,
      tagId: null,
    }
  }

  showModal = (e: React.MouseEvent, tagId: number) => {
    e.preventDefault()
    this.setState({ showModal: true, tagId })
  }

  handleChange = (selected: Tag[]) => {
    let mappedTags = selected.map(s => ({
      ...s,
      selected: true,
    }))

    const stateTags = this.props.selectedTags

    if (stateTags && stateTags.length > selected.length) {
      const selectedIds = selected.map(s => s.id)
      const deselectedTags = stateTags.filter(t => !selectedIds.includes(t.id))

      const mappedDeselected = deselectedTags.map(t => ({
        ...t,
        selected: false,
      }))

      mappedTags = [...mappedTags, ...mappedDeselected]
    }

    this.props.toggleTag(mappedTags)

    this.setState({
      optionSelected: selected,
    })
  }

  debouncedHandleChange = debounce(this.handleChange, 500)

  deleteTag = () => {
    const tagId = this.state.tagId
    if (tagId !== null) {
      this.props.unlinkTag(tagId)
      this.setState({ showModal: false, tagId: null })
      if (this.state.optionSelected) {
        const stateTags = this.state.optionSelected.filter(t => t.id !== tagId)
        this.setState({ optionSelected: stateTags })
      } else {
        this.setState({ optionSelected: null })
      }
    }
  }

  cancel = () => {
    this.setState({ showModal: false })
  }

  renderModal = () => {
    return (

      < Dialog isOpen onClose={this.cancel} className={cx('bp5-focus-disabled')} >
        <div className={cx('bp5-dialog-container', styles.dialogBox)}>
          <div className={cx('bp5-dialog')}>
            <div className="bp5-dialog-header">
              <h4 className="bp5-dialog-header-title">Delete Tag(s)</h4>
              <button aria-label="Close" className="bp5-dialog-close-button bp5-icon-small-cross"></button>
            </div>
            <div className="bp5-dialog-body">
              This changes will impact your tagged titles. Are you sure you want to proceed?
            </div>
            <div className="bp5-dialog-footer">
              <div className="bp5-dialog-footer-actions">
                <button type="button" className={cx('bp5-button', styles.cancelButton)} onClick={this.cancel}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className={cx('bp5-button', 'bp5-intent-primary', styles.yesButton)}
                  onClick={this.deleteTag}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog >
    )
  }

  renderMultiValue = (props: any) => (
    <components.MultiValue {...props}>
      <span title={props.data.label}>{props.data.label}</span>
    </components.MultiValue>
  )

  renderOption = (props: any) => (
    <div className={styles.suggestionContainer}>
      <components.Option {...props}>
        <input type="checkbox" className={styles.checkBox} checked={props.isSelected} onChange={() => null} />{' '}
        <label className={styles.tagName} title={props.data.label}>
          {props.label}
        </label>
      </components.Option>
      <span
        className={cx('bp5-icon', 'bp5-icon-cross', styles.deleteTagButton)}
        onClick={e => this.showModal(e, props.data.id)}
      ></span>
    </div>
  )

  getCssStyles = () => ({
    control: (styles: any) => ({
      ...styles,
      backgroundColor: 'white',
      width: '200px',
      height: '32px',
      minHeight: 'unset',
      borderRadius: '0px',
      boxShadow: 'none',
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      flexWrap: 'wrap',
      textOverflow: 'ellipsis',
      overflowY: 'scroll',
      maxWidth: '168px',
      maxHeight: '31px',
      display: 'flex',
      marginBottom: '5px !important',
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
    }),
    placeholder: (styles: any) => ({
      ...styles,
    }),
    indicatorsContainer: (styles: any) => ({
      ...styles,
      cursor: 'pointer',
      height: '30px',
    }),
    multiValue: (styles: any) => ({ ...styles, width: 'fit-content' }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      cursor: 'pointer',
      backgroundColor: '#dfeef1',
    }),
    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
      ...styles,
      cursor: 'pointer',
      position: 'relative',
      backgroundColor: isDisabled ? undefined : isSelected ? 'white' : isFocused ? '#dfeef1' : undefined,
      color: 'black',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      backgroundColor: '#dfeef1',
    }),
    menuPortal: (styles: any) => ({ ...styles, zIndex: 9999 }),
    menuList: (styles: any) => ({ ...styles, maxHeight: 200, overflowY: 'hidden' }),
    menu: (styles: any) => ({
      ...styles,
      width: '220px',
    }),
  })

  renderTagSelect = (cssStyles: any, sortedTags: any) => (
    <TagSelect
      id="tag-select"
      styles={cssStyles}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      options={sortedTags}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option: this.renderOption, MultiValue: this.renderMultiValue }}
      onChange={this.debouncedHandleChange}
      placeholder={'Select tag...'}
      value={this.props.selectedTags}
    />
  )

  render() {
    const cssStyles = this.getCssStyles()
    const tags = this.props.allTags

    const mappedTags = tags.map(t => ({
      value: t.name,
      label: t.name,
      id: t.id,
    }))
    const sortedTags = mappedTags.sort((x, y) => x?.value?.localeCompare(y?.value))

    return (
      <div className={styles.autosuggestContainer}>
        {this.renderTagSelect(cssStyles, sortedTags)}
        {this.state.showModal && this.renderModal()}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  const selectedTags = getSelectedTags(state)
    .valueSeq()
    .toArray()
    .map((t: any) => ({
      value: t.name,
      label: t.name,
      id: t.id,
    }))

  const allTags = getTags(state)
    .toArray()
    .filter((t: any) => t.name?.toLowerCase() !== 'priority')

  return {
    allTags,
    selectedTags,
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  unlinkTag: (tagId: number) => dispatch(unlinkTag(tagId)),
  toggleTag: (tags: Tag[]) => dispatch(toggleTag(tags)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TagFilter)
