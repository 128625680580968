import { LooperRecord } from './looperRecord'

interface Territory {
  id: number
  name: string
  isoCode: string
  flagUrl: string
  sortKey: number
  selected: boolean
}

const defaultValues: Territory = {
  id: 0,
  name: '',
  isoCode: '',
  flagUrl: '',
  sortKey: 0,
  selected: false,
}

const TerritoryRecordBase = LooperRecord<Territory>(defaultValues)

export default class TerritoryRecord extends TerritoryRecordBase {
  get id(): number {
    return this.get('id')
  }

  get selected(): boolean {
    return this.get('selected')
  }

  get name(): string {
    return this.get('name')
  }

  get isoCode(): string {
    return this.get('isoCode')
  }

  get flagUrl(): string {
    return this.get('flagUrl')
  }

  get sortKey(): number {
    return this.get('sortKey')
  }
}

export type TerritoryRecordType = InstanceType<typeof TerritoryRecord>