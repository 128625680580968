import React from 'react'
import style from './Menu.module.scss'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { linkToPage } from '../utils'
import PropTypes from 'prop-types'

class MenuItem extends React.Component {
  renderSpot(spot) {
    const classnames = cx(style.dropdownItem)
    const externalLinks = [
      'Supported Devices',
      'Free Live TV',
      'Redbox+',
      'Redbox Perks',
      'Redbox Unscripted',
      'Gift Cards',
    ]

    return (
      <li key={spot.id} className={classnames}>
        {externalLinks.includes(spot.name) ? (
          <a href={spot.url}>{spot.name}</a>
        ) : (
          <a href={linkToPage(spot, this.props.match)}>{spot.name}</a>
        )}
      </li>
    )
  }

  renderDropdown() {
    const { spot, spots, page } = this.props

    const spotsList = [...spots.filter(spot => !!spot)]
    const classnames = cx(style.listItem, {
      [style.active]: page.name.includes(spot.name),
    })
    const externalLinks = ['Watch Free', 'More']

    return (
      <Popover
        interactionKind={PopoverInteractionKind['HOVER']}
        position={Position.BOTTOM}
        popoverClassName="bp5-popover-display-none"
        content={<div className={style.dropdown}>
          <ul>{spotsList.map(spot => this.renderSpot(spot))}</ul>
        </div>}
      >
        <li id={`spot_${spot.id}`} className={classnames}>
          {spot && externalLinks.includes(spot.name) && (
            <a href={spot.url} className={style.listItemNav}>
              {spot.name}
            </a>
          )}
          {spot && !externalLinks.includes(spot.name) && (
            <a href={linkToPage(spot, this.props.match)} className={style.listItemNav}>
              {spot.name}
            </a>
          )}
        </li>
      </Popover>
    )
  }

  render() {
    return this.renderDropdown()
  }
}

MenuItem.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
  spot: PropTypes.object,
  match: PropTypes.object,
}

const ConnectedNavbarDropdownWithRouter = withRouter(MenuItem)
export default ConnectedNavbarDropdownWithRouter
