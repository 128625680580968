import React, { useRef, useMemo } from 'react'
import style from './Item.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import cx from 'classnames'
import { linkToPage } from '../utils'
import { SpotItems } from 'Interfaces/interfaces'
import { OrangeSectionTypes } from '../enums'
import { generateClientStyle } from '../helpers'
import { useScrollIntoView } from '../../../hooks/useScrollIntoView'
import SpotBackground from './SpotBackground'

type ItemProps = RouteComponentProps & SpotItems

const Item: React.FC<ItemProps> = ({ spot, client, isMerchandiseFilteringEnabled, section, match }) => {
  const nodeRef = useRef<HTMLAnchorElement | null>(null)
  const { sectionType, metadata } = section
  const { background_color: backgroundColor } = JSON.parse((metadata as unknown) as string)

  useScrollIntoView({ condition: !!spot.selected, ref: nodeRef })

  const inlineStyle = useMemo(() => generateClientStyle(client), [client])

  const classNames = useMemo(() => {
    return cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
      [style.highlight]: spot.selected || client,
    })
  }, [section.selected, isMerchandiseFilteringEnabled, client, spot.selected])

  const shouldRenderSpotName = useMemo(
    () =>
      ![OrangeSectionTypes._HeroCarousel, OrangeSectionTypes._ChartContainer].includes(
        sectionType as OrangeSectionTypes,
      ),
    [sectionType],
  )

  return (
    <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={nodeRef}>
      <SpotBackground spot={spot} sectionType={sectionType} />
      {shouldRenderSpotName && (
        <p
          className={cx({
            [style.spotNameLight]: !backgroundColor,
            [style.spotNameDark]: backgroundColor,
          })}
        >
          {spot.name}
          <br />
          <span>{spot.genre}</span>
        </p>
      )}
    </a>
  )
}

export default withRouter(Item)
