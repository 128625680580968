import React, { useEffect, useState } from 'react'
import { Section } from 'Interfaces/*'
import style from './HeaderImage.module.scss'
import { Icon } from '@blueprintjs/core'
type HeaderImageProps = {
  section: Section
}
const HeadrImage: React.FC<HeaderImageProps> = ({ section }) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  const [textLength, setTextLength] = useState<number>(250)
  const { artwork, subtitle, label } = JSON.parse(section?.metadata as string)
  const backgroundStyle = {
    backgroundImage: `url(${decodeURIComponent(artwork)})`,
  }

  useEffect(() => {
    setTextLength(window.innerWidth < 2000 ? 150 : 250)
  }, [])

  return (
    <div className={style.headerImageContainer}>
      <div className={style.backgroundImage} style={backgroundStyle}></div>
      <div className={style.detailWrapper}>
        {!showMore && (
          <p>
            {subtitle.slice(0, textLength)}...{' '}
            <Icon color="gray" className={style.showText} icon="chevron-down" onClick={() => setShowMore(true)} />
          </p>
        )}
        {showMore && (
          <p>
            {subtitle} <br />
            <br />
            <span className={style.hideText} onClick={() => setShowMore(false)}>
              {label}
            </span>
          </p>
        )}
      </div>
    </div>
  )
}

export default HeadrImage
