import React, { useMemo, useState } from 'react'
import Slider from 'react-slick'
import Item from './Item'
import { PageItems } from 'Interfaces/*'
import { renderSpotsWithItem, sliderSettings } from '../helpers'
import Arrow from './Arrow'
import style from './PageContainer.module.scss'

type PageContainerProps = PageItems

const PageContainer: React.FC<PageContainerProps> = ({
  selectedStudios,
  section,
  isMerchandiseFilteringEnabled,
  spots,
}) => {
  const [spotIndex, setSpotIndex] = useState<number>(0)
  const spotsArray = useMemo(() => [...spots], [spots])

  const renderSpots = renderSpotsWithItem({
    spots: spots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.item,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const spotBackground = useMemo(() => spotsArray[spotIndex]?.metadata?.coverUrl, [spotIndex, spotsArray])

  return (
    <div className={style.pageContainer} style={{ backgroundImage: `url(${decodeURIComponent(spotBackground)})` }}>
      <div className={style.shadow}></div>
      <Slider
        {...sliderSettings({
          slidesToScroll: 1,
          slidesToShow: 1,
          arrow: true,
          dots: true,
          className: 'center',
          centerMode: true,
          centerPadding: '15%',
          nextArrow: <Arrow right section={section.sectionType} setSpotIndex={setSpotIndex} />,
          prevArrow: <Arrow left section={section.sectionType} setSpotIndex={setSpotIndex} />,
          appendDots: dots => {
            const customDots = React.Children.map(dots, dot => {
              const isActive = dot.props.className.includes('slick-active')
              return React.cloneElement(dot, {
                className: isActive ? style.slickActive : style.slickNormal,
              })
            })
            return (
              <div style={{ bottom: '-40px', left: '-25%' }}>
                <ul>{customDots}</ul>
              </div>
            )
          },
          customPaging: i => {
            return <span>{i + 1}</span>
          },
        })}
        className={style.alignSpotsLeft}
      >
        {renderSpots}
      </Slider>
    </div>
  )
}

export default PageContainer
