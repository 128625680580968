import React from 'react'
import { withRouter } from 'react-router-dom'
import style from './ScrollableSection.module.scss'
import Item from './Item'
import PropTypes from 'prop-types'
import { linkToPage } from '../utils'
import cx from 'classnames'

class ScrollableSection extends React.Component {
  render() {
    const { section, match } = this.props
    const spots = this.spots()
    const seeAllSpot = this.seeAllSpot()
    const renderSectionHeader = () => (
      <div className={style.sectionHeader}>
        {
          <div className={style.heading}>
            <h3>{section.name}</h3>
          </div>
        }
        <div className={style.seeMoreLink}>
          {seeAllSpot && <a href={linkToPage(seeAllSpot, match)}>{seeAllSpot.name}</a>}
        </div>
      </div>
    )

    const renderSectionContent = () => (
      <div className={style.scrollWrapper}>
        {spots.map(
          spot =>
            spot && (
              <div key={spot.id} className={style.scrollableItem}>
                <Item
                  spot={spot}
                  isMerchandiseFilteringEnabled={this.props.isMerchandiseFilteringEnabled}
                  studio={this.isStudioSelected(spot.id)}
                  section={section}
                  showTitleName={this.showTitleName()}
                />
              </div>
            ),
        )}
      </div>
    )

    const classNames = cx(style.scrollableSection, {
      [style.tubiScrollable]: section.sectionType.includes('tubi_tv::category'),
    })

    return (
      <div className={classNames}>
        <React.Fragment>
          {renderSectionHeader()}
          {renderSectionContent()}
        </React.Fragment>
      </div>
    )
  }

  showTitleName() {
    const { section } = this.props

    return ['xbox::category', 'tubi_tv::category'].includes(section.sectionType)
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'content')
  }

  links() {
    const { spots } = this.props
    return spots.filter(spot => spot && spot.spotType === 'link')
  }

  seeAllSpot() {
    const { section } = this.props
    return section.sectionType === 'tubi_tv::category'
      ? this.links().first(spot => spot)
      : this.links().find(spot => spot && ['See All', 'Explore All', 'View All', ''].includes(spot.name))
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }
}

ScrollableSection.propTypes = {
  spots: PropTypes.object,
  match: PropTypes.object,
  section: PropTypes.object,
  selectedStudios: PropTypes.object,
  isMerchandiseFilteringEnabled: PropTypes.bool,
}

export default withRouter(ScrollableSection)
