import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import cx from 'classnames'
import { SpotItems } from 'Interfaces/interfaces'
import style from './ChartItem.module.scss'

type HeroItemProps = RouteComponentProps & SpotItems

const HeroItem: React.FC<HeroItemProps> = ({
  spot,
  client,
  isMerchandiseFilteringEnabled,
  section,
  setSelectedSpot,
}) => {
  const nodeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (spot.selected) {
      nodeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [spot.selected])

  useEffect(() => {
    if (spot) {
      setSelectedSpot(spot)
    }
  }, [spot, setSelectedSpot])

  const inlineStyle: React.CSSProperties = useMemo(() => {
    const style: React.CSSProperties = {}
    if (client) {
      style.borderStyle = 'solid'
      style.borderColor = client.color
      style.boxShadow = `0 0 10px ${client.color}`
    }
    return style
  }, [client])

  const handleClick = useCallback(
    event => {
      event.stopPropagation()
      setSelectedSpot(spot)
    },
    [spot, setSelectedSpot],
  )

  const classNames = useMemo(
    () =>
      cx(style.item, {
        [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
        [style.highlight]: spot.selected || client,
      }),
    [section.selected, isMerchandiseFilteringEnabled, client, spot.selected],
  )

  return (
    <div className={classNames} style={inlineStyle} ref={nodeRef}>
      <span className={style.position}>{spot.position}</span>
      <div
        onClick={handleClick}
        id={`spot_${spot.id}`}
        style={{
          background: `black url(${spot.artworkUrl}) center center no-repeat`,
          backgroundSize: 'cover',
          marginBottom: '8px',
        }}
      ></div>
    </div>
  )
}

export default withRouter(HeroItem)
