import React from 'react'
import style from './NavBar.module.scss'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import WrappedSection from 'Containers/WrappedSection'

class NavBar extends React.Component {
  constructor(props) {
    super(props)
  }

  renderLink = spot => {
    const { match, subSections, scan, page } = this.props

    const isDropdown = subSections.some(subsection => subsection.name === spot.name)
    if (isDropdown) {
      return (
        subSections &&
        subSections.map(
          s =>
            s &&
            s.name === spot.name && (
              <React.Fragment key={s.id}>
                {spot.spotType === 'link' ? (
                  <li>
                    <a href={linkToPage(spot, match)}>{spot.name}</a>
                  </li>
                ) : (
                  spot.spotType === 'content' && (
                    <li className={style.navbarSection}>
                      <span>{spot.name}</span>
                    </li>
                  )
                )}
                <WrappedSection spot={spot} type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />
              </React.Fragment>
            ),
        )
      )
    }

    return (
      spot && (
        <li key={spot.id}>
          <a href={linkToPage(spot, match)}>{spot.name}</a>
        </li>
      )
    )
  }

  render() {
    const { spots } = this.props

    const popoverClasses =
      'bp5-popover-content-sizing bp5-popover-display-none bp5-popover-padding-none bp5-margin-top-none bp5-border-radius-none'

    return (
      <div className={style.menu}>
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM_LEFT}
          isOpen={true}
          popoverClassName={popoverClasses}
        >
          <div className={style.dropdownMenu}>
            {/* <h6 className={style.header}>Apple Tv</h6> */}
            <ul>{spots.map(spot => spot && this.renderLink(spot))}</ul>
          </div>
        </Popover>
      </div>
    )
  }
}

export default withRouter(NavBar)
