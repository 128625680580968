import React from 'react'
import Slider from 'react-slick'
import Item from './Item'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { PageItems, Spot } from 'Interfaces/interfaces'
import style from './HeroCarousel.module.scss'
import { renderSpotsWithItem } from '../helpers'
import { List } from 'immutable'
import CustomPaging from './CustomPaging'
import Arrow from './Arrow'

type HeroCarouselProps = RouteComponentProps &
  PageItems & {
    spots: List<Spot>
  }

const dotsContainer = {
  left: '0',
  right: '0',
  margin: '0 2%',
  display: 'flex',
  gap: '6px',
  width: 'fit-content',
  maxWidth: '90vw',
  height: '18vh',
  borderRadius: '8px',
  bottom: '30vh',
  background: 'rgba(185, 183, 197, .302)',
}

const HeroCarousel: React.FC<HeroCarouselProps> = ({
  selectedStudios,
  section,
  isMerchandiseFilteringEnabled,
  spots,
}) => {
  const renderSpots = renderSpotsWithItem({
    spots: spots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.item,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const sliderSettings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    swipe: false,
    speed: 2000,
    arrows: true,
    nextArrow: <Arrow right section={section.sectionType} />,
    prevArrow: <Arrow left section={section.sectionType} />,
    appendDots: dots => {
      const activeIndex = dots.findIndex(dot => dot.props.className === 'slick-active')
      const customDots = dots.map((dot, index) =>
        React.cloneElement(dot, {
          className: index === activeIndex ? style.dotActive : style.dot,
        }),
      )

      return (
        <div style={dotsContainer}>
          <ul className={style.dotsList}>{customDots}</ul>
        </div>
      )
    },
    customPaging: i => {
      const spot = spots.toArray().at(i)
      return <CustomPaging spot={spot} />
    },
  }

  return (
    <div className={style.container}>
      <Slider {...sliderSettings}>{renderSpots}</Slider>
    </div>
  )
}

export default withRouter(HeroCarousel)
