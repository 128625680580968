import React from 'react'
import GenericSection from './GenericSection'
import style from './Carousel.module.scss'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { linkToPage, scrollToNode } from '../utils'

class CarouselItem extends GenericSection {
  UNSAFE_componentWillReceiveProps(newProps) {
    const { section } = this.props
    if (newProps.section.selected && !section.selected) scrollToNode(this.node)
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  getBackgroundImage(sectionData, spot) {
    if (!sectionData.artwork && !spot) return null
    if (sectionData.artwork) return sectionData.artwork[0].url
    if (spot) return spot.artworkUrl
  }

  render() {
    const { isMerchandiseFilteringEnabled, section, fade, spots, match } = this.props
    const spot = spots.first()
    const client = this.isStudioSelected(spot && spot.id)
    const { metadata } = section
    const data = JSON.parse(metadata)
    const backgroundImage = this.getBackgroundImage(data, spot)
    const inlineStyle = {
      backgroundImage: `url(${backgroundImage})`,
    }

    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    const classNames = cx(style.item, {
      [style.highlight]: section.selected || client,
      [style.fade]: fade || (isMerchandiseFilteringEnabled && !client),
    })

    const target = spot && spot.linkedPageId ? null : '_blank'
    const url = linkToPage(spot, match)
    return (
      <a
        id={spot && `spot_${spot.id}`}
        className={classNames}
        style={inlineStyle}
        target={target}
        href={url}
        ref={node => (this.node = node)}
      />
    )
  }
}

const ConnectedCarouselItemWithRouter = withRouter(CarouselItem)
export default ConnectedCarouselItemWithRouter
