import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popover, PopoverInteractionKind, Alert, Position, Intent } from '@blueprintjs/core'
import cx from 'classnames'
import { removeTagging } from 'Actions/taggingActions'
import styles from './Tag.module.scss'
import { enqueueToast } from 'Actions/uiActions'
import { TitleAudit, Tag as TagType } from 'Interfaces/*'
import TagPopover from './TagPopover'

interface TagProps {
  featuredTag: boolean
  isPreorder: boolean
  titleAudit: TitleAudit
}

const Tag = ({ featuredTag, isPreorder, titleAudit }: TagProps) => {
  const dispatch = useDispatch()
  const [alertOpen, setAlertOpen] = useState(false)
  const [inputEnabled, setInputEnabled] = useState(false)
  const { title, titleTags } = useSelector(state => {
    const title = titleAudit.getTitle(state)
    const allTitleTags = title && (title.platformTitlesTags(state) as TagType[])
    const titleTags: TagType[] = Array.from(new Set(allTitleTags))

    return {
      title,
      titleTags,
    }
  })

  const handleErrorClose = () => setAlertOpen(false)

  const showInput = e => {
    e.preventDefault()
    setInputEnabled(true)
  }

  const removeTag = tagId => {
    dispatch(removeTagging(tagId, title))
      .then(() => {
        dispatch(enqueueToast({ intent: Intent.SUCCESS, message: 'Your tag was successfuly cleared.' }))
      })
      .catch(() => Promise.resolve())
  }

  const renderAddTagButton = () => (
    <div className={styles.tagBtnContainer}>
      <Popover
        position={Position.RIGHT}
        interactionKind={PopoverInteractionKind.HOVER}
        popoverClassName="bp5-popover-content-sizing"
      >
        <button className={styles.tagBtn} onClick={showInput}>
          {titleTags.length == 0 ? '+ Add tag' : '+'}
        </button>
        <div>
          <p>Add tag to your title</p>
        </div>
      </Popover>
    </div>
  )

  return (
    <div className={cx(styles.tagsRow)}>
      <div className={cx(styles.tagsContainer)}>
        <Alert confirmButtonText="Ok" isOpen={alertOpen} onConfirm={handleErrorClose}>
          <p>This tag was already added to this title!</p>
        </Alert>

        {featuredTag && <div className={styles.promoted}>Featured</div>}
        {isPreorder && <div className={cx('bp5-tag bp5-minimal', styles.preorder)}>Pre-Order</div>}
        {titleTags.map(tag => (
          <span
            key={tag.id}
            className={cx('bp5-tag', 'bp5-tag-removable', '.modifier ', styles.tagSpan)}
            onClick={e => e.preventDefault()}
            title={tag.name}
          >
            {tag.name}
            <button className="bp5-tag-remove" onClick={() => removeTag(tag.id)} />
          </span>
        ))}
      </div>

      {!inputEnabled ? (
        renderAddTagButton()
      ) : (
        <TagPopover
          onError={() => setAlertOpen(true)}
          title={title}
          titleTags={titleTags}
          onClose={() => setInputEnabled(false)}
        />
      )}
    </div>
  )
}

export default Tag
