import React from 'react'
import moment from 'moment'
import { sortBy } from 'lodash'
import { CATEGORIES, BLUE } from './constants'
import cx from 'classnames'

export const renderSpotsWithItem = ({
  spots,
  ItemComponent,
  section,
  isMerchandiseFilteringEnabled,
  styleClassName,
  additionalProps = {},
  children,
}) =>
  spots.map(spot => {
    if (!spot) return null
    const props = {
      spot,
      section,
      isMerchandiseFilteringEnabled,
      ...additionalProps,
    }
    if (props.client === undefined && additionalProps.isStudioSelected) {
      props.client = additionalProps.isStudioSelected(spot.id)
    }
    return (
      <div className={styleClassName} key={spot.id}>
        <ItemComponent {...props} />
        {typeof children === 'function' ? children(spot) : children}
      </div>
    )
  })

export const renderMenuSpots = (spots, renderLink) => {
  return spots && spots.map(s => s && renderLink(s))
}

export const sliderSettings = (props = {}) => {
  return {
    ...props,
    dots: props.dots || false,
    infinite: props.infinite || false,
    centerPadding: props.centerPadding || 0,
    slidesToScroll: props.slidesToScroll || 5,
    slidesToShow: props.slidesToShow || 5,
    arrows: props.arrows || true,
    swipe: props.swipe || false,
    initialSlide: props.initialSlide || 0,
    nextArrow: props.nextArrow || null,
    prevArrow: props.prevArrow || null,
  }
}

export const isFandangoScan = scan => {
  const scheduledAt = moment(scan.scheduledAt)
  const referenceDate = moment('2024-03-13')

  return scheduledAt.isAfter(referenceDate)
}

const sortAmsByTerritory = item => {
  const order = ['US', 'CA']

  const index = order.indexOf(item.territory)

  if (index !== -1) {
    return index
  } else {
    return order.length + item.territory?.charCodeAt(0)
  }
}

export const groupAssociatedMerchScansByPlatform = ams =>
  ams
    ?.sort((a, b) => a.platform.localeCompare(b.platform))
    .reduce((platformGroups, item) => {
      if (!platformGroups[item.platform]) {
        platformGroups[item.platform] = []
      }

      platformGroups[item.platform].push(item)
      platformGroups[item.platform] = sortBy(platformGroups[item.platform], sortAmsByTerritory)

      return platformGroups
    }, {})

export const generateClientStyle = client => {
  const style = {}
  if (client) {
    style.borderStyle = 'solid'
    style.borderColor = client.color
    style.boxShadow = `0 0 10px ${client.color}`
  }
  return style
}

export const computeContainerClasses = (section, style, position) => {
  return cx(style.container, {
    [style.first]: section && section.position <= position,
  })
}

export const checkCategoryAndBlue = spot => {
  const isCategory = spot?.label.toLowerCase() === CATEGORIES
  const isBlue = spot?.label.toLowerCase() === BLUE
  return { isCategory, isBlue }
}