import React from 'react'
import { OrangeSectionTypes } from '../enums'
import style from './Item.module.scss'
import { Spot } from 'Interfaces/interfaces'
import { Icon } from '@blueprintjs/core'

const SpotDetails: React.FC<{ spot: Spot; sectionType: OrangeSectionTypes }> = ({ spot, sectionType }) => {
  const { currentRating } = spot?.metadata

  switch (sectionType) {
    case OrangeSectionTypes._HeroCarousel:
      return (
        <div className={style.heroCarouselWrapper} data-testid="hero-carousel-wrapper">
          <h6>{spot.name}</h6>
          <p>{spot.genre}</p>
        </div>
      )
    case OrangeSectionTypes._LandscapeContainer:
      return (
        <div className={style.landscapeContainer} data-testid="landscape-wrapper">
          <p>{spot.label}</p>
        </div>
      )
    case OrangeSectionTypes._Container:
    case OrangeSectionTypes._LargeContainer:
    case OrangeSectionTypes._ChartContainer:
    case OrangeSectionTypes._Grid:
    case OrangeSectionTypes._LandscapeGrid:
      return (
        <div className={style.containerDetails}>
          <h6>{spot.label}</h6>
          {currentRating &&
            Array.from({ length: 5 }, (_, i) => (
              <Icon key={i} icon="star" color={`${i < Math.round(parseFloat(currentRating)) ? '#f16e00' : '#fff'}`} />
            ))}
        </div>
      )
    default:
      return null
  }
}

export default SpotDetails
