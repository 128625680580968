import React from 'react'
import WrappedSection from 'Containers/WrappedSection'
import PropTypes from 'prop-types'

class NavBarItem extends React.Component {
  renderSubSections() {
    const { subSections, scan, page, spots } = this.props

    const spot = spots.first()
    return (
      subSections &&
      subSections.map(
        s => s && <WrappedSection type={s.sectionType} key={s.id} section={s} spot={spot} scan={scan} page={page} />,
      )
    )
  }

  render() {
    return this.renderSubSections()
  }
}

NavBarItem.propTypes = {
  subSections: PropTypes.object,
  spots: PropTypes.object,
  page: PropTypes.object,
  scan: PropTypes.object,
  match: PropTypes.object,
}

export default NavBarItem
