export const UpdateableMixin = Base =>
  class extends Base {
    update(newRecord) {
      // Merge any relationship data not present in newRecord
      const result = newRecord.withMutations(s => {
        this.relationships.forEach((v, k) => {
          if (v.get('data') != null) {
            s.setIn(['relationships', k, 'data'], this.relationships.getIn([k, 'data']))
          }
        })
      })

      return result
    }
  }
