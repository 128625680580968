import React from 'react'
import { getSelectedTerritories, getSelectedPlatforms } from 'Selectors'
import { useSelector } from 'react-redux'
import { Dialog } from '@blueprintjs/core'
import { pluralize } from 'Components/utils'
import cx from 'classnames'
import styles from './Toolbar.module.scss'
import { Title } from 'Interfaces/*'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  actionName: string
  title?: Title
}

const ActionConfirmationModal = ({ isOpen, onClose, onSubmit, actionName, title }: Props) => {
  const { selectedTerritories, selectedPlatforms } = useSelector(state => ({
    selectedTerritories: getSelectedTerritories(state).map(t => t.isoCode),
    selectedPlatforms: getSelectedPlatforms(state)
      .map(p => p.name)
      .toSet()
      .toList(),
  }))

  return (


    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className="bp5-dialog-header">
        <h4 className="bp5-dialog-header-title">Confirm action</h4>
        <button aria-label="Close" onClick={onClose} className="bp5-dialog-close-button bp5-icon-small-cross"></button>
      </div>
      <div className="bp5-dialog-body">
        <h5>
          You are about to {actionName} {title ? `${title.name} title` : 'titles'} for {selectedPlatforms.join(', ')}{' '}
          {pluralize('platform', selectedPlatforms.count())} on {selectedTerritories.join(',')}{' '}
          {pluralize('territory', selectedTerritories.count())}
        </h5>
      </div>
      <div className="bp5-dialog-footer">
        <b>Are you sure you want to proceed? Yes or No.</b>
        <div className="bp5-dialog-footer-actions">
          <button type="button" className={cx('bp5-button', styles.cancelButton)} onClick={onClose}>
            No
          </button>
          <button type="submit" className={cx('bp5-button', 'bp5-intent-primary', styles.yesButton)} onClick={onSubmit}>
            Yes
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export default ActionConfirmationModal
