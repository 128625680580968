import React from 'react'
import { connect } from 'react-redux'
import { Cell } from '@blueprintjs/table'

import { makeGetAuditActionForRowIndex, getTerritoryById } from 'Selectors'

class PlatformCell extends React.PureComponent {
  render() {
    const { attribute, propName } = this.props
    return <Cell {...this.props}>{this.props[propName] && this.props[propName].get(attribute)}</Cell>
  }
}

const makeMapStateToProps = () => {
  const getAuditActionForRowIndex = makeGetAuditActionForRowIndex()

  const mapStateToProps = (state, ownProps) => {
    const auditAction = getAuditActionForRowIndex(state, {
      rowIndex: ownProps.rowIndex,
    })
    const price = auditAction && auditAction.price(state)
    const auditPrice = auditAction && auditAction.auditPrice(state)
    const platformTitle = auditAction && auditAction.platformTitle(state)
    const platform = platformTitle && platformTitle.platform(state)
    const territory = platform && getTerritoryById(state, { id: platform.territoryId })

    return {
      auditAction,
      platformTitle,
      platform,
      territory,
      price,
      auditPrice,
    }
  }

  return mapStateToProps
}

export default connect(makeMapStateToProps)(PlatformCell)
