import { Record, Map } from 'immutable'

export default class OptimizationScoreRecord extends Record({
  id: 0,
  score: 0,
  previousScore: 0,
  territoryId: 0,
  name: '',
  artwork: '',
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {}
