import React, { useState } from 'react'
import { Dialog, Button, Intent } from '@blueprintjs/core'
import PlatformAndTerritoryListNew from 'Components/PlatformAndTerritoryListNew'
import { List } from 'immutable'
import { REGION } from 'Components/PlatformAndTerritoryListNew/constants'
import TerritoryRecord, { TerritoryRecordType } from 'Records/territoryRecord'
import { DeselectAllTerritoriesFunction, SelectAllTerritoriesFunction } from 'Interfaces/*'
import styles from './Tag.module.scss'

interface Props {
  onApply: (regionIds: number[]) => void
  onClose: () => void
  isOpen: boolean
  territories: TerritoryRecord[]
}

const ChooseRegionModal = ({ isOpen, onApply, onClose, territories }: Props) => {
  const [selectedTerritoriesIds, setSelectedTerritoriesIds] = useState<number[]>(
    territories.find(t => t.selected) ? [territories.find(t => t.selected)!.id] : [],
  )

  const selectAllTerritories = () => {
    setSelectedTerritoriesIds([...territories.map(t => t.id)])
  }

  const deselectAllTerritories = () => {
    setSelectedTerritoriesIds([])
  }

  const toggleTerritory = (territory: TerritoryRecordType) => {
    if (selectedTerritoriesIds.find(id => territory.id === id)) {
      setSelectedTerritoriesIds(selectedTerritoriesIds.filter(id => id !== territory.id))
      return
    }

    setSelectedTerritoriesIds([...selectedTerritoriesIds, territory.id])
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={styles.regionModal} title={'Choose Regions to Apply the Tag'}>
      <div className="bp5-dialog-body">
        <PlatformAndTerritoryListNew
          territories={List(territories.map(
            t =>
              new TerritoryRecord({
                ...t.toJS(),
                selected: selectedTerritoriesIds.includes(t.id),
              }),
          ))}
          platformGroups={List()}
          withTabs
          availableTabs={[REGION]}
          deselectAllTerritories={deselectAllTerritories as DeselectAllTerritoriesFunction}
          selectAllTerritories={selectAllTerritories as SelectAllTerritoriesFunction}
          toggleTerritory={toggleTerritory}
          selectPlatformsForSelectedTerritories={() => { }}
        />
      </div>
      <div className="bp5-dialog-footer">
        <div className="bp5-dialog-footer-actions">
          <Button intent={Intent.NONE} onClick={onClose} text={'Cancel'} />
          <Button
            disabled={!selectedTerritoriesIds.length}
            intent={Intent.PRIMARY}
            onClick={() => {
              onApply(selectedTerritoriesIds)
              onClose()
            }}
            text={'Apply'}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ChooseRegionModal
