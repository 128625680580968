import React, { useMemo } from 'react'
import { Card } from '@blueprintjs/core'
import BigNumber from 'Components/BigNumber'
import { ScanSummary } from './interfaces'
import RenderPlatform from './RenderPlatform'

import styles from './styles.module.scss'

type ScanStatProps = {
  scanSummary: ScanSummary
}

export const getPlatformsByRegion = platforms => {
  return platforms?.reduce((acc, platform) => {
    if (!acc[platform.name]) {
      acc[platform.name] = {
        name: platform.name,
        logoUrl: platform.logoUrl,
        territories: [],
      }
    }
    acc[platform.name].territories.push({ flagUrl: platform.flagUrl, name: platform.territory })
    return acc
  }, {})
}

const ScanStat: React.FC<ScanStatProps> = ({ scanSummary }) => {
  const platformsByRegion = useMemo(() => getPlatformsByRegion(scanSummary?.platforms), [scanSummary?.platforms])

  if (!scanSummary) {
    return <div />
  }

  const renderBigNumber = (number: number, figure: string) => (
    <div className={styles.headerBorder}>
      <BigNumber number={number} figure={figure} headingLevel={4} />
    </div>
  )

  return (
    <div className={styles.gridItem}>
      <Card className={styles.card}>
        <h5 className={styles.header}>Audit Report</h5>
        <div className={styles.scanStatHeader}>
          {renderBigNumber(scanSummary.titlesCount, 'Titles')}
          {renderBigNumber(scanSummary.platforms.length || 0, 'Platforms')}
          {renderBigNumber(scanSummary.territories.length || 0, 'Territories')}
        </div>
        <div className={styles.statBottom}>
          {Object.keys(platformsByRegion).map((platformName: string) => (
            <RenderPlatform key={platformName} platformsByRegion={platformsByRegion} platformName={platformName} />
          ))}
        </div>
      </Card>
    </div>
  )
}

export default ScanStat
