import React from 'react'
import style from './Menu.module.scss'
import WrappedSection from 'Containers/WrappedSection'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { linkToPage } from '../utils'
import PropTypes from 'prop-types'

class MenuItem extends React.Component {
  renderSpot(spot) {
    const { subSections, scan, page, match } = this.props
    const classnames = cx(style.listItem, style.dropdownItem)

    const isDropdown = subSections.some(subsection => subsection.name === spot.name)
    if (isDropdown) {
      return (
        subSections &&
        subSections.map(
          s =>
            s &&
            s.name === spot.name && (
              <WrappedSection spot={spot} type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />
            ),
        )
      )
    }

    return (
      <li key={spot.id} className={classnames}>
        <a href={linkToPage(spot, match)}>
          <span className={style.firstLetter}>{spot.name.slice(0, 1)}</span>
          {spot.name.slice(1)}
        </a>
      </li>
    )
  }

  renderDropdown() {
    const { spot, spots, match } = this.props
    const { spotType } = spot
    const spotsList = [...spots.filter(spot => !!spot)]

    return (
      <Popover
        interactionKind={PopoverInteractionKind[spotType === 'link' ? 'HOVER' : 'CLICK']}
        position={Position.BOTTOM_LEFT}
        popoverClassName="bp5-popover-display-none"
        content={<div className={style.dropdown}>
          <ul>{spotsList.map(spot => this.renderSpot(spot))}</ul>
        </div>}
      >
        <li className={style.listItem}>
          {spot && spotType === 'link' && (
            <a className={style.listItemNav} href={linkToPage(spot, match)}>
              {spot.name}
            </a>
          )}
          {spot && spotType === 'content' && (
            <span id={`spot_${spot.id}`} className={style.contentSpot}>
              {spot.name}
            </span>
          )}
        </li>
      </Popover>
    )
  }

  render() {
    return this.renderDropdown()
  }
}

MenuItem.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
  spot: PropTypes.object,
  match: PropTypes.object,
}

const ConnectedNavbarDropdownWithRouter = withRouter(MenuItem)
export default ConnectedNavbarDropdownWithRouter
