import React from 'react'
import style from './Item.module.scss'
import { withRouter } from 'react-router-dom'
import pathToRegexp from 'path-to-regexp'
import cx from 'classnames'
import { scrollToNode } from '../../utils'

class Item extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  spotLink() {
    const { spot, match } = this.props

    if (!spot.linkedPageId) {
      return spot.url
    }

    const { path } = match

    const toPath = pathToRegexp.compile(path)

    return toPath({ ...match.params, pageId: spot.linkedPageId })
  }

  isSeeMoreSpot() {
    const { spot } = this.props

    return spot.name && spot.name.includes('See more')
  }

  render() {
    const { spot, client, isMerchandiseFilteringEnabled, section, hideTitle } = this.props

    const inlineStyle = {}
    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    const classNames = cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
      [style.highlight]: spot.selected || client,
    })

    return (
      <a href={this.spotLink()} className={classNames} style={inlineStyle} ref={node => (this.node = node)}>
        <div
          id={`spot_${spot.id}`}
          style={{
            background: `black url(${spot.artworkUrl}) center center no-repeat`,
            backgroundSize: 'contain',
          }}
        >
          {this.isSeeMoreSpot() && <div className={style.seeMoreItem}>See more</div>}
        </div>
        {spot.name && !hideTitle && (
          <div className={style.titleContainer}>
            <span className={style.title}>{spot.name}</span>
          </div>
        )}
      </a>
    )
  }
}

const ConnectedItemWithRouter = withRouter(Item)
export default ConnectedItemWithRouter
