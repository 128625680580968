import { useDispatch } from 'react-redux'

import {
  toggleAllActionsFilter,
  togglePriceActionsFilter,
  toggleHoldbackActionsFilter,
  toggleAvailActionsFilter,
  toggleFormatAll,
  toggleFormatHD,
  toggleFormatSD,
  toggleLicenseTypeAll,
  toggleLicenseTypeBuy,
  toggleLicenseTypeRent,
  clearTitleQuery,
  updateTitleQuery,
  toggleMovieFilter,
  toggleSeriesFilter,
  toggleAllFilter,
  toggleAuditExceptionFilter,
  toggleSubmittedAuditActionsFilter,
  toggleReadyAuditActionsFilter,
  toggleAllAuditActionsFilter,
  exportReadyTitlesForScan,
  reloadScan,
  enablePromotedFilter,
  enablePriorityFilter,
  disablePromotedFilter,
  setTitleAuditsSortParams,
  toggleAllArtwork,
  toggleChangedArtworkFilter,
  toggleBlankArtworkFilter,
} from 'Actions/scanActions'

import {
  relocateTitles,
  retryUnavailableTitles,
  markUnavailableForeverTitles,
  markUnavailableTitles,
  refreshAmazonArtworks,
} from 'Actions/clientActions'
import {
  clearTitleQuery as clearQuery,
  toggleAllFilter as toggleAll,
  toggleMovieFilter as toggleMovie,
  toggleSeriesFilter as toggleSeries,
  updateTitleQuery as updateQuery,
  stateFilterSet,
  stateFilterClear,
  setNewCandidatesFilter,
  toggleEnabledFilter,
  setLetterFilter,
  disablePromoted,
  enablePriority,
  enablePromoted,
} from 'Actions/titleFilterActions'
import { reloadTitles } from 'Actions/titleActions'

import { showScanStats, showScanAsList, showScanAsGrid } from 'Actions/uiActions'
import { toggleTerritory, selectAllTerritories, deselectAllTerritories } from 'Actions/territoryActions'
import { togglePlatforms, selectAllPlatforms, deselectAllPlatforms } from 'Actions/platformActions'
const useToolbarActions = ({ competitors = false }) => {
  const dispatch = useDispatch()

  return {
    reloadScan: () => dispatch(reloadScan(competitors)),
    enablePromotedFilter: () => dispatch(enablePromotedFilter(competitors)),
    enablePriorityFilter: () => dispatch(enablePriorityFilter(competitors)),
    disablePromotedFilter: () => dispatch(disablePromotedFilter(competitors)),
    showScanStats: () => dispatch(showScanStats()),
    setTitleAuditsSortParams: (column, direction) => dispatch(setTitleAuditsSortParams(column, direction)),
    toggleAllActionsFilter: () => dispatch(toggleAllActionsFilter(competitors)),
    togglePriceActionsFilter: () => dispatch(togglePriceActionsFilter(competitors)),
    toggleHoldbackActionsFilter: () => dispatch(toggleHoldbackActionsFilter(competitors)),
    toggleAvailActionsFilter: () => dispatch(toggleAvailActionsFilter(competitors)),
    toggleFormatAll: () => dispatch(toggleFormatAll()),
    toggleFormatHD: () => dispatch(toggleFormatHD()),
    toggleFormatSD: () => dispatch(toggleFormatSD()),
    toggleLicenseTypeAll: () => dispatch(toggleLicenseTypeAll()),
    toggleLicenseTypeRent: () => dispatch(toggleLicenseTypeRent()),
    toggleLicenseTypeBuy: () => dispatch(toggleLicenseTypeBuy()),
    clearTitleQuery: () => dispatch(clearTitleQuery(competitors)),
    updateTitleQuery: query => dispatch(updateTitleQuery(query)),
    toggleMovieFilter: () => dispatch(toggleMovieFilter(competitors)),
    toggleSeriesFilter: () => dispatch(toggleSeriesFilter(competitors)),
    toggleAllFilter: () => dispatch(toggleAllFilter(competitors)),
    showScanAsList: () => dispatch(showScanAsList()),
    showScanAsGrid: () => dispatch(showScanAsGrid()),
    toggleAuditExceptionFilter: () => dispatch(toggleAuditExceptionFilter(competitors)),
    toggleSubmittedAuditActionsFilter: () => dispatch(toggleSubmittedAuditActionsFilter()),
    toggleReadyAuditActionsFilter: () => dispatch(toggleReadyAuditActionsFilter()),
    toggleAllAuditActionsFilter: () => dispatch(toggleAllAuditActionsFilter()),
    exportReadyTitlesForScan: () => dispatch(exportReadyTitlesForScan()),
    toggleTerritory: (territory, isOnScansPage, isOnMerchPage) =>
      dispatch(toggleTerritory(territory, competitors, isOnScansPage, isOnMerchPage)),
    selectAllTerritories: (isOnScansPage, isOnMerchPage) =>
      dispatch(selectAllTerritories(isOnScansPage, isOnMerchPage)),
    deselectAllTerritories: () => dispatch(deselectAllTerritories(competitors)),
    togglePlatforms: (platformIds, isOnScansPage, isOnMerchPage) =>
      dispatch(togglePlatforms(platformIds, competitors, isOnScansPage, isOnMerchPage)),
    selectAllPlatforms: (isOnScansPage, isOnMerchPage) =>
      dispatch(selectAllPlatforms(competitors, isOnScansPage, isOnMerchPage)),
    deselectAllPlatforms: () => dispatch(deselectAllPlatforms(competitors)),
    toggleAllArtwork: () => dispatch(toggleAllArtwork()),
    toggleChangedArtworkFilter: () => dispatch(toggleChangedArtworkFilter()),
    toggleBlankArtworkFilter: () => dispatch(toggleBlankArtworkFilter()),
    setLetterFilter: letter => dispatch(setLetterFilter(letter)),
    toggleEnabledFilter: () => dispatch(toggleEnabledFilter()),
    refreshAmazonArtworks: () => dispatch(refreshAmazonArtworks()),
    markUnavailableForeverTitles: () => dispatch(markUnavailableForeverTitles()),
    markUnavailableTitles: () => dispatch(markUnavailableTitles()),
    relocateTitles: () => dispatch(relocateTitles()),
    retryUnavailableTitles: () => dispatch(retryUnavailableTitles()),
    setNewCandidatesFilter: () => dispatch(setNewCandidatesFilter()),
    stateFilterClear: () => dispatch(stateFilterClear()),
    stateFilterSet: state => dispatch(stateFilterSet(state)),
    clearQuery: () => dispatch(clearQuery()),
    toggleAll: () => dispatch(toggleAll()),
    toggleMovie: () => dispatch(toggleMovie()),
    toggleSeries: () => dispatch(toggleSeries()),
    updateQuery: query => dispatch(updateQuery(query)),
    reloadTitles: () => dispatch(reloadTitles()),
    enablePromoted: () => dispatch(enablePromoted()),
    enablePriority: () => dispatch(enablePriority()),
    disablePromoted: () => dispatch(disablePromoted()),
  }
}

export default useToolbarActions
