import React from 'react'
import Brands from './Brands'
import Titles from './Titles'
import { OptimizationScoreRecord, ScanSummary } from './interfaces'
import { List } from 'immutable'

type LeastMostOptimizedProps = {
  scanSummary: ScanSummary
  mostOptimizedBrands: List<OptimizationScoreRecord>
  leastOptimizedBrands: List<OptimizationScoreRecord>
  mostOptimizedTitles: List<OptimizationScoreRecord>
  leastOptimizedTitles: List<OptimizationScoreRecord>
}

const LeastMostOptimized: React.FC<LeastMostOptimizedProps> = ({
  scanSummary,
  mostOptimizedBrands,
  leastOptimizedBrands,
  mostOptimizedTitles,
  leastOptimizedTitles,
}) => {
  if (!scanSummary) {
    return <div />
  }
  return !mostOptimizedBrands.isEmpty() ? (
    <Brands mostOptimizedBrands={mostOptimizedBrands} leastOptimizedBrands={leastOptimizedBrands} />
  ) : (
    <Titles mostOptimizedTitles={mostOptimizedTitles} leastOptimizedTitles={leastOptimizedTitles} />
  )
}

export default LeastMostOptimized
