import React, { useRef, useMemo } from 'react'
import style from './Item.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import cx from 'classnames'
import { linkToPage } from '../utils'
import { SpotItems, Spot } from 'Interfaces/interfaces'
import { generateClientStyle } from '../helpers'
import { useScrollIntoView } from '../../../hooks/useScrollIntoView'
import { ViaplaySectionTypes } from '../enums'
import SpotDetails from './RenderSpotDetails'
import RenderItemBadge from './RenderItemBadge'

type ItemProps = RouteComponentProps & SpotItems

const generateClassNames = ({ section, isMerchandiseFilteringEnabled, client, spot }: SpotItems) => {
  return cx(style.item, {
    [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
    [style.highlight]: spot.selected || client,
  })
}

const generateArtworkUrl = (sectionType: ViaplaySectionTypes, spot: Spot) => {
  return ![ViaplaySectionTypes._HeroCarousel, ViaplaySectionTypes._CategoryHeroCarousel].includes(sectionType)
    ? spot.artworkUrl
    : spot.metadata.coverUrl
}

const Item: React.FC<ItemProps> = ({ spot, client, isMerchandiseFilteringEnabled, section, match }) => {
  const nodeRef = useRef<HTMLAnchorElement | null>(null)
  const sectionType = section?.sectionType as ViaplaySectionTypes

  useScrollIntoView({ condition: !!spot.selected, ref: nodeRef })

  const inlineStyle = useMemo(() => generateClientStyle(client), [client])
  const classNames = useMemo(() => generateClassNames({ section, isMerchandiseFilteringEnabled, client, spot }), [
    section,
    isMerchandiseFilteringEnabled,
    client,
    spot,
  ])
  const artworkUrl = useMemo(() => generateArtworkUrl(sectionType, spot), [sectionType, spot])

  return (
    <>
      <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={nodeRef}>
        <RenderItemBadge price={spot.metadata.price} availability={spot.metadata.availability} />
        <div className={style.spotBackground} id={`spot_${spot.id}`} style={{ backgroundImage: `url(${artworkUrl})` }}>
          {![ViaplaySectionTypes._PortraitContainer, ViaplaySectionTypes._PortraitGrid].includes(
            sectionType as ViaplaySectionTypes,
          ) && <SpotDetails spot={spot} sectionType={sectionType as ViaplaySectionTypes} />}
        </div>
      </a>
      {[ViaplaySectionTypes._PortraitContainer, ViaplaySectionTypes._PortraitGrid].includes(
        sectionType as ViaplaySectionTypes,
      ) && <SpotDetails spot={spot} sectionType={sectionType as ViaplaySectionTypes} />}
    </>
  )
}

export default withRouter(Item)
