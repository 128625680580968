import React from 'react'
import style from './GridItem.module.scss'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import { linkToPage, scrollToNode } from '../utils'

class GridItem extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, client, isMerchandiseFilteringEnabled, section, match } = this.props

    const inlineStyle = {}
    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    const classNames = cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
      [style.highlight]: spot.selected || client,
    })

    return (
      <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={node => (this.node = node)}>
        <div
          id={`spot_${spot.id}`}
          style={{
            background: `black url(${spot.artworkUrl}) center center no-repeat`,
            backgroundSize: 'cover',
          }}
        >
          {spot.spotType === 'link' && <span>{spot.name}</span>}
        </div>
        <span className={style.amazonCollection}>{spot.collectionName}</span>
      </a>
    )
  }
}

const ConnectedItemWithRouter = withRouter(GridItem)
export default ConnectedItemWithRouter
