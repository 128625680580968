import { PLATFORMS } from './utils.js'

export const TOGGLE_PLATFORMS = 'platform/TOGGLE_PLATFORMS'
export const SELECT_ALL_PLATFORMS = 'territory/SELECT_ALL_PLATFORMS'
export const DESELECT_ALL_PLATFORMS = 'territory/DESELECT_ALL_PLATFORMS'
export const SELECT_MULTIPLE_PLATFORMS = 'platform/SELECT_MULTIPLE_PLATFORMS'

export default function platformReducer(state, action) {
  switch (action.type) {
    case 'LOAD_PLATFORMS': {
      return state.withMutations(s => {
        s.set(PLATFORMS, action.platforms)
      })
    }
    case TOGGLE_PLATFORMS: {
      const { platformIds } = action
      return state.withMutations(s => {
        platformIds.forEach(id => {
          s.updateIn([PLATFORMS, id, 'selected'], selected => !selected)
        })
      })
    }
    case SELECT_ALL_PLATFORMS: {
      const allPlatforms = state.getIn([PLATFORMS])

      return state.withMutations(s => {
        allPlatforms.forEach(platform => {
          s.setIn([PLATFORMS, platform.id, 'selected'], true)
        })
      })
    }
    case DESELECT_ALL_PLATFORMS: {
      const allPlatforms = state.getIn([PLATFORMS])

      return state.withMutations(s => {
        allPlatforms.forEach(platform => {
          s.setIn([PLATFORMS, platform.id, 'selected'], false)
        })
      })
    }
    default:
      return state
  }
}
