import { AUDITS, updateRecords } from './utils'

export default function auditReducer(state, action) {
  switch (action.type) {
    case 'LOAD_AUDITS': {
      return state.withMutations(s => {
        action.reset ? s.setIn([AUDITS], action.audits) : updateRecords(action.audits, s, [AUDITS])
      })
    }
    default:
      return state
  }
}
