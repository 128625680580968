import { Record, Map } from 'immutable'
import { getTags } from 'Selectors'

export default class TaggingRecord extends Record({
  id: 0,
  tagId: 0,
  taggableId: 0,
  links: new Map(),
  relationships: new Map(),
}) {
  tag(state) {
    const tags = getTags(state)

    return tags.get(this.tagId)
  }
}
