import ChannelRecord from 'Records/channelRecord'
import { LOAD_CHANNELS } from 'Reducers/app/channelReducer'
import { OrderedMap } from 'immutable'

export const loadChannels = (channels, reset = false) => ({
  type: LOAD_CHANNELS,
  channels,
  reset,
})

export const channelRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new ChannelRecord({
      id: item.id,
      ...item.attributes,
    }),
  )
}

export const normalizeAndLoadChannels = (data, reset = false) => {
  const channels = data.reduce((memo, item) => {
    return memo.set(
      item.id,
      new ChannelRecord({
        ...item,
      }),
    )
  }, new OrderedMap())

  return loadChannels(channels, reset)
}
