import React from 'react'
import GenericSection from './GenericSection'
import style from './HeaderImage257.module.scss'

class HeaderImage257 extends GenericSection {
  constructor(props) {
    super(props)

    const { section } = this.props
    const { metadata } = section
    this.metadata = JSON.parse(metadata)
  }

  headerImage() {
    const { metadata } = this
    return metadata.artwork[0]
  }

  render() {
    const styles = {
      backgroundImage: `url(${this.headerImage().url})`,
    }
    return <div className={style.root} style={styles} />
  }
}

export default HeaderImage257
