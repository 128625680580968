import React from 'react'
import style from './SpotDetails.module.scss'
import cx from 'classnames'
import { SpotItems } from 'Interfaces/interfaces'
import { MegogoPaymentTypes, MegogoSectionTypes } from '../enums'

type SpotDetailsProps = {
  spot: SpotItems['spot']
  sectionType: string
}

const SpotDetails: React.FC<SpotDetailsProps> = ({ sectionType, spot }) => {
  switch (sectionType) {
    case MegogoSectionTypes._Container:
    case MegogoSectionTypes._LandscapeContainer:
    case MegogoSectionTypes._Grid:
    case MegogoSectionTypes._CollectionGrid:
      return (
        <p className={style.spotDetails}>
          <span className={style.name}>{spot.name}</span>
          <span className={style.label}>{spot.label}</span>
          {spot.description !== MegogoPaymentTypes._Subscription && (
            <span
              className={cx({
                [style.free]: spot.description === MegogoPaymentTypes._Free,
                [style.payment]: spot.description === MegogoPaymentTypes._Payment,
              })}
            >
              {spot?.metadata?.subtitle}
            </span>
          )}
          {spot.description === MegogoPaymentTypes._Subscription && (
            <span className={style.subscription}>
              <span className={style.label}>{spot?.metadata?.subtitle.split(' ')[0]}</span>
              {spot?.metadata?.subtitle.split(' ')[1]}
            </span>
          )}
        </p>
      )
    default:
      return null
  }
}

export default SpotDetails
