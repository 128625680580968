import React from 'react'
import TitleScoreCard from 'Containers/TitleScoreCard'
import { Card } from '@blueprintjs/core'
import cx from 'classnames'
import styles from './styles.module.scss'
import { OptimizationScoreRecord } from './interfaces'
import { List } from 'immutable'

type TitlesProps = {
  leastOptimizedTitles: List<OptimizationScoreRecord>
  mostOptimizedTitles: List<OptimizationScoreRecord>
}

const Titles: React.FC<TitlesProps> = ({ leastOptimizedTitles, mostOptimizedTitles }) => (
  <>
    <div className={styles.gridItem}>
      <Card className={styles.card}>
        <div>
          <h4 className={styles.pageHeader}>Most optimised titles</h4>
          <div className={cx(styles.section, styles.brandSection)}>
            {mostOptimizedTitles.toArray().map((brandScore, index) => (
              <TitleScoreCard score={brandScore} key={index} />
            ))}
          </div>
        </div>
      </Card>
    </div>
    <div>
      <Card className={styles.card}>
        <div>
          <h4 className={styles.pageHeader}>Least optimised titles</h4>
          <div className={cx(styles.section, styles.brandSection)}>
            {leastOptimizedTitles.toArray().map((brandScore, index) => (
              <TitleScoreCard score={brandScore} key={index} />
            ))}
          </div>
        </div>
      </Card>
    </div>
  </>
)

export default Titles
