import { readEndpoint } from 'Actions/apiActions'
import { OrderedMap } from 'immutable'
import queryString from 'query-string'
import MarketShareRecord from 'Records/marketShareRecord'
import assignIn from 'lodash.assignin'

import { getStudios, getPlatforms, getTerritories, getMarketShareFilters, getClient } from 'Selectors'

import { normalizeAndLoadClients } from 'Actions/clientActions'
import { normalizeAndLoadPlatforms } from 'Actions/platformActions'
import { normalizeAndLoadTerritories } from 'Actions/territoryActions'
import { normalizeAndLoadStudios } from 'Actions/studioActions'
import { normalizeAndLoadMerchandizingScans } from 'Actions/merchandizing/scanActions'

import {
  LOADING_MARKET_SHARES,
  LOAD_MARKET_SHARES,
  LOAD_MARKET_SHARES_FILTERING,
  TOGGLE_STUDIO,
  TOGGLE_TERRITORY,
  TOGGLE_PLATFORMS,
  TOGGLE_DATE_RANGE,
  TOGGLE_GROUP_BY_STUDIO,
  TOGGLE_MOVIES,
  TOGGLE_SERIES,
} from 'Reducers/app/marketShareReducer'

export const retrieveOverviewMarketShare = (ownParams, clientId) => (dispatch, getState) => {
  const url = `market_shares?client_id=${clientId}&`
  const params = getMarketShareFilters(getState()).toParams()

  const query = queryString.stringify(assignIn(ownParams, params))

  dispatch({ type: LOADING_MARKET_SHARES })

  return readEndpoint(url + query).then(response => {
    const marketShares = response.marketShares.reduce((memo, item) => {
      const { merchandizingScan, analysedStudio, client } = item
      return memo.set(
        item.id,
        new MarketShareRecord({
          id: item.id,
          ...item,
          merchandizingScanId: merchandizingScan.id,
          clientId: client.id,
          analysedStudioId: analysedStudio.id,
        }),
      )
    }, new OrderedMap())

    const clients = response.marketShares.map(marketShare => marketShare.client)
    const merchandizingScans = response.marketShares.map(marketShare => marketShare.merchandizingScan)
    const analysedStudios = response.marketShares.map(marketShare => marketShare.analysedStudio)

    dispatch(loadMarketShares(marketShares))
    dispatch(normalizeAndLoadMerchandizingScans(merchandizingScans))
    dispatch(normalizeAndLoadClients(clients))
    dispatch(normalizeAndLoadStudios(analysedStudios))
  })
}

export const retrieveMarketShare = (ownParams, clientId) => (dispatch, getState) => {
  const url = `market_shares?with_territories=true&client_id=${clientId}&`
  const params = getMarketShareFilters(getState()).toParams()

  const query = queryString.stringify(assignIn(ownParams, params))

  dispatch({ type: LOADING_MARKET_SHARES })

  return readEndpoint(url + query).then(response => {
    const marketShares = response.marketShares.reduce((memo, item) => {
      const { merchandizingScan, analysedStudio, client } = item
      return memo.set(
        item.id,
        new MarketShareRecord({
          id: item.id,
          ...item,
          merchandizingScanId: merchandizingScan.id,
          clientId: client.id,
          analysedStudioId: analysedStudio.id,
        }),
      )
    }, new OrderedMap())

    const clients = response.marketShares.map(marketShare => marketShare.client)
    const merchandizingScans = response.marketShares.map(marketShare => marketShare.merchandizingScan)
    const analysedStudios = response.marketShares.map(marketShare => marketShare.analysedStudio)
    const platforms = merchandizingScans
      .map(merchandizingScan => merchandizingScan.platform)
      .flat()
      .filter(p => !!p)
    const territories = merchandizingScans
      .map(merchandizingScan => merchandizingScan.territory)
      .flat()
      .filter(t => !!t)

    dispatch(loadMarketShares(marketShares))
    dispatch(normalizeAndLoadMerchandizingScans(merchandizingScans))
    dispatch(normalizeAndLoadClients(clients))
    dispatch(normalizeAndLoadStudios(analysedStudios))
    dispatch(normalizeAndLoadPlatforms(platforms))
    dispatch(normalizeAndLoadTerritories(territories))

    dispatch(loadMarketSharesFiltering())
  })
}

export const reloadMarketShare = () => (dispatch, getState) => {
  const client = getClient(getState())

  return dispatch(retrieveMarketShare(null, client.id))
}

export const loadMarketSharesFiltering = () => (dispatch, getState) => {
  const state = getState()
  const studios = getStudios(state)
  const platforms = getPlatforms(state)
  const territories = getTerritories(state)
  dispatch({
    type: LOAD_MARKET_SHARES_FILTERING,
    studios,
    platforms,
    territories,
  })
}

export const loadMarketShares = marketShares => ({
  type: LOAD_MARKET_SHARES,
  marketShares,
})

export const toggleStudio = studio => dispatch => {
  dispatch({
    type: TOGGLE_STUDIO,
    studio,
  })

  dispatch(reloadMarketShare())
}

export const toggleTerritory = territory => dispatch => {
  dispatch({
    type: TOGGLE_TERRITORY,
    territory,
  })

  dispatch(reloadMarketShare())
}

export const togglePlatforms = platforms => dispatch => {
  dispatch({
    type: TOGGLE_PLATFORMS,
    platforms,
  })

  dispatch(reloadMarketShare())
}

export const toggleGroupByStudio = () => dispatch => {
  dispatch({
    type: TOGGLE_GROUP_BY_STUDIO,
  })

  dispatch(reloadMarketShare())
}

export const toggleDateRange = dateRange => dispatch => {
  dispatch({
    type: TOGGLE_DATE_RANGE,
    dateRange,
  })

  dispatch(reloadMarketShare())
}

export const toggleMovieFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_MOVIES,
  })

  dispatch(reloadMarketShare())
}

export const toggleSeriesFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_SERIES,
  })

  dispatch(reloadMarketShare())
}
