import React from 'react'
import RadioInput from '../PlatformAndTerritoryListNew/RadioInput'
import { MerchandizingScan } from 'Interfaces/*'

type AssociatedMerchScansForPlatformTypes = {
  associatedMerchScans: MerchandizingScan[]
  onChange: (path: string) => void
  pathName: string
}

const AssociatedMerchScansForPlatform: React.FC<AssociatedMerchScansForPlatformTypes> = ({
  associatedMerchScans,
  onChange,
  pathName,
}) => (
  <ul className="bp5-menu">
    {associatedMerchScans.map(
      ams =>
        ams?.linkToRootPage && (
          <li key={ams.id}>
            <RadioInput
              label={ams.name}
              value={ams.name}
              checked={pathName === ams.linkToRootPage}
              onChange={() => onChange(ams.linkToRootPage)}
            />
          </li>
        ),
    )}
  </ul>
)

export default AssociatedMerchScansForPlatform