import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getScans, getHasMoreDeliveredScansToLoad } from 'Selectors'
import { useClientContext } from 'Contexts/ClientContext'
import NavPopover from 'Components/Nav/NavPopover'
import { useNavPaths } from 'Hooks/useNavPaths'
import { retrieveClientDeliveredScans } from 'Actions/scanActions'
import moment from 'moment'
import { retrieveDefaultMerchandizingScan, setSelectedScan } from 'Actions/scanActions'
import { useHistory, matchPath } from 'react-router'

const HistoryPopover = ({ open, onOpenClick }: { open: boolean; onOpenClick: (_isOpen: boolean) => void }) => {
  const dispatch = useDispatch()
  const [offset, setOffset] = useState(2)
  const { client, isAdminRoute } = useClientContext()
  const { getHistoryPath } = useNavPaths({ clientApp: !isAdminRoute })
  const history = useHistory()

  const { hasMoreToLoad, scans } = useSelector(state => ({
    hasMoreToLoad: getHasMoreDeliveredScansToLoad(state),
    scans: getScans(state).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
  }))

  const handleInfiniteLoad = () => {
    if (!hasMoreToLoad) return

    dispatch(
      retrieveClientDeliveredScans(offset).then(() => {
        setOffset(offset + 1)
      }),
    )
  }

  useEffect(() => {
    if (!client?.id) return

    dispatch(retrieveClientDeliveredScans())
  }, [client?.id, dispatch])

  const matchPathHelper = path => matchPath(window.location.pathname, { path, strict: false })
  const isOnMerchPage =
    matchPathHelper(`/v2/clients/:id/merchandizing/scans`) || matchPathHelper(`/dashboard/merch/:scan_id/pages/:id`)

  const handleNavigateToHistory = scanId => {
    if (isOnMerchPage) {
      setSelectedScan(scanId)
      retrieveDefaultMerchandizingScan(scanId).then(res => {
        const { id: merchScanId, rootPageId } = res.merchandizingScan || {}

        window.location.href =
          getHistoryPath({ scanId: scanId, clientId: client.id, merchScanId, rootPageId }).url + history.location.search
      })
    } else {
      window.location.href = getHistoryPath({ scanId: scanId, clientId: client.id }).url + history.location.search
    }
  }

  const items = scans.valueSeq().map(scan => ({
    id: scan.id,
    title: moment(scan.scheduledAt || scan.createdAt, 'YYYY-MM-DD HH:mm:ss.SSSSSSSSS Z').format('MMMM Do YYYY'),
  }))

  return (
    <NavPopover
      items={items}
      isOpen={open}
      setIsOpen={onOpenClick}
      next={handleInfiniteLoad}
      hasMore={hasMoreToLoad}
      iconClassName="bp5-icon-timeline-events"
      historyPopover={true}
      onItemClick={id => handleNavigateToHistory(id)}
    />
  )
}
export default HistoryPopover