import React from 'react'
import GenericSection from './GenericSection'
import style from './MenuItem.module.scss'
import cx from 'classnames'
import { scrollToNode } from '../utils'

class MenuItem extends GenericSection {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, className, section } = this.props
    const inlineStyle = {
      backgroundImage: `url(${spot.artworkUrl})`,
    }
    const classNames = cx(className, style.item, {
      [style.sectionFade]: section.selected,
      [style.spotHighlight]: spot.selected,
    })

    return (
      <li id={`spot_${spot.id}`} className={classNames} ref={node => (this.node = node)}>
        {spot.position === 0 && <div className={style.poster} style={inlineStyle} />}
        <a href={spot.url} target="_blank" className={style.text} rel="noreferrer">
          <span className={style.name}>{spot.name}</span>
          <br />
          <span className={style.genre}>{spot.collectionName || spot.genre}</span>
        </a>
      </li>
    )
  }
}

export default MenuItem
