import React, { useCallback, useMemo } from 'react'
import style from './TabMenu.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { linkToPage } from '../utils'
import { Spot, Navigation, Section, Scan } from 'Interfaces/interfaces'
import WrappedSection from 'Containers/WrappedSection'

type TabMenuProps = RouteComponentProps &
  Navigation & {
    subSections: Section[]
    scan: Scan
  }

const TabMenu: React.FC<TabMenuProps> = ({ spots, page, subSections, scan, match }) => {
  const renderLink = useCallback(
    (spot: Spot, subSections: Section[]) => {
      const isDropdown = subSections.some(subsection => subsection.name === spot.name)
      if (isDropdown) {
        return subSections?.map(
          s =>
            s?.name === spot.name && (
              <WrappedSection
                spot={spot}
                type={s.sectionType}
                key={s.id}
                section={s}
                scan={scan}
                page={page}
                match={match}
              />
            ),
        )
      }

      return (
        spot && (
          <a
            key={spot.id}
            href={linkToPage(spot, match)}
            className={`${style.tabMenuListItem} ${spot.linkedPageId === page.id ? style.activeTab : ''}`}
          >
            <span>{spot.name}</span>
          </a>
        )
      )
    },
    [match, page, scan],
  )

  const renderSpots = useMemo(() => {
    return spots?.map(spot => spot && renderLink(spot, subSections))
  }, [renderLink, spots, subSections])

  return <div className={style.tabMenu}>{renderSpots}</div>
}

export default withRouter(TabMenu)
