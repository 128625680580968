import AssociatedMerchandizingScanRecord from 'Records/associatedMerchandizingScanRecord'
import { Map, OrderedMap, fromJS } from 'immutable'
import { LOAD_ASSOCIATED_MERCHANDIZING_SCANS } from 'Reducers/app/associatedMerchandizingScanReducer'

export const loadAssociatedMerchandizingScans = associatedMerchandizingScans => ({
  type: LOAD_ASSOCIATED_MERCHANDIZING_SCANS,
  associatedMerchandizingScans,
})

export const associatedMerchandizingScanRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new AssociatedMerchandizingScanRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    }),
  )
}

export const normalizeAndLoadAssociatedMerchandizingScans = data => {
  const associatedMerchandizingScans = data.reduce((memo, item) => {
    return memo.set(item.id, new AssociatedMerchandizingScanRecord({ ...item }))
  }, new OrderedMap())

  return loadAssociatedMerchandizingScans(associatedMerchandizingScans)
}
