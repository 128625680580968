import React from 'react'
import cx from 'classnames'
import styles from './TitleDetail.module.scss'
import { Dialog, Classes, Button, Icon, Tooltip } from '@blueprintjs/core'

import LeftRight from 'Components/LeftRight'
import FluidLeft from 'Components/LeftRight/FluidLeft'
import { withRouter } from 'react-router-dom'

class TitleDetail extends React.Component {
  constructor(props) {
    super(props)

    const { title } = this.props
    const { name, nameStem } = title

    this.state = {
      performingAction: false,
      approveSpinner: false,
      unavailableSpinner: false,
      stemCopied: false,
      name,
      nameStem,
    }
  }
  handleClose = () => {
    this.props.history.goBack()
  }

  copyToClipboard = textToCopy => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      this.setState({ stemCopied: true })
      setTimeout(() => {
        this.setState({ stemCopied: false })
      }, [500])
    })
  }

  handleNameChange = e => {
    this.setState({ name: e.target.value })
  }

  handleNameStemChange = e => {
    this.setState({ nameStem: e.target.value })
  }
  handleNameChange = e => {
    this.setState({ name: e.target.value })
  }

  handleSave = () => {
    const { title, updateTitle } = this.props
    const { name, nameStem } = this.state
    this.setState({ performingAction: true }, () => {
      updateTitle(title, { name, nameStem }).then(() => {
        this.handleClose()
      })
    })
  }

  render() {
    const { title } = this.props

    const { name, nameStem } = this.state

    return (
      <Dialog isOpen onClose={this.handleClose} className={cx(styles.dialog, 'bp5-focus-disabled')}>
        <div className={styles.popup}>
          <div className={styles.header}>
            <LeftRight>
              <FluidLeft>
                <h3>{title.name}</h3>
                <div className={styles.titleType}>{title.titleType}</div>
              </FluidLeft>
            </LeftRight>
          </div>

          <div className={cx(styles.body, 'bp5-dialog-body')}>
            <label className="bp5-label">
              Name
              <span className="bp5-text-muted">(required)</span>
              <input
                className="bp5-input bp5-fill"
                type="text"
                placeholder="Name"
                dir="auto"
                value={name}
                onChange={this.handleNameChange}
                autoFocus
              />
            </label>
            <label className="bp5-label">
              Name Stem
              <span className="bp5-text-muted">(required)</span>
              {this.state.stemCopied ? (
                <Tooltip isOpen={this.state.stemCopied} defaultIsOpen={this.state.stemCopied} content={'Copied'}>
                  <Icon
                    className={styles.clipboardIcon}
                    onClick={() => this.copyToClipboard(nameStem)}
                    icon="duplicate"
                  />
                </Tooltip>
              ) : (
                <Tooltip defaultIsOpen={false} content={'Copy to Clipboard'}>
                  <Icon
                    className={styles.clipboardIcon}
                    onClick={() => this.copyToClipboard(nameStem)}
                    icon="duplicate"
                  />
                </Tooltip>
              )}
              <input
                className="bp5-input bp5-fill"
                type="text"
                placeholder="Name"
                dir="auto"
                value={nameStem}
                onChange={this.handleNameStemChange}
                autoFocus
              />
            </label>
          </div>

          <div className={cx(Classes.DIALOG_FOOTER, styles.footer)}>
            <div className={styles.primaryActions} />
            <div className={cx(Classes.DIALOG_FOOTER_ACTIONS, styles.secondaryActions)}>
              <Button className="" onClick={this.handleClose}>
                Close
              </Button>
              <Button className="bp5-intent-primary" onClick={this.handleSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

const ConnectedTitleDetailWithRouter = withRouter(TitleDetail)
export default ConnectedTitleDetailWithRouter
