import React from 'react'
import style from './ContainerWithBackground.module.scss'
import Slider from 'react-slick'
import Item from './Item'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

class ContainerWithBackground extends React.Component {
  renderSpots() {
    const { section, isMerchandiseFilteringEnabled, spots } = this.props

    return spots
      .filter(s => s && s.name !== 'Explore more')
      .map(
        spot =>
          spot && (
            <div className={style.item} key={spot.id}>
              <Item
                spot={spot}
                isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
                client={this.isStudioSelected(spot.id)}
                section={section}
                hideTitle
              />
            </div>
          ),
      )
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  // this is a custom link spot that takes us to Explore more pages, others are content spots
  seeMore() {
    const { spots } = this.props
    return spots.find(spot => spot && spot.label === 'Explore more')
  }

  render() {
    const { section, match } = this.props
    const metadata = JSON.parse(section.metadata) || {}
    const seeMoreSpot = this.seeMore()

    const sliderSettings = {
      dots: false,
      infinite: false,
      centerPadding: 0,
      slidesToScroll: 5,
      slidesToShow: 5,
      arrows: true,
      swipe: false,
      initialSlide: 0,
      nextArrow: <Arrow right />,
      prevArrow: <Arrow left />,
    }
    return (
      <div
        className={style.container}
        style={{ background: `black url(${metadata.artwork}) center center no-repeat`, backgroundSize: 'contain' }}
      >
        <div className={style.sliderWrapper}>
          <div className={style.heading}>
            <h1>{section.name}</h1>
            {metadata.description && <h3>{metadata.description}</h3>}
            <div className={style.linkWrapper}>
              {seeMoreSpot && <a href={linkToPage(seeMoreSpot, match)}>{seeMoreSpot.name}</a>}
            </div>
          </div>
          <Slider {...sliderSettings} className={style.alignSpotsLeft}>
            {this.renderSpots()}
          </Slider>
        </div>
      </div>
    )
  }
}

const ConnectedContainer = withRouter(ContainerWithBackground)
export default ConnectedContainer
