import React from 'react'
import cx from 'classnames'
import { Card } from '@blueprintjs/core'
import styles from './styles.module.scss'
import { AVAILABILITY, PRICING, ARTWORK } from './constants'
import ActionItem from './ActionItem'
import { Client } from 'Interfaces/*'
import { ScanSummary } from './interfaces'

type ActionStatProps = {
  scanSummary: ScanSummary
  client: Client
}

const ActionStat: React.FC<ActionStatProps> = ({ scanSummary, client }) => {
  if (!scanSummary) {
    return <div />
  }
  const {
    auditAvailActionsCount,
    previousAuditAvailActionsCount,
    auditPriceActionsCount,
    previousAuditPriceActionsCount,
    previousAuditArtworkUberActionsCount,
    auditArtworkUberActionsCount,
  } = scanSummary

  const artworkActionsCount = client.uberActions ? auditArtworkUberActionsCount : 0
  const auditAvailableActionsDifference = auditAvailActionsCount - (previousAuditAvailActionsCount || 0)
  const auditPriceActionsCountDifference = auditPriceActionsCount - (previousAuditPriceActionsCount || 0)
  const artworkActionsCountDifference = artworkActionsCount - (previousAuditArtworkUberActionsCount || 0)

  return (
    <div className={cx(styles.gridItem)}>
      <Card className={styles.card}>
        <h5 className={styles.header}>Actions Overview</h5>
        <div className={styles.actionStateWrapper}>
          <ActionItem
            title={AVAILABILITY}
            total={auditAvailActionsCount}
            difference={auditAvailableActionsDifference}
          />
          <ActionItem title={PRICING} total={auditPriceActionsCount} difference={auditPriceActionsCountDifference} />
          <ActionItem title={ARTWORK} total={artworkActionsCount} difference={artworkActionsCountDifference} />
        </div>
      </Card>
    </div>
  )
}

export default ActionStat
