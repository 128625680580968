import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import { SliderArrow } from 'Interfaces/interfaces'
import style from './Arrow.module.scss'

const Arrow: React.FC<SliderArrow> = ({ onClick, left, right }) => {
  const classnames = cx({
    [style.rightArrow]: right,
    [style.leftArrow]: left,
  })

  const intervalRef = useRef<number | null>(null)

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <div
      data-testid="arrow-div"
      className={cx({
        [style.arrowWrapperLeft]: left,
        [style.arrowWrapperRight]: right,
      })}
      onMouseEnter={() => {
        intervalRef.current = window.setInterval(() => {
          onClick()
        }, 500)
      }}
      onMouseLeave={() => {
        if (intervalRef.current) {
          window.clearInterval(intervalRef.current)
          intervalRef.current = null
        }
      }}
    >
      <i className={classnames} data-testid="arrow-icon" />
    </div>
  )
}

export default Arrow
