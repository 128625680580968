import styled from 'styled-components'

const SectionDivider = styled.div`
  width: 100%;
  border-top: 1px solid gray;
  opacity: 0.2;
  margin-bottom: 10px;
  margin-top: 10px;
`

export default SectionDivider
