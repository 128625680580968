import React, { useMemo, useRef, useState } from 'react'
import Slider from 'react-slick'
import Item from './Item'
import Arrow from './Arrow'
import { PageItems, Spot } from 'Interfaces/interfaces'
import style from './Container.module.scss'
import { renderSpotsWithItem, sliderSettings, computeContainerClasses } from '../helpers'
import { List } from 'immutable'
import { OrangeSectionTypes } from '../enums'
import cx from 'classnames'
import BackgroundImage from './BackgroundImage'
import ContainerHeader from './ContainerHeader'

type ContainerProps = PageItems & {
  spots: List<Spot>
}

const Container: React.FC<ContainerProps> = ({ selectedStudios, section, isMerchandiseFilteringEnabled, spots }) => {
  const { metadata } = section
  let parsedMetadata = metadata

  if (typeof metadata === 'string') {
    try {
      parsedMetadata = JSON.parse(metadata)
    } catch (error) {
      console.error('Failed to parse metadata:', error)
    }
  }

  const { artwork: backgroundImage, background_color: backgroundColor } = parsedMetadata

  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef<Slider | null>(null)

  const seeMoreSpots = useMemo(() => spots.find((spot: Spot) => spot?.label?.toLowerCase() === 'view all'), [spots])

  const containerClasses = useMemo(() => computeContainerClasses(section, style, 3), [section])

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.heroCarouselItem]: section.sectionType === OrangeSectionTypes._HeroCarousel,
        [style.containerItem]: section.sectionType === OrangeSectionTypes._Container,
        [style.chartContainerItem]: section.sectionType === OrangeSectionTypes._ChartContainer,
        [style.largeContainerItem]: section.sectionType === OrangeSectionTypes._LargeContainer,
        [style.landscapeItem]: section.sectionType === OrangeSectionTypes._LandscapeContainer,
      }),
    [section],
  )

  const filteredSpots = useMemo(() => spots.filter(spot => spot?.label?.toLowerCase() !== 'view all'), [spots])

  const renderSpots = renderSpotsWithItem({
    spots: filteredSpots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const handleScrollbarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const slideIndex = parseInt(event.target.value, 10)
    setCurrentSlide(slideIndex)
    sliderRef.current?.slickGoTo(slideIndex)
  }

  const spotsPerSlider = {
    [OrangeSectionTypes._HeroCarousel]: 3,
    [OrangeSectionTypes._Container]: 8,
    [OrangeSectionTypes._LargeContainer]: 6,
    [OrangeSectionTypes._LandscapeContainer]: 4,
    [OrangeSectionTypes._ChartContainer]: 7,
  }

  const containerStyle = {
    backgroundColor: backgroundColor,
    padding: '1% 0',
  }

  const maxSpots = spots.size - (spotsPerSlider[section.sectionType] || 0)

  return (
    <div className={containerClasses} style={containerStyle}>
      <BackgroundImage sectionType={section.sectionType as OrangeSectionTypes} backgroundImage={backgroundImage} />
      <ContainerHeader section={section} seeMore={seeMoreSpots} hasBackgroundColor={!!backgroundColor} />
      <Slider
        ref={sliderRef}
        {...sliderSettings({
          slidesToScroll: 1,
          slidesToShow: spotsPerSlider[section.sectionType],
          speed: 1000,
          arrow: true,
          infinite: false,
          swipe: true,
          nextArrow: <Arrow right section={section.sectionType} />,
          prevArrow: <Arrow left section={section.sectionType} />,
          afterChange: (current: number) => setCurrentSlide(current),
        })}
        className={style.alignSpotsLeft}
      >
        {renderSpots}
      </Slider>
      <input
        type="range"
        min="0"
        max={`${maxSpots}`}
        value={currentSlide}
        onChange={handleScrollbarChange}
        className={style.sliderScrollbar}
      />
    </div>
  )
}

export default Container
