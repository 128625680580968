import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getUI,
  getScanFilters,
  getSelectedScan,
  getTitleAuditsForScan,
  getSelectedPlatforms,
  getSelectedPlatformsWithShowFlags,
  getScanSummary,
  getTitlesForCorrection,
  areTitlesForCorrectionLoading,
  getScanDownloads,
  getScanDownloadsInProgress,
  getTerritories,
  getHasMoreTitleAuditsToLoad,
} from 'Selectors'

import { retrieveScan, retrieveScanSummary, setIncludes } from 'Actions/scanActions'
import {
  retrieveTitleAuditsAndCorrections,
  retrieveMoreTitleAudits,
  resetTitleAudits,
  DEFAULT_INCLUDES,
} from 'Actions/titleAuditActions'

import { retrieveTags } from 'Actions/tagActions'
import styles from 'Components/ScanPage/styles.module.scss'
import cx from 'classnames'

import Grid from 'Components/ScanPage/Grid'
import ActionList from 'Containers/ActionList'
import { showScanStats, setRootPath, showErrorMessageToast } from 'Actions/uiActions'
import Toolbar from 'Components/ScanPage/Toolbar'
import LeftRight from 'Components/LeftRight'
import FluidLeft from 'Components/LeftRight/FluidLeft'
import TopBottom from 'Components/TopBottom'
import FluidTop from 'Components/TopBottom/FluidTop'
import SidePanel from 'Containers/SidePanel'
import ActionListPagination from 'Components/ScanPage/ActionList/Pagination'
import { List } from 'immutable'
import { RouteComponentProps } from 'react-router-dom'
import { useClientContext } from 'Contexts/ClientContext'
import ErrorBoundary from 'Components/ErrorBoundary'
import usePreserveFilter from 'Hooks/usePreserveFilter'

const queryString = require('query-string')

interface Props extends RouteComponentProps<{ scanId: string }> {
  scanId: number
  competitors: boolean
}

const ScanPage = ({ location, match, scanId, competitors = false }: Props) => {
  const dispatch = useDispatch()
  const { client, clientId } = useClientContext()

  const scan = useSelector(state => getSelectedScan(state))
  const scanView = useSelector(state => getUI(state).get('scanView'))
  const scanStats = useSelector(state => getUI(state).get('scanStats'))
  const filter = useSelector(state => getScanFilters(state))
  const titleAudits = useSelector(state => getTitleAuditsForScan(state, { scan }))
  const platforms = useSelector(state => getSelectedPlatforms(state))
  const platformsWithFlags = useSelector(state => getSelectedPlatformsWithShowFlags(state))
  const scanSummary = useSelector(state => getScanSummary(state))
  const titlesForCorrectionLoading = useSelector(state => areTitlesForCorrectionLoading(state))
  // const scanDownloads = useSelector(state => getScanDownloads(state))
  // const scanDownloadsInProgress = useSelector(state => getScanDownloadsInProgress(state))
  // const territories = useSelector(state => getTerritories(state))
  const hasMoreTitleAuditsToLoad = useSelector(state => getHasMoreTitleAuditsToLoad(state))
  usePreserveFilter({ enabled: !!scan })

  let correctedAuditActions: any = List()
  const titlesForCorrection = useSelector(state => {
    return getTitlesForCorrection(state).filter(titleForCorrection => {
      const actions = titleForCorrection.auditActions(state)
      if (actions) correctedAuditActions = correctedAuditActions.concat(actions)
      const titlesForCorrectionReady = actions.filter(action => action && action.status === 'ready').size

      return titlesForCorrectionReady
    })
  })

  const autobotEnabled = scan && scan.isLastDelivered && scan.isAutobotEnabled
  const classnames = cx(styles.root, 'bp5-focus-disabled')

  useEffect(() => {
    dispatch(setRootPath(match.url))
    dispatch(resetTitleAudits())
    dispatch(setIncludes(DEFAULT_INCLUDES))

    dispatch(retrieveScan(scanId, queryString.parse(location.search))).then(() => {
      dispatch(retrieveScanSummary(scanId))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanId])

  useEffect(() => {
    if (scanView === 'list' || !scan || !clientId) return

    dispatch(retrieveTags(clientId)).then(() => {
      dispatch(retrieveTitleAuditsAndCorrections(scan.id, competitors))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanView, scan?.id, dispatch, competitors, clientId])

  return (
    <div>
      <div className={classnames}>
        <Toolbar
          client={client}
          scan={scan}
          scanStats={scanStats}
          scanView={scanView}
          filter={filter}
          correctedAuditActions={correctedAuditActions}
          titlesForCorrection={titlesForCorrection}
          titlesForCorrectionLoading={titlesForCorrectionLoading}
        />
        {scanView === 'grid' && (
          <LeftRight>
            <FluidLeft>
              <Grid
                scan={scan}
                client={client}
                titleAudits={titleAudits}
                platforms={platforms}
                retrieveMoreTitleAudits={() => dispatch(retrieveMoreTitleAudits())}
                platformsWithFlags={platformsWithFlags}
                setIncludes={setIncludes}
                autobotEnabled={autobotEnabled}
                scanSummary={scanSummary}
                hasMoreTitleAuditsToLoad={hasMoreTitleAuditsToLoad}
              />
            </FluidLeft>
            {client?.id && scan && scanStats && scanSummary && (
              <SidePanel
                scan={scan}
                client={client}
                platforms={platforms}
                scanSummary={scanSummary}
                showScanStats={showScanStats}
              />
            )}
          </LeftRight>
        )}
        {scanView === 'list' && scan && (
          <LeftRight>
            <FluidLeft>
              <TopBottom>
                <FluidTop>
                  <ActionList scan={scan} />
                </FluidTop>
                <ActionListPagination />
              </TopBottom>
            </FluidLeft>
            {client?.id && scan && scanStats && scanSummary && (
              <SidePanel
                client={client}
                scan={scan}
                platforms={platforms}
                scanSummary={scanSummary}
                showScanStats={showScanStats}
              />
            )}
          </LeftRight>
        )}
      </div>
    </div>
  )
}

const WithError = props => (
  <ErrorBoundary
    onError={() =>
      showErrorMessageToast(
        'Failed to display scan page. Please try to refresh the page. If this error persists, please contact support.',
      )
    }
  >
    <ScanPage {...props} />
  </ErrorBoundary>
)

export { WithError as ScanPage }
