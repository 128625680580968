import { Record, Map } from 'immutable'
import { getTerritories } from 'Selectors'

export default class PlatformRecord extends Record({
  id: 0,
  name: '',
  logoUrl: '',
  code: '',
  territoryId: 0,
  platformTypes: {},
  purchaseOptions: [],
  links: new Map(),
  meta: new Map(),
  selected: true,
  isoCode: null,
}) {
  isAvodType() {
    return this.type === 'avod'
  }

  isSvodType() {
    return this.type === 'svod'
  }

  isTvodType() {
    return this.type === 'tvod'
  }

  buyEnabled() {
    return this.purchaseOptions.includes('buy')
  }

  rentEnabled() {
    return this.purchaseOptions.includes('rent')
  }

  territory(state) {
    const territories = getTerritories(state)

    return territories.find(territory => territory.id === this.territoryId)
  }
}
