import React from 'react'
import style from './ShowcaseCarousel.module.scss'
import Slider from 'react-slick'
import Item from './Item'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const arrowClassnames = cx({
    [style.rightArrow]: right,
    [style.leftArrow]: left,
    [style.disabledArrow]: left && currentSlide === 0,
  })

  const arrowContainerClassnames = cx(style.arrowContainer, {
    [style.rightArrowContainer]: right,
    [style.leftArrowContainer]: left,
  })
  return (
    <div className={arrowContainerClassnames} onClick={onClick}>
      <i className={arrowClassnames} />
    </div>
  )
}

class ShowcaseCarousel extends React.Component {
  render() {
    const { section, match } = this.props
    const metadata = section.metadata || {}

    const seeAllSpot = this.props.spots.find(spot => spot && spot.spotType === 'link')

    let sliderSettings = {
      dots: false,
      infinite: false,
      centerMode: false,
      centerPadding: 0,
      slidesToScroll: 4,
      slidesToShow: 4,
      variableWidth: true,
      arrows: true,
      swipe: false,
      initialSlide: 0,
      nextArrow: <Arrow right />,
      prevArrow: <Arrow left />,
    }

    const hasWallpaperUrl = !!metadata.wallpaperUrl
    const backgroundImageWidth = window.innerWidth

    let sectionStyles = ''
    if (hasWallpaperUrl) {
      sectionStyles = {
        background: `black url(${metadata.wallpaperUrl}?width=${backgroundImageWidth}) center center no-repeat`,
        backgroundSize: 'cover',
        height: '300px',
        paddingTop: '60px',
      }
      sliderSettings = { ...sliderSettings, slidesToScroll: 3, slidesToShow: 3 }
    }

    const sectionClasess = cx(style.container, {
      [style.firstSection]: section && section.position === 1,
    })

    const renderSection = () => {
      return <Slider {...sliderSettings}>{this.renderSpots()}</Slider>
    }

    const renderSectionWithWallpaper = () => {
      return (
        <div style={sectionStyles}>
          <div className={style.sliderContainer}>
            <Slider {...sliderSettings}>{this.renderSpots()}</Slider>
          </div>
        </div>
      )
    }

    return (
      this.spots().size > 0 && (
        <div className={sectionClasess}>
          <div className={style.heading}>
            <h2>{section.name}</h2>
            {seeAllSpot && (
              <a className={style.seeAll} href={linkToPage(seeAllSpot, match)}>
                {seeAllSpot.name}
              </a>
            )}
          </div>
          {hasWallpaperUrl ? renderSectionWithWallpaper() : renderSection()}
        </div>
      )
    )
  }

  renderSpots() {
    const { section, isMerchandiseFilteringEnabled } = this.props

    return this.spots().map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <Item
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={this.isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        ),
    )
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'content')
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }
}

export default withRouter(ShowcaseCarousel)
