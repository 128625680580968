import React from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import { Dialog, Classes, Button, Tab, Tabs } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'
import { sortPlatformsByRegionAndName } from 'Components/utils'
import PlatformRecord from 'Records/platformRecord'
import cx from 'classnames'

import styles from 'Components/TitleDetail/styles.module.scss'

import { getSelectedScan, getClient, isTitleAuditLoading, getTerritoryById } from 'Selectors'
import PlatformsPanel from 'Components/TitleDetail/PlatformsPanel'
import ActionsPanel from 'Components/TitleDetail/ActionsPanel'
import MerchPanel from 'Components/TitleDetail/MerchPanel'
import OptimizationScorePanel from 'Components/TitleDetail/OptimizationScorePanel'

import Artwork from 'Components/TitleDetail/Artwork'
import { retrieveTitleAudit } from 'Actions/titleAuditActions'

class TitleDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTabId: 'platforms',
    }
  }

  componentDidMount() {
    const { retrieveTitleAudit } = this.props
    retrieveTitleAudit()
  }

  handleClose = () => {
    this.props.history.goBack()
  }

  handleTabChange = selectedTabId => {
    this.setState({
      selectedTabId,
    })
  }
  render() {
    const {
      title,
      titleAudit,
      platforms,
      territories,
      scan,
      client,
      actions,
      merchandizingMatches,
      isTitleAuditLoading,
      merchandizingPlatforms,
      merchandizingTerritories,
    } = this.props

    const { selectedTabId } = this.state

    const { primaryArtworkUrl } = titleAudit

    return (
      <Dialog isOpen onClose={this.handleClose} className={cx(styles.dialog, 'bp5-focus-disabled')}>
        <div className={cx(Classes.DIALOG_BODY, styles.dialogBody)}>
          <div className={styles.body}>
            <div className={styles.headerWrapper}>
              <div className={styles.header}>
                <div className={styles.dialogHeader}>
                  <h3 className={styles.title}>{title.name}</h3>
                  {title && <div className={styles.titleType}>{title.titleType}</div>}
                </div>
                <div className={styles.tabs}>
                  <Tabs id="Tabs2Example" onChange={this.handleTabChange} selectedTabId={selectedTabId}>
                    <Tab id="platforms" title="Platforms" />
                    <Tab id="actions" title="Actions" />
                    {scan.enableMerchandizing && <Tab id="merch" title="Merchandising" />}
                    <Tab id="optimizationScore" title="Optimization Score" />
                  </Tabs>
                </div>
              </div>
              {primaryArtworkUrl && (
                <div className={styles.artworkContainer}>
                  <Artwork className={styles.artwork} width={120} height={120} artworkUrl={primaryArtworkUrl} />
                </div>
              )}
            </div>
            <div className={styles.tableContainer}>
              {selectedTabId === 'platforms' && <PlatformsPanel titleAudit={titleAudit} platforms={platforms} />}
              {selectedTabId === 'actions' && (
                <ActionsPanel titleAudit={titleAudit} actions={actions} client={client} />
              )}
              {selectedTabId === 'merch' && platforms && merchandizingTerritories && (
                <MerchPanel
                  titleAudit={titleAudit}
                  platforms={merchandizingPlatforms}
                  territories={merchandizingTerritories}
                  merchandizingMatches={merchandizingMatches}
                  isTitleAuditLoading={isTitleAuditLoading}
                  scan={scan}
                />
              )}
              {selectedTabId === 'optimizationScore' && (
                <OptimizationScorePanel territories={territories} titleAudit={titleAudit} />
              )}
            </div>
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button className="bp5-intent-primary" onClick={this.handleClose}>
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { titleAudit } = ownProps

  const scan = getSelectedScan(state)
  const client = getClient(state)
  const platforms = (scan && scan.platforms(state)) || new List()
  const platformsWithIsoCode = platforms.map(pl => {
    const territory = getTerritoryById(state, { id: pl.territoryId });
    return new PlatformRecord({
      ...pl.toJS(),
      isoCode: territory ? territory.isoCode : null,
    });
  })

  const territories = (scan && scan.territories(state)) || new List()
  let merchandizingMatches = titleAudit.getMerchandizingMatches(state)
  const associatedMerchandizingScans = scan && scan.associatedMerchandizingScans(state)
  const merchandizingPlatforms = platforms.filter(platform =>
    associatedMerchandizingScans.find(ams => ams.platformId.toString() === platform.id.toString()),
  )
  const merchandizingTerritories = territories.filter(territory =>
    merchandizingPlatforms.map(mp => mp.territoryId).includes(territory.id),
  )

  merchandizingMatches = merchandizingMatches.sort((a, b) => {
    if (a.pageId === b.pageId) {
      const aSectionPosition = a.section(state).position
      const bSectionPosition = b.section(state).position
      if (aSectionPosition > bSectionPosition) return 1
      return -1
    }
    return 1
  })

  const isTitleLoading = isTitleAuditLoading(state)

  const actionsWithRegions = titleAudit.getActions(state).map(action => {
    const platformTitle = action && action.platformTitle(state)
    const platform = platformTitle && platformTitle.platform(state)
    const territory = platform && getTerritoryById(state, { id: platform.territoryId })
    return { ...action.toJS(), platformName: platform?.name, isoCode: territory?.isoCode }
  })

  return {
    optimizationScores: titleAudit.optimizationScores(state),
    platforms: sortPlatformsByRegionAndName(platformsWithIsoCode),
    territories,
    scan,
    merchandizingMatches,
    actions: sortPlatformsByRegionAndName(actionsWithRegions),
    client,
    isTitleAuditLoading: isTitleLoading,
    merchandizingPlatforms,
    merchandizingTerritories,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { titleAudit, competitors } = ownProps

  let includes =
    'optimizationScores,auditActions,auditActions.platformTitle.platform,audits.platformTitle,merchandizingMatches,merchandizingMatches.section'

  if (competitors) {
    includes = 'optimizationScores,audits.platformTitle.platform,merchandizingMatches,merchandizingMatches.section'
  }

  return {
    retrieveTitleAudit: () => dispatch(retrieveTitleAudit(titleAudit, includes)),
  }
}

const ConnectedTitleDetail = connect(mapStateToProps, mapDispatchToProps)(TitleDetail)
const ConnectedTitleDetailWithRouter = withRouter(ConnectedTitleDetail)
export default ConnectedTitleDetailWithRouter