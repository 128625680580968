import { initialState, ASSOCIATED_MERCHANDIZING_SCANS } from './utils.js'

export const LOAD_ASSOCIATED_MERCHANDIZING_SCANS = 'associatedMerchandizingScans/LOAD'

export default function associatedMerchandizingScansReducer(state, action) {
  switch (action.type) {
    case LOAD_ASSOCIATED_MERCHANDIZING_SCANS: {
      return state.withMutations(s => {
        s.mergeIn([ASSOCIATED_MERCHANDIZING_SCANS], action.associatedMerchandizingScans)
      })
    }
    default:
      return state || initialState
  }
}
