import React from 'react'
import style from './Item.module.scss'
import cx from 'classnames'
import { ViaplaySectionTypes } from '../enums'
import { Spot } from 'Interfaces/interfaces'
const imdbLogo = require('./imdb.png')

type PortraitDetailsProps = {
  spot: Spot
  imdbRating: string
  year: string | number
  duration: string
  season: string
  sectionType: ViaplaySectionTypes
}

const PortraitDetails: React.FC<PortraitDetailsProps> = ({ spot, sectionType, imdbRating, year, duration, season }) => (
  <span
    className={cx(style.portritDetails, {
      [style.portraitGridDetails]: sectionType === ViaplaySectionTypes._PortraitGrid,
    })}
  >
    <span className={style.count}>{spot.position}</span>
    <span className={style.description}>
      <h5>{spot.name}</h5>
      <span>
        <span className={style.imdbDetails}>
          {!!imdbRating && (
            <>
              <img src={imdbLogo} alt="imdb" /> <span>{imdbRating}</span>
            </>
          )}
          {!!year && <span> | {year}</span>}
          {!!season && <span> | {season}</span>}
          {!!duration && <span> | {duration}</span>}
        </span>
      </span>
    </span>
  </span>
)

export default PortraitDetails
