import React from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'

const AlphabetMenuItem = ({ item, onClick, isSelected, disabled }) => {
  const menuClassnames = cx(`bp5--menu-item ${styles.ptMenuItemLetter}`, {
    'bp5-icon-small-tick': isSelected,
    [styles.disabled]: disabled,
  })

  return (
    <li>
      <a className={menuClassnames} onClick={onClick}>
        {item}
      </a>
    </li>
  )
}

const convertToLetter = index => {
  return String.fromCharCode(65 + index)
}

const convertToNumber = index => {
  return (index - 26).toString()
}

const LeterMultiSelectList = ({ selectedFilter, setLetterFilter }) => {
  const letters = Array.from({ length: 36 }, (_, i) => {
    if (i < 26) {
      return convertToLetter(i)
    } else {
      return convertToNumber(i)
    }
  })

  const handleItemClick = letter => {
    if (selectedFilter.includes(letter)) {
      setLetterFilter('')
    } else {
      setLetterFilter(letter)
    }
  }

  return (
    <ul className="bp5-menu">
      {letters.map((letter, index) => (
        <AlphabetMenuItem
          key={index}
          item={letter}
          isSelected={selectedFilter.includes(letter)}
          onClick={() => handleItemClick(letter)}
          disabled={false}
        />
      ))}
    </ul>
  )
}

export default LeterMultiSelectList
