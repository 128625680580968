import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Tooltip, Button, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'

import Media from 'react-media'
import { humanize } from '../utils'
import styles from './Toolbar.module.scss'
import cx from 'classnames'
import FormatFilter from './Toolbar/FormatFilter'
import LicenseFilter from './Toolbar/LicenseFilter'
import TitleTypeFilter from './Toolbar/TitleTypeFilter'
import ActionFilter from './Toolbar/ActionFilter'
import ViewSwitcher from './Toolbar/ViewSwitcher'
import TagFilter from './Toolbar/TagFilter'
import Search from './Toolbar/Search'

import PlatformAndTerritoryFilter from 'Components/PlatformAndTerritoryFilter'
import TitleSort from 'Components/TitleSort'
import useToolbarActions from './useToolbarActions'
import ToolbarActions from './ToolbarActions'
import { Client as ClientType } from 'Records/clientRecord'
import { Scan } from 'Interfaces/*'

interface ToolbarProps {
  client: ClientType
  scan: Scan
  scanStats: boolean
  scanView: 'list' | 'grid'
  filter: any
  correctedAuditActions?: any
  titlesForCorrection?: any
  titlesForCorrectionLoading?: boolean
  competitors?: boolean
  hideScanView?: boolean
  hideUnitSales?: boolean
}

const Toolbar = ({
  client,
  scan,
  scanStats,
  scanView,
  filter,
  correctedAuditActions,
  titlesForCorrection,
  titlesForCorrectionLoading,
  competitors,
  hideScanView,
  hideUnitSales,
}: ToolbarProps & RouteComponentProps) => {
  const {
    toggleAllActionsFilter,
    togglePriceActionsFilter,
    toggleHoldbackActionsFilter,
    toggleAvailActionsFilter,
    toggleFormatAll,
    toggleFormatHD,
    toggleFormatSD,
    toggleLicenseTypeAll,
    toggleLicenseTypeBuy,
    toggleLicenseTypeRent,
    clearTitleQuery,
    updateTitleQuery,
    toggleMovieFilter,
    toggleSeriesFilter,
    toggleAllFilter,
    toggleAuditExceptionFilter,
    toggleSubmittedAuditActionsFilter,
    toggleReadyAuditActionsFilter,
    toggleAllAuditActionsFilter,
    reloadScan,
    enablePriorityFilter,
    setTitleAuditsSortParams,
    disablePromotedFilter,
    enablePromotedFilter,
    showScanAsGrid,
    showScanAsList,
  } = useToolbarActions({ competitors })

  if (!scan) return null

  let titleAuditsSortColumnLabel = filter && humanize(filter.titleAuditsSortColumn)

  titleAuditsSortColumnLabel =
    titleAuditsSortColumnLabel === 'Production Year' ? 'Launch Date' : titleAuditsSortColumnLabel

  const sortingOptions = {}
  client &&
    client.sortOptions &&
    Object.keys(client.sortOptions).forEach(sortColumn => {
      if (client.sortOptions[sortColumn] && client.sortOptions[sortColumn]['enabled']) {
        let listItemName = humanize(sortColumn)
        listItemName = listItemName === 'Production Year' ? 'Launch Date' : listItemName
        sortingOptions[listItemName] = sortColumn
      }
    })

  return (
    <div>
      <Media query="(max-width: 1439px)">
        <div>
          <nav className={cx(styles.header, 'bp5-navbar')}>
            <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
              {!hideScanView && [
                <ViewSwitcher
                  key="0"
                  scanView={scanView}
                  showScanAsGrid={showScanAsGrid}
                  showScanAsList={showScanAsList}
                />,
                <div className={cx(styles.divider, 'bp5-navbar-divider')} key="1" />,
              ]}
              <Search
                filter={filter}
                reloadScan={reloadScan}
                clearTitleQuery={clearTitleQuery}
                updateTitleQuery={updateTitleQuery}
              />
            </div>
            <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
              <div className={cx(styles.divider, 'bp5-navbar-divider')} />

              <Popover
                interactionKind={PopoverInteractionKind.CLICK}
                position={Position.BOTTOM_RIGHT}
                content={<PlatformAndTerritoryFilter newUI competitors={competitors} withTabs />}>
                <Tooltip content="Territory/Platform Filter" position={Position.BOTTOM_LEFT}>
                  <Button className="bp5-icon-filter" />
                </Tooltip>
              </Popover>
              {!hideUnitSales && (
                <div className="bp5-navbar-group">
                  <div className={cx(styles.divider, 'bp5-navbar-divider')} />

                  <Popover
                    interactionKind={PopoverInteractionKind.CLICK}
                    position={Position.BOTTOM_RIGHT}
                    content={<TitleSort
                      sortingOptions={sortingOptions}
                      filter={filter}
                      setTitleAuditsSortParams={setTitleAuditsSortParams}
                    />}
                  >
                    <Tooltip content="Sort" position={Position.BOTTOM_LEFT}>
                      <Button className="bp5-icon-sort">{titleAuditsSortColumnLabel}</Button>
                    </Tooltip>
                  </Popover>
                </div>
              )}

              {client && client.tagsEnabled && (
                <div className={cx('bp5-navbar-group bp5-align-left', styles.tagFilter)}>
                  <TagFilter />
                </div>
              )}
            </div>

            <ToolbarActions
              scan={scan}
              scanStats={scanStats}
              competitors={competitors}
              titlesForCorrection={titlesForCorrection}
            />
          </nav>
          <nav className={cx(styles.header, 'bp5-navbar', styles.staticHeader)}>
            {
              // TODO: p1 — Implement a proper fix for hiding films/tv buttons per client.
              /* <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
                            <TitleTypeFilter/>
                          </div> */
            }

            <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
              <ActionFilter
                correctedAuditActions={correctedAuditActions}
                filter={filter}
                scan={scan}
                client={client}
                toggleAllActionsFilter={toggleAllActionsFilter}
                togglePriceActionsFilter={togglePriceActionsFilter}
                toggleHoldbackActionsFilter={toggleHoldbackActionsFilter}
                toggleAvailActionsFilter={toggleAvailActionsFilter}
                toggleAuditExceptionFilter={toggleAuditExceptionFilter}
                toggleSubmittedAuditActionsFilter={toggleSubmittedAuditActionsFilter}
                toggleReadyAuditActionsFilter={toggleReadyAuditActionsFilter}
                toggleAllAuditActionsFilter={toggleAllAuditActionsFilter}
                titlesForCorrection={titlesForCorrection}
                titlesForCorrectionLoading={titlesForCorrectionLoading}
              />
            </div>
            {scanView === 'list' && (
              <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
                <div className={cx(styles.divider, 'bp5-navbar-divider')} />
                <LicenseFilter
                  filter={filter}
                  toggleLicenseTypeAll={toggleLicenseTypeAll}
                  toggleLicenseTypeBuy={toggleLicenseTypeBuy}
                  toggleLicenseTypeRent={toggleLicenseTypeRent}
                />
              </div>
            )}
            {scanView === 'list' && (
              <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
                <div className={cx(styles.divider, 'bp5-navbar-divider')} />
                <FormatFilter
                  filter={filter}
                  toggleFormatAll={toggleFormatAll}
                  toggleFormatHD={toggleFormatHD}
                  toggleFormatSD={toggleFormatSD}
                />
              </div>
            )}
            {scan.enableMerchandizing && scanView === 'grid' && (
              <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
                <div className={cx(styles.divider, 'bp5-navbar-divider')} />
                <div className="bp5-button-group">
                  <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: !filter.promoted })} onClick={disablePromotedFilter}>
                    All
                  </button>
                  <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.promoted })} onClick={enablePromotedFilter}>
                    Featured
                  </button>
                  {client && client.tagsEnabled && (
                    <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.priority })} onClick={enablePriorityFilter}>
                      Priority
                    </button>
                  )}
                </div>
              </div>
            )}
          </nav>
        </div>
      </Media>
      <Media query="(min-width: 1440px)">
        <nav className={cx(styles.header, 'bp5-navbar')}>
          <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
            {!hideScanView && [
              <ViewSwitcher
                key="0"
                scanView={scanView}
                showScanAsGrid={showScanAsGrid}
                showScanAsList={showScanAsList}
              />,
              <div className={cx(styles.divider, 'bp5-navbar-divider')} key="1" />,
            ]}
            {client &&
              client.titleTypeBoth && [
                <TitleTypeFilter
                  key="0"
                  filter={filter}
                  toggleMovieFilter={toggleMovieFilter}
                  toggleSeriesFilter={toggleSeriesFilter}
                  toggleAllFilter={toggleAllFilter}
                />,
                <div className={cx(styles.divider, 'bp5-navbar-divider')} key="1" />,
              ]}
            <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
              <ActionFilter
                correctedAuditActions={correctedAuditActions}
                filter={filter}
                client={client}
                scan={scan}
                toggleAllActionsFilter={toggleAllActionsFilter}
                togglePriceActionsFilter={togglePriceActionsFilter}
                toggleHoldbackActionsFilter={toggleHoldbackActionsFilter}
                toggleAvailActionsFilter={toggleAvailActionsFilter}
                toggleAuditExceptionFilter={toggleAuditExceptionFilter}
                toggleSubmittedAuditActionsFilter={toggleSubmittedAuditActionsFilter}
                toggleReadyAuditActionsFilter={toggleReadyAuditActionsFilter}
                toggleAllAuditActionsFilter={toggleAllAuditActionsFilter}
                titlesForCorrection={titlesForCorrection}
                titlesForCorrectionLoading={titlesForCorrectionLoading}
              />
            </div>

            {scanView === 'list' && (
              <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
                <div className={cx(styles.divider, 'bp5-navbar-divider')} />
                <LicenseFilter
                  filter={filter}
                  toggleLicenseTypeAll={toggleLicenseTypeAll}
                  toggleLicenseTypeBuy={toggleLicenseTypeBuy}
                  toggleLicenseTypeRent={toggleLicenseTypeRent}
                />
              </div>
            )}
            {scanView === 'list' && (
              <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
                <div className={cx(styles.divider, 'bp5-navbar-divider')} />
                <FormatFilter
                  filter={filter}
                  toggleFormatAll={toggleFormatAll}
                  toggleFormatHD={toggleFormatHD}
                  toggleFormatSD={toggleFormatSD}
                />
              </div>
            )}

            {scan.enableMerchandizing && scanView === 'grid' && (
              <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
                <div className={cx(styles.divider, 'bp5-navbar-divider')} />
                <div className="bp5-button-group">
                  <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: !filter.promoted && !filter.priority })} onClick={disablePromotedFilter}>
                    All
                  </button>
                  <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.promoted })} onClick={enablePromotedFilter}>
                    Featured
                  </button>
                  {client && client.tagsEnabled && (
                    <button className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.priority })} onClick={enablePriorityFilter}>
                      Priority
                    </button>
                  )}
                </div>
              </div>
            )}

            <div className={cx(styles.divider, 'bp5-navbar-divider')} />
            <Search />
          </div>
          <div className={cx(styles.customNavbarGroup, 'bp5-navbar-group bp5-align-left')}>
            <div className={cx(styles.divider, 'bp5-navbar-divider')} />

            <Popover
              interactionKind={PopoverInteractionKind.CLICK}
              position={Position.BOTTOM_RIGHT}
              content={<PlatformAndTerritoryFilter newUI competitors={competitors} withTabs />}>
              <Tooltip content="Territory/Platform Filter" position={Position.BOTTOM_LEFT}>
                <Button className={cx('bp5-button', 'bp5-icon-filter', styles.btnDefault)} />
              </Tooltip>
            </Popover>
            {!hideUnitSales && (
              <div className="bp5-navbar-group">
                <div className={cx(styles.divider, 'bp5-navbar-divider')} />

                <Popover
                  interactionKind={PopoverInteractionKind.CLICK}
                  position={Position.BOTTOM_RIGHT}
                  content={<TitleSort
                    sortingOptions={sortingOptions}
                    filter={filter}
                    setTitleAuditsSortParams={setTitleAuditsSortParams}
                  />}>
                  <Tooltip content="Sort" position={Position.BOTTOM_LEFT}>
                    <Button className={cx('bp5-button', 'bp5-icon-sort', styles.btnDefault)}>{titleAuditsSortColumnLabel}</Button>
                  </Tooltip>
                </Popover>
              </div>
            )}
          </div>

          {client && client.tagsEnabled && (
            <div className={cx('bp5-navbar-group bp5-align-left', styles.tagFilter)}>
              <TagFilter />
            </div>
          )}

          <ToolbarActions
            scan={scan}
            scanStats={scanStats}
            competitors={competitors}
            titlesForCorrection={titlesForCorrection}
          />
        </nav>
      </Media>
    </div>
  )
}

export default withRouter(Toolbar)
