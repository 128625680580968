import React from 'react'
import style from './UnsupportedPage.module.scss'

function UnsupportedPage() {
  return (
    <div className={style.page}>
      <h3>This platform is not currently supported</h3>
    </div>
  )
}

export default UnsupportedPage
