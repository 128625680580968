import { OrderedMap } from 'immutable'
import PlatformTitleRecord from 'Records/platformTitleRecord'
import { normalizeAndLoadChannels } from 'Actions/channelActions'
import { normalizeAndLoadLocationCandidates } from 'Actions/locationCandidateActions'
import { normalizeAndLoadTaggings } from './taggingActions'
import { apiRequest, readEndpoint } from './apiActions'

export const normalizeAndLoadPlatformTitles = (data, reset) => dispatch => {
  const platformTitles = data.reduce((memo, item) => {
    if (item.taggings) {
      dispatch(normalizeAndLoadTaggings(item.taggings))
      item.taggingsIds = item.taggings.map(t => t.id)
    }

    if (item.channels) {
      dispatch(normalizeAndLoadChannels(item.channels, reset))
      item.channelsIds = item.channels.map(c => c.id)
    }

    return memo.set(item.id, new PlatformTitleRecord(item))
  }, new OrderedMap())

  return dispatch(loadPlatformTitles(platformTitles, reset))
}

export const loadingPlatformTitles = clientId => ({
  type: 'LOADING_PLATFORM_TITLES',
  clientId,
})

export const loadPlatformTitles = (platformTitles, reset) => ({
  type: 'LOAD_PLATFORM_TITLES',
  platformTitles,
  reset,
})

export const mergePlatformTitles = platformTitles => ({
  type: 'MERGE_PLATFORM_TITLES',
  platformTitles,
})

export const selectPlatformTitle = platformTitleId => ({
  type: 'SELECT_PLATFORM_TITLE',
  platformTitleId,
})

export const deselectPlatformTitles = () => ({
  type: 'DESELECT_PLATFORM_TITLES',
})

export const removePlatformTitle = platformTitleId => ({
  type: 'REMOVE_PLATFORM_TITLE',
  platformTitleId,
})

export const editPlatformTitle = platformTitleId => ({
  type: 'EDIT_PLATFORM_TITLE',
  platformTitleId,
})

export const cancelEditPlatformTitle = platformTitleId => ({
  type: 'CANCEL_EDIT_PLATFORM_TITLE',
  platformTitleId,
})

export const updatePlatformTitleFromApi = (response, reset = false) => dispatch => {
  const { platformTitle } = response
  const { locationCandidates } = platformTitle

  const data = new PlatformTitleRecord(platformTitle)

  dispatch({
    type: 'UPDATE_PLATFORM_TITLE',
    platformTitleId: platformTitle.id,
    data,
    reset,
  })

  if (locationCandidates) dispatch(normalizeAndLoadLocationCandidates(locationCandidates, true))
}

export const retrievePlatformTitle = platformTitleId => dispatch =>
  readEndpoint(`platform_titles/${platformTitleId}`).then(response => dispatch(updatePlatformTitleFromApi(response)))

export const updatePlatformTitle = (platformTitle, attributes) => dispatch =>
  dispatch(
    apiRequest(`/platform_titles/${platformTitle.id}`, 'PUT', {
      platform_title: {
        url: attributes.url,
        platform_identifier: attributes.platformIdentifier,
        platform_cdid: attributes.platformCdid,
        vendor_identifier: attributes.vendorIdentifier,
        artwork_url: attributes.artworkUrl,
      },
    }),
  ).then(response => dispatch(updatePlatformTitleFromApi(response)))

export const updatePlatformTitleThenApprove = (platformTitle, attributes) => dispatch =>
  dispatch(updatePlatformTitle(platformTitle, attributes)).then(() => dispatch(approvePlatformTitle(platformTitle.id)))

export const approvePlatformTitle = (platformTitleId, locationCandidateId) => dispatch =>
  dispatch(
    apiRequest(`platform_titles/${platformTitleId}/approve`, 'POST', {
      location_candidate_id: locationCandidateId,
    }),
  ).then(response => dispatch(updatePlatformTitleFromApi(response)))

export const retryPlatformTitle = platformTitleId => dispatch =>
  dispatch(apiRequest(`platform_titles/${platformTitleId}/retry`, 'POST')).then(response =>
    dispatch(updatePlatformTitleFromApi(response)),
  )

export const abortPlatformTitle = platformTitleId => dispatch =>
  dispatch(apiRequest(`platform_titles/${platformTitleId}/abort`, 'POST')).then(response =>
    dispatch(updatePlatformTitleFromApi(response)),
  )

export const deleteTransitionsPlatformTitle = platformTitleId => dispatch =>
  dispatch(apiRequest(`platform_titles/${platformTitleId}/delete_transitions`, 'POST')).then(response =>
    dispatch(updatePlatformTitleFromApi(response)),
  )

export const updatePlatformTitleFromCable = payload => dispatch => {
  const response = JSON.parse(payload)

  dispatch(updatePlatformTitleFromApi(response))
}

export const markAsUnavailablePlatformTitle = platformTitleId => dispatch =>
  dispatch(apiRequest(`platform_titles/${platformTitleId}/mark_as_unavailable`, 'POST')).then(response =>
    dispatch(updatePlatformTitleFromApi(response)),
  )

export const markAsUnavailableForeverPlatformTitle = platformTitleId => dispatch =>
  dispatch(apiRequest(`platform_titles/${platformTitleId}/mark_as_unavailable_forever`, 'POST')).then(response =>
    dispatch(updatePlatformTitleFromApi(response)),
  )

export const markAsVirtualPlatformTitle = platformTitleId => dispatch =>
  dispatch(apiRequest(`platform_titles/${platformTitleId}/mark_as_virtual`, 'POST')).then(response =>
    dispatch(updatePlatformTitleFromApi(response)),
  )

export const platformTitlesUpdatingState = updating => ({
  type: 'UPDATING_STATES',
  updating,
})