import React from 'react'
import cx from 'classnames'
import { Card } from '@blueprintjs/core'
import AmCharts from '@amcharts/amcharts3-react'

import BigNumber from 'Components/BigNumber'
import { ScanSummary } from './interfaces'

import styles from './styles.module.scss'

type ScoreStatProps = {
  scanSummary: ScanSummary
}

const ScoreStat: React.FC<ScoreStatProps> = ({ scanSummary }) => {
  const percentage = scanSummary.optimizationScore

  if (!scanSummary) {
    return <div />
  }

  return (
    <div className={styles.gridItem}>
      <Card className={styles.card}>
        <h5 className={styles.header}>Optimisation Overview</h5>
        <div className={styles.scoreStatContainer}>
          <div className={styles.detailWrapper}>
            <div className={styles.cardHeading}>
              <h4 className={styles.cardSubHeading}>Total Actions</h4>
            </div>
            <div className={styles.percentageWrapper}>
              <BigNumber
                number={parseFloat(scanSummary.optimizationScore)}
                units="%"
                headingLevel={1}
                decimalPlaces={2}
                numberClassname={styles.optimizationScore}
              />
              <span
                className={cx({
                  [styles.changeAmount]: parseInt(scanSummary.changeAmount) === 0,
                  [styles.changeAmountUp]: parseInt(scanSummary.changeAmount) > 0,
                  [styles.changeAmountDown]: parseInt(scanSummary.changeAmount) < 0,
                })}
              >{`${scanSummary.changeAmount}%`}</span>
            </div>
          </div>
          <div className={styles.chartWrapper}>
            <AmCharts.React
              style={{
                width: '100%',
                height: '100%',
              }}
              options={{
                type: 'pie',
                theme: 'light',
                innerRadius: '65%',
                dataProvider: [
                  {
                    category: 'Filled',
                    value: percentage,
                    color: '#32A467',
                  },
                  {
                    category: 'Remaining',
                    value: (100 - Number(percentage)).toFixed(2),
                    color: '#DDDDDD',
                  },
                ],
                valueField: 'value',
                titleField: 'category',
                colorField: 'color',
                labelsEnabled: false,
                autoMargins: false,
                marginTop: 10,
                marginBottom: 10,
                marginLeft: 10,
                marginRight: 10,
                pullOutRadius: 0,
                startDuration: 0,
                balloon: {
                  enabled: false,
                  fixedPosition: true,
                },
                creditsPosition: 'top-right',
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default ScoreStat
