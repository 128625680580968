import React from 'react'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import pathToRegexp from 'path-to-regexp'
import styles from './Menu18.module.scss'
import GenericSection from './GenericSection'
import MenuLink from './MenuLink'

class Menu18 extends GenericSection {
  constructor(props) {
    super(props)

    const { section } = this.props
    const { metadata } = section
    this.metadata = JSON.parse(metadata)
  }

  pagePath(pageId) {
    const { match } = this.props
    const { path } = match
    const toPath = pathToRegexp.compile(path)
    return toPath({ ...match.params, pageId: pageId })
  }

  render() {
    const { section, spotsWithLinkedPages } = this.props

    return (
      <div className={cx(styles.root)}>
        <h4 className={styles.header}>{section.name}</h4>
        <ol className={styles.list}>
          {spotsWithLinkedPages.map(s => {
            return s && <MenuLink key={s.id} path={this.pagePath(s.linkedPageId)} spot={s} section={section} />
          })}
        </ol>
      </div>
    )
  }
}

const ConnectedMenu18Router = withRouter(Menu18)
export default ConnectedMenu18Router
