import { MERCHANDIZING, MATCHES, DATA, META } from '../utils'

export const LOAD_MERCHANDIZING_MATCHES = 'merchandizing/matches/LOAD'
export const LOADING_MERCHANDIZING_MATCHES = 'merchandizing/matches/LOADING'

export default function merchandizingMatch(state, action) {
  switch (action.type) {
    case LOAD_MERCHANDIZING_MATCHES: {
      return state.withMutations(s => {
        const { matches } = action

        action.reset
          ? s.setIn([MERCHANDIZING, MATCHES, DATA], matches)
          : s.mergeIn([MERCHANDIZING, MATCHES, DATA], matches)
      })
    }
    case LOADING_MERCHANDIZING_MATCHES: {
      return state.withMutations(s => {
        const { isLoading } = action
        s.setIn([MERCHANDIZING, MATCHES, META], { loading: isLoading })
      })
    }

    default:
      return state
  }
}
