import React, { useState } from 'react'
import { MultiSelect, Select } from '@blueprintjs/select'
import { MenuItem, Button } from '@blueprintjs/core'
import {
  COMPETITIOR_PERFORMANCE,
  PLATFORM_PERFORMANCE,
  SELECT_REGION,
  SELECT_PLATFORM,
  SELECT_METRIC,
  SELECT_DISTRIBUTORS
} from './constants'
import styles from './Toolbar.module.scss'
import { Scan, Platform, } from 'Interfaces/*'
import { TerritoryRecordType } from 'Records/territoryRecord'
import { Client } from 'Records/clientRecord'
import cx from 'classnames'
import { List } from 'immutable'

type Props = {
  scan: Scan
  client: Client
  distributors: List<Platform>
  platforms: List<Platform>
  territories: List<TerritoryRecordType>
  performancePage: string
  handleSetPerformancePage: (page: string) => void
}

const Toolbar: React.FC<Props> = ({
  scan,
  client,
  distributors,
  platforms,
  territories,
  performancePage,
  handleSetPerformancePage
}) => {
  const [selectedDistributors, setSelectedDistributors] = useState<Platform[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<TerritoryRecordType>();
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>();

  const handleDistributorSelect = (item: Platform) => {
    setSelectedDistributors((prevSelected) => {
      const isSelected = prevSelected.includes(item);
      return isSelected
        ? prevSelected.filter(i => i !== item)
        : [...prevSelected, item];
    });
  };

  const handleTagRemove = (item: string) => {
    setSelectedDistributors((prevSelected) => prevSelected.filter(i => i.name !== item));
  };

  const multiSelectItem = (item: Platform, { handleClick }: { handleClick: (event: React.MouseEvent<HTMLElement>) => void }) => (
    <MenuItem
      key={item.id}
      text={item.name}
      onClick={handleClick}
      active={selectedDistributors.some(i => i.id === item.id)}
    />
  );

  return (
    <nav className={cx(styles.header, 'bp5-navbar')}>
      <div className={cx('bp5-navbar-group bp5-align-left', styles.toolbarGroup)}>
        <div className="bp5-button-group">
          <button
            className={cx('bp5-button', styles.btnDefault, { [styles.active]: performancePage === COMPETITIOR_PERFORMANCE })}
            onClick={() => handleSetPerformancePage(COMPETITIOR_PERFORMANCE)}
          >
            {COMPETITIOR_PERFORMANCE}
          </button>
          <button
            className={cx('bp5-button', styles.btnDefault, { [styles.active]: performancePage === PLATFORM_PERFORMANCE })}
            onClick={() => handleSetPerformancePage(PLATFORM_PERFORMANCE)}
          >
            {PLATFORM_PERFORMANCE}
          </button>
        </div>
      </div>
      <div className={cx('bp5-navbar-group bp5-align-right', styles.toolbarGroup)}>
        <div className="bp5-button-group">
          <MultiSelect
            items={distributors.toArray()}
            selectedItems={selectedDistributors}
            onItemSelect={handleDistributorSelect}
            itemRenderer={multiSelectItem}
            tagRenderer={(item: Platform) => item.name}
            tagInputProps={{
              onRemove: (value: string) => handleTagRemove(value),
              className: styles.tagWrapper,
              autoResize: true,
              tagProps: {
                minimal: true,
                className: styles.tag
              }
            }}
            placeholder={SELECT_DISTRIBUTORS}
          />
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className="bp5-button-group">
          <Select
            items={territories.toArray()}
            itemRenderer={(item: TerritoryRecordType, { handleClick }) => (
              <MenuItem
                key={item.id}
                text={item.name}
                onClick={handleClick}
              />
            )}
            onItemSelect={(item: TerritoryRecordType) => setSelectedRegion(item)}
            filterable={false}
          >
            <Button text={selectedRegion ? selectedRegion.name : SELECT_REGION} rightIcon="caret-down" />
          </Select>
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className="bp5-button-group">
          <Select
            items={platforms.toArray()}
            itemRenderer={(item: Platform, { handleClick }) => (
              <MenuItem
                key={item.id}
                text={item.name}
                onClick={handleClick}
              />
            )}
            onItemSelect={(item: Platform) => setSelectedPlatform(item)}
            filterable={false}
          >
            <Button text={selectedPlatform ? selectedPlatform.name : SELECT_PLATFORM} rightIcon="caret-down" />
          </Select>
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} />
        <div className="bp5-button-group">
          <Select
            items={platforms.toArray()}
            itemRenderer={(item: Platform, { handleClick }) => (
              <MenuItem
                key={item.id}
                text={item.name}
                onClick={handleClick}
              />
            )}
            onItemSelect={(item: Platform) => setSelectedPlatform(item)}
            filterable={false}
          >
            <Button text={selectedPlatform ? selectedPlatform.name : SELECT_METRIC} rightIcon="caret-down" />
          </Select>
        </div>
      </div>
    </nav>
  )
}

export default Toolbar
