import { Record } from 'immutable'
import { getSelectedTerritories, getSelectedPlatforms, getSelectedTags } from 'Selectors'

export default class ScanFilterRecord extends Record({
  all: true,
  movies: false,
  tv: false,
  allActions: true,
  priceActions: false,
  holdbackActions: false,
  availActions: false,
  auditActionsStatus: [],
  auditExceptions: false,
  actionListSortColumn: 'platform_titles.titles.name',
  actionListSortDirection: 'asc',
  titleAuditsSortColumn: 'platform_titles.titles.name',
  titleAuditsSortDirection: 'asc',
  licenseTypeAll: true,
  licenseTypeRent: false,
  licenseTypeBuy: false,
  formatAll: true,
  formatHD: false,
  formatSD: false,
  changedArtwork: false,
  blankArtwork: false,
  promoted: false,
  priority: false,
  query: '',
  lastQuery: '',
  active: false,
  includes: null,
  titleIds: [],
}) {
  setIncludes(includes) {
    return this.withMutations(s => (s.includes = includes))
  }

  actionTypeFilter() {
    return [
      this.allActions &&
      'AuditHoldbackAction,AuditPriceAction,AuditAvailAction,AuditPriceParityAction,AuditPriceOutlierAction,AuditArtworkUberAction',
      this.holdbackActions && 'AuditHoldbackAction',
      this.priceActions && 'AuditPriceAction,AuditPriceParityAction,AuditPriceOutlierAction',
      this.availActions && 'AuditAvailAction',
    ].filter(x => x)[0]
  }

  toParams(state) {
    return {
      'filter[type]': [this.all && 'movie,tv_season', this.movies && 'movie', this.tv && 'tv_season'].filter(x => x),
      'filter[actionType]': this.actionTypeFilter(),
      'filter[licenseType]': [
        this.licenseTypeAll && '',
        this.licenseTypeRent && 'rent',
        this.licenseTypeBuy && 'buy',
      ].filter(x => x),
      'filter[format]': [this.formatAll && '', this.formatHD && 'hd', this.formatSD && 'sd'].filter(x => x),
      'filter[query]': this.query,
      'filter[platforms]': getSelectedPlatforms(state)
        .valueSeq()
        .map(p => p.id)
        .toArray(),
      'filter[territories]': getSelectedTerritories(state)
        .valueSeq()
        .map(t => t.id)
        .toArray(),
      'filter[artworkChanged]': this.changedArtwork,
    }
  }

  toUrlParams() {
    return {
      type: [this.all && 'movie,tv_season', this.movies && 'movie', this.tv && 'tv_season'].filter(x => x).join(','),
      actionType: this.actionTypeFilter(),
      licenseType: [this.licenseTypeAll && '', this.licenseTypeRent && 'rent', this.licenseTypeBuy && 'buy']
        .filter(x => x)
        .join(','),
      format: [this.formatAll && '', this.formatHD && 'hd', this.formatSD && 'sd'].filter(x => x).join(','),
      changedArtwork: this.changedArtwork,
      blankArtwork: this.blankArtwork,
      auditExceptions: this.auditExceptions,
      promoted: this.promoted,
      priority: this.priority,
      sort: this.titleAuditSort(),
      titleIds: this.titleIds?.join(','),
    }
  }

  getAuditDownloadParams(state) {
    return {
      type: [this.all && 'movie,tv_season', this.movies && 'movie', this.tv && 'tv_season'].filter(x => x),
      tags: getSelectedTags(state)
        .valueSeq()
        .map(t => t.id)
        .toArray(),
      promoted: this.promoted,
      priority: this.priority,
      titles: this.titleIds,
      action_type: [
        this.allActions &&
        'AuditHoldbackAction,AuditPriceAction,AuditAvailAction,AuditPriceParityAction,AuditPriceOutlierAction,AuditAvodAction',
        this.holdbackActions && 'AuditHoldbackAction',
        this.priceActions && 'AuditPriceAction,AuditPriceParityAction,AuditPriceOutlierAction',
        this.availActions && 'AuditAvailAction',
      ].filter(x => x),
      audit_exceptions: this.auditExceptions,
    }
  }

  getArtworkDownloadParams(state) {
    return {
      type: [this.all && 'movie,tv_season', this.movies && 'movie', this.tv && 'tv_season'].filter(x => x),
      tags: getSelectedTags(state)
        .valueSeq()
        .map(t => t.id)
        .toArray(),
      promoted: this.promoted,
      priority: this.priority,
      titles: this.titleIds,
      changed: this.changedArtwork,
      blank: this.blankArtwork,
    }
  }

  setFromUrlParams(filter) {
    const {
      type,
      actionType,
      auditExceptions,
      licenseType,
      format,
      changedArtwork,
      blankArtwork,
      promoted,
      priority,
      sort,
      titleIds,
    } = filter

    return this.withMutations(s => {
      s.all = type === 'movie,tv_season' || !type
      s.movies = type === 'movie'
      s.tv = type === 'tv_season'
      s.allActions =
        (actionType ===
          'AuditHoldbackAction,AuditPriceAction,AuditAvailAction,AuditPriceParityAction,AuditPriceOutlierAction,AuditArtworkUberAction' ||
          !actionType) &&
        auditExceptions !== 'true'
      s.priceActions = actionType === 'AuditPriceAction,AuditPriceParityAction,AuditPriceOutlierAction'
      s.holdbackActions = actionType === 'AuditHoldbackAction'
      s.availActions = actionType === 'AuditAvailAction'
      s.auditExceptions = auditExceptions === 'true'
      s.licenseTypeAll = licenseType === '' || !licenseType
      s.licenseTypeRent = licenseType === 'rent'
      s.licenseTypeBuy = licenseType === 'buy'
      s.formatAll = format === '' || !format
      s.formatHD = format === 'hd'
      s.formatSD = format == 'sd'
      s.changedArtwork = changedArtwork === 'true'
      s.blankArtwork = blankArtwork === 'true'
      s.promoted = promoted === 'true'
      s.priority = priority === 'true'
      s.titleAuditsSortColumn = sort ? sort.split(' ')[0] : 'platform_titles.titles.name'
      s.titleAuditsSortDirection = sort && sort.split(' ').length > 1 ? 'desc' : 'asc'
      s.titleIds = titleIds ? titleIds.split(',') : []
    })
  }

  toggleFormatAll() {
    return this.withMutations(s => {
      s.formatAll = true
      s.formatHD = false
      s.formatSD = false
    })
  }

  toggleFormatHD() {
    return this.withMutations(s => {
      s.formatAll = false
      s.formatHD = true
      s.formatSD = false
    })
  }

  toggleFormatSD() {
    return this.withMutations(s => {
      s.formatAll = false
      s.formatHD = false
      s.formatSD = true
    })
  }

  toggleLicenseTypeAll() {
    return this.withMutations(s => {
      s.licenseTypeAll = true
      s.licenseTypeRent = false
      s.licenseTypeBuy = false
    })
  }

  toggleLicenseTypeRent() {
    return this.withMutations(s => {
      s.licenseTypeAll = false
      s.licenseTypeRent = true
      s.licenseTypeBuy = false
    })
  }

  toggleLicenseTypeBuy() {
    return this.withMutations(s => {
      s.licenseTypeAll = false
      s.licenseTypeRent = false
      s.licenseTypeBuy = true
    })
  }

  sort() {
    return this.actionListSortDirection === 'asc' ? this.actionListSortColumn : `${this.actionListSortColumn} desc`
  }

  setActionListSortParams({ column, direction }) {
    return this.withMutations(s => {
      s.actionListSortColumn = column
      s.actionListSortDirection = direction
    })
  }

  titleAuditSort() {
    return this.titleAuditsSortDirection === 'asc' ? this.titleAuditsSortColumn : `${this.titleAuditsSortColumn} desc`
  }

  setTitleAuditsSortParams({ column, direction }) {
    return this.withMutations(s => {
      s.titleAuditsSortColumn = column
      s.titleAuditsSortDirection = direction
    })
  }

  enableAll() {
    return this.withMutations(s => {
      s.tv = false
      s.movies = false
      s.all = true
    })
  }

  enableMovies() {
    return this.withMutations(s => {
      s.tv = false
      s.movies = true
      s.all = false
    })
  }

  enableTv() {
    return this.withMutations(s => {
      s.tv = true
      s.movies = false
      s.all = false
    })
  }

  enableAllActions() {
    return this.withMutations(s => {
      s.allActions = true
      s.priceActions = false
      s.holdbackActions = false
      s.availActions = false
      s.auditExceptions = false
      s.auditActionsStatus = []
    })
  }

  enablePriceActions() {
    return this.withMutations(s => {
      s.allActions = false
      s.priceActions = true
      s.holdbackActions = false
      s.availActions = false
      s.auditExceptions = false
      s.auditActionsStatus = []
    })
  }

  enableHoldbackActions() {
    return this.withMutations(s => {
      s.allActions = false
      s.priceActions = false
      s.holdbackActions = true
      s.availActions = false
      s.auditExceptions = false
      s.auditActionsStatus = []
    })
  }

  enableAvailActions() {
    return this.withMutations(s => {
      s.allActions = false
      s.priceActions = false
      s.holdbackActions = false
      s.availActions = true
      s.auditExceptions = false
      s.auditActionsStatus = []
    })
  }

  enableAuditExceptions() {
    return this.withMutations(s => {
      s.allActions = false
      s.priceActions = false
      s.holdbackActions = false
      s.availActions = false
      s.auditExceptions = true
      s.auditActionsStatus = []
    })
  }

  enableReadyExportActions() {
    return this.withMutations(s => {
      s.allActions = false
      s.priceActions = false
      s.holdbackActions = false
      s.availActions = false
      s.auditExceptions = false
      s.auditActionsStatus = ['ready']
    })
  }

  enableSubmittedExportActions() {
    return this.withMutations(s => {
      s.allActions = false
      s.priceActions = false
      s.holdbackActions = false
      s.availActions = false
      s.auditExceptions = false
      s.auditActionsStatus = ['submitted']
    })
  }

  enableAllExportActions() {
    return this.withMutations(s => {
      s.allActions = false
      s.priceActions = false
      s.holdbackActions = false
      s.availActions = false
      s.auditExceptions = false
      s.auditActionsStatus = ['submitted', 'ready']
    })
  }

  reset(client) {
    const { sortOptions } = client
    let defaultSortColumn = 'platform_titles.titles.name'
    let defaultsortDirection = 'asc'

    if (sortOptions) {
      defaultSortColumn =
        Object.keys(sortOptions).find(sortColumn => sortOptions[sortColumn]['default']) || 'platform_titles.titles.name'
      defaultsortDirection = sortOptions[defaultSortColumn]['direction'] || 'asc'
    }

    return this.withMutations(s => {
      s.all = true
      s.movies = false
      s.tv = false
      s.allActions = true
      s.priceActions = false
      s.holdbackActions = false
      s.availActions = false
      s.auditActionsStatus = []
      s.auditExceptions = false
      s.actionListSortColumn = 'platform_titles.title.name'
      s.actionListSortDirection = 'asc'
      s.titleAuditsSortColumn = defaultSortColumn
      s.titleAuditsSortDirection = defaultsortDirection
      s.licenseTypeAll = true
      s.licenseTypeRent = false
      s.licenseTypeBuy = false
      s.formatAll = true
      s.formatHD = false
      s.formatSD = false
      s.changedArtwork = false
      s.blankArtwork = false
      s.promoted = false
      s.priority = false
      s.query = ''
      s.lastQuery = ''
      s.active = false
      s.includes = null
    })
  }

  updateTitleQuery(query) {
    return this.withMutations(s => {
      s.query = query
    })
  }

  setTitleIds(ids, limit) {
    return this.withMutations(s => {
      s.titleIds = ids
      s.limit = limit
    })
  }

  clearTitleQuery() {
    return this.withMutations(s => {
      s.query = ''
    })
  }

  hasActiveQuery() {
    return this.lastQuery.trim().length > 0
  }

  setLastUsedQuery(query) {
    return this.withMutations(s => {
      s.lastQuery = query
    })
  }

  enableChangedArtwork() {
    return this.withMutations(s => {
      s.changedArtwork = true
      s.blankArtwork = false
    })
  }

  enableAllArtwork() {
    return this.withMutations(s => {
      s.changedArtwork = false
      s.blankArtwork = false
    })
  }

  enableBlankArtwork() {
    return this.withMutations(s => {
      s.blankArtwork = true
      s.changedArtwork = false
    })
  }

  enablePromoted() {
    return this.withMutations(s => {
      s.promoted = true
      s.priority = false
    })
  }

  enablePriority() {
    return this.withMutations(s => {
      s.priority = true
      s.promoted = false
    })
  }

  disablePromoted() {
    return this.withMutations(s => {
      s.promoted = false
      s.priority = false
    })
  }

  hasChangedParams() {
    return (
      this.all !== true ||
      this.allActions !== true ||
      this.licenseTypeAll !== true ||
      this.formatAll !== true ||
      this.changedArtwork !== false ||
      this.blankArtwork !== false ||
      this.auditExceptions !== false ||
      this.promoted !== false ||
      this.priority !== false ||
      this.titleAuditsSortColumn !== 'platform_titles.titles.name' ||
      this.titleAuditsSortDirection !== 'asc' ||
      this.titleIds.length !== 0
    )
  }
}