import React from 'react'
import cx from 'classnames'

import styles from './styles.module.scss'

class FluidLeft extends React.Component {
  render() {
    const { children, className } = this.props
    return <div className={cx(styles.fluidLeft, className)}>{children}</div>
  }
}

export default FluidLeft
