import { getMerchandizingScanById, getScrollingSpotId } from 'Selectors'
import { readEndpoint } from 'Actions/apiActions'
import { OrderedMap, List } from 'immutable'

import PageRecord from 'Records/merchandizing/pageRecord'
import {
  LOAD_MERCHANDIZING_PAGES,
  LOADING_PAGE_DATA,
  UPDATE_MERCHANDIZING_PAGE,
} from 'Reducers/app/merchandizing/pageReducer'

import { retrieveMerchandizingMatchesForPage } from 'Actions/merchandizing/matchActions'

import {
  retrieveMerchandizingSections,
  normalizeAndLoadMerchandizingSections,
} from 'Actions/merchandizing/sectionActions'
import { findSpotOnPage, setScrollingSpot } from 'Actions/merchandizing/spotActions'

export const retrieveMerchandizingPage = (scanId, pageId) => (dispatch, getState) => {
  const state = getState()
  const scan = getMerchandizingScanById(state, { id: scanId })
  const platformsThatSupportPagination = ['amazon', 'tubi_tv', 'google_play']
  dispatch(loadingPageData(true))

  if (scan && platformsThatSupportPagination.includes(scan.platformCode)) {
    return dispatch(retrieveMerchandizingPageAndSections(pageId))
  } else {
    return dispatch(retrieveMerchandizingPageWithSections(pageId))
  }
}

// Retrieve page and sections in single request
export const retrieveMerchandizingPageWithSections = pageId => (dispatch, getState) => {
  return readEndpoint(`merchandizing/pages/${pageId}?view=with_sections`).then(response => {
    const { merchandizingPage } = response
    const sections = merchandizingPage?.sections

    dispatch(
      loadMerchandizingPages(
        [merchandizingPage].reduce((memo, item) => {
          return memo.set(
            item.id,
            new PageRecord({
              ...item,
              sectionIds: item.sections.map(s => s.id),
            }),
          )
        }, new OrderedMap()),
      ),
    )

    dispatch(normalizeAndLoadMerchandizingSections(sections, pageId, true))
    dispatch(loadingPageData(false))

    const selectedSpotId = getScrollingSpotId(getState())
    if (selectedSpotId) {
      dispatch(findSpotOnPage(selectedSpotId))
      const emptyList = new List()
      dispatch(setScrollingSpot(emptyList))
    }

    dispatch(retrieveMerchandizingMatchesForPage(pageId))
  })
}

// After retrieving page retrieve all page sections
export const retrieveMerchandizingPageAndSections = pageId => dispatch => {
  return readEndpoint(`merchandizing/pages/${pageId}`).then(response => {
    const { merchandizingPage } = response
    dispatch(
      loadMerchandizingPages(
        [merchandizingPage].reduce((memo, item) => {
          return memo.set(
            item?.id,
            new PageRecord({
              ...item,
            }),
          )
        }, new OrderedMap()),
      ),
    )
    dispatch(retrieveMerchandizingSections(pageId)).then(() => dispatch(loadingPageData(false)))
  })
}

export const normalizeAndLoadMerchandizingPages = (data, reset) => dispatch => {
  const merchandizingPages = data.reduce((memo, item) => {
    return memo.set(
      item?.id,
      new PageRecord({
        ...item,
      }),
    )
  }, new OrderedMap())

  dispatch(loadMerchandizingPages(merchandizingPages, reset))
}

export const loadMerchandizingPages = (pages, reset) => ({
  type: LOAD_MERCHANDIZING_PAGES,
  pages,
  reset,
})

export const loadingPageData = loading => ({
  type: LOADING_PAGE_DATA,
  loading,
})

export const updateMerchandizingPage = page => ({
  type: UPDATE_MERCHANDIZING_PAGE,
  page,
})
