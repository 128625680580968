import React from 'react'

import ContainerDimensions from 'react-container-dimensions'
import styles from './styles.module.scss'
import cssVariables from '../variables.scss'
import PlatformHeaderItem from 'Containers/ScanPlatformHeaderItem'
import { mergePlatformAndClientPlatofrmTypes } from '../../utils'

class PlatformHeader extends React.PureComponent {
  render() {
    const { platformsWithFlags, paddingWidth, client, platformWidth } = this.props

    const gridCellWidth = parseInt(cssVariables.gridCellWidth)
    return (
      <ContainerDimensions>
        {({ width }) => {
          const headerWidth = width - parseInt(paddingWidth)

          const ghostCells = Math.trunc((headerWidth - platformWidth) / gridCellWidth)

          return (
            <div className={styles.platformHeader}>
              {platformsWithFlags.map(({ platform, showFlag }) => {
                const platformTypes = mergePlatformAndClientPlatofrmTypes(client, platform)
                const platformTypeExists = Object.keys(platformTypes).some(element => platformTypes[element])
                return (
                  platformTypeExists &&
                  platform && (
                    <PlatformHeaderItem
                      platformId={platform.id}
                      key={platform.id}
                      showFlag={showFlag}
                      platform={platform}
                      client={client}
                    />
                  )
                )
              })}

              {Array(Math.max(0, ghostCells))
                .fill(0)
                .map((_id, index) => (
                  <PlatformHeaderItem key={`ghost-${index}`} ghost />
                ))}
            </div>
          )
        }}
      </ContainerDimensions>
    )
  }
}

export default PlatformHeader
