import TerritoryRecord from 'Records/territoryRecord'
import { Map, OrderedMap } from 'immutable'

import {
  TOGGLE_TERRITORY,
  SELECT_TERRITORY,
  SELECT_ALL_TERRITORIES,
  DESELECT_ALL_TERRITORIES,
  SELECT_TERRITORIES,
  DESELECT_TERRITORY,
} from 'Reducers/app/territoryReducer'
import { TOGGLE_PLATFORMS } from 'Reducers/app/platformReducer'
import { reloadScan } from 'Actions/scanActions'
import { reloadTitles } from 'Actions/titleActions'
import { territorySorter } from '../components/utils'
import { readEndpoint } from 'Actions/apiActions'
import queryString from 'query-string'
import { getPlatforms, getSelectedTerritories, getSelectedPlatforms } from 'Selectors'
import { deselectAllPlatforms } from 'Actions/platformActions'

export const loadTerritories = territories => ({
  type: 'LOAD_TERRITORIES',
  territories,
})

export const territoryRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new TerritoryRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: new Map(item.relationships),
    }),
  )
}

export const retrieveTerritories = clientId => async dispatch => {
  const response = await readEndpoint(`clients/${clientId}/territories`)
  dispatch(normalizeAndLoadTerritories(response.territories))
}

export const normalizeAndLoadTerritories = data => dispatch => {
  const { territoryIds } = queryString.parse(window.location.search)
  let territoryFromUrlExists = false
  const territories = data
    .reduce((memo, item) => {
      if (territoryIds !== undefined) {
        const selectedTerritoriesIds = territoryIds.split(',')

        item.selected = selectedTerritoriesIds.includes(item.id.toString())
        if (!territoryFromUrlExists && item.selected) territoryFromUrlExists = true
      }
      return memo.set(item.id, new TerritoryRecord({ ...item }))
    }, new OrderedMap())
    .sort(territorySorter)

  dispatch(loadTerritories(territories))

  territories.first() && !territoryFromUrlExists && dispatch(selectTerritory(territories.first()))
}

const selectTerritory = territory => dispatch => {
  dispatch({
    type: SELECT_TERRITORY,
    territoryId: territory.id,
  })
}

export const selectPlatformsForSelectedTerritories = () => (dispatch, getState) => {
  const state = getState()
  const platforms = getPlatforms(state)
  const selectedTerritories = getSelectedTerritories(state)
    .map(t => t.id)
    .toArray()

  const territoryPlatforms = platforms.filter(platform => selectedTerritories.includes(platform.territoryId))

  dispatch(deselectAllPlatforms())

  dispatch({
    type: TOGGLE_PLATFORMS,
    platformIds: territoryPlatforms.map(platform => platform.id),
  })
}

export const selectTerritoriesForSelectedPlatforms = () => (dispatch, getState) => {
  const state = getState()
  const selectedTerritoryIds = [
    ...getSelectedPlatforms(state)
      .map(p => p.territoryId)
      .toArray(),
  ]

  dispatch(deselectAllTerritories())

  dispatch({
    type: SELECT_TERRITORIES,
    territoryIds: selectedTerritoryIds,
  })
}

export const selectAllTerritories = (competitors, isOnScansPage, isOnMerchPage) => dispatch => {
  dispatch({
    type: SELECT_ALL_TERRITORIES,
  })

  dispatch(reloadData(competitors, isOnScansPage, isOnMerchPage))
}

export const deselectAllTerritories = () => dispatch => {
  dispatch({
    type: DESELECT_ALL_TERRITORIES,
  })
}

export const toggleTerritory = (territory, competitors, isOnScansPage, isOnMerchPage) => dispatch => {
  dispatch({
    type: TOGGLE_TERRITORY,
    territoryId: territory.id,
    isOnScansPage,
  })

  dispatch(reloadData(competitors, isOnScansPage, isOnMerchPage))
}

export const selectTerritories = (territoryIds, competitors, isOnScansPage, isOnMerchPage) => dispatch => {
  dispatch({
    type: SELECT_TERRITORIES,
    territoryIds,
  })

  dispatch(reloadData(competitors, isOnScansPage, isOnMerchPage))
}

export const deselectTerritory = territory => dispatch => {
  dispatch({
    type: DESELECT_TERRITORY,
    territoryId: territory.id,
  })
}

const reloadData = (competitors, isOnScansPage, isOnMerchPage) => dispatch => {
  if (isOnMerchPage) return
  if (isOnScansPage) return dispatch(reloadScan(competitors))

  dispatch(reloadTitles())
}
