import { Record, Map } from 'immutable'
import {
  getTitleAudits,
  getTitles,
  getPlatforms,
  getGetMerchandizingSpotsById,
  getMerchandizingSections,
} from 'Selectors/index'

export default class MatchRecord extends Record({
  id: 0,
  selected: false,
  position: 0,
  mpv: 0,
  totalSpots: 0,
  name: '',
  breadcrumbs: '',
  highlighted: false,
  linkToMerchPage: '',
  platformId: 0,
  sectionId: '',
  pageDepth: 0,
  pageId: 0,
  spotId: 0,
  titleId: 0,
  titleAuditId: 0,
  sectionPosition: 0,
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {
  titleAudit(state) {
    const titleAudits = getTitleAudits(state)

    return titleAudits.get(this.titleAuditId)
  }

  title(state) {
    const titles = getTitles(state)

    return titles.get(this.titleId)
  }

  platform(state) {
    const platforms = getPlatforms(state)

    return platforms.get(this.platformId)
  }

  artwork(state) {
    const platform = this.platform(state)
    const titleAudit = this.titleAudit(state)
    const audit = platform && titleAudit && titleAudit.getAuditForPlatform(state, platform.id)
    const keyArtwork = audit && audit.keyArtwork(state)
    return keyArtwork
  }

  spot(state) {
    return getGetMerchandizingSpotsById(state, { id: this.spotId })
  }

  section(state) {
    const sections = getMerchandizingSections(state)

    return sections.get(this.sectionId)
  }
}
