import React from 'react'
import style from './SliderDetails.module.scss'
import { SpotItems } from 'Interfaces/interfaces'
import { MegogoSectionTypes } from '../enums'
import { Icon } from '@blueprintjs/core'
import cx from 'classnames'

type SliderDetailsProps = {
  spot: SpotItems['spot']
  sectionType: string
}

const SliderDetails: React.FC<SliderDetailsProps> = ({ sectionType, spot }) => {
  switch (sectionType) {
    case MegogoSectionTypes._HeroCarousel:
      return (
        <span className={style.heroCarouselDetails}>
          <span className={style.descriptions}>
            <h2>{spot.name}</h2>
            <span className={style.logoWrapper}>
              <img src={spot.metadata?.coverUrl} />
              <span className={style.textWrapper}>
                {spot.description && <span className={style.desc}>{spot.description}</span>}
                <span className={style.label}>{spot.label}</span>
              </span>
            </span>
          </span>
          {spot?.metadata?.subtitle && <span className={style.heroSubtitle}>{spot?.metadata?.subtitle}</span>}
        </span>
      )
    case MegogoSectionTypes._PageContainer:
      return (
        <span className={style.containerDetail}>
          <span>{spot.name}</span>
          <span>{spot.metadata?.currentRating}</span>
          <span>{spot.genre}</span>
          <span>
            <Icon icon="play" />
            {spot.label}
          </span>
        </span>
      )
    case MegogoSectionTypes._LandscapeContainer:
    case MegogoSectionTypes._Container:
      return (
        <span className={style.landscapeDetails}>
          {spot.metadata?.availability && (
            <span
              className={cx({
                [style.availability]: spot.metadata?.variant !== 'soon',
                [style.soon]: spot.metadata?.variant === 'soon',
              })}
            >
              {spot.metadata?.availability}
            </span>
          )}
          <img src={spot.metadata?.coverUrl} />
        </span>
      )
    case MegogoSectionTypes._LandscapeGrid:
      return <span className={style.landscapeGridDetails}>{spot.name}</span>
    default:
      return null
  }
}

export default SliderDetails
