import React from 'react'
import style from 'Components/Search/style.module.scss'

interface SuggestionProps {
  suggestion: { id: string; name: string }
  onClick: (_suggestion: { id: string; name: string }) => void
}

const Suggestion: React.FC<SuggestionProps> = ({ suggestion, onClick }) => {
  const handleOnClick = () => {
    onClick(suggestion)
  }

  return (
    <div className={style.suggestionContent} onClick={handleOnClick}>
      <div className={style.suggestionContentInfo}>
        <h6>{suggestion.name}</h6>
      </div>
    </div>
  )
}

export default Suggestion
