import React, { useMemo } from 'react'
import style from './NavBar.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { linkToPage } from '../utils'
import { Spot, Navigation, Section, Scan } from 'Interfaces/interfaces'
import cx from 'classnames'
const logo = require('./logo.png')

type NavBarProps = RouteComponentProps &
  Navigation & {
    subSections: Section[]
    scan: Scan
  }

const NavBar: React.FC<NavBarProps> = ({ spots, page, match }) => {
  const renderSpots = useMemo(
    () =>
      spots.map((spot: Spot) => (
        <a
          key={spot.id}
          href={linkToPage(spot, match)}
          className={cx(style.listItem, {
            [style.activeNav]: spot.linkedPageId === page.id,
          })}
        >
          <span>{spot.name}</span>
        </a>
      )),
    [spots, match, page],
  )

  return (
    <div className={style.root}>
      <a className={style.logo}>
        <img alt="Viaplay" data-selenium="site-logo" src={logo} />
      </a>
      <div className={style.menuLinks}>{renderSpots}</div>
    </div>
  )
}

export default withRouter(NavBar)
