import React from 'react'
import style from './Banner.module.scss'
import ContainerDimensions from 'react-container-dimensions'
import Slider from 'react-slick'
import cx from 'classnames'
import cssVariables from './variables.scss'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

class GooglePlayBanner extends React.Component {
  render() {
    const { section } = this.props

    const sliderSettings = {
      dots: false,
      infinite: false,
      centerMode: true,
      centerPadding: 0,
      slidesToScroll: 1,
      slidesToShow: 1,
      variableWidth: false,
      arrows: true,
      swipe: false,
      nextArrow: <Arrow right />,
      prevArrow: <Arrow left />,
      appendDots: dots => {
        const customDots = React.Children.map(dots, dot => {
          if (dot.props['className'] === 'slick-active') {
            return React.cloneElement(dot, {
              className: style.slickActive,
            })
          } else {
            return React.cloneElement(dot, {
              className: style.slickNormal,
            })
          }
        })
        return (
          <div style={{ bottom: '10px' }}>
            <ul style={{ paddingLeft: '7px' }}>{customDots}</ul>
          </div>
        )
      },
      customPaging: i => (
        <div
          style={{
            display: 'block',
            textIndent: '-9999px',
            backgroundColor: '#fff',
            width: '13px',
            height: '13px',
            borderRadius: '50%',
            boxShadow: '1px 1px 4px rgba(0,0,0,.4)',
            overflow: 'hidden',
          }}
        >
          {i + 1}
        </div>
      ),
    }

    const classNames = cx(style.container, style.inner, {
      [style.banner]: section.sectionType.includes('google_play::banner'),
    })

    return (
      <div className={classNames}>
        <ContainerDimensions>
          {({ width }) => {
            const cards = Math.min(Math.floor(width / parseInt(cssVariables.cardWidthWithPadding)))
            const headerStyle = {
              width: `calc(${cssVariables.cardWidthWithPadding}*${cards})`,
            }
            return (
              <div style={headerStyle}>
                <Slider {...sliderSettings} className={style.alignSpotsLeft}>
                  {this.renderSpots()}
                </Slider>
              </div>
            )
          }}
        </ContainerDimensions>
      </div>
    )
  }

  renderSpots() {
    const { section } = this.props

    const classNames = cx(style.item, {
      [style.bannerItem]: section.sectionType.includes('google_play::banner'),
    })

    return this.spots().map(
      spot =>
        spot.metadata && (
          <div className={classNames} key={spot.id}>
            <div
              className={style.backgroundColorProperties}
              style={{
                backgroundColor: `${spot.metadata.background_color}`,
              }}
            >
              <div className={style.backgroundImagePosition}>
                <img className={style.backgroundImage} src={spot.artworkUrl} />
                <div
                  className={style.backgroundGradientProperties}
                  style={{
                    backgroundImage: `linear-gradient(to right, ${spot.metadata.background_color}, transparent)`,
                  }}
                />
              </div>
            </div>
          </div>
        ),
    )
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  spots() {
    const { spots, section } = this.props

    return section.sectionType.includes('google_play::banner')
      ? spots.filter(spot => spot && spot.spotType === 'content')
      : spots
  }
}

export default GooglePlayBanner
