import React from 'react'
import { useRef } from 'react'
import styles from './Tag.module.scss'

interface Props {
  inputProps: {
    value: string
    onChange: (e) => void
    onKeyDown: (e) => void
    autoFocus: boolean
  }
}

const Input = ({ inputProps }: Props) => {
  const inputRef = useRef(null)

  return (
    <input
      {...inputProps}
      ref={inputRef}
      className={styles.tagInput}
      autoFocus
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        inputRef.current.focus()
      }}
    />
  )
}

export default Input
