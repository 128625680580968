import React from 'react'
import cx from 'classnames'
import styles from './styles.module.scss'

const PriceHeader = ({ fade, client }) => {
  const classnames = cx(styles.priceHeader, {
    [styles.fadeHeader]: fade,
  })
  return (
    <div className={classnames}>
      {client && client.variants['4k'] && (
        <div className={styles.priceRow}>
          <div>4K</div>
        </div>
      )}
      {client && client.variants['hd'] && (
        <div className={styles.priceRow}>
          <div>HD</div>
        </div>
      )}
      {client && client.variants['sd'] && (
        <div className={styles.priceRow}>
          <div>SD</div>
        </div>
      )}
    </div>
  )
}

export default PriceHeader
