import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import styles from 'Components/ScanPage/PlatformHeader/styles.module.scss'
import { getPlatformById, getTerritoryById, getClient } from 'Selectors'
import { Tooltip } from '@blueprintjs/core'
import HeaderColumn from '../components/ScanPage/PlatformHeader/HeaderColumn'
import HeaderTvod from '../components/ScanPage/PlatformHeader/HeaderTvod'
import { mergePlatformAndClientPlatofrmTypes } from 'components/utils.js'

const PlatformHeaderItem = ({ platform, territory, showFlag, ghost, client }) => {
  const isMoviesAnywhere = platform?.name === 'Movies Anywhere'
  const platformTypes = mergePlatformAndClientPlatofrmTypes(client, platform)
  const platformTypeExists = Object.keys(platformTypes).some(element => platformTypes[element])

  const classnames = cx(styles.platformItem, {
    [styles.withFlag]: showFlag,
  })

  const tvodColumnWidth = cx({
    [styles.halfTvodColumn]: platform?.purchaseOptions?.length < 2,
    [styles.tvodColumn]: platform?.purchaseOptions?.length >= 2,
  })

  if (ghost) {
    return <div className={styles.headerGhostRow} />
  }

  return (
    platform && (
      <div className={classnames}>
        {territory && showFlag && (
          <div className={styles.flag} style={{ backgroundImage: `url(${territory.flagUrl})` }} />
        )}
        <Tooltip content={`${platform && platform.name}, ${territory && territory.name}`}>
          <div
            className={styles.platformLogo}
            style={{
              backgroundImage: `url(${platform.logoUrl})`,
            }}
          />
        </Tooltip>
        {platformTypeExists && (
          <table className={styles.platformItemTable}>
            <tbody>
              <tr>
                {platformTypes?.tvod && (
                  <td className={tvodColumnWidth}>
                    <HeaderTvod purchaseOptions={platform.purchaseOptions} />
                  </td>
                )}
                {platformTypes?.svod && (
                  <td className={styles.svodColumn}>
                    <HeaderColumn type={'svod'} />
                  </td>
                )}
                {platformTypes?.avod && (
                  <td className={styles.avodColumn}>
                    <HeaderColumn isAvail={!!isMoviesAnywhere} type={'avod'} />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        )}
      </div>
    )
  )
}

const mapStateToProps = (state, ownProps) => {
  const client = getClient(state)
  const platform = getPlatformById(state, { id: ownProps.platformId })
  const territory = platform && getTerritoryById(state, { id: platform.territoryId })
  return {
    platform,
    territory,
    client,
  }
}

export default connect(mapStateToProps)(PlatformHeaderItem)
