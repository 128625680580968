import { AUDIT_ARTWORKS } from './utils'

export default function auditArtworkReducer(state, action) {
  switch (action.type) {
    case 'LOAD_AUDIT_ARTWORKS': {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([AUDIT_ARTWORKS], action.auditArtworks)
          : s.mergeIn([AUDIT_ARTWORKS], action.auditArtworks)
      })
    }
    default:
      return state
  }
}
