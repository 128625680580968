import React from 'react'
import cx from 'classnames'
import styles from './Tag.module.scss'

export enum ConfirmOptions {
  CurrentRegion = 'current',
  AllRegions = 'all',
  SelectRegion = 'select',
}

export const ConfirmOptionsLabels = [
  { label: 'Apply To Current Region', value: ConfirmOptions.CurrentRegion },
  { label: 'Apply To All Regions', value: ConfirmOptions.AllRegions },
  { label: 'Select Region', value: ConfirmOptions.SelectRegion },
]

interface ConfirmOptionsListProps {
  onOptionClick: (option: ConfirmOptions) => void
  selectedOption: ConfirmOptions
}

const ConfirmOptionsList = ({ onOptionClick, selectedOption }: ConfirmOptionsListProps) => {
  return (
    <div className={styles.confirmOptions}>
      {ConfirmOptionsLabels.map(({ label, value }) => (
        <label key={value} className={cx(styles.option, 'bp5-control bp5-radio')} onClick={() => onOptionClick(value)}>
          <input
            defaultChecked={selectedOption === value}
            checked={selectedOption === value}
            type="radio"
            value={value}
          />
          <span className="bp5-control-indicator" />
          {label}
        </label>
      ))}
    </div>
  )
}

export default ConfirmOptionsList
