import reduceReducers from 'reduce-reducers'
import clientReducer from './clientReducer'
import titleReducer from './titleReducer'
import platformTitleReducer from './platformTitleReducer'
import scanReducer from './scanReducer'
import auditReducer from './auditReducer'
import titleAuditReducer from './titleAuditReducer'
import auditArtworkReducer from './auditArtworkReducer'
import platformReducer from './platformReducer'
import territoryReducer from './territoryReducer'
import priceReducer from './priceReducer'
import auditPriceReducer from './auditPriceReducer'
import channelReducer from './channelReducer'
import auditActionReducer from './auditActionReducer'
import auditExceptionReducer from './auditExceptionReducer'
import optimizationScoreReducer from './optimizationScoreReducer'
import clientSalesSummaryReducer from './clientSalesSummaryReducer'
import marketShareReducer from './marketShareReducer'
import publisherReducer from './publisherReducer'
import artworkComparisonReducer from './artworkComparisonReducer'
import titleFilterReducer from './titleFilterReducer'
import locationCandidateReducer from './locationCandidateReducer'
import merchandizingScanReducer from './merchandizing/scanReducer'
import merchandizingPageReducer from './merchandizing/pageReducer'
import merchandizingSpotReducer from './merchandizing/spotReducer'
import merchandizingSectionReducer from './merchandizing/sectionReducer'
import merchandizingMatchReducer from './merchandizing/matchReducer'
import associatedMerchandizingScanReducer from './associatedMerchandizingScanReducer'
import titlesForCorrectionReducer from './titlesForCorrectionReducer'
import tagReducer from './tagReducer'
import studioReducer from './studioReducer'
import merchandizingPlatformTitleReducer from './merchandizing/platformTitleReducer'
import tableauReducer from './tableauReducer'

import { enableBatching } from 'redux-batched-actions'
import taggingReducer from './taggingReducer'
import scanDownloadReducer from './scanDownloadReducer'
import merchandizingScanDownloadReducer from './merchandizing/scanDownloadReducer'

export default enableBatching(
  reduceReducers(
    clientReducer,
    titleReducer,
    scanReducer,
    auditReducer,
    auditArtworkReducer,
    titleAuditReducer,
    platformReducer,
    territoryReducer,
    priceReducer,
    auditPriceReducer,
    channelReducer,
    auditActionReducer,
    auditExceptionReducer,
    platformTitleReducer,
    optimizationScoreReducer,
    clientSalesSummaryReducer,
    marketShareReducer,
    publisherReducer,
    artworkComparisonReducer,
    titleFilterReducer,
    locationCandidateReducer,
    merchandizingScanReducer,
    merchandizingPageReducer,
    merchandizingSpotReducer,
    merchandizingSectionReducer,
    merchandizingMatchReducer,
    associatedMerchandizingScanReducer,
    titlesForCorrectionReducer,
    tagReducer,
    studioReducer,
    merchandizingPlatformTitleReducer,
    tableauReducer,
    taggingReducer,
    scanDownloadReducer,
    merchandizingScanDownloadReducer,
  ),
)
