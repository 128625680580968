import React from 'react'
import { connect } from 'react-redux'
import { getTitleAuditById, getPlatformById, getScanFilters } from 'Selectors'
import styles from 'Components/ArtworkPage/AuditArtwork.module.scss'
import cx from 'classnames'
import { List } from 'immutable'
import VisibilitySensor from 'react-visibility-sensor'
import { Popover, PopoverInteractionKind } from '@blueprintjs/core'
import ArtworkPopover from 'Components/ArtworkPage/ArtworkPopover'
import { isValidPlatformTitle } from '../components/utils'
import { Platform, TitleAudit, Artwork, Audit, Title, PlatformTitle, Filter } from 'Interfaces/interfaces'

interface Props {
  titleAuditId: string
  platformId: number
  platform: Platform
  titleAudit: TitleAudit
  audit: Audit
  artworks: List<Artwork>
  title: Title
  platformTitle: PlatformTitle
  keyArtwork: Artwork
  filter: Filter
  artworkComparison: any
  previousArtwork: any
}

interface State {
  loaded: boolean
  visible: boolean
}
class AuditArtwork extends React.Component<Props, State> {
  img: HTMLImageElement | null = null

  constructor(props: Props) {
    super(props)
    this.state = { loaded: false, visible: false }
    this.state = {
      loaded: false,
      visible: false,
    }
  }
  keyArtwork = (): Artwork | undefined => {
    const { artworks } = this.props
    return artworks.filter((a: Artwork | undefined) => a && a.isKey()).first()
  }

  onLoad = (): void => {
    this.setState({ loaded: true })
  }

  onVisible = (isVisible: boolean): void => {
    if (isVisible) {
      this.setState({ visible: true }, () => {
        this.loadImage()
      })
    }
  }

  loadImage = (): void => {
    const { loaded, visible } = this.state
    const keyArtwork = this.keyArtwork()
    const url = keyArtwork?.url || ''

    if (keyArtwork && visible && !loaded && this.img === null) {
      this.img = new Image()
      this.img.onload = this.onLoad
      this.img.src = url
    }
  }

  componentWillUnmount(): void {
    if (this.img) {
      this.img.onload = function () { }
    }
  }

  renderTooltip = (overlayStyle: React.CSSProperties, distance: string): JSX.Element => {
    const { platformTitle } = this.props

    return isValidPlatformTitle(platformTitle) ? (
      <div className={styles.overlay} style={overlayStyle}>
        {distance}
      </div>
    ) : (
      <div>Not Available</div>
    )
  }

  render(): JSX.Element {
    const { loaded, visible } = this.state
    const { title, platformTitle, artworkComparison, filter, previousArtwork } = this.props

    const keyArtwork = this.keyArtwork()
    const url = keyArtwork?.url || ''
    const changeDetected = artworkComparison && artworkComparison.changeDetected
    const unavailable = !platformTitle || !isValidPlatformTitle(platformTitle)
    const posterStyle = {
      backgroundImage: `url('${url}')`,
    }

    this.loadImage()

    const sizingClasses = {
      [styles.moviePoster]: title && title.titleTypeCode === 'movie',
      [styles.tvPoster]: title && title.titleTypeCode === 'tv_season',
    }

    const posterClassnames = cx(sizingClasses, styles.posterImage, {
      [styles.loaded]: keyArtwork && loaded,
      [styles.changed]: changeDetected && loaded,
      [styles.notChanged]: filter?.changedArtwork && !changeDetected,
    })

    return (
      <VisibilitySensor onChange={this.onVisible} partialVisibility>
        <div className={styles.auditArtwork}>

          <Popover
            interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
            popoverClassName="bp5-dark"
            content={<>{platformTitle && (
              <ArtworkPopover
                title={title}
                keyArtwork={keyArtwork}
                artworkComparison={artworkComparison}
                platformTitle={platformTitle}
                previousArtwork={previousArtwork}
              />
            )}</>}
          >
            <div className={cx(sizingClasses, styles.inner)}>
              {keyArtwork && visible && <div className={posterClassnames} style={posterStyle} />}

              {changeDetected && <div className={styles.changeIndicator}>NEW</div>}
              {unavailable && <div className={styles.changeIndicator}>Unavailable</div>}
            </div>
          </Popover>
        </div>
      </VisibilitySensor>
    )
  }
}

const mapStateToProps = (state: any, ownProps: Props) => {
  const platform = getPlatformById(state, { id: ownProps.platformId })
  const titleAudit = getTitleAuditById(state, { id: ownProps.titleAuditId })
  const audit = titleAudit && titleAudit.getAuditForPlatform(state, platform.id)
  const artworks = (audit && audit.artworks(state)) || List<Artwork>()
  const keyArtwork = audit && audit.keyArtwork(state)
  const artworkComparison = keyArtwork && keyArtwork.artworkComparison(state)
  const title = titleAudit && titleAudit.getTitle(state)
  const platformTitle = audit && audit.platformTitle(state)
  const filter = getScanFilters(state)
  const previousArtwork = artworkComparison && artworkComparison.previousArtwork(state)

  return {
    platform,
    titleAudit,
    audit,
    artworks,
    title,
    platformTitle,
    keyArtwork,
    artworkComparison,
    filter,
    previousArtwork,
  }
}

export default connect(mapStateToProps)(AuditArtwork)
