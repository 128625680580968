import React from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import styles from 'Components/ContainerPage/PlatformHeader/styles.module.scss'
import { getPlatformById, getTerritoryById, arePlatformTitlesUpdating } from 'Selectors'

import { Popover, Icon, PopoverInteractionKind, Position, Menu, MenuItem, Tooltip } from '@blueprintjs/core'

const PlatformHeaderItem = ({
  platform,
  territory,
  showFlag,
  ghost,
  markAllAsVirtualPlatformTitles,
  arePlatformTitlesUpdating,
}) => {
  const classnames = cx(styles.platformItem, {
    [styles.withFlag]: showFlag,
  })

  if (ghost) {
    return <div className={classnames} />
  }
  return (
    platform && (
      <div className={classnames}>
        {territory && showFlag && (
          <div className={styles.flag} style={{ backgroundImage: `url(${territory.flagUrl})` }} />
        )}
        <Tooltip content={`${platform && platform.name}, ${territory && territory.name}`}>
          <div
            className={styles.platformLogo}
            style={{
              backgroundImage: `url(${platform.logoUrl})`,
            }}
          />
        </Tooltip>
        {platform.code === 'set_top_box' && (
          <div className={styles.platformCog}>
            <Popover
              interactionKind={PopoverInteractionKind.CLICK}
              position={Position.BOTTOM}
              content={<Menu>
                <MenuItem
                  icon="flag"
                  text="Mark all as virtual"
                  onClick={() => markAllAsVirtualPlatformTitles(platform.id)}
                />
              </Menu>}>
              <Icon icon={cx('cog', { [styles.spinCog]: arePlatformTitlesUpdating })} />
            </Popover>
          </div>
        )}
      </div>
    )
  )
}

const mapStateToProps = (state, ownProps) => {
  const platform = getPlatformById(state, { id: ownProps.platformId })
  const territory = platform && getTerritoryById(state, { id: platform.territoryId })
  const updatingPlatformTitles = arePlatformTitlesUpdating(state)
  return {
    platform,
    territory,
    arePlatformTitlesUpdating: updatingPlatformTitles,
  }
}

export default connect(mapStateToProps)(PlatformHeaderItem)
