import React from 'react'
import styles from 'Components/ContainerPage/LocationCandidate.module.scss'
import cx from 'classnames'
import { Button, Icon, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import NewCandidateBadge from 'Components/ContainerPage/NewCandidateBadge'
import { connect } from 'react-redux'
import { approvePlatformTitle } from 'Actions/platformTitleActions'

class LocationCandidate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      performingAction: false,
    }
  }
  handleApproveClick = () => {
    const { approvePlatformTitle, closeParent } = this.props

    this.setState({ performingAction: true }, () => approvePlatformTitle().then(() => closeParent()))
  }

  render() {
    const { locationCandidate, platformTitle } = this.props

    const { performingAction } = this.state

    const { artworkUrl } = locationCandidate

    const imageClassNames = cx(styles.img, {
      [styles.selected]: locationCandidate.selected,
    })

    const classNames = cx(styles.root, {
      [styles.selected]: locationCandidate.selected,
    })

    return (
      <div className={classNames}>
        <Popover
          interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
          popoverClassName="bp5-dark bp5-popover-content-sizing"
          position={Position.BOTTOM}
        >
          <div className={styles.imgContainer}>
            <React.Fragment>
              <img className={imageClassNames} src={artworkUrl} />
              {/* we’re not showing new badge on first locating attempt. platform title has new candidates only if locator finds new candidate after first locating attempt  */}
              {!locationCandidate.expired && platformTitle.hasNewCandidates && (
                <NewCandidateBadge badgeForModalPage={true} />
              )}
            </React.Fragment>

            <Button
              className={cx(styles.button, 'bp5-intent-success')}
              onClick={this.handleApproveClick}
              disabled={performingAction}
            >
              Approve
            </Button>
          </div>
          <div>{locationCandidate.title}</div>
        </Popover>
        <a href={locationCandidate.url} className={styles.link} target="_blank" rel="noreferrer">
          <Icon icon="link" />
          <span className={styles.linkInner}>{locationCandidate.title}</span>
        </a>
      </div>
    )
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const { platformTitle, locationCandidate } = ownProps

  return {
    approvePlatformTitle: () => dispatch(approvePlatformTitle(platformTitle.id, locationCandidate.id)),
  }
}
export default connect(null, mapDispatchToProps)(LocationCandidate)
