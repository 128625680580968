import React from 'react'
import { OrangeSectionTypes } from '../enums'
import style from './Container.module.scss'

const BackgroundImage: React.FC<{ sectionType: OrangeSectionTypes; backgroundImage: string }> = ({
  sectionType,
  backgroundImage,
}) => {
  switch (sectionType) {
    case OrangeSectionTypes._HeroCarousel:
    case OrangeSectionTypes._ChartContainer:
      return (
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            filter: 'blur(15px)',
          }}
          className={style.sectionBackgroundImage}
        />
      )
    default:
      return null
  }
}

export default BackgroundImage
