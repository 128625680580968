import React from 'react'
import style from './CineplexHeaderCarousel.module.scss'
import Slider from 'react-slick'
import Item from './Item'
import cx from 'classnames'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

class CineplexHeaderCarousel extends React.Component {
  render() {
    const sliderSettings = {
      dots: true,
      infinite: false,
      centerMode: true,
      centerPadding: 0,
      slidesToScroll: 1,
      slidesToShow: 1,
      variableWidth: false,
      arrows: true,
      swipe: false,
      nextArrow: <Arrow right />,
      prevArrow: <Arrow left />,
      appendDots: dots => {
        const customDots = React.Children.map(dots, dot => {
          if (dot.props['className'] === 'slick-active') {
            return React.cloneElement(dot, {
              className: style.slickActive,
            })
          } else {
            return React.cloneElement(dot, {
              className: style.slickNormal,
            })
          }
        })
        return (
          <div style={{ bottom: '10px' }}>
            <ul style={{ paddingLeft: '7px' }}>{customDots}</ul>
          </div>
        )
      },
      customPaging: i => (
        <div
          style={{
            display: 'block',
            textIndent: '-9999px',
            backgroundColor: '#fff',
            width: '13px',
            height: '13px',
            boxShadow: '1px 1px 4px rgba(0,0,0,.4)',
            overflow: 'hidden',
          }}
        >
          {i + 1}
        </div>
      ),
    }

    return (
      <div className={style.container}>
        <Slider {...sliderSettings} className={style.alignSpotsLeft}>
          {this.renderSpots()}
        </Slider>
      </div>
    )
  }

  renderSpots() {
    const { section, isMerchandiseFilteringEnabled } = this.props

    return this.spots().map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <Item
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              studio={this.isStudioSelected(spot.id)}
              section={section}
            />
          </div>
        ),
    )
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  spots() {
    const { spots } = this.props

    return spots
  }
}

export default CineplexHeaderCarousel
