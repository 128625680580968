import React from 'react'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import { Card, ProgressBar } from '@blueprintjs/core'
import { getTerritoryById } from 'Selectors'
import styles from 'Components/Overview/TitleScoreCard.module.scss'
import shared from 'Components/Overview/styles.module.scss'

interface Score {
  id: string
  score: number
  previousScore: number
  territoryId: number
  name: string
  artwork: string
}

interface TitleScoreCardProps {
  score: Score
}

const TitleScoreCard: React.FC<TitleScoreCardProps> = ({ score }) => {
  const territory = useSelector(state => score && getTerritoryById(state, { id: score.territoryId }))
  const scoreDifference = score?.previousScore ? Number((score.score - score.previousScore).toFixed(2)) : 0
  return (
    <Card className={styles.brandCard}>
      <div className={styles.left}>
        {score && <img className={styles.brandCardImg} src={score.artwork} style={{ maxWidth: 100 }} />}
      </div>
      <div className={styles.right}>
        <h3 className={styles.brandCardHeader}>{score && score.name} </h3>
        <div>
          <div className={styles.scoreData}>
            {score && (
              <span className={styles.scoreValue}>
                {`${score.score.toFixed(2)} % `}
                <span
                  className={cx({
                    [styles.difference]: scoreDifference === 0,
                    [styles.differenceUp]: scoreDifference > 0,
                    [styles.differenceDown]: scoreDifference < 0,
                  })}
                >{`${scoreDifference > 0 ? '+' : ''}${scoreDifference}`}</span>
              </span>
            )}
            {territory && <img className={styles.flag} src={territory.flagUrl} alt="flag" />}
          </div>
          {score && (
            <ProgressBar
              className={cx('bp5-no-animation bp5-no-stripes', shared.progressBar)}
              value={score.score / 100}
            />
          )}
        </div>
      </div>
    </Card>
  )
}

export default TitleScoreCard
