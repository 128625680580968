import React from 'react'
import cx from 'classnames'
import style from './SpotIcon.module.scss'
import { PRIMARY_LABEL_NAME } from './constants'

const SpotIcon: React.FC<{ icon?: string; coverUrl?: string }> = ({ icon, coverUrl }) => (
  <>
    {icon && (
      <span
        className={cx({
          [style.primaryIcon]: icon.includes(PRIMARY_LABEL_NAME),
          [style.grayIcon]: !icon.includes(PRIMARY_LABEL_NAME),
        })}
      >
        {icon}
      </span>
    )}
    {coverUrl && <img src={coverUrl} className={style.logoLabel} />}
  </>
)

export default SpotIcon
