import { TABLEAU_TOKEN } from './utils'
export const LOAD_TABLEAU_TOKEN = 'tableau/LOAD_TABLEAU_TOKEN'

export default function tableauReducer(state, action) {
  switch (action.type) {
    case LOAD_TABLEAU_TOKEN: {
      return state.withMutations(s => {
        s.set(TABLEAU_TOKEN, action.token)
      })
    }
    default:
      return state
  }
}
