import React from 'react'
import WrappedSection from 'Containers/WrappedSection'
import style from './Page.module.scss'
import PropTypes from 'prop-types'
import SectionDivider from './SectionDivider'

function Page({ page, scan, rootSections }) {
  return (
    <div className={style.page}>
      <div className={style.container}>
        {page && page.name && <h2>{page.name}</h2>}
        {rootSections.valueSeq().map(s => (
          <React.Fragment key={s.id}>
            {s.sectionType !== 'apple_tv::navbar' && <SectionDivider />}
            <WrappedSection type={s.sectionType} key={s.id} scan={scan} page={page} section={s} />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

Page.propTypes = {
  page: PropTypes.object,
  scan: PropTypes.object,
  rootSections: PropTypes.object,
}

export default Page
