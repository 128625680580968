import React from 'react'
import Item from './Item'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import style from './StaticSection.module.scss'
import PropTypes from 'prop-types'
import { linkToPage } from '../utils'

class StaticSection extends React.Component {
  render() {
    const { section, page } = this.props
    const metadata = section.metadata || {}
    const pageMetadata = page.metadata || {}

    const hasBackgroundImage = !!metadata.background_image
    const sectionType = section.sectionType

    const classNames = cx(style.root, {
      [style.xboxRoot]: sectionType.includes('xbox'),
      [style.fandangoRoot]: sectionType.includes('fandango_now'),
      [style.xboxFeatured]: sectionType.includes('xbox::featured'),
      [style.tubiRoot]: sectionType.includes('tubi_tv::carousel'),
      [style.xboxBrowsePage]: !!pageMetadata.is_browse_page,
    })

    const backroungImageStyle = {}

    if (hasBackgroundImage) {
      backroungImageStyle.background = `url(${metadata.background_image}) center center no-repeat`
      backroungImageStyle.backgroundSize = '100% 100%'
      backroungImageStyle.display = 'block'
    }

    const spotListStyles = cx(style.spotList, {
      [style.showAll]: this.isSeeMorePage(),
    })

    return (
      <div className={classNames} style={backroungImageStyle}>
        {this.renderSectionHeader()}
        <div className={spotListStyles}>{this.renderSectionContent()}</div>
        {!this.paginationSpots().isEmpty() && <div className={style.xboxPagination}>{this.renderPagination()}</div>}
      </div>
    )
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  renderSectionHeader = () => {
    const { match } = this.props
    const seeAllSpot = this.seeAllSpot()
    return (
      <div className={style.sectionHeader}>
        <div className={style.seeMoreLink}>
          {seeAllSpot && <a href={linkToPage(seeAllSpot, match)}>{seeAllSpot.name}</a>}
        </div>
      </div>
    )
  }

  renderSectionContent() {
    const { section } = this.props

    return this.spots().map(
      spot =>
        spot && (
          <div key={spot.id} className={style.spotItem}>
            <Item
              key={spot.id}
              spot={spot}
              isMerchandiseFilteringEnabled={this.props.isMerchandiseFilteringEnabled}
              studio={this.isStudioSelected(spot.id)}
              section={section}
              showTitleName={this.showTitleName()}
            />
          </div>
        ),
    )
  }

  renderPagination() {
    const { match } = this.props
    return this.paginationSpots().map(
      spot =>
        spot && (
          <a key={spot.id} href={linkToPage(spot, match)}>
            {spot.name}
          </a>
        ),
    )
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'content')
  }

  links() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'link')
  }

  paginationSpots() {
    return this.links().filter(spot => spot && ['Next', 'Previous'].includes(spot.name))
  }

  seeAllSpot() {
    return this.links().find(spot => spot && ['See All', 'Explore All', 'View All'].includes(spot.name))
  }

  showTitleName() {
    const { section } = this.props

    return [
      'xbox::home_page',
      'xbox::top',
      'xbox::featured',
      'xfinity::saved_favorites',
      'xfinity::saved_movies',
      'xfinity::saved_tv',
      'tubi_tv::carousel',
    ].includes(section.sectionType)
  }

  isSeeMorePage() {
    const { section } = this.props
    return [
      'xbox::home_page',
      'xbox::top',
      'fandango_now::defaultList',
      'xfinity::saved_favorites',
      'xfinity::saved_movies',
      'xfinity::saved_tv',
      'tubi_tv::carousel',
    ].includes(section.sectionType)
  }
}

StaticSection.propTypes = {
  spots: PropTypes.object,
  match: PropTypes.object,
  page: PropTypes.object,
  section: PropTypes.object,
  selectedStudios: PropTypes.object,
  isMerchandiseFilteringEnabled: PropTypes.bool,
}

export default withRouter(StaticSection)
