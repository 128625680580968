import React from 'react'
import cx from 'classnames'
import { Card, ProgressBar } from '@blueprintjs/core'
import { List } from 'immutable'
import BigNumber from 'Components/BigNumber'

import styles from './BrandScoreCard.module.scss'
import shared from './styles.module.scss'

class BrandScoreCard extends React.Component {
  render() {
    const { score } = this.props
    return (
      <Card className={styles.brandCard}>
        <div className={styles.left}>
          <h5 className={styles.brandCardHeader}>{score && score.brandName}</h5>
          <div>
            {score && <BigNumber number={parseFloat(score.score)} units="% optimized" />}
            {score && (
              <ProgressBar
                className={cx('bp5-no-animation bp5-no-stripes', shared.progressBar)}
                value={score.score / 100}
              />
            )}
            {score && score.titlesCount !== 1 && `${score.titlesCount} titles`}
            {score && score.titlesCount === 1 && `${score.titlesCount} title`}
          </div>
        </div>

        <div className={styles.right}>
          {score &&
            new List(score.sampleArtwork).map((url, index) => {
              return <img className={styles.brandCardImg} src={url} style={{ maxWidth: 100 }} key={index} />
            })}
        </div>
      </Card>
    )
  }
}

export default BrandScoreCard
