import React from 'react'
import style from './NavBar.module.scss'
import { Popover, PopoverInteractionKind, Icon, Position } from '@blueprintjs/core'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'

class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  handleInteraction(nextOpenState) {
    this.setState({ isOpen: nextOpenState })
  }

  iconName(spot) {
    if (spot.name === 'Home') {
      return 'home'
    } else if (spot.name === 'TV Shows' || spot.name === 'Serie Tv') {
      return 'film'
    } else {
      return 'video'
    }
  }

  render() {
    const { spots, page, match } = this.props
    const buttonIcon = this.state.isOpen ? 'cross' : 'menu'

    const navbarButtonClasses = cx(style.navbarButton, {
      [style.navbarButtonOppened]: this.state.isOpen,
    })

    const navbarClasses = cx(style.menu, {
      [style.rootPageNavbar]: page && page.isRoot,
    })

    const popoverClasses =
      'bp5-popover-content-sizing bp5-popover-display-none bp5-popover-padding-none bp5-margin-top-none bp5-border-radius-none'

    return (
      <div className={navbarClasses}>
        <Popover
          interactionKind={PopoverInteractionKind.CLICK}
          position={Position.BOTTOM_LEFT}
          isOpen={this.state.isOpen}
          onInteraction={state => this.handleInteraction(state)}
          popoverClassName={popoverClasses}
          content={<div className={style.dropdownMenu}>
            <ul>
              {spots.map(
                spot =>
                  spot && (
                    <li key={spot.id}>
                      <a href={linkToPage(spot, match)}>
                        <Icon className={style.menuItemIcon} icon={this.iconName(spot)} />
                        {spot.name}
                      </a>
                    </li>
                  ),
              )}
            </ul>
          </div>}
        >
          <div>
            <div className={navbarButtonClasses}>
              <Icon icon={buttonIcon} />
            </div>
          </div>
        </Popover>
        <div className={style.chiliLogo}></div>
      </div>
    )
  }
}

export default withRouter(NavBar)
