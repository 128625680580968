import { AUDIT_PRICES } from './utils'

export default function auditPriceReducer(state, action) {
  switch (action.type) {
    case 'LOAD_AUDIT_PRICES': {
      return state.withMutations(s => {
        action.reset ? s.setIn([AUDIT_PRICES], action.auditPrices) : s.mergeIn([AUDIT_PRICES], action.auditPrices)
      })
    }
    default:
      return state
  }
}
