import React from 'react'
import style from './Header.module.scss'
import cx from 'classnames'
import { scrollToNode } from '../utils'

class Header extends React.Component {
  artworkUrl() {
    const { spots } = this.props

    return spots.first() && spots.first().metadata.backdropUrl
  }

  pageTitle() {
    const { spots } = this.props

    return spots.first() && (spots.first().label || spots.first().name)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { section } = this.props
    if (newProps.section.selected && !section.selected) scrollToNode(this.node)
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  render() {
    const { page, section, fade, spots } = this.props

    const spot = spots.first()
    const client = this.isStudioSelected(spot && spot.id)

    const headerStyle = {
      background: `black url(${this.artworkUrl()}?width=${window.innerWidth}) center center no-repeat`,
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      opacity: '0.7',
    }

    if (client) {
      headerStyle.borderStyle = 'solid'
      headerStyle.borderColor = client.color
      headerStyle.boxShadow = `0 0 10px ${client.color}`
    }

    const headerClasses = cx(style.header, {
      [style.rootHeader]: page && page.isRoot,
      [style.highlight]: section.selected,
      [style.fade]: fade,
    })

    return (
      <div className={headerClasses}>
        <h1>{this.pageTitle()}</h1>
        <div style={headerStyle} ref={node => (this.node = node)}></div>
      </div>
    )
  }
}

export default Header
