import React, { useEffect, useRef } from 'react'
import style from './Item.module.scss'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { linkToPage } from '../utils'
import cx from 'classnames'
import { RowEightSectionTypes } from '../enums'
import { Section, Spot } from 'Interfaces/interfaces'

interface ItemProps extends RouteComponentProps {
  spot: Spot
  section: Section
}

const Item: React.FC<ItemProps> = ({ spot, section, match }) => {
  const node = useRef<HTMLAnchorElement>(null)
  const { coverUrl } = spot.metadata

  useEffect(() => {
    if (spot.selected && node.current) {
      node.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [spot.selected])

  const classNames = cx(style.item, {
    [style.fade]: section.selected,
    [style.highlight]: spot.selected,
  })

  return (
    <a href={linkToPage(spot, match)} className={classNames} ref={node}>
      {section.sectionType === RowEightSectionTypes._Grid && (
        <div className={style.spotCover}>
          <h3>{spot.name}</h3>
          <p>{spot.description}</p>
          <span className={style.coverButton}></span>
        </div>
      )}
      {coverUrl && section.sectionType === RowEightSectionTypes._HeroCarousel && (
        <img className={style.carouselCoverImg} src={coverUrl} alt={`${spot.name} cover`} />
      )}
      <div
        id={`spot_${spot.id}`}
        style={{
          background: `black url(${spot.artworkUrl}) center center no-repeat`,
          backgroundSize: 'cover',
        }}
      >
        {coverUrl && section.sectionType === RowEightSectionTypes._Grid && (
          <img className={style.girdCoverImg} src={coverUrl} alt={`${spot.name} grid cover`} />
        )}
      </div>
    </a>
  )
}

export default withRouter(Item)
